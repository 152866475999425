import React, { useEffect, useState } from 'react'
import ReactSelect from 'react-select/creatable';
import * as Constants from '../../../data/constants';
import { Button, Tooltip } from '@mui/material';
import { getLocalStorageValue, useForceUpdate } from '../../../helper/util';
import axios from 'axios';
import EditIcon from '@mui/icons-material/Edit';
import AssigneeOptions from './assigneeOptions';
import { NavLink } from 'react-router-dom';

const SubtaskListComponent = (props) => {
    const refreshComponent = useForceUpdate()
    const [newTaskAddLoader, setNewTaskAddLoader] = useState(false)
    const [doubleClickCount, setDoubleClickCount] = useState({})
    const [tempData, setTempData] = useState({})
    const [showAssigneeOptions, setShowAssigneeOptions] = useState(false)
    const oldSubtaskDetails = props.parentData
    var [data,openCard,subtaskNew,setSubtaskNew,subtaskList,setSubtaskList] = [props.data,props.openCard,props.subtaskNew,props.setSubtaskNew,props.subtaskList,props.setSubtaskList]
    
    var assigneeOptions = props.assigneeOptions
    const createSubTaskFunction = async(tempData,setTempData) => {
        setNewTaskAddLoader(true)
		var title = '['+ tempData.subtask_tag + '] ' + tempData.title 
        await axios({
            method: "POST",
            url: Constants.BACKENDHOST+"task_tracker/addTask",
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token,
                'Content-type':'application/json'
            },
            data:{
                "task_type": 'child', 
                "script_name": data[openCard].script_name, 
                "package_name": data[openCard].package_name, 
                "priority":data[openCard].priority,
                "team": data[openCard].team ? data[openCard].team : undefined,
                "tag": data[openCard].tag ? data[openCard].tag : undefined,
				"title":title
            }

        })
        .then(resp => {
            var xx = {
                "task_type": 'child', 
                "script_name": data[openCard].script_name, 
                "package_name": data[openCard].package_name, 
                "priority":data[openCard].priority,
                "team": data[openCard].team ? data[openCard].team : undefined,
                "tag": data[openCard].tag ? data[openCard].tag : undefined,
				"title":tempData.title,
                "subtask_tag":tempData.subtask_tag,
                'id':resp.data.data.task_id
            }
            setSubtaskList([...subtaskList,xx])
        })
        .catch(err=>{
            
        })
        setNewTaskAddLoader(false)
    }

    useEffect(() => {
        document.addEventListener('click',e=>{
            setDoubleClickCount({})
            setShowAssigneeOptions(false);
        })
    
        return () => {
            document.removeEventListener('click',e=>{
                setShowAssigneeOptions(false);
                setDoubleClickCount({})
            })
        }
    }, [])
    
    const changeSubtaskDetailsAPI = (item,index,param_to_be_changed) => {
        var oldDetails = oldSubtaskDetails.sub_task[item.id]
        var old_value = oldDetails[param_to_be_changed]
        if(param_to_be_changed==='subtask_tag'){
            old_value = oldDetails.title
        }
        var new_value = item[param_to_be_changed]
        if(param_to_be_changed==='title' || param_to_be_changed === 'subtask_tag'){
            param_to_be_changed = 'title'
            var new_value = '['+item.subtask_tag+'] '+item.title
        }
        props.updateStintData(item.id,param_to_be_changed,new_value,old_value)
    }

    const subtaskListSubComponent = (item=null,index=null,newTask=false) => {
        const valuesOfTheEvent = (item,index,label_value) => {
            if(index===0 || index){
                if(label_value==='subtask_tag'){
                    return item[label_value] ? {label:item[label_value],value:item[label_value]} : null
                }
                if(label_value==='title'){
                    return item[label_value]
                }
            }
        }
        const changeSubtaskDetails = (value,param_to_be_changed,index=null) => {
            if(index===0 || index){
                var tempList = subtaskList
                tempList[index][param_to_be_changed] = value
                setSubtaskList(tempList)
                refreshComponent(123);
            }
            else{

            }
        }
        return <div>
                    <div style={{display:'flex',alignItems:'center'}}>
                        <div style={{width:'100px',transform:'scale(0.8)',transformOrigin:'0% 0%'}}>
                            <ReactSelect
                                placeholder={'Type'}
                                isClearable={false}
                                components={{
                                    IndicatorSeparator: () => null
                                }}
                                value={valuesOfTheEvent(item,index,'subtask_tag')}
                                options={[{label:'Logs',value:'Logs'},{label:'Scripting',value:'Scripting'}]}
                                onChange={(e)=>{changeSubtaskDetails(e.value,'subtask_tag',index);changeSubtaskDetailsAPI(item,index,'subtask_tag',e.value)}}
                                menuPortalTarget={document.body} 
                                styles={{...Constants.ReactSelectStylesStint}}
                            />
                        </div>
                        <div className='subtaskTextContainer'>
                            {
                                doubleClickCount[index]>=1
                                &&
                                <input 
                                    disabled={doubleClickCount[index]>=1 ? false : true} 
                                    className='subtaskTextField' 
                                    value={valuesOfTheEvent(item,index,'title')} 
                                    onClick={e=>{e.stopPropagation();}}
                                    onKeyDown={(e)=>{if(e.key==='Enter'){changeSubtaskDetailsAPI(item,index,'title',e.target.value);setDoubleClickCount(false)}}}
                                    onChange={(e)=>{changeSubtaskDetails(e.target.value,'title',index)}}
                                />
                            }
                            {
                                !doubleClickCount[index]>=1
                                &&
                                <a href={'/home/dashboard?cardid='+item.id} target='_blank' style={{paddingLeft:'10px',paddingRight:'10px',fontSize:'12px',cursor:'pointer',textDecoration:'none',color:'black'}}>
                                    {valuesOfTheEvent(item,index,'title')}
                                </a>
                            }
                            {!doubleClickCount[index]>=1
                                &&
                            <div className='subtaskTextFieldEdit' onClick={(e)=>{e.stopPropagation();setDoubleClickCount({[index]:2})}}><EditIcon sx={{fontSize:'13px',color:'rgb(100,100,100)'}}/></div>
                            }
                            <Tooltip title={item.assignee ? 'Assigned To: '+ assigneeOptions[item.assignee] : 'Not Assigned'}>
                                <div className='subtaskTextFieldAssignnee' onClick={(e)=>{e.stopPropagation();setShowAssigneeOptions(index)}}>{item.assignee ? assigneeOptions[parseInt(item.assignee)][0] : 'NA'}</div>
                            </Tooltip>
                            {
                                showAssigneeOptions===index
                                &&
                                <div style={{position:'absolute',right:0,top:0,marginTop:'35px',zIndex:10}} onClick={e=>{e.stopPropagation();}}>
                                    <AssigneeOptions updateStintData={props.updateStintData} index={index} subtaskList={subtaskList} setSubtaskList={setSubtaskList} setShowAssigneeOptions={setShowAssigneeOptions} assigneeOptions={assigneeOptions}/>
                                </div>
                            }
                        </div>
                    </div>
                    {   
                        (subtaskNew && newTask)
                        &&
                        <div style={{display:'flex',justifyContent:'flex-end',transform:'scale(0.8)',transformOrigin:'100% 0%'}}>
                            <div><Button sx={{...Constants.CONTAINEDBUTTON,transform:'scale(0.8)'}} onClick={()=>{createSubTaskFunction(tempData,setTempData);setSubtaskNew(false);}}>Save</Button></div>
                            <div><Button color={'warning'} sx={{transform:'scale(0.8)'}} onClick={()=>{setSubtaskNew(false);}}>Cancel</Button></div>
                        </div>
                    }
                </div>
    }
    return (
        <div>
            {
                subtaskList.map((item,index)=>{
                    return <div key={index} id={'subtask_'+index}>
                            {subtaskListSubComponent(item,index)}
                        </div>
                })
            }
            {
                subtaskNew &&
                <div>
                    <div style={{display:'flex',alignItems:'center'}}>
                        <div style={{width:'100px',transform:'scale(0.8)',transformOrigin:'0% 0%'}}>
                            <ReactSelect
                                placeholder={'Type'}
                                isClearable={false}
                                components={{
                                    IndicatorSeparator: () => null
                                }}
                                value={{label:subtaskNew.subtask_tag,value:subtaskNew.subtask_tag}}
                                options={[{label:'Logs',value:'Logs'},{label:'Scripting',value:'Scripting'}]}
                                onChange={(e)=>{setSubtaskNew({...subtaskNew,subtask_tag:e.value})}}
                                menuPortalTarget={document.body} 
                                styles={{...Constants.ReactSelectStylesStint}}
                            />
                        </div>
                        <div className='subtaskTextContainer'>
                            <input 
                                className='subtaskTextField' 
                                onKeyDown={(e)=>{if(e.key==='Enter'){}}}
                                onChange={(e)=>{setSubtaskNew({...subtaskNew,title:e.target.value})}}
                            />
                        </div>
                    </div>
                    <div style={{display:'flex',justifyContent:'flex-end',transform:'scale(0.8)',transformOrigin:'100% 0%'}}>
                        <div><Button sx={{...Constants.CONTAINEDBUTTON,transform:'scale(0.8)'}} onClick={()=>{createSubTaskFunction(subtaskNew,setSubtaskNew);setSubtaskNew(false);}}>Save</Button></div>
                        <div><Button color={'warning'} sx={{transform:'scale(0.8)'}} onClick={()=>{setSubtaskNew(false);}}>Cancel</Button></div>
                    </div>
                </div>
            }
        </div>
    )
}

export default SubtaskListComponent