import React, { useState } from 'react'
import TopbarV2 from '../../../components/topbarv2/topbarv2'
import HistoryComponent from '../../../components/historyComponent/historyComponent';

const AdjApkUpload = () => {
    var title = 'Upload ADJ Apk'
    window.location.title = title;

    if(localStorage.getItem(window.location.pathname+'historyWindowWidth')){
        var tempHistoryWindowWidth = localStorage.getItem(window.location.pathname+'historyWindowWidth')
    }
    else{
        tempHistoryWindowWidth = 300
    }
    const [historyWindowWidth, setHistoryWindowWidth] = useState(tempHistoryWindowWidth)
    const [showAddTab, setShowAddTab] = useState(false)
    const [activeTab, setActiveTab] = useState(false)
    const [historyResponse, setHistoryResponse] = useState({})
    const [historyResponseLoader, setHistoryResponseLoader] = useState(false)
    const [historyPage, setHistoryPage] = useState(1)
    const [historyPages, setHistoryPages] = useState(0)
    const [query, setQuery] = useState('')
    var getHistoryTabData = async(item) => {
        
    }

    const getHistory = async(manualPage,query_=null) => {
        
    };
    return (
        <div>
            <TopbarV2 title={title}/>
            <HistoryComponent
                historyWindowWidth={historyWindowWidth}
                setHistoryWindowWidth={setHistoryWindowWidth}
                openTabs={getHistoryTabData}
                historyResponse={historyResponse}
                setHistoryResponse={setHistoryResponse}
                historyResponseLoader={historyResponseLoader}
                setHistoryResponseLoader={setHistoryResponseLoader}
                getDebugHistory={getHistory}
                historyPage={historyPage}
                setHistoryPage={setHistoryPage}
                historyPages={historyPages}
                historyFor={'Report'}
                query={query}
                setQuery={setQuery}
            />
        </div>
    )
}

export default AdjApkUpload