import React from 'react'
import NotAllowedGif from '../img/notAllowed.gif';
import TopbarV2 from './topbarv2/topbarv2';

const NotAllowedPage = () => {
    return (
        <>
            <TopbarV2/>
            <div style={{height:'calc(100vh - 100px)',display:'flex',alignItems:'center',justifyContent:'center'}}>
                <img src={NotAllowedGif} alt=''/>
            </div>
        </>
    )
}

export default NotAllowedPage