import axios from 'axios'
import TopbarV2 from '../../components/topbarv2/topbarv2'
import './idssimulation.css'
import React, { useState } from 'react'
import TableModel from '../../components/tableModel/tablemodel'
import FilterRow from '../../components/filterV2/filtersRow'
import { BugReport, Description, UpdateDisabledTwoTone } from '@mui/icons-material'
import { TeamIcon } from '../../data/icons'
import { Button, CircularProgress } from '@mui/material'
import { CssTextField } from '../../helper/util'
import ReactSelect from 'react-select'
import * as Constants from '../../data/constants';
import { Store } from 'react-notifications-component'

const IdsSimulation = () => {
    const [manualUseEffect, setManualUseEffect] = useState(false)
    const [appsListData, setAppsListData] = useState([])
    const initFilterData = {
        'status':{label:'Needs Update',value:'Needs Update'},
        'team':{label:'Team 2B',value:'2b'},
    }
    const [filterData, setFilterData] = useState(initFilterData)
    const [dataLoader, setDataLoader] = useState(false)
    const [addNewAppPopup, setAddNewAppPopup] = useState(false)
    const [addNewAppLoader, setAddNewAppLoader] = useState(false)
    const [addNewAppData, setAddNewAppData] = useState({data:[{id:'',timestamp:''},{id:'',timestamp:''}]})
    const [updatePopup, setUpdatePopup] = useState(false)
    const [updatePopupData, setUpdatePopupData] = useState({})

    const filterList = [
        {label:'App Name',value:'campaign_name',type:'textfield',icon:Description},
        {label:'Status',value:'status',type:'select',options:[{label:'Updated',value:'Updated'},{label:'Needs Update',value:'Needs Update'}],icon:BugReport},
        {label:'Team',value:'team',type:'select',options:[{label:'Team 2A',value:'2a'},{label:'Team 2B',value:'2b'}],icon:TeamIcon},
        {label:'Submit Reset Button',type:'submit_reset_button',renameSubmit:'Search',onSubmit:()=>{apiForGettingData()},onReset:()=>{setFilterData(initFilterData)}}
    ]

    const typekeys = {
        'user_id':'User ID',
        'order_id':'Order ID',
        'order_no':'Order No',
    }
    const apiForGettingData = async() => {
        setDataLoader(true)
        await axios({
            method:'get',
            url:'https://team2.appanalytics.in/team2b/apps_for_simulation',
        })
        .then(resp=>{
            setAppsListData(resp.data.data)
        })
        setDataLoader(false)
    }

    const addNewAppApi = async() => {
        setAddNewAppLoader(true)
        await axios({
            method:'put',
            url:'https://team2.appanalytics.in/team2b/apps_for_simulation',
            headers:{
                'Content-Type': "application/json",
            },
            data: {
                "campaign_name": addNewAppData.campaign_name,
                "type": addNewAppData.type,
                "data": addNewAppData.data,
                "description":addNewAppData.description
            }
        })
        .then(resp=>{
            Store.addNotification({
                title: "Success!",
                message: resp.data.message,
                type: "success",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                  duration: 6000,
                  onScreen: true
                }
            });
            setAddNewAppData({data:[{id:'',timestamp:''},{id:'',timestamp:''}]})
            setAddNewAppPopup(false)
            apiForGettingData()
        })
        .catch(err => {
            console.log(err)
        })
        setAddNewAppLoader(false)
    }


    const updateDataApi = async() => {
        setAddNewAppLoader(true)
        console.log(updatePopupData)
        await axios({
            method:'put',
            url:'https://team2.appanalytics.in/team2b/idsimulated',
            headers:{
                'Content-Type': "application/json",
            },
            data: {
                "campaign_name": updatePopup.script_name,
                "type": updatePopup.type,
                "timestamp": new Date(new Date(updatePopupData.timestamp).getTime()).toISOString().replace("T"," ").split(".")[0],
                "id": parseInt(updatePopupData.id),
                "date_added": new Date().toISOString().split('T')[0],
                "constraint":parseFloat(updatePopupData.constraint)
            }
        })
        .then(resp=>{
            Store.addNotification({
                title: "Success!",
                message: 'Id Has been updated successfully.',
                type: "success",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                  duration: 6000,
                  onScreen: true
                }
            });
            setUpdatePopup(false)
            setUpdatePopupData({})
            apiForGettingData()
        })
        .catch(err => {
            console.log(err)
        })
        setAddNewAppLoader(false)
    }
    if(!manualUseEffect){
        apiForGettingData()
        setManualUseEffect(true)
    }

    const timestampToDtComponent = (valueItem,cs,item) => {
        if(valueItem[item.value]){
            return new Date(parseInt(valueItem[item.value]*1000)).toUTCString()
        }
        return '-'
    }

    const statusComponent = (valueItem,cs,item) => {
        var colorDict = {
            'Updated':'green',
            'Needs Update':'orange',
            'No Past Values':'red',
        }
        return <div style={{color:colorDict[valueItem[item.value]]}} onClick={()=>{item.setUpdatePopup(valueItem)}}>
                    {valueItem[item.value]}
                </div>
            
    }
    const typeComponent = (valueItem,cs,item) => {
        return typekeys[valueItem[item.value]] ? typekeys[valueItem[item.value]] : valueItem[item.value]
    }
    
    const tableHeaders = [
        {label:'App Name',value:'script_name'},
        {label:'Type',value:'type',customComponent:typeComponent},
        {label:'I2',value:'i2'},
        {label:'Status',value:'status',customComponent:statusComponent,setUpdatePopup},
        {label:'Last Used ID',value:'last_used_id'},
        {label:'Last Used ID Timestamp',value:'last_used_id_timestamp',customComponent:timestampToDtComponent},
        {label:'Last Updated ID',value:'last_updated_id'},
        {label:'Last Updated ID Timestamp',value:'last_updated_id_timestamp',customComponent:timestampToDtComponent},
    ]

    function idHelperFunction(idHelperData, constantTimestamp = null, constraint = 1) {
        let userIdIncreasePerSecondList = [];
        for (let i = 0; i < idHelperData.length; i++) {
            if (i + 1 !== idHelperData.length) {
                let timestampDiff = idHelperData[i].timestamp - idHelperData[i + 1].timestamp;
                let userIdDiff = idHelperData[i].id - idHelperData[i + 1].id;
    
                let userIdIncreasePerSecond = userIdDiff / timestampDiff;
                userIdIncreasePerSecondList.push(userIdIncreasePerSecond);
            }
        }
    
        let currentTime = constantTimestamp ? constantTimestamp : Date.now() / 1000;
        let lastOrderedItem = idHelperData[0];
        let timeDiff = currentTime - lastOrderedItem.timestamp;
        let avgOrdIdIncrease = (userIdIncreasePerSecondList.reduce((a, b) => a + b, 0) / userIdIncreasePerSecondList.length) * constraint;
        let transId = (timeDiff * avgOrdIdIncrease) + lastOrderedItem.id;
    
        return Math.floor(transId);
    }

    const [predictedData, setPredictedData] = useState('-')
    const updateDifferenceID = (timestamp,constraint) => {
        let data_list = updatePopup.data_list
        setPredictedData(idHelperFunction(data_list,new Date(timestamp ? timestamp : updatePopupData.timestamp).getTime()/1000,parseFloat(constraint ? constraint : updatePopupData.constraint)))
    }
    return (
        <div>
            <TopbarV2 title={'IDs Simulation'}/>
            <div style={{paddingTop:'5px',paddingBottom:'5px'}}>
                <FilterRow
                    filtersList={filterList}
                    filterData={filterData}
                    setFilterData={setFilterData}
                    filterItemWidth='23'
                />
            </div>
            <div style={{marginTop:'20px',display:'flex',alignItems:'center',justifyContent:'flex-end'}}>
                <Button color='warning' onClick={()=>{setAddNewAppPopup(true)}}>
                   + Add New App
                </Button>
            </div>
            {addNewAppPopup
            &&
            <div style={{display:'flex',justifyContent:'center',alignItems:'center',position:'fixed',top:0,left:0,height:'100vh',width:'100vw',background:'rgba(240,240,240,0.2)',backdropFilter:'blur(1px)',zIndex:20}}  onClick={()=>{setAddNewAppPopup(false)}}>
                <div style={{height:'max-content',width:'40vw',background:'white',border:'1px solid rgb(200,200,200)',boxShadow:'0 0 5px 2px rgb(220,220,220)',paddingBottom:'20px'}} onClick={(e)=>{e.stopPropagation()}}>
                    <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',padding:'10px'}}>
                        <div>Add New App For ID Helper</div>
                        <div style={{cursor:'default'}} onClick={()=>{setAddNewAppPopup(false)}}>X</div>
                    </div>
                    <br/>
                    <div className='newappFlexContainer'>
                        <div style={{width:'30%'}}>Script Name</div>
                        <div style={{width:'70%'}}>
                            <CssTextField size='small' sx={{transform:'scale(0.8)',transformOrigin:'left'}} fullWidth
                                onChange={e=>{setAddNewAppData({...addNewAppData,campaign_name:e.target.value})}}
                            />
                        </div>
                    </div>
                    <div className='newappFlexContainer'>
                        <div style={{width:'30%'}}>ID Type</div>
                        <div style={{width:'70%'}}>
                            <div style={{transform:'scale(0.8)',transformOrigin:'left'}}>
                                <ReactSelect                                                
                                    placeholder={'Id Type'}
                                    options={Object.keys(typekeys).map(item=>{return {label:typekeys[item],value:item}})}
                                    onChange={(e)=>{setAddNewAppData({...addNewAppData,type:e.value})}}
                                    menuPortalTarget={document.body} 
                                    styles={{...Constants.ReactSelectStyles}}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='newappFlexContainer'>
                        <div style={{width:'30%'}}>ID Data</div>
                        <div style={{width:'70%'}}>
                            {addNewAppData.data.map((item,index)=>{
                                return <div style={{display:'flex'}} key={index}>
                                        <div>
                                            <CssTextField label={'ID-'+(index+1)} size='small' sx={{transform:'scale(0.8)',transformOrigin:'left'}} fullWidth
                                                        onChange={e=>{
                                                            var tempAddNewAppData = addNewAppData
                                                            tempAddNewAppData.data[index].id = e.target.value
                                                            setAddNewAppData(tempAddNewAppData)
                                                        }}
                                                    />
                                        </div>
                                        <div>
                                            <CssTextField label={'Timestamp-'+(index+1)} size='small' sx={{marginLeft:'-15%',transform:'scale(0.8)',transformOrigin:'left'}} fullWidth
                                                        onChange={e=>{
                                                            var tempAddNewAppData = addNewAppData
                                                            tempAddNewAppData.data[index].timestamp = e.target.value
                                                            setAddNewAppData(tempAddNewAppData)
                                                        }}
                                                    />
                                        </div>
                                    </div>
                            })}
                        </div>
                    </div>
                    <div className='newappFlexContainer'>
                        <div style={{width:'30%'}}>Description</div>
                        <div style={{width:'70%'}}>
                            <CssTextField size='small' sx={{transform:'scale(0.8)',transformOrigin:'left'}} fullWidth
                                placeholder='Where to find ID in logs?'
                                minRows={3}
                                multiline
                                maxRows={3}
                                onChange={e=>{setAddNewAppData({...addNewAppData,description:e.target.value})}}
                            />
                        </div>
                    </div>
                    <br/>
                    <div style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                        <Button disabled={addNewAppLoader || !addNewAppData.data || !addNewAppData.campaign_name || !addNewAppData.type} variant='contained' color='warning' sx={{transform:'scale(0.8)',minHeight:'28px',minWidth:'80px'}} onClick={()=>{addNewAppApi()}}>
                            {addNewAppLoader
                            &&
                            <CircularProgress size={'18px'} sx={{color:'orange'}}/>
                            }
                            {!addNewAppLoader
                            &&
                            "Submit"}
                        </Button>
                    </div>
                </div>
            </div>
            }
            {
                updatePopup
                &&
                <div style={{display:'flex',justifyContent:'center',alignItems:'center',position:'fixed',top:0,left:0,height:'100vh',width:'100vw',background:'rgba(240,240,240,0.2)',backdropFilter:'blur(1px)',zIndex:20}}  onClick={()=>{setAddNewAppPopup(false)}}>
                <div style={{height:'max-content',width:'40vw',background:'white',border:'1px solid rgb(200,200,200)',boxShadow:'0 0 5px 2px rgb(220,220,220)',paddingBottom:'20px'}} onClick={(e)=>{e.stopPropagation()}}>
                    <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',padding:'10px'}}>
                        <div>Update ID - {updatePopup.script_name}</div>
                        <div style={{cursor:'default'}} onClick={()=>{setUpdatePopup(false)}}>X</div>
                    </div>
                    <div style={{display:'flex',alignItems:'center',padding:'15px'}}>
                        <CssTextField label={'ID'} size='small' sx={{transform:'scale(0.8)'}} fullWidth
                            onChange={e=>{updateDifferenceID();setUpdatePopupData({...updatePopupData,id:e.target.value})}}
                        />
                        <CssTextField label={'Date time'} defaultValue={new Date().toISOString().split('T')[0]+" "} size='small' sx={{transform:'scale(0.8)'}} fullWidth
                            onChange={e=>{updateDifferenceID(e.target.value);setUpdatePopupData({...updatePopupData,timestamp:e.target.value})}}
                        />
                        <CssTextField label={'Constraint'} defaultValue={1} size='small' sx={{transform:'scale(0.8)'}} fullWidth
                            onChange={e=>{updateDifferenceID(null,e.target.value);setUpdatePopupData({...updatePopupData,constraint:e.target.value})}}
                        />
                    </div>
                    <br/>
                    <div style={{display:'flex',alignItems:'center',justifyContent:'center',fontSize:'12px'}}>
                        ID on above date time as per ID Helper Function : {predictedData}
                    </div>
                    <br/>
                    <div style={{display:'flex',alignItems:'center',justifyContent:'center',fontSize:'12px'}}>
                        Difference between predicted ID and actual ID : {parseInt(updatePopupData.id) - predictedData}
                    </div>
                    <br/>
                    <div style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                        <Button disabled={addNewAppLoader || !updatePopupData.timestamp || !updatePopupData.id} variant='contained' color='warning' sx={{transform:'scale(0.8)',minHeight:'28px',minWidth:'80px'}} onClick={()=>{updateDataApi()}}>
                            {addNewAppLoader
                            &&
                            <CircularProgress size={'18px'} sx={{color:'orange'}}/>
                            }
                            {!addNewAppLoader
                            &&
                            "Update"}
                        </Button>
                    </div>
                </div>
                </div>
            }
            {dataLoader
            &&
            <div style={{display:'flex',alignItems:'center',justifyContent:'center',minHeight:'40vh'}}>
                <CircularProgress sx={{color:'orange'}}/>
            </div>
            }
            {appsListData.length>0
            &&
            !dataLoader
            &&
            <TableModel
                tableHeaders={tableHeaders}
                tableValues={appsListData}
            />
            }
        </div>
    )
}

export default IdsSimulation