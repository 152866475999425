import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { getLocalStorageValue, useForceUpdate } from '../../../helper/util';
import * as Constants from '../../../data/constants';
import { Button, CircularProgress, Tooltip } from '@mui/material';
import { BoxesLoader } from "react-awesome-loaders"
import { Store } from 'react-notifications-component';
import { Resizable } from 're-resizable';
import Popup from '../../../components/popup/popup';

const MinorUpdatePopup = (props) => {
    const refreshComponent = useForceUpdate();
    var fileData  = props.fileData
    const [scriptData, setScriptData] = useState([])
    const [newScriptData, setNewScriptData] = useState({})
    const [height, setHeight] = useState(300)
    const [updateLoader, setUpdateLoader] = useState(false)
    const [dataLoader, setDataLoader] = useState(true)
    const [canUpdateSdk, setCanUpdateSdk] = useState(false)
    const [updateResponse, setUpdateResponse] = useState([])
	const getFiles = async(packageName) => {
        setDataLoader(true);
        refreshComponent(1);
		await axios({
			method: 'GET',
			url: "".concat(Constants.BACKENDHOST2+"campaigns/get-script-name/"+packageName),
			// url:"http://192.168.0.113:5000/api/get-script-details",
			headers: {
			  	'Content-Type': "application/json",
                Authorization:getLocalStorageValue('adminPanelLoginData').token,
            },
			})
		.then(resp => {
            var data = Object.keys(resp.data.data).map((item)=>{
                if(item.endsWith('modd.py') || item.endsWith('auto.py') || item.endsWith('autoios.py')){
                    if(!canUpdateSdk){
                        setCanUpdateSdk(true);
                    }
                }
                return {...resp.data.data[item],script_name : item, checked:true}
            })
            setScriptData(data)
            refreshComponent(1);
		})
		.catch(error => {
			if (error.response) {
				// Request made and server responded
				console.log(error.response.data);
				console.log(error.response.status);
				console.log(error.response.headers);
			  } else if (error.request) {
				// The request was made but no response was received
				console.log(error.request);
			  } else {
				// Something happened in setting up the request that triggered an Error
				console.log('Error', error.message);
			  }
			console.error(error);
		})
        setDataLoader(false);
	}

	const updateFiles = async() => {
		await axios({
			method: 'GET',
			url: "".concat(Constants.BACKENDHOST+"campaigns/get-package-name"),
			headers: {
				'Content-Type': "application/json",
                Authorization:getLocalStorageValue('adminPanelLoginData').token,
			},
            params:{
                filename:fileData.datadifference.filename
            }
			})
		.then(async(resp) => {
            await getFiles(resp.data.data.package_name)
			})
		.catch(error => {
			if (error.response) {
				// Request made and server responded
				console.log(error.response.data);
				console.log(error.response.status);
				console.log(error.response.headers);
			  } else if (error.request) {
				// The request was made but no response was received
				console.log(error.request);
			  } else {
				// Something happened in setting up the request that triggered an Error
				console.log('Error', error.message);
			  }
			console.error(error);
		})
	}
    useEffect(() => {
        updateFiles()
    
        return () => {
        }
    }, [])

    const updateFilesOnServer = async() => {
        setUpdateLoader(true);
        var responseData = []
        for(var index in scriptData){
            var item = scriptData[index]
            if(item.checked){
                var data = {
                    app_version_name : (newScriptData.app_version_name && newScriptData.app_version_name!=="" ? newScriptData.app_version_name : item.app_version_name).toString(),
                    app_version_code : (newScriptData.app_version_code ? newScriptData.app_version_code : item.app_version_code).toString(),
                    app_size : (newScriptData.app_size ? newScriptData.app_size : item.app_size).toString(),
                    script_name : item.script_name,
                }
                if(newScriptData.tracker || item.tracker){
                    data = {...data,
                        tracker : newScriptData.tracker ? newScriptData.tracker.toString() : item.tracker.toString(),
                    }
                }

                if(newScriptData.sdk_version || item.sdk_version){
                    data = {...data,
                        sdk_version : newScriptData.sdk_version ? newScriptData.sdk_version.toString() : item.sdk_version.toString(),
                    }
                }

                 
                await axios({
                    method: 'POST',
                    url: "".concat(Constants.BACKENDHOST2+"campaigns/minorupdate"),
                    headers: {
                        'Content-Type': "application/json",
                        Authorization:getLocalStorageValue('adminPanelLoginData').token,
                    },
                    data
                    })
                .then(resp => {
                    var status = resp.data.message
                    var combined = resp.data.combined_entry
                    responseData.push(
                        {...item,status,combined}
                    )
                })
                .catch(error => {
                    if (error.response) {
                        var message = error.response.data.message
                        // Request made and server responded
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                      } else if (error.request) {
                        message = 'Something bad with request.'
                        // The request was made but no response was received
                        console.log(error.request);
                      } else {
                        message = error.message
                        // Something happened in setting up the request that triggered an Error
                        console.log('Error', error.message);
                    }
                    responseData.push(
                        {...item,status:message}
                    )
                    console.error(error);
                })
            }
        }
        setUpdateResponse(responseData)
        // updateFiles();
        setUpdateLoader(false);
		refreshComponent(1);
        // setNewScriptData({});
        // props.setShowMinorUpdate(false);
	}

    var updateTable = [
        {label:'',value:'checkbox'},
        {label:'Filename',value:'script_name'},
        {label:'App Version Name',value:'app_version_name'},
        {label:'App Version Code',value:'app_version_code'},
        {label:'App Size',value:'app_size'},
    ]
    if(canUpdateSdk){
        updateTable = [...updateTable,
            {label:'Tracker',value:'tracker',disabled:true},
            {label:'SDK Version',value:'sdk_version'},
        ]
    }
    const responseDataFunction = () => {
        return <div style={{height:'200px',display:'flex',justifyContent:'flex-start',alignItems:'center',flexDirection:'column'}}>
                <table style={{tableLayout:'fixed',width:'100%',fontSize:'14px'}}>
                    <thead style={{fontSize:'12px',fontSize:'15px',fontWeight:'500'}}>
                        <tr>
                            <td>
                                Filename
                            </td>
                            <td>
                                Status
                            </td>
                        </tr>
                    </thead>
                    <tbody style={{fontSize:'12px'}}>
                        {updateResponse.map((item,index)=>{
                            var message = item.status
                            if(item.combined){
                                message += ' and combined has also been updated.'
                            }
                            return <tr key={index}>
                                    <td>
                                        {item.script_name}
                                    </td>
                                    <td>
                                        {message}
                                    </td>
                                </tr>
                        })}
                        <tr>
                            <td>

                            </td>
                        </tr>
                    </tbody>
                </table>
        </div>
    }
    return (
        <Resizable
            size={{height:height,width:'98%'}}
            style={{transition:'all 0.1s linear',position:'absolute',bottom:0,minHeight:'200px',width:'100%',background:'red'}}
            enable={{ top:true, right:false, bottom:false, left:false, topRight:false, bottomRight:false, bottomLeft:false, topLeft:false }}
            onResizeStop={(e, direction, ref, d) => {
                var tempHistoryWindowWidth = height
                if(tempHistoryWindowWidth + d.height < 200){
                    tempHistoryWindowWidth = 200
                }
                else{
                    tempHistoryWindowWidth = tempHistoryWindowWidth + d.height
                }
                setHeight(tempHistoryWindowWidth)
                localStorage.setItem(window.location.pathname+'historyWindowWidth',tempHistoryWindowWidth)
                // setLocalStorageValue(window.location.pathname+'historyWindowWidth',tempHistoryWindowWidth)
                refreshComponent(e);
                }}
                >
            {
                    updateResponse.length > 0
                    &&
                    <Popup head={'Update Response'} data={responseDataFunction()} handleClose={(e)=>{setUpdateResponse([]);}}/>
                }
            <div style={{fontSize:'15px',background:'white',overflow:'auto',height:height,borderTop:'1px solid rgb(220,220,220)',width:'100%',position:'absolute',bottom:0,zIndex:'1',left:0}}>
                <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',padding:'10px'}}>
                    <div>Minor Update&nbsp;
                        {scriptData.length>0 && 
                        "("+scriptData.length + ' Files)'}
                    </div>
                    <div style={{cursor:'pointer'}} onClick={()=>{props.setShowMinorUpdate(false);}}>X</div>
                </div>
                
                {dataLoader && 
                    <div style={{display:'flex',justifyContent:'center',alignItems:'center',height:'250px'}}>
                        <BoxesLoader
                            boxColor={"#ffa500"}
                            style={{ marginBottom: "20px" }}
                            desktopSize={"30px"}
                            mobileSize={"20px"}
                        />
                    </div>
                }
                {scriptData.length>0 && 
                <div>
                    <div style={{display:'flex',justifyContent:'end',marginRight:'2%'}}>
                        <Button 
                            disabled={updateLoader || Object.keys(newScriptData).length===0 || !newScriptData.app_version_name || !newScriptData.app_version_code || !newScriptData.app_size}
                            sx={{...Constants.OUTLINEDBUTTON,transform:'scale(0.8)',transformOrigin:'top',minWidth:'100px'}}
                            onClick={e=>{updateFilesOnServer()}}
                            >
                                {updateLoader &&
                                <CircularProgress sx={{color:'orange'}} size={'20px'}/>}
                                {!updateLoader &&
                                    'Update Script'
                                }
                        </Button>
                    </div>
                    <div style={{marginTop:'-20px',paddingLeft:'20px',paddingRight:'25px',display:'flex',alignItems:'center'}}>
                        
                        <table style={{tableLayout:'fixed',width:'15%',minWidth:'100px'}}>
                            <tbody>
                                <td style={{position:'sticky',left:'0'}}>
                                    {updateTable.map((tableItem,tableIndex)=>{
                                        return <tr key={tableIndex}>
                                            <div style={{minHeight:'25px'}}>
                                                {tableItem.label}
                                            </div>
                                        </tr>
                                    })}
                                </td>
                            </tbody>
                        </table>
                        <div style={{width:'70%',overflow:'auto'}}>
                            <table style={{tableLayout:'fixed',width:'80%',overflow:'auto',fontSize:'13px'}}>
                                <tbody>
                                    {scriptData.map((item,index)=>{
                                        return <td key={index} width={'130px'} style={{minWidth:'100px',overflow:'hidden'}}>
                                            {updateTable.map((tableItem,tableIndex)=>{
                                                return <tr key={tableIndex}>
                                                    <div style={{minHeight:'25px',display:'flex',alignItems:'center'}}>
                                                        {tableItem.value==='checkbox'
                                                        &&
                                                        <input type='checkbox' checked={item.checked} onChange={(e)=>{
                                                            var tempScriptData = scriptData;
                                                            tempScriptData[index].checked = e.target.checked;
                                                            setScriptData(tempScriptData);
                                                            refreshComponent('2');
                                                        }}/>}
                                                        <Tooltip title={item[tableItem.value]}>
                                                            <div style={{cursor:'pointer'}}>
                                                                {item[tableItem.value]}
                                                            </div>
                                                        </Tooltip>
                                                        
                                                    </div>
                                                </tr>
                                            })}
                                        </td>
                                    })}
                                </tbody>
                            </table>
                        </div>
                        <table style={{tableLayout:'fixed',width:'15%',minWidth:'100px'}}>
                            <tbody>
                                <td style={{position:'sticky',right:'0'}}>
                                    {updateTable.map((tableItem,tableIndex)=>{
                                        return <tr key={tableIndex}>
                                            <div style={{minHeight:'25px'}}>
                                                {tableItem.value!=='checkbox' && tableItem.value!=='script_name'
                                                &&
                                                !tableItem.disabled
                                                &&
                                                <input style={{width:'140px'}} placeholder={tableItem.label} onChange={e=>{
                                                    var tempScriptData = newScriptData;
                                                    tempScriptData[tableItem.value] = e.target.value;
                                                    setNewScriptData(tempScriptData);
                                                    refreshComponent(1);
                                                }}/>
                                                }
                                            </div>
                                        </tr>
                                    })}
                                </td>
                            </tbody>
                        </table>
                        
                    </div>
                </div>
                }
            </div>
        </Resizable>
    )
}

export default MinorUpdatePopup