import { createSlice } from '@reduxjs/toolkit'

export const counterSlice = createSlice({
  name: 'counter',
  initialState: {
    loggedin: false,
  },
  reducers: {
    loggedin: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes.
      // Also, no return statement is required from these functions.
      state.loggedin = true
    },
    logout: (state) => {
      state.loggedin = false
    }
  },
})

// Action creators are generated for each case reducer function
export const { loggedin, logout } = counterSlice.actions

export default counterSlice.reducer