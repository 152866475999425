import React, { useEffect, useState } from 'react'
import './selectComponent.css';

const SelectComponent = ({activeSelect,setActiveSelect,filterItem,propsParent,filterData,setFilterData}) => {
    const [itemSearched, setItemSearched] = useState('')
    var options = filterItem.options
    useEffect(() => {
        document.addEventListener('click',e=>{
            setItemSearched('');
            setActiveSelect(false);
        })
        
        return () => {
            document.removeEventListener('click',e=>{
                setItemSearched('');    
                setActiveSelect(false);
            })
        }
    }, [])

    var optionsSelectedDict = {}
    if(filterData[filterItem.value]){
        if(filterItem.multiple){
            for(var index in filterData[filterItem.value]){
                var item = filterData[filterItem.value][index]
                optionsSelectedDict[item.value] = item.label
            }
        }
        else{
            var item = filterData[filterItem.value]
            optionsSelectedDict[item.value] = item.label
        }
    }

    const optionSelected = (optionItem) => {
        var oldFilterData = filterData[filterItem.value]
        if(!oldFilterData){
            if(filterItem.multiple){
                oldFilterData = []
            }
            else{
                oldFilterData = {}
            }
        }
        if(filterItem.multiple){
            if(optionsSelectedDict[optionItem.value]){
                oldFilterData = oldFilterData.filter(e=>e.value!==optionItem.value)
            }
            else{
                var valueList = []
                for(var optionIndexx in oldFilterData){
                    var optionItemm = oldFilterData[optionIndexx]
                    valueList.push(optionItemm.value)
                }
                if(!valueList.includes(optionItem.value)){
                    oldFilterData.push(optionItem)
                }
            }
        }
        else{
            if(optionsSelectedDict[optionItem.value]){
                oldFilterData = {}
            }
            else{
                oldFilterData = optionItem
            }
        }
        setFilterData(oldFilterData,filterItem)
        if(!filterItem.multiple){
            setActiveSelect(false)
        }
    }
    
    var selectedOptionsList = []

    var optionsList = options.map((optionItem,optionIndex)=>{
        var value = optionItem.value
        var label = '('+value+') '+optionItem.label.split(' - ')[0]
        if(optionsSelectedDict[optionItem.value]){
            if(!itemSearched || optionItem.label.toLowerCase().startsWith(itemSearched.toLowerCase())){
                selectedOptionsList.push(
                    <div key={optionIndex} className='v2OptionContainer' style={{background:'var(--ambient-color-90-percent-lighter',marginBottom:'2px'}} onClick={(e)=>{e.stopPropagation();document.getElementById(filterItem.value+optionItem.value).click()}}>
                        <input 
                            checked={optionsSelectedDict[optionItem.value]}
                            className={filterItem.multiple ? 'v2SelectCheckboxIcon' : 'v2SelectRadioIcon'} 
                            style={{marginRight:'10px',marginTop:'5px'}} 
                            id={filterItem.value+optionItem.value} 
                            name={filterItem.value}
                            type={filterItem.multiple ? 'checkbox' : 'radio'}
                            onClick={(e)=>{e.stopPropagation();optionSelected(optionItem)}}
                        />
                        <div className='v2OptionLabel'>{label}</div>
                    </div>   
                )
            }
            return null
        }
        if(!itemSearched || optionItem.label.toLowerCase().startsWith(itemSearched.toLowerCase())){
            return <div key={optionIndex} className='v2OptionContainer' onClick={(e)=>{e.stopPropagation();document.getElementById(filterItem.value+optionItem.value).click()}}>
                    <input 
                        checked={optionsSelectedDict[optionItem.value]}
                        className={filterItem.multiple ? 'v2SelectCheckboxIcon' : 'v2SelectRadioIcon'} 
                        style={{marginRight:'10px',marginTop:'5px'}} 
                        id={filterItem.value+optionItem.value} 
                        name={filterItem.value}
                        type={filterItem.multiple ? 'checkbox' : 'radio'}
                        onChange={(e)=>{e.stopPropagation();optionSelected(optionItem)}}
                        onClick={(e)=>{e.stopPropagation();optionSelected(optionItem)}}
                    />
                    <div className='v2OptionLabel'>{label}</div>
                </div>
        }
    })
    optionsList = optionsList.filter(e=> e!=null)
    optionsList = [...selectedOptionsList,...optionsList]
    var defaultLabel = () => {
        if(Object.keys(optionsSelectedDict).length>0){
            if(Object.keys(optionsSelectedDict).length===1){
                var labll = optionsSelectedDict[Object.keys(optionsSelectedDict)[0]]
                if(labll && labll.includes(' - ')){
                    labll = '('+ labll.split(' - ')[1] +') ' +  labll.split(' - ')[0]
                }
                return labll
            }
            else{
                return (Object.keys(optionsSelectedDict).length +' items selected')
            }
        } 
        return ''
    }
    return (
        <div className={'v2SelectComponent'} onClick={(e)=>{e.stopPropagation();setActiveSelect(filterItem.value)}}>
            <input 
                placeholder={filterItem.label} 
                value={activeSelect!==filterItem.value && defaultLabel() ? defaultLabel() : itemSearched && activeSelect===filterItem.value ? itemSearched : ''} 
                onChange={(e)=>{setItemSearched(e.target.value);}}  
                className='v2FilterTextField'
            />
            <div className='v2OpenSelectIcon'></div>
            {
                activeSelect===filterItem.value
                &&
                <div className='v2SelectOptionContainer'>
                    {
                    optionsList.length > 0 &&
                    optionsList
                    }
                    {
                    optionsList.length === 0 &&
                    'No Results'
                    }
                </div>
            }
        </div>
    )
}

export default SelectComponent