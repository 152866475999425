import React from 'react'
import TopbarV2 from '../../components/topbarv2/topbarv2'
import FilterRow from '../../components/filterV2/filtersRow'
import { BugReport, CalendarMonth, Description } from '@mui/icons-material'
import { DateRangeIcon, TeamIcon } from '../../data/icons'
import { useState } from 'react'
import axios from 'axios'
import TableModel from '../../components/tableModel/tablemodel'
import { isUserBT } from '../stint/stintCustomizations/stintCustomizations'
import { CircularProgress } from '@mui/material'

const Team2RunningCamps = () => {
    const initFilterData = {
        from_date:new Date(),
        end_date:new Date()
    }
    const [filterData, setFilterData] = useState(initFilterData)
    const [tableValues, setTableValues] = useState([])
    const [loader, setLoader] = useState(false)

    const filterList = [
        {label:'Date Range',value:'date_range',type:'daterangepicker',icon:CalendarMonth},
        {label:'App Name',value:'campaign_name',type:'textfield',icon:Description},
        {label:'Channel',value:'channel',type:'textfield',icon:Description},
        {label:'Network',value:'network',type:'textfield',icon:Description},
        {label:'Offer ID',value:'offer_id',type:'textfield',icon:Description},
        {label:'Submit Reset Button',type:'submit_reset_button',renameSubmit:'Search',onSubmit:()=>{getData()},onReset:()=>{setFilterData(initFilterData)}}
    ]

    const getData = async(tempFilterData) => {
        if(!tempFilterData){
            tempFilterData = filterData
        }
        setLoader(true)

        var from_date = tempFilterData.from_date.toLocaleString('fr-CA',{year:'numeric',month:'numeric',day:'numeric'})
        var end_date = tempFilterData.end_date.toLocaleString('fr-CA',{year:'numeric',month:'numeric',day:'numeric'})
        
        var filter_dict = {
            from_date,
            end_date
        }
        for(var index in filterList){
            var item = filterList[index]
            if(item.type==='textfield'){
                if(tempFilterData[item.value]){
                    filter_dict[item.value] = tempFilterData[item.value]
                }
            }
            if(item.type==='select' && !item.isSingle){
                var value = ''
                for(var xIndex in tempFilterData[item.value]){
                    var xItem = tempFilterData[item.value][xIndex]
                    if(parseInt(xIndex)===tempFilterData[item.value].length-1){
                        value+= xItem.value
                    }
                    else{
                        value+= xItem.value + ','
                    }
                }
                if(value || value===0 || value ==="0"){
                    filter_dict[item.value] = value
                }
            }
            if(item.type==='select' && item.isSingle){
                if(tempFilterData[item.value] && (tempFilterData[item.value].value || tempFilterData[item.value].value===0)){
                    filter_dict[item.value] = tempFilterData[item.value].value
                }
            }
        }
        await axios({
            method:'GET',
            url: 'https://team2.appanalytics.in/team2b/track_script',
            headers:{
                "Content-Type": "application/json",
            },
            params:filter_dict
        })
        .then(resp=>{
            
            setTableValues(resp.data.data)
        })
        setLoader(false)

    }

    const tableHeaders = [
        {label:'App Name',value:'campaign_name'},
        {label:'Install Count',value:'install_count'},
        {label:'Event Count',value:'event_count'},
        {label:'Event Percent',value:'event_percent'},
        {label:'Total Revenue',value:'total_revenue'},
        {label:'ARPU',value:'roi'},
        {label:'AOV',value:'aov'},
    ]

    const overrideSetFilterData = (value,filterItem) => {
        if(filterItem==='dateRangePicker'){
            setFilterData({...filterData,...value})
        }
        else{
            if(filterItem.value === 'dev_team'){
                if(value.value!==undefined){
                    setFilterData({...filterData,[filterItem.value]:value})
                }
            }
            else{
                setFilterData({...filterData,[filterItem.value]:value})
            }
        }
    }
    return (
        <div>
            <TopbarV2 title={'Team 2 Running Camps'}/>
            <br/>

            <FilterRow
                filtersList={filterList}
                filterData={filterData}
                setFilterData={overrideSetFilterData}
                filterItemWidth='15'
            />
            <br/>
            <br/>
            {
                loader
                &&
                <div style={{minHeight:'30vh',display:'flex',alignItems:'center',justifyContent:'center'}}>
                    <CircularProgress sx={{color:'orange',fontSize:'30px'}}/>
                </div>
            }
            {
                !loader
                &&
                <TableModel
                    tableHeaders={tableHeaders}
                    tableValues={tableValues}
                />
            }
        </div>
    )
}

export default Team2RunningCamps