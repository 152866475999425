import React, { useState } from 'react';
// import '../css/pages/minorUpdate.css';
import TextField from '@mui/material/TextField';
import { Button } from '@mui/material';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt';
import TopbarV2 from '../../../components/topbarv2/topbarv2';
import ErrorIcon from '@mui/icons-material/Error';
import { getLocalStorageValue } from '../../../helper/util';
import * as Constants from '../../../data/constants';
import './minorUpdate.css';

function useForceUpdate(){
    const [value, setValue] = useState(0); // integer state
    if(false){
        console.log(value)
    }
    return () => setValue(value => value + 1); // update the state to force render
  }


export const MinorUpdate = () => {
	var updateComponent = useForceUpdate()
	const [packageName, setPackageName] = useState("")
	const [getFilesRequest, setGetFilesRequest] = useState(false)
	const [filesResponse, setFilesResponse] = useState({})

	const [updateFilesList, setUpdateFilesList] = useState([])

	const [loaderList, setLoaderList] = useState([{loader:false},{loader:false}])
	const [error, setError] = useState(false)
	const [useManualUseState, setUseManualUseState] = useState(false)
	if(!useManualUseState){
		setUseManualUseState(true);
		// if(localStorage.getItem('packageNameAndFiles')){
		// 	var response =  JSON.parse(localStorage.getItem('packageNameAndFiles'))
		// 	var tempList = Object.keys(response.scripts).map((filename,fileindex)=>{
		// 		return{
		// 			script_name:filename, app_version_name:"", app_version_code:"", app_size:""
		// 		}
		// 	})
		// 	var tempLoaderList = Object.keys(response.scripts).map((filename,fileindex)=>{
		// 		return{
		// 			loader:false,
		// 		}
		// 	})
		// 	setFilesResponse(response.scripts)
		// 	setLoaderList(tempLoaderList)
		// 	setUpdateFilesList(tempList)
        //     setPackageName(response.packageName)
		// }
	}

	const getFiles = async() => {
		setGetFilesRequest(true);
		await axios({
			method: 'GET',
			url: "".concat(Constants.BACKENDHOST2+"campaigns/get-script-name/"+packageName),
			// url:"http://192.168.0.113:5000/api/get-script-details",
			headers: {
			  	'Content-Type': "application/json",
                Authorization:getLocalStorageValue('adminPanelLoginData').token,
            },
			})
		.then(resp => {
			if(resp.data.response_code===200){
				setFilesResponse(resp.data.data);
				var tempList = Object.keys(resp.data.data).map((filename,fileindex)=>{
					return{
						script_name:filename, app_version_name:"", app_version_code:"", app_size:""
					}
				})
				var loaderList = Object.keys(resp.data.data).map((filename,fileindex)=>{
					return{
						loader:false,
					}
				})
				setLoaderList(loaderList)
				setUpdateFilesList(tempList)
			}
			else{
				setFilesResponse({})
				setLoaderList([])
				setUpdateFilesList([])
				setError(resp.data.message)
			}
		})
		.catch(error => {
			if (error.response) {
				// Request made and server responded
				console.log(error.response.data);
				console.log(error.response.status);
				console.log(error.response.headers);
			  } else if (error.request) {
				// The request was made but no response was received
				console.log(error.request);
			  } else {
				// Something happened in setting up the request that triggered an Error
				console.log('Error', error.message);
			  }
			console.error(error);
		})
        setGetFilesRequest(false);
	}


	const updateFiles = async(e,index) => {
        // console.log( updateFilesList[index])
		await axios({
			method: 'POST',
			url: "".concat(Constants.BACKENDHOST+"campaigns/minorupdate"),
			headers: {
				'Content-Type': "application/json",
                Authorization:getLocalStorageValue('adminPanelLoginData').token,
			},
			data: updateFilesList[index]
			})
		.then(resp => {
			// setFilesResponse(resp.data)
			var temploaderList = loaderList
			temploaderList[index]['loader'] = resp.data;
			setLoaderList(temploaderList);
			})
		.catch(error => {
			var temploaderList = loaderList
			temploaderList[index]['loader'] = 'Error, Please try again.';
			setLoaderList(temploaderList)
			if (error.response) {
				// Request made and server responded
				console.log(error.response.data);
				console.log(error.response.status);
				console.log(error.response.headers);
			  } else if (error.request) {
				// The request was made but no response was received
				console.log(error.request);
			  } else {
				// Something happened in setting up the request that triggered an Error
				console.log('Error', error.message);
			  }
			console.error(error);
		})
		updateComponent(e)

	}

    return (
        <div>
            <TopbarV2/>
            <div className='minorUpdateContainer'>
                <div style={{marginTop:'18px',display:'flex',marginBottom:'25px',justifyContent:'center'}}>
                    <div style={{fontSize:'12px',width:'60%',}}>
                        <TextField
                            required
                            size='small'
                            color='warning'
                            fullWidth
                            label="Package Name"
                            placeholder='Package Name'
                            style={{transform:'scale(0.8)'}}
                            defaultValue={packageName}
                            onChange={(e)=>{setPackageName(e.target.value)}}
                            onKeyDown={(e)=>{if(e.key==="Enter"){getFiles();}}}
                            />
                    </div>
                    <Button size='small' color='warning' sx={{marginLeft:'5%'}} style={{transform:'scale(0.8)'}} variant='contained' disabled={(packageName==="" || getFilesRequest) ? true : false} onClick={getFiles}>Get Files</Button>
                </div>
                {
                    getFilesRequest && 
                    <div style={{display:'flex',alignItems:'center',justifyContent:'center',minHeight:'30vh',minWidth:'50vw'}}>
                        <CircularProgress color='warning'/>
                    </div>
                }
                {
                !getFilesRequest &&
                <div style={{display:'flex',flexWrap:'wrap'}}>
                    {Object.keys(filesResponse).map((fileName,fileIndex)=>{
                        var fileData = filesResponse[fileName]
                        console.log(fileData)
                        return <div className='fileContainer' key={fileIndex}>
                            
                            <div style={{fontSize:'14px',fontWeight:'500',display:'flex'}}> 
                                {fileName}
                            </div>
                            <div style={{borderBottom:'1px solid rgb(220,220,220)',marginTop:'10px'}}></div>
                            <div style={{marginTop:'10px'}}>
                                {
                                    loaderList[fileIndex]['loader']===true && 
                                    <div style={{background:'rgb(240,240,240,0.2)',position:'absolute',marginTop:'-5px',width:'500px',height:'150px',zIndex:10,backdropFilter:'blur(1px)',display:'flex',alignItems:'center',justifyContent:'center'}}>
                                        <CircularProgress color='warning'/>
                                    </div>
                                }
                                {
                                    loaderList[fileIndex]['loader']!==true && loaderList[fileIndex]['loader']!==false && 
                                    <div
                                    onClick={(e)=>{var tempLoaderList= loaderList; tempLoaderList[fileIndex]['loader'] = false; setLoaderList(tempLoaderList);updateComponent(e);}}
                                    style={{background:'rgb(240,240,240,0.2)',position:'absolute',marginTop:'-5px',width:'500px',height:'230px',zIndex:10,backdropFilter:'blur(1px)',display:'flex',alignItems:'center',justifyContent:'center'}}>
                                        <div style={{display:'grid',background:'white',padding:'20px',paddingLeft:'30px',paddingRight:'30px',boxShadow:'0 0 5px 2px grey'}}>
                                            {loaderList[fileIndex]['loader'].message==="Script pushed successfully" 
                                            &&
                                            <div style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                                                <CheckCircleIcon style={{fontSize:'34px',color:'green'}}/>
                                            </div>
                                            }
                                            {loaderList[fileIndex]['loader'].message==="Script not pushed!! No change found" 
                                                &&
                                                <div style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                                                    <DoNotDisturbAltIcon style={{fontSize:'34px',color:'red',paddingBottom:'12px'}}/>
                                                </div>
                                            }
                                            {
                                                loaderList[fileIndex]['loader'] === "Error, Please try again." 
                                                &&
                                                <div style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                                                    <ErrorIcon style={{fontSize:'34px',color:'red',paddingBottom:'12px'}}/>
                                                </div>
                                            }
                                            {loaderList[fileIndex]['loader'].message ? loaderList[fileIndex]['loader'].message : loaderList[fileIndex]['loader']}
                                        </div>
                                    </div>
                                }
                                
                                <div className='flex-v-center' style={{marginTop:'10px'}}>
                                    <div className='fileDataKeys'>App version name</div>
                                    <TextField label="Old App version name" color='warning' size='small' sx={{transform:'scale(0.8)',fontSize:'12px'}} disabled defaultValue={fileData.app_version_name}/>
                                    <div style={{display:'grid'}}>
                                        <TextField label="New App version name" color='warning' size='small' sx={{transform:'scale(0.8)',fontSize:'12px'}} onChange={(e)=>{var tempFileList = updateFilesList; tempFileList[fileIndex]['app_version_name']=e.target.value;setUpdateFilesList(tempFileList);updateComponent(e);}}/>
                                        {updateFilesList[fileIndex].app_version_name!=="" && fileData.app_version_name.includes(".") && !updateFilesList[fileIndex].app_version_name.includes(".") && <label style={{position:'absolute',fontSize:'11px',color:'red',marginTop:'5%',marginLeft:'5%'}}>Invalid App version name</label>}
                                    </div>
                                </div>
                                <div className='flex-v-center' style={{marginTop:'20px'}}>
                                    <div className='fileDataKeys'>App version code</div>
                                    <TextField label="Old App version code" color='warning' size='small' sx={{transform:'scale(0.8)',fontSize:'12px'}} disabled defaultValue={fileData.app_version_code}/>
                                    <div style={{display:'grid'}}>
                                        <TextField label="New App version code" color='warning' size='small' sx={{transform:'scale(0.8)',fontSize:'12px'}} onChange={(e)=>{var tempFileList = updateFilesList; tempFileList[fileIndex]['app_version_code']=e.target.value;setUpdateFilesList(tempFileList);updateComponent(e);}}/>
                                        {updateFilesList[fileIndex].app_version_code!=="" && ((fileData.app_version_code.includes(".") && !updateFilesList[fileIndex].app_version_code.includes(".")) || (parseFloat(updateFilesList[fileIndex].app_version_code) - parseFloat(fileData.app_version_code)>10000)) && <label style={{position:'absolute',fontSize:'11px',color:'red',marginTop:'5%',marginLeft:'5%'}}>Invalid App version code</label>}
                                    </div>
                                </div>
                                <div className='flex-v-center' style={{marginTop:'20px'}}>
                                    <div className='fileDataKeys'>App size</div>
                                    <TextField label="Old App size" color='warning' size='small' sx={{transform:'scale(0.8)',fontSize:'12px'}} disabled defaultValue={fileData.app_size}/>
                                    <div style={{display:'grid'}}>
                                        <TextField label="New App size" color='warning' size='small' sx={{transform:'scale(0.8)',fontSize:'12px'}} onChange={(e)=>{var tempFileList = updateFilesList; tempFileList[fileIndex]['app_size']=e.target.value;setUpdateFilesList(tempFileList);updateComponent(e);}}/>
                                        {updateFilesList[fileIndex].app_size!=="" && (!parseFloat(updateFilesList[fileIndex].app_size) || parseFloat(updateFilesList[fileIndex].app_size)<20 || parseFloat(updateFilesList[fileIndex].app_size)>2046) && <label style={{position:'absolute',fontSize:'11px',color:'red',marginTop:'5%',marginLeft:'5%'}}>App size is invalid</label>}
                                    </div>
                                </div>
                                {(fileName.endsWith('ios.py') || fileName.endsWith('iosmodd.py'))
                                &&
                                <div className='flex-v-center' style={{marginTop:'20px'}}>
                                    <div className='fileDataKeys'>Install Receipt</div>
                                    <TextField label="Old Install Receipt" color='warning' size='small' sx={{transform:'scale(0.8)',fontSize:'12px'}} disabled defaultValue={fileData.install_receipt ? fileData.install_receipt : 'Cannot fetch from file.'}/>
                                    <div style={{display:'grid'}}>
                                        <TextField label="Install Receipt" color='warning' size='small' sx={{transform:'scale(0.8)',fontSize:'12px'}} onChange={(e)=>{var tempFileList = updateFilesList; tempFileList[fileIndex]['install_receipt']=e.target.value;setUpdateFilesList(tempFileList);updateComponent(e);}}/>
                                    </div>
                                </div>
                                }
                            </div>
                            
                            <div style={{borderBottom:'1px solid rgb(220,220,220)',marginTop:'20px'}}></div>
                            <div style={{display:'flex',justifyContent:'center'}}>
                                <Button variant='contained' color='warning' sx={{transform:'scale(0.8)',marginTop:'10px'}} disabled={loaderList[fileIndex]['loader']===true ? true : false} onClick={(e)=>{updateFiles(e,fileIndex);var temploaderList = loaderList;temploaderList[fileIndex]['loader'] = true;setLoaderList(temploaderList);updateComponent(e)}}> 
                                    Update 
                                </Button>
                            </div>
                        </div>
                    })}
                    {Object.keys(filesResponse).length===0 && <div> {error}</div>}
                </div>
                }
            </div>
        </div>
	)
}

export default MinorUpdate;