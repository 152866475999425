import './App.css';
import CheckLogin from './components/checkLogin';
import { ReactNotifications } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css'
import { useState } from 'react';
import { getLighterColor, increase_brightness, rgbToHex } from './helper/util';

function App() {

	var ambientColor = 'rgb(255, 140, 0)'
	// inside the jsx being returned:
    localStorage.setItem('ambientColor','rgb(255, 140, 0)')
    localStorage.setItem('ambientColor2','rgb(255, 166, 0)')

    if(localStorage.getItem('ambientColor')){
        ambientColor = localStorage.getItem('ambientColor')
        if(ambientColor.startsWith('rgb')){
            ambientColor = rgbToHex(ambientColor)
        }
        document.querySelector(':root').style.setProperty('--ambient-color', ambientColor);
        document.querySelector(':root').style.setProperty('--ambient-color-10-percent-lighter', getLighterColor(ambientColor,10));
        document.querySelector(':root').style.setProperty('--ambient-color-20-percent-lighter', getLighterColor(ambientColor,20));
        document.querySelector(':root').style.setProperty('--ambient-color-30-percent-lighter', getLighterColor(ambientColor,30));
        document.querySelector(':root').style.setProperty('--ambient-color-40-percent-lighter', getLighterColor(ambientColor,40));
        document.querySelector(':root').style.setProperty('--ambient-color-50-percent-lighter', getLighterColor(ambientColor,50));
        document.querySelector(':root').style.setProperty('--ambient-color-60-percent-lighter', getLighterColor(ambientColor,60));
        document.querySelector(':root').style.setProperty('--ambient-color-70-percent-lighter', getLighterColor(ambientColor,70));
        document.querySelector(':root').style.setProperty('--ambient-color-80-percent-lighter', getLighterColor(ambientColor,80));
        document.querySelector(':root').style.setProperty('--ambient-color-90-percent-lighter', getLighterColor(ambientColor,90));
        document.querySelector(':root').style.setProperty('--ambient-color-95-percent-lighter', getLighterColor(ambientColor,95));
    }
    else{
        localStorage.setItem('ambientColor', ambientColor);
        document.querySelector(':root').style.setProperty('--ambient-color', "rgb(0, 166, 178)");
    }
    var r = document.querySelector(':root');
    var reverse = localStorage.getItem('nightMode')==='false' ? false : true
    if(!reverse){
        r.style.setProperty('--container-shadow-color', increase_brightness(ambientColor,-10));
        r.style.setProperty('--container-shadow-color-hover', ambientColor);
        r.style.setProperty('--container-shadow-color-hover-lighter', increase_brightness(ambientColor,10));    
        r.style.setProperty('--container-shadow-color-hover-more-lighter', increase_brightness(ambientColor,95));    
        r.style.setProperty('--top-bg-color', ambientColor);
        r.style.setProperty('--hamburger-icon-hover-color', increase_brightness(ambientColor,-10));
        r.style.setProperty('--menu-item-hover-color', 'white');
        r.style.setProperty('--menu-item-hover-font-color', 'black');
        r.style.setProperty('--font-color', 'white');
        r.style.setProperty('--sidebar-bg-color', ambientColor);
    }
    else{
        var backgroundColor = 'rgb(252,252,252)'
        r.style.setProperty('--container-shadow-color', increase_brightness(backgroundColor,-10));
        r.style.setProperty('--container-shadow-color-hover', backgroundColor);
        r.style.setProperty('--container-shadow-color-hover-lighter', increase_brightness(backgroundColor,10));     
        r.style.setProperty('--container-shadow-color-hover-more-lighter', increase_brightness(backgroundColor,0));    
        r.style.setProperty('--top-bg-color', backgroundColor);
        r.style.setProperty('--hamburger-icon-hover-color', increase_brightness(backgroundColor,-10));
        r.style.setProperty('--menu-item-hover-color', ambientColor);
        r.style.setProperty('--menu-item-hover-font-color', 'white');
        r.style.setProperty('--font-color', 'rgb(50,50,50)');
        r.style.setProperty('--sidebar-bg-color', backgroundColor);
    }
    r.style.setProperty('--sidebar-gradient-color1', 'rgb(255, 140, 0)');
	r.style.setProperty('--sidebar-gradient-color2', 'rgb(255, 166, 0)');
	return (
		<div>
	        <ReactNotifications />
			<CheckLogin/>
		</div>
	);
}

export default App;
