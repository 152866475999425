import { Close, Download, DownloadForOffline, EnergySavingsLeaf, SearchOutlined } from '@mui/icons-material'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Store } from 'react-notifications-component'
import TopbarV2 from '../../../components/topbarv2/topbarv2'
import { getLocalStorageValue, useForceUpdate } from '../../../helper/util'
import * as Constants from '../../../data/constants';
import CreatableSelect from 'react-select/creatable';
import { CircularProgress, IconButton, Tooltip } from '@mui/material'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import CodeEditor from '@uiw/react-textarea-code-editor';

export const downloadFile = (data,file) => {
    if(!file.endsWith('.py')){
        file+='.py'
    }
    const urldownload = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = urldownload;
    link.setAttribute('download', file); //or any other extension
    document.body.appendChild(link);
    link.click();
}

const DownloadCampaigns = () => {
    const refreshComponent = useForceUpdate()
    const [filename, setFilename] = useState('')
    const [downloadLoader, setDownloadLoader] = useState(false)
    const [commitMessage, setCommitMessage] = useState(false)
    const [showPopup, setShowPopup] = useState(false)
    const [code, setCode] = React.useState();
    const [viewProffessionally, setViewProffessionally] = React.useState(false);


    const handleDownloadFile = async(e,viewOnly) => {
        e.stopPropagation();
        for(var fileIndex in filename.split(',')){
            var file= filename.split(',')[fileIndex]
            setDownloadLoader(true)
            if(!file.endsWith('.py')){
                file+='.py'
            }
            await axios({
                method: "GET",
                url: Constants.BACKENDHOST2 + 'campaigns/download-script/'+file, //Constants.BACKENDHOST2+"tools/create_flow_report",
                headers:{
                    "Content-Type": "multipart/form-data",
                    Authorization:getLocalStorageValue('adminPanelLoginData').token,
                    'Cache-Control': 'no-cache',
                    'Pragma': 'no-cache',
                    'Expires': '0',
                },
                params:{
                    comment:commitMessage.value,
                },
                // responseType:'blob'
            }).then(response => {
                if(response.data.data && response.data.data.error){
                    Store.addNotification({
                        title: "Warning!",
                        message:  file + ' ' + response.data.data.error.toLowerCase(),
                        type: "warning",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                          duration: 5000,
                          onScreen: true
                        }
                    });
                }
                else{
                    if(viewOnly){
                        setCode(response.data);
                        setShowPopup(true);
                    }
                    else{
                        downloadFile(response.data,file)
                    }
                }
            })
            .catch(err=>{
                console.log(err)
                if(err.response && err.response.data){
                    Store.addNotification({
                        title: "Error!",
                        message:  err.response.data.message,
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                          duration: 5000,
                          onScreen: true
                        }
                    });
                }
            });
            setDownloadLoader(false)
        }
    }

    useEffect(() => {
        document.addEventListener('keydown',e=>{
            if(e.key==='Escape'){
                setShowPopup(false);
            }
        })
        
        return () => {
            document.removeEventListener('keydown',e=>{
                if(e.key==='Escape'){
                    setShowPopup(false);
                }
            })
        }
    }, [])
    
    const commentMessages = [
        "Just Check",
        "Detailed",
        "Detailed CT install",
        "Detailed CT open",
        "Updates",
        "Error",
        "Level 1",
        "Level 2",
        "Modification/Customization",
        "Recheck",
        "Traffic Issues",
    ].map((item,index)=>{
        return {label:item,value:item}
    })
    return (
        <div>
            <TopbarV2 title={'Download Campaigns'}/>
            <div style={{display:"flex",height:'calc(100vh - 100px)',width:'100%',alignItems:'center',justifyContent:'center'}}>
                <div>
                    <div style={{display:'flex',alignItems:'center',marginBottom:'20px'}}>
                        <SearchOutlined sx={{fontSize:'32px',marginLeft:'15px',color:'rgb(100,100,100)',position:'absolute'}}/>
                        <input value={filename} onKeyDown={(e)=>{if(e.key==='Enter'){}}} onChange={(e)=>{setFilename(e.target.value);refreshComponent(123);}} style={{padding:'15px',minWidth:'250px',paddingLeft:'55px',fontSize:'17px',outline:'1px solid transparent',border:'1px solid rgb(200,200,200)'}}/>
                    </div>
                    <CreatableSelect                                                
                        isClearable
                        placeholder={'Reason for Download??'}
                        options={commentMessages}
                        value={commitMessage}
                        onChange={(e)=>{setCommitMessage(e)}}
                        menuPortalTarget={document.body} 
                        styles={{...Constants.ReactSelectStyles}}
                    />
                </div>
                <div style={{display:'flex',flexDirection:'column',marginLeft:'20px',cursor:((!commitMessage || !filename) ? 'not-allowed' : 'pointer')}}>
                    {
                        downloadLoader
                        &&
                        <div style={{position:"absolute",paddingTop:'5px',paddingLeft:'5px'}}>
                            <CircularProgress size={'22px'} sx={{color:'orange'}}/>
                        </div>
                    }

                    {!downloadLoader && commitMessage.value==='Just Check'
                    &&
                    <Tooltip title={'View File'}>
                        <IconButton sx={{transform:'scale(0.8)'}} onClick={(e)=>{handleDownloadFile(e,true)}}>
                            <RemoveRedEyeIcon sx={{fontSize:'32px',color:(!commitMessage || !filename) ? 'grey' : 'orange'}}/>
                        </IconButton>
                    </Tooltip>
                    }
                    {!downloadLoader 
                    &&
                    <Tooltip title={'Download File'}>
                        <IconButton sx={{transform:'scale(0.8)'}} onClick={(e)=>{if(commitMessage && filename){handleDownloadFile(e)}}}>
                            <DownloadForOffline sx={{fontSize:'32px',color:(!commitMessage || !filename) ? 'grey' : 'green'}}/>
                        </IconButton>
                    </Tooltip>
                    }
                </div>
            </div>
            {showPopup
            &&
            <div style={{position:'fixed',height:'calc(100vh - 60px)',width:'calc(100vw - 100px)',top:62,left:100,zIndex:100,display:'flex',alignItems:'center',justifyContent:'center',backdropFilter:'blur(1px)'}}>
                <div style={{height:'calc(100vh - 60px)',width:'calc(100vw - 30%)',overflow:'auto',border:'1px solid rgb(100,100,100)'}}>
                    <div style={{height:'50px',fontSize:'15px',width:'calc(100% - 30px)',background:'white',color:'black',display:"flex",alignItems:'center',justifyContent:'space-between',paddingLeft:'15px',paddingRight:'15px',borderBottom:'1px solid rgb(100,100,100)',position:'sticky',top:0,zIndex:'100'}}>
                        <div>{filename}</div>
                        <div>
                            <Tooltip title={viewProffessionally ? 'View Normally' : 'View As IDE, Will take time to load.'}>
                                <EnergySavingsLeaf sx={{fontSize:'20px',marginRight:'15px'}} onClick={(e)=>{setViewProffessionally(!viewProffessionally)}}/>
                            </Tooltip>
                            <Tooltip title={'Download File'}>
                                <Download sx={{fontSize:'20px',marginRight:'15px'}} onClick={(e)=>{downloadFile(code,filename)}}/>
                            </Tooltip>
                            <Tooltip title={'Close File'}>
                                <Close sx={{fontSize:'20px'}} onClick={()=>{setShowPopup(false);}}/>
                            </Tooltip>
                        </div>
                    </div>
                    {!viewProffessionally
                        &&
                        <pre style={{background:'white'}}>
                            {code}
                        </pre>
                    }
                    {viewProffessionally
                        &&
                        <CodeEditor
                        value={code}
                        language="py"
                        placeholder="Please enter Python code."
                        onChange={(evn) => setCode(evn.target.value)}
                        padding={15}
                        data-color-mode="dark"
                        style={{
                            fontSize: 12,
                            backgroundColor: "rgb(0,0,0)",
                            fontFamily: 'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
                          }}
                        />    }
                    
                </div>
            </div>        
                }
        </div>
    )
}

export default DownloadCampaigns