import { Button, CircularProgress} from '@mui/material';
import React, { useEffect, useState } from 'react'
import HistoryComponent from '../../../components/historyComponent/historyComponent'
import TopbarV2 from '../../../components/topbarv2/topbarv2'
import '../../../css/pages/v1/newapp.css';
import * as Constants from '../../../data/constants';
import '../../../css/pages/v1/datadifference.css';
import { Store } from 'react-notifications-component';
import axios from 'axios';
import { CssTextField, getLocalStorageValue, useForceUpdate } from '../../../helper/util';
import FiddlerImg from '../../../img/fiddler.png'
import ReactSelect from 'react-select';

import CreatableSelect from 'react-select/creatable';

const CT = () => {
    const refreshComponent = useForceUpdate();
    const FileDownload = require('js-file-download');

    if(localStorage.getItem(window.location.pathname+'historyWindowWidth')){
        var tempHistoryWindowWidth = localStorage.getItem(window.location.pathname+'historyWindowWidth')
    }
    else{
        tempHistoryWindowWidth = 300
    }

    useEffect(() => {
        document.addEventListener('keydown',(e)=>{
            if(e.key==='Escape'){
                setShowPopup(false);
            }
        })
        
        return () => {
            document.removeEventListener('keydown',(e)=>{
                if(e.key==='Escape'){
                    setShowPopup(false);
                }
            })
        }
    }, [])
    
    const [useEffectManual, setUseEffectManual] = useState(false)
    const [historyWindowWidth, setHistoryWindowWidth] = useState(tempHistoryWindowWidth)
    const [historyPage, setHistoryPage] = useState(1)
    const [historyPages, setHistoryPages] = useState(0)
    
    const [historyResponse, setHistoryResponse] = useState([])
    const [historyResponseLoader, setHistoryResponseLoader] = useState(false)
    const [addLogsLoader, setAddLogsLoader] = useState(false)
    const [selectedLogFile, setSelectedLogFile] = useState(false)
    const [logFileName, setLogFileName] = useState(false)
    const [showPopup, setShowPopup] = useState(false)
    const [activeTab, setActiveTab] = useState(false)
    const [openTabsList, setOpenTabsList] = useState([])
    const [selectedItem, setSelectedItem] = useState({})

    const [uploadType, setUploadType] = useState({label:'Script',value:'script'})
    const [uploadVertical, setUploadVertical] = useState({label:'Other',value:'other'})
    const [retentionDay, setRetentionDays] = useState(70)
    const [filename, setFilename] = useState('')
    const [appflowLink, setAppFlowLink] = useState('')
    
    const [devkey, setDevkey] = useState(false)
    const [loaderPopup, setLoaderPopup] = useState(false)

    var getLogsUploadHistory = async(push) => {
        setHistoryResponseLoader(true);
        await axios({
            method: "GET",
            url: Constants.LEVELCREATOR_BACKENDHOST+"logs",
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token,
            },
            params:{
                user_id:getLocalStorageValue('adminPanelLoginData').id
            }
        })
        .then(resp => {
            setHistoryResponse(resp.data.result)
        })
        .catch(err=>{
            if(err.response && err.response.data){
                Store.addNotification({
                    title: "Error!",
                    message: err.response.data.message,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });
            }
        })
        setHistoryResponseLoader(false);
    }
    
    if(!useEffectManual){
        setUseEffectManual(true)
        getLogsUploadHistory()
    }
    
	const handleChooseLogFile = (event) => {
        if(event.target && event.target.files.length>0){
            setSelectedLogFile(event.target.files[0]);
            if(event.target.files[0]){
                if(event.target.files[0].name){
                    setLogFileName(event.target.files[0].name)
                }
            }
        }
        event.target.files = null
        event.target.value = null
	};

    var pushLogsApiRequest = async() => {
        setAddLogsLoader(true);
        const formData = new FormData();
        formData.append("logs_file", selectedLogFile);
        formData.append("user_id", getLocalStorageValue('adminPanelLoginData').id);
        formData.append("type", uploadType.value);
        formData.append("vertical", uploadVertical.value);
        formData.append("retention_days",retentionDay);
        await axios({
            method: "POST",
            url: Constants.LEVELCREATOR_BACKENDHOST+"logs",
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token,
                "Content-Type": "multipart/form-data",
            },
            params:{
                purpose:'datadifference',
            },
            data:formData
        })
        .then(resp => {
        })
        .catch(err=>{
            if(err.response && err.response.data){
                Store.addNotification({
                    title: "Error!",
                    message: err.response.data.message,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });
            }
        })
        getLogsUploadHistory()
        setAddLogsLoader(false);
        setLogFileName(false);
        setShowPopup(false);
    }

    var updateLogsDataAPI = async(package_name,devkey) => {
        setAddLogsLoader(true);
        await axios({
            method: "POST",
            url: Constants.LEVELCREATOR_BACKENDHOST+"appsflyer/devkey",
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token,
                "Content-Type": "application/json",
            },
            params:{
                purpose:'datadifference',
            },
            data:{
                "logs_id": selectedItem.id,
                "package_name": package_name,
                "devkey": devkey,
                'type':uploadType.value ? uploadType.value : undefined,
                'vertical':uploadVertical.value ? uploadVertical.value : undefined,
                'retention_days':retentionDay ? retentionDay : 70
            }
        })
        .then(resp => {
        })
        .catch(err=>{
            
        })
        getLogsUploadHistory()
        setAddLogsLoader(false);
        setLogFileName(false);
        setShowPopup(false);
    }

    var getDataDifference = async(item,isDecrypt,fromError) => {
        setAddLogsLoader(true);

        var url = Constants.BACKENDHOST+"scriptcreator/v2/level1"
        var method = 'POST'
        await axios({
            method,
            url ,
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token,
                "Content-Type": "multipart/form-data",
            },
            params:{
                'script_name':filename,
                'appflow_link':appflowLink,
                'logs_serial':selectedItem.logsserial,
            },
        })
        .then(resp => {
            if(!filename.includes('.py')){
                var filenametemp = filename+'.py'
            }
            else{
                filenametemp = filename
            }
            FileDownload(resp.data, filenametemp);
            // openTabs({...selectedItem,name:filenametemp,file:resp.data})
            refreshComponent(1);
            // setLogFileName(false);
        })
        .catch(err=>{

            if(err.response && err.response.data){
                Store.addNotification({
                    title: "Error!",
                    message: err.response.data.message,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });
            }
        })
        setAddLogsLoader(false);
        // setShowPopup(false);
        // setSelectedScriptName(false);
        // setLogFileName(false);
        // setDevkey(false);
    }

    const openTabs = (item) => {
        var tempTabsList = openTabsList;
        tempTabsList.push(item)
        setActiveTab(tempTabsList.length-1)
        setOpenTabsList(tempTabsList);
        refreshComponent(1);
    }

    const closeTab = (item) => {
        var tempTabsList = openTabsList
        for(var index in tempTabsList){
            if(tempTabsList[index].logsname === item.logsname && tempTabsList[index].logsserial === item.logsserial){
                break;
            }
        }
        tempTabsList.splice(index,1)
        setOpenTabsList(tempTabsList);
        if(tempTabsList.length>0){
            setActiveTab(tempTabsList.length-1);
        }
        else{
            setActiveTab(false);
        }
        refreshComponent(1);
    }

    const loaderPopupDetails = {
        'devkey':{
            header:'Developer Key?',
            popupValue: <div>
                <div style={{display:'flex',alignItems:'center',marginBottom:'10px'}}>
                    <div style={{width:'200px'}}>
                        Package Name
                    </div>
                    <div style={{width:'calc(100% - 200px)'}}>
                        <Constants.CssTextField fullWidth id='packageNameField' value={selectedItem.package_name} onChange={(e)=>{setDevkey({...devkey,package:e.target.value})}} size='small'/>
                    </div>
                </div>
                <div style={{display:'flex',alignItems:'center',marginBottom:'10px'}}>
                    <div style={{width:'200px'}}>
                        DevKey
                    </div>
                    <div style={{width:'calc(100% - 200px)'}}>
                        <Constants.CssTextField fullWidth id='devkeyField' onChange={(e)=>{setDevkey({...devkey,devkey:e.target.value})}} size='small'/>
                    </div>
                </div>
                <div style={{display:'flex',alignItems:'center',marginTop:'30px'}}>
                    <Button sx={{...Constants.CONTAINEDBUTTON,fontSize:'12px',transform:'scale(0.8)'}} onClick={()=>{if(!devkey){Store.addNotification({
                                        title: "Error!",
                                        message: "Devkey cannot be blank",
                                        type: "danger",
                                        insert: "top",
                                        container: "top-right",
                                        animationIn: ["animate__animated", "animate__fadeIn"],
                                        animationOut: ["animate__animated", "animate__fadeOut"],
                                        dismiss: {
                                            duration: 5000,
                                            onScreen: true
                                        }
                                    })}else{updateLogsDataAPI(document.getElementById('packageNameField').value,document.getElementById('devkeyField').value);setLoaderPopup(false);}}}>Save</Button>
                </div>
            </div>
        },
    }

    var statusHistoryDict = {
        '0':{
            color:'orange'
        },
        '1':{
            label:'Logs are in processing, please wait.',
            color:'orange'
        },
        '2':{
            label:'Logs have been processed, please enter the details',
            color:'green'
        },
        '-1':{
            label:'Some Error at backend, we will debug this issue.',
            color:'red'
        },
        '-2':{
            label:'There is some error on backend, we are currently working on this error.',
            color:'red'
        },
        '-3':{
            label:'Input Error, need more information',
            color:'red'
        },
        '-4':{
            label:'Sorry the process was terminated due to some issue.',
            color:'red'
        },
        '-5':{
            label:'Dev Key Required',
            color:'red'
        }
    }

    const statusHistoryComponent = (item,index,tableHead,tableHeadIndex) => {
        if(item.status){
            var status = item.status
            var bgColor = statusHistoryDict[status] ? statusHistoryDict[status].color : 'red'
        }
        return <div style={{width:'8px',height:'8px',borderRadius:'4px',marginTop:'2px',background:bgColor}}></div>
    }
    const requestedAtComponent = (item,index,tableHead,tableHeadIndex) => {
        var dateOptions = { year: 'numeric', month: 'short', day: 'numeric' ,hour:'numeric',minute:'numeric',second:'numeric'};
        return new Date(item.created_at).toLocaleDateString("en-US", dateOptions)
    }
    const historyLogsNameComponent = (item,index,tableHead,tableHeadIndex) => {
        if(item.logs_file){
            return item.logs_file.split('/')[1]
        }
    }

    var historyHeaders = [
        {label:'',value:'reportstatus',width:'5%',customComponent:statusHistoryComponent},
        {label:'Logs Name',value:'logs_file',width:'45%',customComponent:historyLogsNameComponent},
        {label:'Requested At',value:'created_at',width:'50%',customComponent:requestedAtComponent},
    ]
    var rightClicked = [
                        // {label:'Create'},
                        // {label:'Compare with Server Script'}
                        ]
    
    const showPopupFunction = () => {
        var body = {
            'addLogs':{
                title:'Add Logs For Data Difference',
                body:<div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',marginTop:'30px'}}> 
                        <div className='logsIconContainer' onClick={(e)=>{document.getElementById('logsFileUpload').click(e);}}>
                            <input accept='.saz' id='logsFileUpload' type='file' hidden onChange={handleChooseLogFile}/>
                            {logFileName
                            &&
                            <img src={FiddlerImg} alt='' width={'80px'}/>}
                             {!logFileName
                             &&
                            'Select Logs'}
                        </div>
                        {logFileName
                        &&
                        <div style={{fontSize:'12px',marginTop:'10px'}}>
                            {logFileName}
                        </div>
                        }
                        <div style={{marginTop:'20px',transform:'scale(0.8)',display:'flex',alignItems:'center'}}>
                            <div> Type : &nbsp; &nbsp; &nbsp; &nbsp;</div>
                            <ReactSelect                                                
                                placeholder={'Select Type.'}
                                // isMulti
                                options={[{label:'Script',value:'script'},{label:'Flow',value:'flow'}]}
                                value={uploadType}
                                onChange={(e)=>{setUploadType(e)}}
                                menuPortalTarget={document.body} 
                                styles={{...Constants.ReactSelectStyles}}
                            />
                        </div>
                        {
                            uploadType && uploadType.value === 'script'
                            &&
                            <div style={{marginTop:'20px',transform:'scale(0.8)',display:'flex',alignItems:'center'}}>
                                <div> Vertical : &nbsp; &nbsp; &nbsp; &nbsp;</div>
                                <ReactSelect                                                
                                    placeholder={'Select Type.'}
                                    // isMulti
                                    options={[{label:'Shopping',value:'shopping'},{label:'Finance',value:'finance'},{label:'Other',value:'other'}]}
                                    value={uploadVertical}
                                    onChange={(e)=>{setUploadVertical(e);if(e.value === 'shopping'){setRetentionDays(45)};if(e.value === 'finance'){setRetentionDays(25)};if(e.value === 'others'){setRetentionDays(70)}}}
                                    menuPortalTarget={document.body} 
                                    styles={{...Constants.ReactSelectStyles}}
                                />
                                <div style={{transform:'scale(0.9)',width:'125px'}}>
                                    <CssTextField label='Retention' size='small' value={retentionDay} onChange={e=>{setRetentionDays(e.target.value)}}/>
                                </div>
                            </div>
                        }
                        <div style={{display:'flex',alignItems:'center',fontSize:'14px',marginTop:'20px'}}>
                            <Button disabled={addLogsLoader || !logFileName} color='warning' variant='outlined' size='small' sx={{minHeight:'35px',minWidth:'120px'}} onClick={pushLogsApiRequest}>
                                {addLogsLoader && <CircularProgress size={'14px'} sx={{color:'orange',fontSize:'12px'}}/>}
                                {!addLogsLoader && 'Upload Logs'}
                            </Button>
                        </div>
                    </div>
            },
            'uploadOrSelectFile':{
                title:'Create Flow or Script',
                body:<div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',marginTop:'40px'}}> 
                        <Button disabled={!appflowLink || !filename } color='warning' variant='outlined' size='small' sx={{minHeight:'35px',minWidth:'120px',marginTop:'30px'}} onClick={getDataDifference}>
                            {addLogsLoader && <CircularProgress size={'14px'} sx={{color:'orange',fontSize:'12px'}}/>}
                            {!addLogsLoader && 'Submit'}
                        </Button>
                    </div>
            }
        }
        return <div className='addTabContainer'>
                    <div className='addTabWrapper'>
                        <div style={{display:'flex',justifyContent:'space-between',alignItems:'center',cursor:'default'}}>
                            <div>{body[showPopup].title}</div>
                            <div style={{cursor:'pointer'}} onClick={()=>{setShowPopup(false);}}>X</div>
                        </div>
                        {body[showPopup].body}
                        <div style={{display:'flex',justifyContent:'center'}}>
                            {/* <Button disabled={!addTabData.file || !addTabData.noOfDays || !addTabData.noOfUser} sx={{...Constants.CONTAINEDBUTTON,textTransform:'none',fontWeight:'400',marginTop:'20px'}} onClick={()=>{addDebug()}}> 
                                {addLoader && <CircularProgress sx={{color:'white'}} size={'18px'}/>}
                                {!addLoader && "Submit"}
                            </Button> */}
                        </div>
                    </div>
                </div>
    }

     const downloadFile = (data,file) => {
        const urldownload = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = urldownload;
        link.setAttribute('download', file); //or any other extension
        document.body.appendChild(link);
        link.click();
    }
    const downloadData = async(item) => {
        await axios({
            method: "GET",
            url: Constants.LEVELCREATOR_BACKENDHOST+"action/info",
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token,
                "Content-Type": "application/json",
            },
            params:{
                "logs_id": item.id,
            }
        })
        .then(resp => {
            try{
                var filename = item.logs_file.split('/')[1].split('_')[0]
            }
            catch{
                filename = 'result'
            }
            if(resp.headers['content-type'] === 'text/csv'){
                downloadFile(resp.data,filename+'.csv')
            }
            else{
                downloadFile(resp.data,filename+'.py')
            }
        })
        .catch(err=>{
            
        })
    }
    const openLogsFile = (item) => {
        setSelectedItem(item);
        if(item.status===2){
            downloadData(item)
        }
        else{
            var label = statusHistoryDict[item.status.toString()].label
            Store.addNotification({
                title: "Error!",
                message: label ? label : "Logs are being processed please wait for a while.",
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 5000,
                    onScreen: true
                }
            });
            if(item.status===-5){
                setLoaderPopup('devkey')
            }
        }
    }
    
    return (
        <div>
            <TopbarV2 title='Level 1 Creator'/>
            <div style={{display:'flex'}}>
                <HistoryComponent
                    historyWindowWidth={historyWindowWidth}
                    setHistoryWindowWidth={setHistoryWindowWidth}
                    openTabs={openLogsFile}
                    historyResponse={historyResponse}
                    setHistoryResponse={setHistoryResponse}
                    historyResponseLoader={historyResponseLoader}
                    setHistoryResponseLoader={setHistoryResponseLoader}
                    getDebugHistory={getLogsUploadHistory}
                    historyPage={historyPage}
                    setHistoryPage={setHistoryPage}
                    historyPages={historyPages}
                    historyFor={'Log'}
                    historyHeaders={historyHeaders}
                    hintVariable={'Red: Not Uploaded, Green: Uploaded.'}
                    rightClicked={rightClicked}
                />
                
                <div style={{position:'relative',width:'calc(100% - '+historyWindowWidth+'px - 00px)'}}>
                    <div className='debugHistoryHead' >
                        <div style={{position:'absolute',width:'100%',display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                            <div style={{width:'90%',display:'flex',alignItems:'center',overflowX:'auto'}}>
                                {openTabsList.map((item,index)=>{
                                    return <div id='tabsContainer' className='openTabContainer' style={{height:(index===activeTab ? 'calc(40px - 5px)' : '40px'),color:(index===activeTab ? 'black' : 'rgb(100,100,100 )'),borderBottom:(index===activeTab ? '5px solid orange' : '1px solid rgb(200,200,200)')}} key={index} onClick={()=>{setActiveTab(index);}}>
                                            <div id='tabsContainer' style={{width:'90%',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',fontSize:'12px',fontWeight:'500'}}>
                                                {item.name}
                                            </div>
                                            <div id='tabsContainer' style={{cursor:'pointer'}} onClick={(e)=>{e.stopPropagation();closeTab(item)}}>
                                                X
                                            </div>
                                        </div>
                                })}
                            </div>
                            <div className='addBoxContainer' style={{cursor:'pointer',width:'70px'}} onClick={()=>{setShowPopup('addLogs');}}>
                                Add Logs
                            </div>
                        </div>
                    </div>
                    {
                        showPopup &&
                        showPopupFunction()
                    }
                    {   
                        openTabsList.length>0
                        &&
                        (activeTab || activeTab===0)
                        &&
                        <div style={{overflow:'auto',height:'calc(100vh - 40px - 90px)',paddingTop:'15px',paddingLeft:'20px',paddingRight:'20px'}}>
                            <div style={{display:'flex',alignItems:'center',justifyContent:'space-around'}}>
                                <div>
                                    Script download
                                </div>
                                <div>
                                    <Button sx={{...Constants.CONTAINEDBUTTON,fontSize:'12px'}} onClick={()=>{FileDownload(openTabsList[activeTab].file,openTabsList[activeTab].name);}}>Download</Button>
                                </div>
                            </div>
                        </div>
                    }
                </div>
                
                {loaderPopup
                    &&
                <div style={{position:'fixed',top:0,left:0,width:'100vw',height:'100vh',backdropFilter:'blur(2px)',zIndex:10}} onClick={()=>{setLoaderPopup(false);}}>
                    <div style={{display:"flex",justifyContent:'center'}}>
                        <div style={{minHeight:'30vh',minWidth:'50vw',background:'white',border:'1px solid rgb(200,200,200)',boxShadow:'0 0 5px 1px rgb(200,200,200)',marginTop:'15vh'}} onClick={(e)=>{e.stopPropagation();}}>
                            <div style={{display:'flex',height:'30px',alignItems:'center',justifyContent:'space-between',paddingTop:'15px',paddingBottom:'15px',paddingLeft:'20px',paddingRight:'20px',fontWeight:'500'}}>
                                <div>{loaderPopupDetails[loaderPopup].header}</div>
                                <div style={{cursor:'pointer'}} onClick={()=>{setLoaderPopup(false);}}>X</div>
                            </div>
                            <div style={{paddingTop:'10px',paddingBottom:'15px',paddingLeft:'25px',paddingRight:'25px'}}>
                                {loaderPopupDetails[loaderPopup].popupValue}
                            </div>
                        </div>
                    </div>
                </div>
                }
            
            </div>
        </div>
    )
}

export default CT;