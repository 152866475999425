import React, { useEffect, useState } from 'react'
// import { NavLink } from 'react-router-dom';
import './topbar.css';
// import ReplayIcon from '@mui/icons-material/Replay';
// import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import NotificationsIcon from '@mui/icons-material/Notifications';
import CircleIcon from '@mui/icons-material/Circle';

import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { capitalize, getLocalStorageValue, removeLocalStorageValue, useForceUpdate } from '../../helper/util';
import { Replay } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import VolcanoIcon from '@mui/icons-material/Volcano';
import { isUserBT } from '../../pages/stint/stintCustomizations/stintCustomizations';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import AdjustIcon from '@mui/icons-material/Adjust';

const firebaseConfig = {
    apiKey: "AIzaSyBmQHCxNzDgGczPFi2fMdcDpTGWoAxzcO8",
    authDomain: "autolabs-b043a.firebaseapp.com",
    projectId: "autolabs-b043a",
    storageBucket: "autolabs-b043a.appspot.com",
    messagingSenderId: "750138625090",
    appId: "1:750138625090:web:64d3679a15ee8b3d00b1db",
    measurementId: "G-MZSXPQBENT"
};


const TopbarV2 = (props) => {


    const refreshComponent = useForceUpdate()
    const [isTokenFound, setTokenFound] = useState(false);
    const [show, setShow] = useState(false);
    const [notificationMenu, setShowNotificationMenu] = useState(false);
    const [notification, setNotification] = useState(localStorage.getItem('notifications') ? JSON.parse(localStorage.getItem('notifications')) : []);
    const [notificationUnread, setNotificationUnread] = useState(false);
    try{
        const firebaseApp = initializeApp(firebaseConfig);
        const messaging = getMessaging(firebaseApp);

        const getTokenFunction = (setTokenFound) => {
            return getToken(messaging, {vapidKey: 'BCeRdYwocTPpAbbNCfr-8B2ksUqtPrs-Y1LZ0JoGzuXY-rrJrn7ZQL-zDjbySPTBwLiR5wA_QUp52eW226m9xNc'}).then((currentToken) => {
            if (currentToken) {
                setTokenFound(true);
                // Track the token -> client mapping, by sending to backend server
                // show on the UI that permission is secured
            } else {
                console.log('No registration token available. Request permission to generate one.');
                setTokenFound(false);
                // shows on the UI that permission is required 
            }
            }).catch((err) => {
            // catch error while creating client token
            });
        }

        const onMessageListenerFunction = () =>
            new Promise((resolve) => {
                onMessage(messaging, (payload) => {
                resolve(payload);
                });
        });
        getTokenFunction(setTokenFound);

        if(show){
            console.log(isTokenFound)
        }
        if(notificationUnread===false){
            var tempNotificationsUnread = 0 
            for(var notIndex in notification){
                if(notification[notIndex].unread){
                    tempNotificationsUnread+=1
                }
            }
            setNotificationUnread(tempNotificationsUnread)
        }
        onMessageListenerFunction().then(payload => {
            setShow(true);
            var previousNotifications = localStorage.getItem('notifications') ? JSON.parse(localStorage.getItem('notifications')) : []
            var foundFlag = false
            for(var index in previousNotifications){
                if(previousNotifications[index].title === payload.notification.title && previousNotifications[index].title === payload.notification.body){
                    foundFlag = true
                }
            }
            if(!foundFlag){
                setNotificationUnread(notificationUnread+1)
                var notifications = [...previousNotifications,{title: payload.notification.title, body: payload.notification.body,unread:true,fullNotification:payload}]
                localStorage.setItem('notifications',JSON.stringify(notifications))
                setNotification(notifications)
            }
        }).catch(err => console.log('failed: ', err));
    }
    catch{
        
    }

    
    const [userLogoHovered, setUserLogoHovered] = useState(false)

    useEffect(() => {
        document.addEventListener('click',e=>{
            setShowNotificationMenu(false);
            setUserLogoHovered(false)     
        })
      
        return () => {
            document.removeEventListener('click',e=>{
                setShowNotificationMenu(false);
                setUserLogoHovered(false)     
            })
        }
      }, [])
      
      
    const getNotifications = () => {
        if(notification.length===0){
            return <div style={{color:'rgb(130,130,130)',fontSize:'14px',display:'flex',alignItems:'center',justifyContent:'center',minHeight:'50px'}}>
                No New Notifications
            </div>
        }
        else{
            return notification.map((item,index)=>{
                return <div className='notificationTile' onClick={()=>{var tempNotifications = notification; tempNotifications[index].unread=false;setNotification(tempNotifications);localStorage.setItem('notifications',JSON.stringify(tempNotifications));setNotificationUnread(notificationUnread-1);refreshComponent(123);}}>
                    <div style={{fontSize:'16px'}}>{item.title}</div>
                    <div style={{fontSize:'13px'}}>{item.body}</div>
                    {item.unread && <div style={{position:'absolute',top:'18px',right:0,width:'20px'}}>
                    <CircleIcon sx={{fontSize:'10px',color:'orange'}}/>    
                    </div>}
                </div>
            })
        }
    }

    const resetPageData = () => {
        removeLocalStorageValue(window.location.pathname+'historyWindowWidth')
        window.location.reload();
    }

    const allowed_to_see_localfire = !isUserBT() && getLocalStorageValue('adminPanelLoginData').allowedPages && getLocalStorageValue('adminPanelLoginData').allowedPages.includes('can_access_local_fire')
    const showResetButton = () => {
        if(getLocalStorageValue(window.location.pathname+'historyWindowWidth')){
            return <div className='topbarV2IconHolder' style={{position:'absolute',right:allowed_to_see_localfire ? '335px' : '285px',zIndex:'11'}} onClick={(e)=>{e.stopPropagation();resetPageData();}}>
                <Tooltip title='Reset Page Data'>
                    <Replay sx={{fontSize:'17px'}}/>
                </Tooltip>
            </div>
        }
        return ;
        
    }

    var usernameLogo = getLocalStorageValue('adminPanelLoginData').username ? getLocalStorageValue('adminPanelLoginData').username[0].toUpperCase() : 'U'
    return (
        <div className='topbarV2Container' style={props.sticky ? {position:'sticky',top:0} : {}}>
            <div style={{display:'flex',alignItems:'center'}}>
                <div className='topbarV2PageTitle'>
                    {props.title}
                </div>
                {/* <ReplayIcon className='reloadButton' sx={{fontSize:'18px'}}/> */}
            </div>
            <div style={{display:'flex',alignItems:'center'}}>
                {showResetButton()}

                {allowed_to_see_localfire
                &&
                <div className='topbarV2IconHolder' style={{position:'absolute',right:'235px',zIndex:'11'}}>
                    <a target='_blank' style={{color:'orange'}} href={'http://singlelinkv2.appanalytics.in.s3-website-eu-west-1.amazonaws.com/authentication/sign-in?token='+getLocalStorageValue('adminPanelLoginData').token}>
                        <Tooltip title={'Go To Local Fire'}>
                            <LocalFireDepartmentIcon sx={{fontSize:'17px'}} />
                        </Tooltip>
                    </a>
                </div>
                }
                {!isUserBT()
                &&
                <div className='topbarV2IconHolder' style={{position:'absolute',right:'285px',zIndex:'11'}}>
                    <a target='_blank' style={{color:'brown'}} href={'https://autolabs-bk.appanalytics.in/loginpage?token='+getLocalStorageValue('adminPanelLoginData').token}>
                        <Tooltip title={'Go To Adjust Creator'}>
                            <AdjustIcon sx={{fontSize:'17px'}} />
                        </Tooltip>
                    </a>
                </div>
                }
                {!isUserBT()
                &&
                <div className='topbarV2IconHolder' style={{position:'absolute',right:'185px',zIndex:'11'}}>
                    <a target='_blank' style={{color:'brown'}} href={'http://updater.appanalytics.in/?token='+getLocalStorageValue('adminPanelLoginData').token+'&accessAllowed='+'/newapp'}>
                        <Tooltip title={'Go To Updater'}>
                            <VolcanoIcon sx={{fontSize:'17px'}} />
                        </Tooltip>
                    </a>
                </div>
                }
                {!isUserBT()
                &&
                <div className='topbarV2IconHolder' style={{position:'absolute',right:'135px',zIndex:'11'}}>
                    <a target='_blank' style={{color:'blue'}} href={'http://automodds.appanalytics.in/?token='+getLocalStorageValue('adminPanelLoginData').token}>
                        <Tooltip title={'Go To Automodds'}>
                            <AcUnitIcon sx={{fontSize:'17px'}} />
                        </Tooltip>
                    </a>
                </div>
                }
                <div className='topbarV2IconHolder' style={{position:'absolute',right:'80px',zIndex:'11'}} onClick={(e)=>{e.stopPropagation();setShowNotificationMenu(true);setUserLogoHovered(false);}}>
                    <NotificationsIcon sx={{fontSize:'17px'}}/>
                    {notificationUnread>0 && 
                    <div style={{position:'absolute',bottom:'7px',right:'7px',background:'white',width:'8px',height:'8px',fontSize:'12px',display:'flex',alignItems:'center',justifyContent:'center',color:'orange',fontWeight:'500'}}>
                        {notificationUnread}
                    </div>
                    }
                    <div className={notificationMenu ? 'topbarV2ProfileDropdownContainer showListMenu' : 'topbarV2ProfileDropdownContainer hideListMenu'}>
                        <div className='topbarNoticationMenu'>
                            {getNotifications()}
                        </div>
                    </div>
                </div>
                <div className='topbarV2IconHolder' onClick={(e)=>{e.stopPropagation();setUserLogoHovered(true);setShowNotificationMenu(false)}} >
                    {usernameLogo}
                    {userLogoHovered && 
                        <div className='topbarV2ProfileDropdownContainer'>
                            <div className={userLogoHovered ? 'topbarV2ProfileDropdown' : 'topbarV2ProfileDropdown topbarV2ProfileDropdownHide'}>
                                <div className='topbarV2ProfileDropdownItem'>
                                    {capitalize(getLocalStorageValue('adminPanelLoginData').username)}
                                    {/* <NavLink 
                                        style={{textDecoration:"none",color:"black"}}
                                        to={{
                                            pathname:"/account/profile",
                                            }}>
                                        {localStorage.getItem('email')}
                                    </NavLink> */}
                                </div>
                                <div className='topbarV2ProfileDropdownItem' onClick={()=>{localStorage.clear(); window.location = "/";}}>
                                    Logout
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default TopbarV2