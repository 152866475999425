import { Button, CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react'
import TopbarV2 from '../../../components/topbarv2/topbarv2'
import { getLocalStorageValue, useForceUpdate } from '../../../helper/util';
import './uploadCampaign.css';
import Loader from 'react-animated-loader'; // import the package
import { CssTextField } from '../../../data/constants';
import ReactSelect from 'react-select';
import * as Constants from '../../../data/constants';
import CreatableSelect from 'react-select/creatable';
import axios from 'axios';
import { Store } from 'react-notifications-component';
import Popup from '../../../components/popup/popup';
import FileUploadIcon from '@mui/icons-material/FileUpload';

import {diffLines, formatLines} from 'unidiff';
import {parseDiff, Diff, Hunk} from 'react-diff-view';
import 'react-diff-view/style/index.css';
import { Close } from '@mui/icons-material';

const EMPTY_HUNKS = [];

const UploadCampaignV2 = () => {
    const [scriptUploaded, setScriptUploaded] = useState(false)
    const [filesSelected, setFilesSelected] = useState([])
    const [uploadLoader, setUploadLoader] = useState(false)
    const [forcePushCheck, setForcePushCheck] = useState(false)
    const [showPopup, setShowPopup] = useState(false)
    const [scriptData, setScriptData] = useState({})
    const [changesDone, setChangesDone] = useState([])
    const [developerOptions, setDeveloperOptions] = useState([])
    const [teamData, setTeamData] = useState({})
    const refreshComponent = useForceUpdate();
    var detailsDict = {
        'Status Of Script' : [
            {label:'Conversion Status',value:'cnv_true',field:'select',options:['Yes','No','Need Link','Issue | No Fire']},
            {label:'Final App Status',value:'final_app_status',field:'select',options:['Install Completed','Completed','Updated']},
            // {label:'Level Status',value:'',field:'select'},
            {label:'Purchase Status',value:'purchase',field:'creatableSelect',options:['Yes','No']},
            {label:'Events Found in Smali',value:'smali',field:'creatableSelect',options:['All Found','Partially Found','No Token Found']},
        ],
        'KPI Notes': [
            {label:'KPI Action*',value:'kpi_action',field:'creatableSelect',options:['Performed and Event Occured','Performed but the event didnt occur','Could not perform']},
            {label:'KPI Event*',value:'kpi_event',field:'creatableSelect',options:['Added as SDK','Added as S2S']},
            {label:'KPI Event Value*',value:'kpi_event_value',field:'creatableSelect',options:['Blank','Created Manually','Event value Report','Other Pubs report','Copied from another event']},
            {label:'KPI Event Source*',value:'kpi_event_source',field:'creatableSelect',options:['Business Team','Java','Smali']},
        ],
        'File Information' : [
            // {label:'App Version',value:'',field:'textfield'},
            // {label:'Tracking',value:'',field:'select'},
            // {label:'Sdk Version',value:'',field:'textfield'},
            {label:'Self Calls',value:'selfcalls',field:'creatableSelect',options:['Mandatory Done','All Done','Partially Done','Not Done','No self calls']},
            {label:'CRM Calls',value:'CRM',field:'creatableSelect',options:['Amplitude','Clevertap','Mixpanel','Moengage','Segment','No CRM Found']},
        ],
        'Developer Status' : [
            {label:'Developer Team',value:'dev team',field:'select',options:['Team1','Team2','Team3']},
            {label:'Developer Name',value:'developer',field:'select',options:developerOptions},
            {label:'Developer Comment',value:'dev comment',field:'textfield',multiRow:true},
            // {label:'Developer Issue',value:'dev issue',field:'textfield',multiRow:true},
        ],
        'Notes' : [
            // {label:'Level Note',value:'lvl note',field:'creatableSelect',options:Constants.LVL_NOTE},
            {label:'CRM Note',value:'dev issue',field:'textfield',multiRow:true}, // dev issue is used from backend.
        ],
    }

    const [code, setCode] = useState(false)
    const [showComparePopup, setShowComparePopup] = useState(false)
    const [downloadScriptLoaders, setShowDownloadScriptLoaders] = useState(false)
    const [changesVerified, setChangesVerified] = useState(false)

    const handleDownloadFile = async(e,filename,viewOnly,result) => {
        setShowDownloadScriptLoaders(true);
        e.stopPropagation();
        var file= filename
        if(!file.endsWith('.py')){
            file+='.py'
        }
        return await axios({
            method: "GET",
            url: Constants.BACKENDHOST2 + 'campaigns/download-script/'+file, //Constants.BACKENDHOST2+"tools/create_flow_report",
            headers:{
                "Content-Type": "multipart/form-data",
                Authorization:getLocalStorageValue('adminPanelLoginData').token,
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache',
                'Expires': '0',
            },
            params:{
                comment:'Just Check',
            },
            // responseType:'blob'
        }).then(response => {
            if(response.data.data && response.data.data.error){
                Store.addNotification({
                    title: "Warning!",
                    message:  file + ' ' + response.data.data.error.toLowerCase(),
                    type: "warning",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });
            }
            else{
                if(viewOnly){
                    result.oldCode = response.data
                    setShowDownloadScriptLoaders(false);
                    return response.data
                }
            }
        })
        .catch(err=>{
            setShowDownloadScriptLoaders(false);
            console.log(err)
            if(err.response && err.response.data){
                Store.addNotification({
                    title: "Error!",
                    message:  err.response.data.message,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });
            }
        });
    }
    const compareFile = async(oldFile) => {

    }
	const handleChooseLogFile = async(e) => {
        if(e.target.files[0]){
            var tempFilesSelected = [e.target.files[0]]
            if(e.target.files[0].name){
                setScriptUploaded(e.target.files[0].name)
                getFileData(e.target.files[0].name)
            }
            setFilesSelected(tempFilesSelected)
        }
        refreshComponent(123)
        e.target.value =null;
	};
    var diffText = formatLines(diffLines(code ? code.oldCode : '',code ? code.newCode : ''), {context: 3});
    const [diff] = parseDiff(diffText, {nearbySequences: 'zip'});

    useEffect(() => {
        getTeamData()

        return () => {
            
        }
    }, [])
    
    const getTeamData = async() => {
        await axios({
            method: "GET",
            url: Constants.BACKENDHOST + 'campaigns/get-team-data', //Constants.BACKENDHOST+"tools/create_flow_report",
            headers:{
                "Content-Type": "multipart/form-data",
                Authorization:getLocalStorageValue('adminPanelLoginData').token,
            },
        }).then(resp => {
            setTeamData(resp.data.data.data)
        })
        .catch(err=>{
            console.log(err)
            if(err.response && err.response.data){
                Store.addNotification({
                    title: "Error!",
                    message: err.response.data.message,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });
            }
        });
    }

    const showDeveloperOptions = (teamSelected,changeDeveloper=false) => {
        var tempDeveloperOptions = []
        for(var team in teamData){
            if(team === teamSelected){
                for(var member in teamData[team]){
                    if(teamData[team][member].department === 'dev'){
                        var name = teamData[team][member]['full-name']
                        name = name.split(" ")[0]
                        if(name === 'Md' || name === 'Mohd'){
                            name = teamData[team][member]['full-name'].split(" ")[1]
                        }
                        tempDeveloperOptions.push(name)
                    }
                }
            }
        }
        if(changeDeveloper){
            setScriptData({...scriptData,'developer':tempDeveloperOptions[0],'dev team':teamSelected})
            var tempChangesDone = changesDone
            if(!tempChangesDone.includes('developer')){
                tempChangesDone.push('developer')
            }
            if(!tempChangesDone.includes('dev team')){
                tempChangesDone.push('dev team')
            }
            setChangesDone(tempChangesDone)

        }
        setDeveloperOptions(tempDeveloperOptions)
    }
    const getFileData = async(filename) => {
        await axios({
            method: "GET",
            url: Constants.BACKENDHOST + 'campaigns/get-combined-data/'+filename.replace('.py',''), //Constants.BACKENDHOST+"tools/create_flow_report",
            headers:{
                "Content-Type": "multipart/form-data",
                Authorization:getLocalStorageValue('adminPanelLoginData').token,
            },
        }).then(resp => {
            var tempscriptData = resp.data.data
            tempscriptData['scriptType'] = 'existing'
            var temp_lvl_note_list = resp.data.data['lvl note'].split(' | ')
            var kpi_list = ["kpi_action",
                'kpi_event',
                "kpi_event_value",
                "kpi_event_source"]
            for(var i in temp_lvl_note_list){
                tempscriptData[kpi_list[i]] = temp_lvl_note_list[i]
            }
            setScriptData(tempscriptData)
            showDeveloperOptions(tempscriptData['dev team'])
            refreshComponent(12);
        })
        .catch(err=>{
            setScriptData({scriptDataNotRequired:true})
            console.log(err)
            if(err.response && err.response.data && err.response.data.message){
                Store.addNotification({
                    title: "Cannot Fetch Script Details!",
                    message: err.response.data.message,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });
            }
            else{
                Store.addNotification({
                    title: "Cannot Fetch Script Details!",
                    message: "Some error occured with our backend server. Please contact Tech team.",
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });
            }
        });
    }
    const handleUploadFiles = async(e) => {      
        e.stopPropagation();
        setUploadLoader(true)
        var commitMessage = []
        var scriptType = []
        var additionalComment = []
        var formData = new FormData();
        for (var index in filesSelected) {
            formData.append("script", filesSelected[index]);
            commitMessage.push(scriptData.commitMessage)
            scriptType.push(scriptData.scriptType)
            additionalComment.push(scriptData.additionalMessage)
        }
        formData.append('is_combined_entry',true);
        formData.append("scriptType", scriptType.length>0 ? scriptType : '');
        formData.append("message", commitMessage.length>0 ? commitMessage : '');
        formData.append("comment", additionalComment.length>0 ? additionalComment : '');
        if(forcePushCheck){
            formData.append("forced", true);
        }
        changesDone.forEach(change => {
            formData.append(change, scriptData[change]);
        });
        var lvl_note = ''
        if(scriptData.kpi_action){
            lvl_note += scriptData.kpi_action + " | "
            if(scriptData.kpi_event){
                lvl_note += scriptData.kpi_event + " | "
                if(scriptData.kpi_event_value){
                    lvl_note += scriptData.kpi_event_value + " | "
                    if(scriptData.kpi_event_source){
                        lvl_note += scriptData.kpi_event_source
                    }
                }
            }
            formData.append('lvl note',lvl_note)
        }
        await axios({
            method: "POST",
            url: Constants.BACKENDHOST2 + 'campaigns/v2/upload-script', //Constants.BACKENDHOST+"tools/create_flow_report",
            headers:{
                "Content-Type": "multipart/form-data",
                Authorization:getLocalStorageValue('adminPanelLoginData').token,
            },
            data: formData,
        }).then(resp => {
            setShowPopup(resp)
        })
        .catch(err=>{
            console.log(err)
            if(err.response && err.response.data){
                setShowPopup(err.response)
                Store.addNotification({
                    title: "Error!",
                    message:  err.response.data.message,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                      duration: 5000,
                      onScreen: true
                    }
                });
            }
        });
        setUploadLoader(false)
        setShowComparePopup(false)
    }

    const scriptTypeValues = {
        existing : 'Existing Script',
        newapp : 'Newapp',
        // modds : 'Modds'
    }
    const handlePopupFunction = (resp) => {
        if(resp.status == 440){
            var popupData = <div style={{fontSize:'13px'}}>
                {resp.data.data.error}

                <div style={{marginTop:'20px'}}>
                    <div>
                        <input id='forcepushCheckbox' type={'checkbox'} onChange={(e)=>{setForcePushCheck(e.target.checked)}}/>
                        <label htmlFor='forcepushCheckbox' style={{fontSize:'12px'}}>Select this for force push</label>
                    </div>
                    <div style={{display:'flex',justifyContent:'center',}}>

                        <Button disabled={!forcePushCheck} variant='contained' color='warning' size='small' onClick={(e)=>{handleUploadFiles(e,true)}}>
                            {uploadLoader &&
                                <CircularProgress size={'28px'} sx={{color:'white'}}/>
                            }
                            {!uploadLoader &&
                            <>
                            <FileUploadIcon sx={{fontSize:'18px',color:'orange'}}/>Submit
                            </>
                            }
                        </Button>
                    </div>
                </div>

            </div>
            var popupHead = 'Error'
        }
        else if(resp.data && resp.data.data && resp.data.data.scripts){
            popupData = <div>
                {/* {resp.data.message} */}

                <table style={{tableLayout:'fixed',width:'100%',fontSize:'13px'}}>
                    <thead>
                        <tr style={{fontSize:'14px',fontWeight:'500'}}>
                            <td>
                                Scriptname
                            </td>
                            <td>
                                Status
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.keys(resp.data.data.scripts).map((item,index)=>{
                            return <tr key={index}>
                                <td style={{padding:'5px'}}>{item}</td>
                                <td style={{color:resp.status===200 ? 'green' : 'red'}}>{resp.data.data.scripts[item]}</td>
                            </tr>
                        })}
                    </tbody>
                </table>
            </div>
            popupHead = 'Success'
        }
        else if(resp.data && resp.data.data && resp.data.data.error){
            popupData = <div style={{fontSize:'13px'}}>
                {resp.data.data.error}
                
                <div style={{marginTop:'20px'}}>
                    <div>
                        <input id='forcepushCheckbox' type={'checkbox'} onChange={(e)=>{setForcePushCheck(e.target.checked)}}/>
                        <label htmlFor='forcepushCheckbox' style={{fontSize:'12px'}}>Select this for force push</label>
                    </div>
                    <div style={{display:'flex',justifyContent:'center',}}>
                            
                        <Button disabled={!forcePushCheck} variant='contained' color='warning' size='small' onClick={(e)=>{handleUploadFiles(e,true)}}>
                            {uploadLoader &&
                                <CircularProgress size={'28px'} sx={{color:'white'}}/>
                            }
                            {!uploadLoader &&
                            <>
                            <FileUploadIcon sx={{fontSize:'18px',color:'orange'}}/>Submit
                            </>
                            }
                        </Button>
                    </div>
                </div>
                
            </div>
            popupHead = 'Error'

        }
        return {data:popupData,head:popupHead}
    }
    const changesDoneFunction = (key) => {
        if(!changesDone.includes(key)){
            setChangesDone([...changesDone,key])
        }
    }
    const closePopupFunction = () => {
        if(showPopup.data.response_code===200){
            setShowPopup(false)
            setScriptUploaded(false)
            setFilesSelected([])
            setUploadLoader(false)
            setForcePushCheck(false)
            setScriptData({})
            setChangesDone([])
        }
        else{
            setShowPopup(false)
        }
    }
    return (
        <div onClick={e=>{e.stopPropagation()}}>
            <TopbarV2 title='Upload Campaign'/>
            {showPopup && <Popup head={handlePopupFunction(showPopup).head} data={handlePopupFunction(showPopup).data} handleClose={()=>{closePopupFunction()}}/>}
            <div style={{display:'flex',alignItems:'center',justifyContent:'center',flexDirection:'column',paddingTop:'25px'}}>

                <div className='fileSelectWrapper'>
                    <div style={{paddingRight:'20px'}}>
                        {scriptUploaded ? scriptUploaded : 'No File Selected.'}
                    </div>
                    <div  style={{transform:'scale(0.8)',width:'20%',transformOrigin:'left'}}>
                        <CreatableSelect  
                            value={scriptData.commitMessage ? {label:scriptData.commitMessage,value:scriptData.commitMessage} : ''}
                            onChange={(e)=>{setScriptData({...scriptData,commitMessage:e.value});changesDoneFunction('commitMessage');refreshComponent(12);}}           
                            isClearable
                            placeholder={'Commit Message'}
                            options={Constants.UploadCommitMessages.map((item,index)=>{return {label:item,value:item}})}
                            menuPortalTarget={document.body} 
                            styles={{...Constants.ReactSelectStyles}}
                        />
                    </div>
                    <div style={{transform:'scale(0.8)',width:'20%',transformOrigin:'left'}}>
                        <Constants.CssTextField    
                            placeholder='Additional Comment*'    
                            fullWidth 
                            value={scriptData.additionalMessage ? scriptData.additionalMessage : ''}
                            onChange={(e)=>{setScriptData({...scriptData,additionalMessage:e.target.value});changesDoneFunction('additionalMessage');refreshComponent(12);}}           
                            size='small'                                       
                            // onChange={(e)=>{var tempFilesSelected = filesSelected; tempFilesSelected[index]['additionalMessage'] = e.target.value; setFilesSelected(tempFilesSelected);refreshComponent(123);checkDisableUploadFileButton(tempFilesSelected);}}
                        />
                    </div>
                    {getLocalStorageValue('adminPanelLoginData').allowedPages.includes('can_push_on_behalf_of')
                    &&
                    <div style={{width:'20%',transform:"scale(0.7)",transformOrigin:'left'}}>
                        <ReactSelect                                                
                            placeholder={'Pushed On Behalf of'}
                            value={scriptData.behalf_of ? {label:scriptData.behalf_of,value:scriptData.behalf_of} : false}
                            options={getLocalStorageValue('adminPanelLoginData').managing.map((item,index)=>{return {label:item,value:item}})}
                            onChange={(e)=>{setScriptData({...scriptData,behalf_of:e.value});changesDoneFunction('behalf_of');refreshComponent(12);}}
                            menuPortalTarget={document.body} 
                            styles={{...Constants.ReactSelectStyles}}
                        />
                    </div>
                    }
                    <div style={{width:'20%',transform:"scale(0.7)",transformOrigin:'left'}}>
                        <ReactSelect                                                
                            placeholder={'Script Type'}
                            value={scriptData.scriptType ? {label:scriptTypeValues[scriptData.scriptType],value:scriptData.scriptType} : false}
                            options={Object.keys(scriptTypeValues).map((item,index)=>{return {label:scriptTypeValues[item],value:item}})}
                            onChange={(e)=>{setScriptData({...scriptData,scriptType:e.value});changesDoneFunction('scriptType');refreshComponent(12);}}
                            menuPortalTarget={document.body} 
                            styles={{...Constants.ReactSelectStyles}}
                        />
                    </div>
                    <div>
                        <Button color='warning' onClick={()=>{document.getElementById('chooseFileButton').click();}}>Choose File</Button>
                        <input id='chooseFileButton' type='file' hidden accept='.py' onChange={handleChooseLogFile}/>
                    </div>
                </div>
                {
                    showComparePopup
                    &&
                    code
                    &&
                    (code.oldCode || code.oldCode==='')
                    &&
                    code.newCode 
                    &&
                    <div style={{position:'fixed',top:0,left:100,height:'100vh',width:'calc(100vw - 100px)',zIndex:'1000',display:'flex',alignItems:'center',justifyContent:'center',background:'rgba(100,100,100,0.3)'}} onClick={()=>{setShowComparePopup(false)}}>
                        <div style={{height:'90vh',width:'80vw',background:"white",border:'1px solid rgb(200,200,200)',position:'relative',boxShadow:'0 0 10px 5px rgb(200,200,200)'}} onClick={(e)=>{e.stopPropagation();}}>
                            <div style={{position:'sticky',top:0,fontWeight:'500',background:'white',display:'flex',justifyContent:'space-between',height:'50px',alignItems:'center',paddingLeft:'10px',paddingRight:'10px',borderBottom:'1px solid rgb(200,200,200)'}}>
                                <div>File Comparison &nbsp;{scriptUploaded+'(old)'+" <-> " +scriptUploaded+"(new)"}</div>
                                <div onClick={()=>{setShowComparePopup(false);}}><Close sx={{color:'rgb(100,100,100)'}}/></div>
                            </div>
                            <div style={{fontSize:'13px',maxHeight:'calc(90vh - 100px)',overflow:'auto'}}>
                                <Diff viewType="split" diffType='' hunks={diff.hunks}>
                                    {hunks =>
                                        hunks.map(hunk => (
                                            <Hunk key={hunk.content} hunk={hunk} />
                                        ))
                                    }
                                </Diff>
                            </div>
                            <div style={{height:'50px',width:'calc(100% - 20px)',background:'white',position:'absolute',bottom:0,zIndex:100000000,display:'flex',justifyContent:'space-between',alignItems:'center',marginLeft:'10px',marginRight:'10px'}}>
                                <div style={{display:'flex',alignItems:'center'}}>
                                    <input id='soleResponsibleCheckbox' type='checkbox' checked={changesVerified} onChange={(e)=>{setChangesVerified(e.target.checked)}}/>
                                    <label htmlFor='soleResponsibleCheckbox' style={{fontSize:'13px',marginLeft:'5px'}}>I have verified these changes.</label>
                                </div>
                                <Button 
                                    disabled={!changesVerified || uploadLoader || filesSelected.length<1 || !scriptData.additionalMessage || !scriptData.commitMessage || !scriptData.scriptType || (!scriptData.scriptDataNotRequired && (scriptData.commitMessage==='Level 1' || scriptData.commitMessage==='Level 2') && ( !scriptData.kpi_action || !scriptData.kpi_event || !scriptData.kpi_event_source || !scriptData.kpi_event_value))}
                                    color='warning' 
                                    sx={{...Constants.CONTAINEDBUTTON,minHeight:'25px',minWidth:'80px'}} 
                                    onClick={handleUploadFiles}
                                >
                                    {
                                    uploadLoader 
                                        &&
                                        <CircularProgress size={'18px'} sx={{color:'white'}}/>
                                    }
                                    {
                                    !uploadLoader 
                                        &&
                                    'Push'
                                    }
                                </Button>
                            </div>
                        </div>
                    </div>
                }
                {(scriptData.scriptType!=='newapp')
                &&
                <div className='fileDataContainer'>
                    <div style={{display:'flex',justifyContent:'space-between',flexWrap:'wrap'}}>
                        {Object.keys(detailsDict).map((item,index)=>{
                            var childItems = detailsDict[item]
                            return <div key={index} className='fileItemWrapper'>
                                    <div style={{padding:'10px',fontWeight:'500',background:'rgb(255, 249, 238)',borderBottom:'1px solid rgb(187,187,187)'}}>{item}</div>
                                    <div style={{padding:'15px'}}>
                                        {childItems.map((childItem,childIndex)=>{
                                            return <div key={childIndex} className='fileChildItemWrapper'>
                                                    <div style={{width:'30%'}}>{childItem.label}</div>
                                                    <div style={{width:'70%'}}>
                                                        {childItem.field==='textfield'
                                                        &&
                                                        <CssTextField size='small' multiline={childItem.multiRow} value={scriptData[childItem.value] ? scriptData[childItem.value] : ''} onChange={(e)=>{changesDoneFunction(childItem.value);setScriptData({...scriptData,[childItem.value]:e.target.value});refreshComponent(12);}} sx={{transform:'scale(0.8)',width:'110%',transformOrigin:'left'}}/>}
                                                        {childItem.field === 'select'
                                                        &&
                                                        <div style={{transform:'scale(0.8)',width:'110%',transformOrigin:'left'}}>
                                                            <ReactSelect   
                                                                options={childItem.options.map((item,index)=>{return {label:item,value:item}})}
                                                                value={scriptData[childItem.value] ? {label:scriptData[childItem.value],value:scriptData[childItem.value]} : ''}                                             
                                                                onChange={(e)=>{
                                                                    if(childItem.value==='dev team'){
                                                                        showDeveloperOptions(e.value,true)
                                                                    }
                                                                    else{
                                                                        setScriptData({...scriptData,[childItem.value]:e.value});
                                                                        changesDoneFunction(childItem.value);
                                                                        refreshComponent(12);
                                                                    }
                                                                }}
                                                                menuPortalTarget={document.body} 
                                                                styles={{...Constants.ReactSelectStyles}}
                                                            />
                                                        </div>
                                                        }
                                                        {childItem.field === 'creatableSelect'
                                                        &&
                                                        <div style={{transform:'scale(0.8)',width:'110%',transformOrigin:'left'}}>
                                                            <CreatableSelect  
                                                                isClearable
                                                                isDisabled={childItem.disabled} 
                                                                value={scriptData[childItem.value] ? {label:scriptData[childItem.value],value:scriptData[childItem.value]} : ''}                                             
                                                                options={childItem.options.map((item,index)=>{return {label:item,value:item}})}
                                                                menuPortalTarget={document.body} 
                                                                styles={{...Constants.ReactSelectStyles}}
                                                                onChange={(e)=>{
                                                                    setScriptData({...scriptData,[childItem.value]:e.value});
                                                                    changesDoneFunction(childItem.value);
                                                                    refreshComponent(12);
                                                                }}
                                                            />
                                                        </div>
                                                        }
                                                    </div>
                                                </div>
                                        })}
                                    </div>
                                </div>
                        })}
                    </div>
                    <Button 
                        disabled={downloadScriptLoaders || uploadLoader || filesSelected.length<1 || !scriptData.additionalMessage || !scriptData.commitMessage || !scriptData.scriptType || (!scriptData.scriptDataNotRequired && (scriptData.commitMessage==='Level 1' || scriptData.commitMessage==='Level 2') && ( !scriptData.kpi_action || !scriptData.kpi_event || !scriptData.kpi_event_source || !scriptData.kpi_event_value))}
                        color='warning' 
                        sx={{...Constants.OUTLINEDBUTTON,minHeight:'25px',minWidth:'250px',marginRight:'10px'}} 
                        onClick={async(e)=>{
                            var codes = {}
                            await handleDownloadFile(e,filesSelected[0].name,true,codes)
                            
                            var filereader = new FileReader()
                            filereader.onloadend = (e) => {
                                const content = filereader.result;
                                setCode({oldCode:codes.oldCode ? codes.oldCode : '',newCode:content})
                            }
                            filereader.readAsText(filesSelected[0]);
                            setShowComparePopup(true);
                        }}
                        >
                        {
                            downloadScriptLoaders
                            &&
                            <CircularProgress size={'18px'} sx={{color:'orange'}}/>
                        }
                        {
                        !downloadScriptLoaders 
                            &&
                        'Compare With Server Script'
                        }
                    </Button>
                    <Button 
                                    disabled={uploadLoader || filesSelected.length<1 || !scriptData.additionalMessage || !scriptData.commitMessage || !scriptData.scriptType || (!scriptData.scriptDataNotRequired && (scriptData.commitMessage==='Level 1' || scriptData.commitMessage==='Level 2') && ( !scriptData.kpi_action || !scriptData.kpi_event || !scriptData.kpi_event_source || !scriptData.kpi_event_value))}
                                    color='warning' 
                                    sx={{...Constants.CONTAINEDBUTTON,minHeight:'25px',minWidth:'80px'}} 
                                    onClick={handleUploadFiles}
                                >
                                    {
                                    uploadLoader 
                                        &&
                                        <CircularProgress size={'18px'} sx={{color:'white'}}/>
                                    }
                                    {
                                    !uploadLoader 
                                        &&
                                    'Push'
                                    }
                                </Button>
                    
                </div>
                }
                {scriptData.scriptType==='newapp'
                &&
                <div style={{marginTop:'20px'}}>
                    <Button 
                        disabled={uploadLoader || filesSelected.length<1 || !scriptData.additionalMessage || !scriptData.commitMessage || !scriptData.scriptType}
                        color='warning' 
                        sx={{...Constants.CONTAINEDBUTTON,minHeight:'25px',minWidth:'80px'}} 
                        onClick={handleUploadFiles}
                    >
                        {
                        uploadLoader 
                            &&
                            <CircularProgress size={'18px'} sx={{color:'white'}}/>
                        }
                        {
                        !uploadLoader 
                            &&
                        'Push'
                        }
                    </Button>
                </div>
                }
                
            </div>
        </div>
    )
}

export default UploadCampaignV2