import React, { useState } from 'react'
import './stint.css';

import { CssTextField } from '../../data/constants';
import ReactSelect from 'react-select';
import * as Constants from '../../data/constants';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Button,CircularProgress } from '@mui/material';
import { ClosedCaption } from '@mui/icons-material';
import { assigneeOptions, getOptions, priorityOptions, tagOptions, teamOptions } from './genericOptionsStint';
import axios from 'axios';
import { getLocalStorageValue } from '../../helper/util';
const NewTask = (props) => {
    const [addNewTask,setAddNewTask] = [props.addNewTask,props.setAddNewTask]
    const [newtaskDetails,setNewtaskDetails] = useState({})
    const [newTaskAddLoader,setNewTaskAddLoader] = useState(false)

    const getAssigneeFromTeam = () => {
        var options = []
        for(var userid in props.assigneeOptions){
            var userData = props.assigneeOptions[userid]
            if(true){
            // if(newtaskDetails.team && (newtaskDetails.team.value.toString() === userData.team.toString())){  //deprecated
                // var label = <div style={{display:'flex',alignItems:'center'}}>
                //                 <div style={{background:'rgb(220,220,220)',height:'15px',width:'15px',fontSize:'12px',padding:'5px',display:'flex',alignItems:'center',justifyContent:'center',borderRadius:'50%',marginRight:'5px'}}>
                //                     {userData[0]}
                //                 </div>
                //                 {userData}
                //             </div>
                var label = userData
                options.push({label:label,value:userid})
            }
        }
        return options
    }

    const formColumns = [
        {label:'Package Name',value:'package_name',type:'textfield',mandatory:true,width:'40%'},
        {label:'Script Name',value:'script_name',type:'textfield',mandatory:true,width:'33%'},
        {label:'Tag',value:'tag',type:'select',mandatory:true,width:'33%',options:getOptions(tagOptions)},
        {label:'Team',value:'team',type:'select',mandatory:true,width:'33%',childDependent:'assignee',options:getOptions(teamOptions)},
        {label:'Assignee',value:'assignee',type:'select',mandatory:true,width:'33%',options:getAssigneeFromTeam()},
        {label:'Priority',value:'priority',type:'select',mandatory:true,width:'33%',options:getOptions(priorityOptions)},
        {label:'Description',value:'description',type:'textarea',mandatory:true,width:'95%'},
    ]

    const createTaskFunction = async() => {
        setNewTaskAddLoader(true)
        await axios({
            method: "POST",
            url: Constants.BACKENDHOST+"task_tracker/addTask",
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token,
                'Content-type':'application/json'
            },
            data:{
                "task_type": props.addNewTask==='parent' ? 'new' : props.addNewTask, 
                "script_name": newtaskDetails.script_name, 
                "package_name": newtaskDetails.package_name, 
                "assignee":newtaskDetails.assignee ? newtaskDetails.assignee.value : undefined, 
                "priority": newtaskDetails.priority ? newtaskDetails.priority.value : undefined,
                "team": newtaskDetails.team ? newtaskDetails.team.value : undefined,
                "description": newtaskDetails.description ? newtaskDetails.description : undefined,
                "tag": newtaskDetails.tag ? newtaskDetails.tag.value : undefined
            }

        })
        .then(resp => {
            var task_id = resp.data.data.task_id
            var __data = {
                "id":task_id,
                "is_parent":true,
                "script_name": newtaskDetails.script_name, 
                "package_name": newtaskDetails.package_name, 
                "assignee":newtaskDetails.assignee ? newtaskDetails.assignee.value : undefined, 
                "priority": newtaskDetails.priority ? newtaskDetails.priority.value : undefined,
                "team": newtaskDetails.team ? newtaskDetails.team.value : undefined,
                "description": newtaskDetails.description ? newtaskDetails.description : undefined,
                "tag": newtaskDetails.tag ? newtaskDetails.tag.value : undefined,
                "created_at":(new Date()).toString()
            }
            var tempColumns = props.columns;
            tempColumns['Not Assigned'].items.push(task_id.toString())
            props.setColumns(tempColumns)
            props.setData({...props.data,[task_id]:__data})
            props.setDetailData({...props.data,[task_id]:__data})
            setAddNewTask(false);
        })
        .catch(err=>{
            
        })
        setNewTaskAddLoader(false)
    }
    const isCreateButtonDisabled = () => {
        if(newTaskAddLoader){
            return true
        }
        for(var index in formColumns){
            var item = formColumns[index]
            if(item.mandatory && !newtaskDetails[item.value]){
                return true
            }
        }
        return false
    }
    const formColumnComponent = () => {
        return formColumns.map((formItem,formIndex)=>{
            if(formItem.type==='textfield'){
                return <div className='formItemRow' style={{width:formItem.width ? formItem.width : '100%'}}>
                    <div className='formItemLabel'>{formItem.label}{formItem.mandatory ? '*' : ''}</div>
                    <div style={{marginLeft:'2px'}}>
                        <CssTextField size={'small'} fullWidth placeholder={formItem.label} onChange={(e)=>{setNewtaskDetails({...newtaskDetails,[formItem.value]:e.target.value});}}/>
                    </div>
                </div>
            }
            if(formItem.type==='select'){
                return <div className='formItemRow' style={{width:formItem.width ? formItem.width : '100%'}}>
                    <div className='formItemLabel'>{formItem.label}{formItem.mandatory ? '*' : ''}</div>
                    <div style={{marginLeft:'2px'}}>
                        <ReactSelect 
                            value={newtaskDetails[formItem.value] ? newtaskDetails[formItem.value] : null}
                            onChange={e=>{
                                if(formItem.childDependent){
                                    setNewtaskDetails({...newtaskDetails,[formItem.value]:e,[formItem.childDependent]:null})
                                }
                                else{
                                    setNewtaskDetails({...newtaskDetails,[formItem.value]:e})
                                }
                            }}
                            options={formItem.options}
                            placeholder={formItem.label}
                            menuPortalTarget={document.body} 
                            styles={{...Constants.ReactSelectStyles}}
                        />
                    </div>
                </div>
            }
            if(formItem.type==='textarea'){
                return <div className='formItemRow' style={{width:formItem.width ? formItem.width : '100%'}}>
                    <div className='formItemLabel'>{formItem.label}{formItem.mandatory ? '*' : ''}</div>
                    <div style={{marginLeft:'2px'}}>
                        <CKEditor
                            editor={ ClassicEditor }
                            data="<p><br><br><br></p>"
                            onReady={ editor => {
                                // You can store the "editor" and use when it is needed.
                                console.log( 'Editor is ready to use!', editor );
                            } }
                            onChange={ ( event, editor ) => {
                                const data = editor.getData();
                                setNewtaskDetails({...newtaskDetails,[formItem.value]:data})
                            } }
                            onBlur={ ( event, editor ) => {
                                console.log( 'Blur.', editor );
                            } }
                            onFocus={ ( event, editor ) => {
                                console.log( 'Focus.', editor );
                            } }
                        />
                    </div>
                </div>
            }
            return <div>

            </div>
        })
    } 
    return (
        <div>
            <div className='stintFixed' onClick={()=>{setAddNewTask(false);}}>
                <div className='stintPopup' onClick={(e)=>{e.stopPropagation();}}>
                    <div className='stintLabelRow'>
                        <div>Create Task</div>
                        <div style={{cursor:'pointer'}} onClick={()=>{setAddNewTask(false);}}>X</div>
                    </div>
                    <div className='formComponentWrapper'>
                        {formColumnComponent()}
                    </div>
                    <div className='formComponentButtonRow'>
                        <div>
                            {/* <input type='checkbox' id={'another_issue'}/>
                            <label htmlFor={'another_issue'}>Create Another Issue</label> */}
                        </div>
                        <div style={{display:'flex',alignItems:'center'}}>
                            <div style={{cursor:'pointer'}} onClick={()=>{setAddNewTask(false);}}>Cancel</div>
                            <div style={{marginLeft:'30px'}}>
                                <Button disabled={isCreateButtonDisabled()} sx={{...Constants.CONTAINEDBUTTON,textTransform:'none',fontSize:'15px',minWidth:'100px',minHeight:'35px'}} onClick={()=>{createTaskFunction()}}>
                                    {newTaskAddLoader && <CircularProgress  size={'20px'} sx={{color:'white',fontSize:'12px'}}/>}
                                   {!newTaskAddLoader && 'Create'}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NewTask