import React, { useState } from 'react';
import TopbarV2 from '../../../components/topbarv2/topbarv2';
import '../../../css/pages/v1/uploadCampaigns.css';
import uploadVector from '../../../img/vector/uploadVector.png'
import pythonFile from '../../../img/pythonFile.svg'
import { bytesToSize, useForceUpdate, getLocalStorageValue } from '../../../helper/util';
import { UploadCommitMessages } from '../../../data/constants';
import ReactSelect from 'react-select';
import CreatableSelect from 'react-select/creatable';
import * as Constants from '../../../data/constants';
import { CopyAllOutlined } from '@mui/icons-material';
import { Button, CircularProgress, IconButton, TextField, Tooltip } from '@mui/material';
import { Store } from 'react-notifications-component';
import axios from 'axios';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import Popup from '../../../components/popup/popup';
const UploadCampaigns = () => {
    const refreshComponent = useForceUpdate()
    const [filesSelected, setFilesSelected] = useState([])
    const [forcePushCheck, setForcePushCheck] = useState(false)
    const handleFileSelected = (e) => {
        var files = e.target.files
        var tempFilesSelected = filesSelected
        for(var index in files){
            var foundFlag = false;
            for(var tempFilesIndex in tempFilesSelected){
                if(typeof files[index]==='object' && typeof tempFilesSelected[tempFilesIndex].file === 'object'){
                    if(files[index].name === tempFilesSelected[tempFilesIndex].file.name){
                        foundFlag = true;
                        break;
                    }
                }
            }
            if(foundFlag){
                tempFilesSelected.splice(tempFilesIndex,1)
            }
            if(typeof files[index]==='object'){
                tempFilesSelected.push({
                    commitMessage:null,
                    scriptType:'newapp',
                    file:files[index]
                })
            }
        }
        setDisableUploadButton(true)
        setFilesSelected(tempFilesSelected)
        e.target.value = null;
        refreshComponent(123)
    }

    // http://192.168.0.112:5008/autolabs/campaigns/upload-script
    const removeFile = (index) => {
        var tempFilesSelected = filesSelected;
        tempFilesSelected.splice(index,1);
        setFilesSelected(tempFilesSelected);
        refreshComponent(12);
    }

    const copyToAll = (index,key,value) => {
        var tempFilesSelected = filesSelected;
        for(var filesIndex in tempFilesSelected){
            if(index<filesIndex){
                tempFilesSelected[filesIndex][key] = value
            }
        }
        setFilesSelected(tempFilesSelected);
        refreshComponent(12);
    }
    const [uploadLoader, setUploadLoader] = useState(false)
    const handleUploadFiles = async(e) => {
        e.stopPropagation();
        setUploadLoader(true)
        var commitMessage = []
        var scriptType = []
        var additionalComment = []
        var formData = new FormData();
        for (var index in filesSelected) {
            formData.append("script", filesSelected[index].file);
            commitMessage.push(filesSelected[index].commitMessage)
            scriptType.push(filesSelected[index].scriptType)
            additionalComment.push(filesSelected[index].additionalMessage)
        }
        formData.append("scriptType", scriptType.length>0 ? scriptType : '');
        formData.append("message", commitMessage.length>0 ? commitMessage : '');
        formData.append("comment", additionalComment.length>0 ? additionalComment : '');
        if(forcePushCheck){
            formData.append("forced", true);
        }

        await axios({
            method: "POST",
            url: Constants.BACKENDHOST + 'campaigns/upload-script', //Constants.BACKENDHOST+"tools/create_flow_report",
            headers:{
                "Content-Type": "multipart/form-data",
                Authorization:getLocalStorageValue('adminPanelLoginData').token,
            },
            data: formData,
        }).then(resp => {
            setShowPopup(resp)
            console.log(resp)
        })
        .catch(err=>{
            console.log(err)
            if(err.response && err.response.data){
                setShowPopup(err.response)

                Store.addNotification({
                    title: "Error!",
                    message:  err.response.data.message,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                      duration: 5000,
                      onScreen: true
                    }
                });
            }
        });
        setForcePushCheck(false)
        setUploadLoader(false)
    }
    const scriptTypeValues = {
        existing : 'Existing Script',
        newapp : 'Newapp',
        // modds : 'Modds'
    }

    const [disableUploadButton, setDisableUploadButton] = useState(false)
    const checkDisableUploadFileButton = (tempFilesSelected) => {
        if(tempFilesSelected){
            var filesSelectedTemp = tempFilesSelected
        }
        else{
            filesSelectedTemp = filesSelected
        }
        for (var index in filesSelectedTemp) {
            if(!filesSelectedTemp[index].commitMessage || !filesSelectedTemp[index].scriptType || !filesSelectedTemp[index].additionalMessage){
                setDisableUploadButton(true)
                return true
            }
        } 
        setDisableUploadButton(false)
        return false
    }

    const [showPopup, setShowPopup] = useState(false)
    const handlePopupFunction = (resp) => {
        if(resp.data && resp.data.data && resp.data.data.scripts){
            var popupData = <div>
                <table style={{tableLayout:'fixed',width:'100%',fontSize:'13px'}}>
                    <thead>
                        <tr style={{fontSize:'14px',fontWeight:'500'}}>
                            <td>
                                Scriptname
                            </td>
                            <td>
                                Status
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.keys(resp.data.data.scripts).map((item,index)=>{
                            return <tr key={index}>
                                <td style={{padding:'5px'}}>{item}</td>
                                <td style={{color:resp.data.data.scripts[item]==='Pushed' ? 'green' : 'red'}}>{resp.data.data.scripts[item]}</td>
                            </tr>
                        })}
                    </tbody>
                </table>
            </div>
            var popupHead = 'Success'
        }
        else if(resp.data && resp.data.data && resp.data.data.error){
            popupData = <div style={{fontSize:'13px'}}>
                {resp.data.data.error}
                
                <div style={{marginTop:'20px'}}>
                    <div>
                        <input id='forcepushCheckbox' type={'checkbox'} onChange={(e)=>{setForcePushCheck(e.target.checked)}}/>
                        <label htmlFor='forcepushCheckbox' style={{fontSize:'12px'}}>Select this for force push</label>
                    </div>
                    <div style={{display:'flex',justifyContent:'center',}}>
                            
                        <Button disabled={!forcePushCheck} variant='contained' color='warning' size='small' onClick={(e)=>{handleUploadFiles(e,true)}}>
                            {uploadLoader &&
                                <CircularProgress size={'28px'} sx={{color:'white'}}/>
                            }
                            {!uploadLoader &&
                            <>
                            <FileUploadIcon sx={{fontSize:'18px',color:'orange'}}/>Submit
                            </>
                            }
                        </Button>
                    </div>
                </div>
                
            </div>
            popupHead = 'Error'

        }
        return {data:popupData,head:popupHead}
    }
    
    return (
        <div style={{width:'100%',maxWidth:'100%'}}>
            <TopbarV2 title={'Upload Campaigns'}/>
            {showPopup && <Popup head={handlePopupFunction(showPopup).head} data={handlePopupFunction(showPopup).data} handleClose={()=>{setShowPopup(false);setFilesSelected([]);window.location.reload();}}/>}
            <div className='uploadCampaignsContainer'>
                <div className='addFileContainer' onDrop={(e)=>{console.log(e)}} onClick={()=>{document.getElementById('uploadFilesInput').click()}}>
                    <div style={{position:'absolute',height:'65vh',width:'90%'}}>
                        <div className='filesContainer' >
                            {filesSelected.map((item,index)=>{
                                if(typeof item === 'object'){
                                    return <div className='filenameWrapper' key={index} onClick={(e)=>{e.stopPropagation()}}>
                                            <img src={pythonFile} alt=''/>
                                            <div style={{display:'flex',width:'100%',alignItems:'center'}}>
                                                <div className='filenameLabel'>
                                                    <div>{item.file.name}</div>
                                                    <div style={{fontSize:'12px',}}>
                                                        {new Date(item.file.lastModified).toLocaleDateString('en-US',{year:'numeric',month:'short',day:'numeric',hour:"2-digit",minute:'2-digit',second:'2-digit'})}
                                                    </div>

                                                </div>
                                                <div style={{display:'flex',width:'75%',alignItems:'center'}}>
                                                    <div style={{width:'100%',display:'flex',position:'relative',}}>
                                                        <div style={{display:'flex',alignItems:'center',position:'relative',width:'30%'}}>
                                                            <div style={{width:'100%',transform:"scale(0.7)",transformOrigin:'left'}}>
                                                                <CreatableSelect                                                
                                                                    isClearable
                                                                    placeholder={'Commit Message'}
                                                                    options={UploadCommitMessages.map((item,index)=>{return {label:item,value:item}})}
                                                                    value={item.commitMessage ? {label:item.commitMessage,value:item.commitMessage} : false}
                                                                    onChange={(e)=>{var tempFilesSelected = filesSelected; tempFilesSelected[index]['commitMessage'] = e.value; setFilesSelected(tempFilesSelected);refreshComponent(123);checkDisableUploadFileButton(tempFilesSelected);}}
                                                                    menuPortalTarget={document.body} 
                                                                    styles={{...Constants.ReactSelectStyles}}
                                                                />
                                                            </div>
                                                            <div style={{position:'absolute',right:'10%'}}>
                                                                <Tooltip title={'Copy commit message to all files under this.'}>
                                                                    <IconButton onClick={()=>{copyToAll(index,'commitMessage',item.commitMessage);checkDisableUploadFileButton();}}>
                                                                        <CopyAllOutlined sx={{fontSize:'16px'}}/>
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </div>
                                                        </div>
                                                        <div style={{display:'flex',alignItems:'center',position:'relative',width:'30%'}}>
                                                            <div style={{width:'100%',transformOrigin:'right',transform:"scale(0.8)"}}>
                                                                <Constants.CssTextField    
                                                                    placeholder='Additional Comment*'     
                                                                    value={item.additionalMessage}
                                                                    size='small'                                       
                                                                    onChange={(e)=>{var tempFilesSelected = filesSelected; tempFilesSelected[index]['additionalMessage'] = e.target.value; setFilesSelected(tempFilesSelected);refreshComponent(123);checkDisableUploadFileButton(tempFilesSelected);}}
                                                                />
                                                            </div>
                                                            <div style={{position:'absolute',right:'-15%'}}>
                                                                <Tooltip title={'Copy commit message to all files under this.'}>
                                                                    <IconButton onClick={()=>{copyToAll(index,'additionalMessage',item.additionalMessage);checkDisableUploadFileButton();}}>
                                                                        <CopyAllOutlined sx={{fontSize:'16px'}}/>
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </div>
                                                        </div>
                                                        
                                                        <div style={{position:'absolute',display:'flex',alignItems:'center',width:'30%',right:0}}>
                                                            <div style={{width:'100%',transform:"scale(0.7)",transformOrigin:'left'}}>
                                                                <ReactSelect                                                
                                                                    placeholder={'Script Type'}
                                                                    options={Object.keys(scriptTypeValues).map((item,index)=>{return {label:scriptTypeValues[item],value:item}})}
                                                                    value={item.scriptType ? {label:scriptTypeValues[item.scriptType],value:item.scriptType} : false}
                                                                    onChange={(e)=>{var tempFilesSelected = filesSelected; tempFilesSelected[index]['scriptType'] = e.value; setFilesSelected(tempFilesSelected);refreshComponent(123);checkDisableUploadFileButton(tempFilesSelected);}}
                                                                    menuPortalTarget={document.body} 
                                                                    styles={{...Constants.ReactSelectStyles}}
                                                                />
                                                            </div>
                                                            <div style={{position:'absolute',right:'-10%'}}>
                                                                <Tooltip title={'Copy script type to all files under this.'}>
                                                                    <IconButton onClick={()=>{copyToAll(index,'scriptType',item.scriptType);checkDisableUploadFileButton();}}>
                                                                        <CopyAllOutlined sx={{fontSize:'16px'}}/>
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </div>
                                                            
                                                        </div>
                                                    </div>
                                                    <div style={{display:'flex',justifyContent:'flex-end',alignItems:'center',width:'20%'}}>
                                                        <div style={{paddingRight:'10px',fontSize:'12px'}}>{bytesToSize(item.file.size)}</div>
                                                        <div className='closeButton' onClick={()=>{removeFile(index)}}>X</div>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                }
                                return null;
                            })}
                        </div>
                        {filesSelected.length>0 &&
                            <div style={{height:'32px',background:'white',position:'absolute',bottom:0,width:'100%',display:'flex',alignItems:'center',justifyContent:'center'}} onClick={(e)=>{e.stopPropagation();}}>
                                <Button disabled={disableUploadButton} sx={{...Constants.OUTLINEDBUTTON,transform:'scale(0.8)',minWidth:'150px'}} onClick={handleUploadFiles}>
                                    {uploadLoader &&
                                    <CircularProgress size={'28px'} sx={{color:'orange'}}/>
                                    }
                                    {!uploadLoader &&
                                    <>
                                    <FileUploadIcon sx={{fontSize:'18px',color:'orange'}}/>Upload Files
                                    </>
                                    }
                                </Button>
                            </div>
                        }
                    </div>
                    {filesSelected.length===0 &&
                        <img src={uploadVector} style={{opacity:'0.8',marginBottom:'30px'}} alt='' height={'50%'}></img>
                    }
                    <div style={{fontSize:'20px',fontWeight:'600',position:'absolute',bottom:0,padding:'20px'}}>
                       + Click to add <label style={{color:'orange',fontSize:'22px'}}>Files</label>
                    </div>
                    <input id='uploadFilesInput' type='file' onChange={handleFileSelected} accept='.py,.java' multiple hidden/>
                </div>
            </div>
        </div>
    )
}

export default UploadCampaigns