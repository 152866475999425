import React from 'react'
import '../stint.css';
const AssigneeOptions = (props) => {
    var assigneeOptions = props.assigneeOptions
    const changeAssignee = (value) => {
        if(props.subtaskList){
            var data = props.subtaskList[props.index]
            props.updateStintData(data.id,'assignee',value,data.assignee)
            var tempSubtaskList = props.subtaskList
            tempSubtaskList[props.index].assignee = value
            props.setSubtaskList(tempSubtaskList)
            props.setShowAssigneeOptions(false);
        }
        if(props.dataIndex && props.detailData && props.data){
            var data = props.detailData[props.dataIndex]
            props.updateStintData(data.id,props.column,value,data.assignee)
            var tempData = props.detailData
            tempData[props.dataIndex][props.column] = value
            props.setData(tempData)
            props.setDetailData(tempData)
            props.setShowAssigneeOptions(false);
        }
    }
    return (
        <div className='assigneeOptionsContainer'>
            <div>
                {Object.keys(assigneeOptions).map((item,index)=>{
                    return <div key={index} className='assigneeItem' onClick={()=>{changeAssignee(item)}}>
                        {assigneeOptions[item]}
                        </div>
                })}
            </div>
        </div>
    )
}

export default AssigneeOptions