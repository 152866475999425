import React,{useState, useEffect} from 'react'
import '../../../css/pages/v1/debugscript.css'

import TopbarV2 from '../../../components/topbarv2/topbarv2'
import HistoryComponent from '../../../components/historyComponent/historyComponent';
import { getLocalStorageValue, useForceUpdate } from '../../../helper/util'
import * as Constants from '../../../data/constants';
import { Button, CircularProgress } from '@mui/material';
import axios from 'axios';
import { Store } from 'react-notifications-component';
// import DebugFileData from './debugFiledata';
import ReactSelect from 'react-select';
// import CreatableSelect from 'react-select/creatable';
import ErrorComponent from '../../../components/error/error'


const RetentionReport = () => {
    const refreshComponent = useForceUpdate();
    
    if(localStorage.getItem(window.location.pathname+'historyWindowWidth')){
        var tempHistoryWindowWidth = localStorage.getItem(window.location.pathname+'historyWindowWidth')
    }
    else{
        tempHistoryWindowWidth = 300
    }
    const [historyWindowWidth, setHistoryWindowWidth] = useState(tempHistoryWindowWidth)
    const [showAddTab, setShowAddTab] = useState(false)
    const [activeTab, setActiveTab] = useState(false)
    const [addTabData, setAddTabData] = useState({file:false,noOfDays:7,noOfUser:100})
    const [openTabsList, setOpenTabsList] = useState([])
    const [historyPage, setHistoryPage] = useState(1)
    const [historyPages, setHistoryPages] = useState(0)
    const [isRetentionPage, setIsRetentionPage] = useState(window.location.pathname==='/campaigns/retentionreport' ? false : true)
    
    const [getDataLoader, setGetDataLoader] = useState(false)
    var getDebugData = async(item) => {
        var foundFlag = false;
        for(var index in openTabsList){
            if(openTabsList[index].serial === item.serial){
                foundFlag = true;
                break;
            }
        }
        if(foundFlag){
            setActiveTab(parseInt(index))
        }
        if(!foundFlag){
            setGetDataLoader(true);
            if(item.reportstatus==='Pending'){
                Store.addNotification({
                    title: "Warning!",
                    message: 'Retention for this script is still under process, Please refresh and try again when it is processed.',
                    type: "warning",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                      duration: 6000,
                      onScreen: true
                    }
                });
            }
            else{
                openTabs({...item,loader:true})
                
                await axios({
                    method: "GET",
                    url: Constants.BACKENDHOST2+"tools/retentioncountreportstatus/"+item.serial,
                    headers:{
                        Authorization:getLocalStorageValue('adminPanelLoginData').token,
                    },
                    // params:{
                    //     report_serial:item.serial,
                    // },
                })
                .then(resp => {
                    if(resp.data.data){
                        var respItem = {...item,response:resp.data.data,loader:true}
                        var tempTabsList = openTabsList;
                        tempTabsList[tempTabsList.length-1] = respItem
                        setOpenTabsList(tempTabsList);
                    }
                })
                .catch(err=>{
                    console.log(err)
                    if(err.response && err.response.data){
                        var respItem = {...item,error:err.response.data,loader:false}

                        Store.addNotification({
                            title: "Error!",
                            message: err.response.data.message,
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            animationIn: ["animate__animated", "animate__fadeIn"],
                            animationOut: ["animate__animated", "animate__fadeOut"],
                            dismiss: {
                              duration: 5000,
                              onScreen: true
                            }
                        });
                    }
                    else{
                        respItem = {...item,loader:false}
                    }
                    var tempTabsList = openTabsList;
                    tempTabsList[tempTabsList.length-1] = respItem
                    setOpenTabsList(tempTabsList);
                })
            }
            setGetDataLoader(false);
        }
    }

    const openTabs = (item) => {
        var tempTabsList = openTabsList;
        tempTabsList.push(item)
        setActiveTab(tempTabsList.length-1)
        setOpenTabsList(tempTabsList);
        setShowAddTab(false);
        refreshComponent(1);
    }

    const closeTab = (item) => {
        var tempTabsList = openTabsList
        for(var index in tempTabsList){
            if(tempTabsList[index].filename === item.filename && tempTabsList[index].serial === item.serial){
                break;
            }
        }
        setOpenTabsList(tempTabsList);
        tempTabsList.splice(index,1)
        if(tempTabsList.length>0){
            setActiveTab(tempTabsList.length-1);
        }
        else{
            setActiveTab(false);
        }
        refreshComponent(1);
    }
    
    const [historyResponse, setHistoryResponse] = useState([])
    const [historyResponseLoader, setHistoryResponseLoader] = useState(false)
    
    const getDebugHistory = async(manualPage) => {
        setHistoryResponseLoader(true);
        await axios({
            method: 'GET',
            url: "".concat(Constants.BACKENDHOST2,"tools/retentioncountreportstatus"),
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token,
            },
            params:{
                page:manualPage ? manualPage : historyPage,
                from_file:window.location.href.includes('/campaigns/kpicheck') ? false : true,
            }
        })
        .then(resp => {
            if(resp.data.response_code===200){
                setHistoryResponse(resp.data.data.items)
                setHistoryPages(resp.data.data.pages)
            }
        })
        .catch(err => {
            console.error(err);
        })
        setHistoryResponseLoader(false);
    };
    const [manualUseState, setManualUseState] = useState(false)
    if(!manualUseState){
        setManualUseState(true)
        getDebugHistory()
    }
    else{
        if(window.location.href.includes('/campaigns/kpicheck') && isRetentionPage){
            getDebugHistory()
            setIsRetentionPage(false)
            setOpenTabsList([])
            setActiveTab(false);
        }
        else if(!isRetentionPage && window.location.href.includes('/tools/debugscript')){
            getDebugHistory()
            setOpenTabsList([])
            setIsRetentionPage(true)
            setActiveTab(false);
        }
    }

    const changeHandler = (event) => {
        if(event.target && event.target.files.length>0){
            setAddTabData({...addTabData , file:event.target.files[0]});
        }
	};
    
    const [addLoader, setAddLoader] = useState(false)
    var addRetention = async() => {
        setAddLoader(true)
        var formData = new FormData();
        await axios({
            method: !isRetentionPage ? 'GET' : "POST",
            url: Constants.BACKENDHOST2+"tools/createretentionreport",
            headers:{
                "Content-Type": "multipart/form-data",
                Authorization:getLocalStorageValue('adminPanelLoginData').token,
            },
            params:{
                script:!isRetentionPage ? addTabData.file : null,
            },
            // withCredentials:true,
        }).then(resp => {
            if(resp.data.response_code===201){
                getDebugHistory();
                setShowAddTab(false);
                setAddTabData({...addTabData,file:null});
                refreshComponent(true);
                Store.addNotification({
                    title: "Success!",
                    message: resp.data.message,
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                      duration: 5000,
                      onScreen: true
                    }
                });
            }
        })
        .catch(err=>{
            console.log(err)
            if(err.response && err.response.data){
                Store.addNotification({
                    title: "Error!",
                    message:  err.response.data.message,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                      duration: 5000,
                      onScreen: true
                    }
                });
            }
        });
        setAddLoader(false)
    }
    var title = <div style={{display:'flex',alignItems:'center'}}>
                    {!isRetentionPage ? 'Retention Report' : 'Retention Report'}
                    {getDataLoader && <CircularProgress sx={{color:'orange',marginLeft:'12px'}} size={'20px'}/>}
                </div>
    

    useEffect(() => {
        document.addEventListener('keydown',e=>{
            if(e.key==='Escape'){
                setShowAddTab(false);
                setAddTabData({...addTabData,file:null});
            }
        })
        
        return () => {
            document.removeEventListener('keydown',e=>{
                if(e.key==='Escape'){
                    setShowAddTab(false);
                    setAddTabData({...addTabData,file:null});
                }
            })
        }
    }, [addTabData])
    
    const enterKeyPressed = (e) => {
        if(e.key==='Enter'){
            if(addTabData.file && addTabData.noOfDays && addTabData.noOfUser){
                addRetention()
            }
        }
    }
    return (
        <div>
            <TopbarV2 title={title}/>
            <div style={{display:'flex'}}>
                <HistoryComponent
                    historyWindowWidth={historyWindowWidth}
                    setHistoryWindowWidth={setHistoryWindowWidth}
                    openTabs={getDebugData}
                    historyResponse={historyResponse}
                    setHistoryResponse={setHistoryResponse}
                    historyResponseLoader={historyResponseLoader}
                    setHistoryResponseLoader={setHistoryResponseLoader}
                    getDebugHistory={getDebugHistory}
                    historyPage={historyPage}
                    setHistoryPage={setHistoryPage}
                    historyPages={historyPages}
                    historyFor={'Report'}
                />
                <div style={{position:'relative',width:'calc(100% - '+historyWindowWidth+'px - 5px)'}}>
                    <div className='debugHistoryHead' >
                        <div style={{position:'absolute',width:'100%',display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                            <div style={{width:'90%',display:'flex',alignItems:'center',overflowX:'auto'}}>
                                {openTabsList.map((item,index)=>{
                                    return <div className='openTabContainer' style={{height:(index===activeTab ? 'calc(40px - 5px)' : '40px'),color:(index===activeTab ? 'black' : 'rgb(100,100,100 )'),borderBottom:(index===activeTab ? '5px solid orange' : '1px solid rgb(200,200,200)')}} key={index} onClick={()=>{setActiveTab(index);}}>
                                            <div style={{width:'90%',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',fontSize:'12px',fontWeight:'500'}}>
                                                {item.filename}
                                            </div>
                                            <div style={{cursor:'pointer'}} onClick={()=>{closeTab(item)}}>
                                                X
                                            </div>
                                        </div>
                                })}
                            </div>
                            <div className='addBoxContainer' style={{cursor:'pointer'}} onClick={()=>{
                                if(historyResponse.length > 0 && historyResponse[0].reportstatus==='Pending'){
                                    Store.addNotification({
                                        title: "Warning!",
                                        message: 'Please wait for the last file to complete processing, or refresh the page and try again.',
                                        type: "warning",
                                        insert: "top",
                                        container: "top-right",
                                        animationIn: ["animate__animated", "animate__fadeIn"],
                                        animationOut: ["animate__animated", "animate__fadeOut"],
                                        dismiss: {
                                          duration: 6000,
                                          onScreen: true
                                        }
                                    });
                                }
                                else{
                                    setShowAddTab(true);
                                }
                            }}>
                                Add
                            </div>
                        </div>
                    </div>
                    {(activeTab || activeTab===0) 
                        && 
                        openTabsList[activeTab]
                        &&
                        !getDataLoader
                        &&
                        openTabsList[activeTab].error
                        && 
                        openTabsList[activeTab].error.data
                        &&
                        openTabsList[activeTab].error.data.error
                        &&
                        <ErrorComponent data={openTabsList[activeTab].error.data.error}/>
                    }
                    {(activeTab || activeTab===0) 
                        && 
                        openTabsList[activeTab]
                        &&
                        !getDataLoader
                        &&
                        openTabsList[activeTab].response
                        && 
                    <div>
                        <div style={{padding:'20px',fontSize:'13px'}}>
                            <label style={{fontWeight:'500'}}>Filename : </label>
                            <label>{openTabsList[activeTab].filename}</label>
                        </div>
                        <div style={{padding:'20px',paddingTop:'0px',fontSize:'13px'}}>
                            <label style={{fontWeight:'500'}}>Tracking : </label>
                            <label style={{textTransform:'capitalize'}}>{openTabsList[activeTab].response['Tracker']}</label>
                        </div>
                        <div style={{padding:'20px',paddingTop:'0px',fontSize:'13px'}}>
                            <label style={{fontWeight:'500'}}>Average Count in Real Time : </label>
                            <label>{openTabsList[activeTab].response['Average Count in Real Time (Sum of avg Count List)']}</label>
                        </div>
                        <div style={{padding:'20px',paddingTop:'0px',fontSize:'13px'}}>
                            <label style={{fontWeight:'500'}}>Count Per Cycle : </label>
                            <label>{openTabsList[activeTab].response['Count Per Cycle']}</label>
                        </div>
                        <div style={{padding:'20px',paddingTop:'0px',fontSize:'13px'}}>
                            <label style={{fontWeight:'500'}}>Open Average Count : </label>
                            <label>{openTabsList[activeTab].response['Open Average Count (Avg. No of Opens to be Completed in 14 Hours']}</label>
                        </div>
                        {openTabsList[activeTab].response['Total Time [Hours]'] &&
                            <div style={{padding:'20px',paddingTop:'0px',fontSize:'13px'}}>
                                <label style={{fontWeight:'500'}}>Total Time [Hours] : </label>
                                <label>{openTabsList[activeTab].response['Total Time [Hours]']}</label>
                            </div>
                        }
                        <div style={{padding:'20px',paddingTop:'0px',fontSize:'13px'}}>
                            <label style={{fontWeight:'500'}}>Average Count List : </label>
                            {openTabsList[activeTab].response['Average Count List'].map((item,index)=>{
                                return  <label style={{paddingLeft:'5px'}} key={index}>{item}</label>
                            })}
                        </div>
                        {openTabsList[activeTab].response['Day Count']
                            &&
                            <div style={{padding:'20px',paddingTop:'0px',fontSize:'13px'}}>
                                <label style={{fontWeight:'500'}}>Day Count : </label>
                                {openTabsList[activeTab].response['Day Count'].map((item,index)=>{
                                    return  <label style={{paddingLeft:'5px'}} key={index}>{item}</label>
                                })}
                            </div>
                        }
                    </div>}
                    {getDataLoader && 
                    <div style={{display:'flex',alignItems:'center',justifyContent:'center',minHeight:'40vh'}}>
                        <CircularProgress sx={{fontSize:'40px',color:'orange'}}/>
                    </div>
                    }
                    {
                        showAddTab &&
                        <div className='addTabContainer'>
                            <div className='addTabWrapper'>
                                <div style={{display:'flex',justifyContent:'space-between',alignItems:'center',cursor:'default'}}>
                                    <div>Add File for Debug</div>
                                    <div style={{cursor:'pointer'}} onClick={()=>{setShowAddTab(false);setAddTabData({...addTabData,file:null})}}>X</div>
                                </div>
                                
                              
                                {isRetentionPage &&
                                <>
                                    <input type="file" name="file" accept='.py' id='scriptSelect' style={{height:'100%',width:'100%'}} onChange={changeHandler}  hidden/>
                                    <label className='addFileWrapper' htmlFor='scriptSelect' >
                                        {addTabData.file ? addTabData.file.name : '+ Click to Add File'}
                                    </label>
                                </>
                                }
                                {!isRetentionPage &&
                                    <div style={{marginTop:'40px',paddingBottom:'10px',transform:'scale(0.9)'}}>
                                        <Constants.CssTextField size='small' fullWidth value={addTabData.file ? addTabData.file : ''} label='Filename' onKeyDown={enterKeyPressed} onChange={(e)=>{setAddTabData({...addTabData,file:e.target.value})}}/>
                                        
                                        {/* <CreatableSelect                                                
                                            isClearable
                                            placeholder={'Filename'}
                                            value={addTabData.file ? addTabData.file : false}
                                            onChange={(e)=>{setAddTabData({...addTabData,file:e})}}
                                            menuPortalTarget={document.body} 
                                            styles={{...Constants.ReactSelectStyles}}
                                        /> */}
                                    </div>
                                }
                               
                                <div className='addFileParams'>
                                    <div style={{width:'33%'}}>
                                        <div style={{fontSize:'12px'}}>Retention Days</div>
                                        <ReactSelect                                                
                                            placeholder={'Retention Days'}
                                            options={[1,3,5,7,10,15].map((item,index)=>{return {label:item,value:item}})}
                                            value={addTabData.noOfDays ? {label:addTabData.noOfDays,value:addTabData.noOfDays} : false}
                                            onChange={(e)=>{setAddTabData({...addTabData,noOfDays:e.value})}}
                                            menuPortalTarget={document.body} 
                                            styles={{...Constants.ReactSelectStyles}}
                                        />
                                    </div>
                                    <div style={{width:'33%'}}>
                                        <div style={{fontSize:'12px'}}>I1 Count</div>
                                        <ReactSelect                                                
                                            placeholder={'I1 Count'}
                                            options={[10,50,100,200,300,400,500,1000].map((item,index)=>{return {label:item,value:item}})}
                                            value={addTabData.noOfDays ? {label:addTabData.noOfUser,value:addTabData.noOfUser} : false}
                                            onChange={(e)=>{setAddTabData({...addTabData,noOfUser:e.value})}}
                                            menuPortalTarget={document.body} 
                                            styles={{...Constants.ReactSelectStyles}}
                                        />
                                    </div>

                                    {/* <Constants.CssTextField size='small' value={addTabData.noOfDays} label='Retention Days' onKeyDown={enterKeyPressed} onChange={(e)=>{setAddTabData({...addTabData,noOfDays:e.target.value})}}/>
                                    <Constants.CssTextField size='small' value={addTabData.noOfUser} label='I1 Count' onKeyDown={enterKeyPressed} onChange={(e)=>{setAddTabData({...addTabData,noOfUser:e.target.value})}}/> */}
                                </div>
                                <div style={{display:'flex',justifyContent:'center'}}>
                                    <Button disabled={!addTabData.file || !addTabData.noOfDays || !addTabData.noOfUser} sx={{...Constants.CONTAINEDBUTTON,textTransform:'none',fontWeight:'400',marginTop:'20px'}} onClick={()=>{addRetention()}}> 
                                        {addLoader && <CircularProgress sx={{color:'white'}} size={'18px'}/>}
                                        {!addLoader && "Submit"}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default RetentionReport;