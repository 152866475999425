import React, { useCallback, useEffect, useState } from 'react'
import '../../../css/pages/v1/newkeys.css';
import TextField from '@mui/material/TextField';

import Button from '@mui/material/Button';
import { getLocalStorageValue, increase_brightness } from '../../../helper/util';
import axios from 'axios';
import * as Constants from '../../../data/constants';

function useForceUpdate(){
    const [value, setValue] = useState(0); // integer state
    if(false){
        console.log(value)
    }
    return () => setValue(value => value + 1); // update the state to force render
  }

export const NewKeys = (props) => {
    const updateComponent = useForceUpdate()
    
    var ambientColor = localStorage.getItem('ambientColor')  ? localStorage.getItem('ambientColor') : "rgb(0, 166, 178)"
    var r = document.querySelector(':root');
    r.style.setProperty('--container-shadow-color', increase_brightness(ambientColor,0));
    r.style.setProperty('--container-shadow-color-hover', ambientColor);
    
    
    var respData = {}
    if(props.respData){
        respData = props.respData.new_keys
    }
    const [callsList, setCallsList] = useState({})
    const [generatedValueList, setGeneratedValueList] = useState([])
    const [openCallsList, setOpenCallsList] = useState([])
    const [popup, setShowPopup] = useState(false)
    const [generatedValueLength, setGeneratedValueLength] = useState("<length>")
    const [generatedDate, setGeneratedDate] = useState(false)
    const [generatedValueUpperLimit, setGeneratedValueUpperLimit] = useState("<upper limit>")
    const [generatedValueLowerLimit, setGeneratedValueLowerLimit] = useState("<lower limit>")
    const [selectTextFocused, setSelectTextFocused] = useState(false)
    const [selectedTextCount, setSelectedTextCount] = useState(0)
    const [generateOptions, setGenerateOptions] = useState(localStorage.getItem('generateOptions') ? JSON.parse(localStorage.getItem('generateOptions')).data : [])
    const [manualEnterSelect, setManualEnterSelect] = useState("")
    const [manualUseEffect, setManualUseEffect] = useState(false)
    const [activeTab, setActiveTab] = useState(false)
    if(activeTab !== props.activeTab){
        if(activeTab===0 || activeTab){
            props.setBackupData({...props.backupData,[activeTab]:callsList})
        }
        setActiveTab(props.activeTab)
    }
    const handleTabChangedClicked = useCallback(e => {
        e.stopPropagation();
        if(e.target.id === 'tabsContainer'){
            if(props.backupData[props.activeTab]){
                setCallsList(props.backupData[props.activeTab])
            }
            else{
                setCallsList({})
            }
        }
    }, [props,setCallsList]);

    useEffect(() => {
        window.addEventListener('click',handleTabChangedClicked,false)
        
        return () => {
            window.removeEventListener('click', handleTabChangedClicked,false)
        }
    }, [handleTabChangedClicked])
    
    // if(!props.tabChanged){
    //     props.setTabChanged(true)
    //     console.log('herer')
    // }

    const optionsRequest = async() => {
        console.log('jerer')
        await axios({
            method: "GET",
            url: Constants.BACKENDHOST+"scriptcreator/new_keys_options",
            headers:{
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache',
                'Expires': '0',
                Authorization:getLocalStorageValue('adminPanelLoginData').token,
            }
        })
        .then(resp => {
            localStorage.setItem('generateOptions',JSON.stringify(resp.data.data))
            setGenerateOptions(resp.data.data)
        })
    }
    if(!manualUseEffect){
        setManualUseEffect(true)
        optionsRequest()
    }
    const handleGeneratedValueChange = (e,call_id,keyDetails,storeInAppData) => {
        var storeInAppDataSecond = false
        if(Object.keys(callsList).length===0){
            var tempCallsList = {}
            for(var item in respData){
                if(!tempCallsList[respData[item].call_id]){
                    tempCallsList[respData[item].call_id] = []
                }
                tempCallsList[respData[item].call_id].push({...respData[item],reverseKey:item})
            }
        }
        else{
            tempCallsList = callsList
        }
        if(e[e.length-1]){
            if(e[e.length-1]['label'].toLowerCase().startsWith('random') || e[e.length-1]['label'].toLowerCase().startsWith('current')){
                storeInAppDataSecond = true
            }
            if(e[e.length-1]['previous_value']){
                var updateMultiplePlace = e[e.length-1]
                tempCallsList[updateMultiplePlace.callId][updateMultiplePlace.keyId]['multiple_places'] = true;
            }
            if(e[e.length-1]['value'].includes("<length>")){
                setShowPopup({type:'length',e,call_id,keyDetails})
            }
            if(e[e.length-1]['value'].includes("<date_format>")){
                setShowPopup({type:'date format',e,call_id,keyDetails})
            }
            if(e[e.length-1]['value'].includes("<lower limit>") && e[e.length-1]['value'].includes("<upper limit>")){
                setShowPopup({type:'range',e,call_id,keyDetails})
            }
        }
        setGeneratedValueList([...generatedValueList,call_id])
        tempCallsList[call_id][keyDetails]['generated_value'] = []
        for(var callIds in tempCallsList){
            for(var keyIds in tempCallsList[callIds]){
                if(parseInt(callIds)===parseInt(call_id) && parseInt(keyIds) === parseInt(keyDetails)){

                    tempCallsList[callIds][keyIds].generated_value = e
                    tempCallsList[callIds][keyIds]['app_data'] = (storeInAppDataSecond && storeInAppData)
                    if((storeInAppDataSecond && storeInAppData)){
                        var newOption = {
                            label: tempCallsList[callIds][keyIds].value.toString(),
                            value : tempCallsList[callIds][keyIds].value.toString(),
                            previous_value:true,
                            callId:callIds,
                            keyId:keyIds,
                        }
                        var foundOptionCheck = false;
                        for(var generateOptionsIndex in generateOptions){
                            if(generateOptions[generateOptionsIndex].value===newOption.value){
                                foundOptionCheck = true;
                                break;
                            }
                        }
                        if(!foundOptionCheck && !generateOptions.includes(newOption)){
                            console.log(newOption)
                            setGenerateOptions([...generateOptions,newOption])
                        }
                    }
                }
                // if(tempCallsList[callIds][keyIds].key === tempCallsList[call_id][keyDetails].key){
                //     tempCallsList[callIds][keyIds].generated_value = e
                // }
            }
        }
        setCallsList(tempCallsList)
        updateComponent(e)
    }

    const updateGeneratedValues = (e) => {
        var tempCallsList = callsList
        for(var callIds in tempCallsList){
            for(var keyIds in tempCallsList[callIds]){
                if(callIds === popup.call_id && keyIds === popup.keyDetails.toString()){
                    var oldValue = popup.e
                    if(popup.type==="length"){
                        oldValue[oldValue.length-1]['value'] = oldValue[oldValue.length-1].value.replace("<length>",generatedValueLength)
                        oldValue[oldValue.length-1]['label'] = oldValue[oldValue.length-1].label + " (" + generatedValueLength + ")"
                    }
                    if(popup.type==="range"){
                        oldValue[oldValue.length-1]['value'] = oldValue[oldValue.length-1].value.replace("<lower limit>",generatedValueLowerLimit).replace("<upper limit>",generatedValueUpperLimit)
                        oldValue[oldValue.length-1]['label'] = oldValue[oldValue.length-1].label + " (" + generatedValueLowerLimit +","+generatedValueUpperLimit+ ")"
                    }
                    if(popup.type==="date format"){
                        oldValue[oldValue.length-1]['value'] = oldValue[oldValue.length-1].value.replace("<date_format>",generatedDate)
                        oldValue[oldValue.length-1]['label'] = oldValue[oldValue.length-1].label + " (" + generatedDate + ")"
                    }
                    tempCallsList[callIds][keyIds].generated_value = oldValue
                }
            }
        }
        setShowPopup(false);
        setGeneratedValueLength(0);
        setGeneratedValueLowerLimit(0);
        setGeneratedValueUpperLimit(0);
        var tempGenerateOptions = JSON.parse(localStorage.getItem('generateOptions'))
        var tempNewlyAddedGeneratedOptions = generateOptions.slice(tempGenerateOptions.length,generateOptions.length)
        setGenerateOptions([...tempGenerateOptions,...tempNewlyAddedGeneratedOptions])
        setCallsList(tempCallsList)
        updateComponent(e)
    }

    const checkForAllGeneratedValues = (call_id) => {
        var allValuesGenerated = true;
        for (var index in callsList[call_id]){
            if(!callsList[call_id][index].generated_value || callsList[call_id][index].generated_value.length===0){
                allValuesGenerated = false;
                break;
            }
        }
        if(!allValuesGenerated){
            return 'red'
        }
        else{
            return 'black'
        }
    }
    
    const deletedSelectedOption = (e,item,index2,index3) => {
        var tempCallsList = callsList
        for(var callIds in tempCallsList){
            if(callIds === item ){
                for(var keyIds in tempCallsList[callIds]){
                    if(keyIds === index2.toString()){
                        var tempGeneratedValue = []
                        for(var generated_id in tempCallsList[callIds][keyIds].generated_value){
                            if(index3==="closeButton"){
                                index3 = tempCallsList[callIds][keyIds].generated_value.length-1
                            }
                            if(generated_id===index3.toString()){
                            }
                            else{
                                tempGeneratedValue.push(tempCallsList[callIds][keyIds].generated_value[generated_id])
                            }
                        }
                        tempCallsList[callIds][keyIds].generated_value = tempGeneratedValue
                    }
                }
            }
        }
        setCallsList(tempCallsList)
        updateComponent(e)
    }
    
    const showCalls = (respData) => {
        var tempOpenCallsList = openCallsList
        if(Object.keys(callsList).length===0){
            var tempCallsList = {}
            for(var item in respData){
                if(!tempCallsList[respData[item].call_id]){
                    tempCallsList[respData[item].call_id] = []
                }
                tempCallsList[respData[item].call_id].push({...respData[item],reverseKey:item})
            }
        }
        else{
            tempCallsList = callsList
        }
        return Object.keys(tempCallsList).map((item,index)=>{
            return <div key={index} className={"urlContainer"}  >
                <div style={{display:'flex',alignItems:'center',paddingTop:'5px',paddingBottom:'5px',userSelect:'none',cursor:'pointer'}} onClick={(e)=>{if(openCallsList.includes(item)){setOpenCallsList(openCallsList.filter(e=>e!==item))}else{setOpenCallsList([...openCallsList,item])}}}>
                    <div className={'openUrlIcon'}>
                        {">"}
                    </div>
                    <div className={'callUrls'} >
                        <div style={{color:checkForAllGeneratedValues(item)}}>{tempCallsList[item][0].call_id}</div>
                        &nbsp;
                        {"-"}
                        &nbsp;
                        {tempCallsList[item][0].call_url 
                            ? 
                                tempCallsList[item][0].call_url.includes("->") 
                                    ? 
                                    <div style={{display:'flex'}}><div>{tempCallsList[item][0].call_url.split("->")[0] + " ->" }</div><div style={{color:'green'}}>&nbsp;{tempCallsList[item][0].call_url.split("->")[1]}</div></div>
                                    :  
                                    tempCallsList[item][0].call_url
                            : 
                            "NA"
                        }
                    </div>
                </div>
                {(openCallsList.includes(item) || tempOpenCallsList.includes(item)) && 
                    <div>
                        <div style={{display:'flex',paddingLeft:'20px',paddingRight:'20px',paddingTop:'5px',paddingBottom:'5px',fontSize:'13px',fontWeight:'500'}}>
                            <div style={{width:'30%'}}>
                                Key
                            </div>
                            <div style={{width:'25%',marginLeft:'2.5%'}}>
                                Original Value
                            </div>

                            <div style={{width:'40%',marginLeft:'2.5%'}}>
                                Generated Value
                            </div>
                        </div>
                        {tempCallsList[item].map((item2,index2)=>{
                            return <div key={index2} style={{display:'flex',alignItems:'center',paddingLeft:'20px',paddingRight:'20px',paddingTop:'5px',paddingBottom:'5px',fontSize:'13px'}}>
                                    <div style={{width:'30%'}}>
                                        {item2.key}
                                    </div>
                                    <div style={{width:'25%',marginLeft:'2.5%',wordBreak:'break-all',wordWrap:'break-word'}} onClick={(e)=>{setSelectedTextCount(document.getSelection().toString().length);updateComponent(e);}} onMouseMove={(e)=>{setSelectedTextCount(document.getSelection().toString().length);updateComponent(e);}}>
                                        {item2.original_value}
                                    </div>
                                    <div style={{width:'40%',marginLeft:'2.5%'}}>
                                        <div style={{minHeight:'30px',minWidth:"100%",border:'1px solid rgb(150,150,150)',background:'white',borderRadius:'5px',display:'flex',flexWrap:'wrap',alignItems:'center'}}>
                                            {item2.generated_value && item2.generated_value.map((item3,index3)=>{
                                                return <div key={index3} className='selectedOptionContainer'>
                                                        <div className='selectedOptionText'>{item3.label}</div>
                                                        <div className='deleteSelectedOption' onClick={(e)=>{deletedSelectedOption(e,item,index2,index3)}}>X</div>
                                                        </div>
                                            })}
                                            <input style={{width:(item2.generated_value ? 'auto' : '100%')}} className='inputSelectField' placeholder='Select' onClick={(e)=>{e.stopPropagation();setSelectTextFocused(item+item2.key)}} 
                                                value={selectTextFocused===(item+item2.key) ? manualEnterSelect : ""}
                                                onChange={(e)=>{setManualEnterSelect(e.target.value)}} 
                                                onKeyDown={(e)=>{if(e.key==="Enter"){handleGeneratedValueChange(item2.generated_value ? [...item2.generated_value,{label:e.target.value,value:e.target.value}] : [{label:e.target.value,value:e.target.value}],item,index2);setManualEnterSelect("");}                                                                    
                                                                }}
                                            ></input>
                                            {/* <div style={{borderLeft:'1px solid rgb(150,150,150)',margin:'5px'}}></div> */}
                                            </div>
                                        {selectTextFocused===(item+item2.key) && 
                                            <div className='optionsListContainter'>
                                                {generateOptions.map((optionItem,optionIndex)=>{
                                                    if(optionItem.label.toString().toLowerCase().includes(manualEnterSelect.toLowerCase()) || manualEnterSelect===""){
                                                        return <div id={"option" + (optionIndex+1)} key={optionIndex} className='optionItem' onClick={()=>{setSelectTextFocused(false);handleGeneratedValueChange(item2.generated_value ? [...item2.generated_value,optionItem] : [optionItem],item,index2,true);setManualEnterSelect("");}}>
                                                            {optionItem.label}
                                                            </div>
                                                    }
                                                    return null;
                                                })}
                                                {manualEnterSelect.length>=1 &&
                                                    <div className='optionItem' id="option_0" onClick={()=>{setSelectTextFocused(false);handleGeneratedValueChange(item2.generated_value ? [...item2.generated_value,{label:manualEnterSelect,value:manualEnterSelect}] : [{label:manualEnterSelect,value:manualEnterSelect}],item,index2);setManualEnterSelect("")}}>
                                                        Create {manualEnterSelect}
                                                    </div>
                                                }
                                            </div>}
                                    </div>
                                </div>
                        })}
                    </div>

                }
                </div>
        })
    }
    
    const handleSubmitButton = () => {
    
        var tempCallsList = {}
        for(var callid in callsList){
            for(var original_value in callsList[callid]){
                tempCallsList[callsList[callid][original_value].reverseKey] = callsList[callid][original_value]
            }
        }
        props.newKeysSubmit(tempCallsList)
        props.setResponseNewKeys(tempCallsList)
    }

    const getSelectedTextCount = (e) => {
        if(document.getSelection().toString().length===0 && selectedTextCount){
            setSelectedTextCount(0);
        }
    
        if(document.getSelection().toString().length && !selectedTextCount){
            setSelectedTextCount(document.getSelection().toString().length);
        }
        updateComponent(e)
    }
    return (
        <div className='ctContainer' onClick={(e)=>{if(selectTextFocused){setSelectTextFocused(false);};getSelectedTextCount(e)}} onMouseMove={(e)=>{getSelectedTextCount(e);}}>
            <div style={{display:'flex',justifyContent:'space-between',fontSize:'12px',userSelect:'none',cursor:'pointer'}}>
                <div style={{display:'flex'}}> 
                    <div style={{margin:'10px'}} onClick={()=>{
                        var tempOpenCallsList = []
                        for(var item in respData){
                            if(!tempOpenCallsList.includes(respData[item].call_id.toString())){
                                tempOpenCallsList.push(respData[item].call_id.toString())
                            }
                        }
                        setOpenCallsList(tempOpenCallsList)
                    }}>Show all</div>
                    <div style={{margin:'10px'}} onClick={()=>{setOpenCallsList([])}}>Close all</div>
                </div>
                <div style={{margin:'10px'}}>
                    Selected Text : {selectedTextCount}
                </div>
            </div>
            <div>
                {showCalls(respData)}
            </div>
            <br/>
            <br/>
            <br/>
            <div style={{display:'flex',alignItems:'center',justifyContent:'center',paddingTop:'10px',paddingBottom:'10px',position:'sticky',bottom:0,width:'100%',left:0,background:'white'}}>
                <Button color='warning' variant='contained' onClick={handleSubmitButton}> Submit </Button>
            </div>
            {   
                popup &&
                    <div style={{display:'flex',zIndex:2,position:'fixed',width:'100vw',height:'100vh',left:0,top:52,justifyContent:'center',alignItems:'center',backdropFilter:'blur(2px)'}} onClick={(e)=>{setShowPopup(false);deletedSelectedOption(e,popup.call_id,popup.keyDetails,"closeButton")}}>
                        <div style={{padding:"2%",marginBottom:'10%',background:'white',userSelect:'none',boxShadow:'0 0 5px 1px grey',zIndex:2,width:'40vw',height:'28vh'}} onClick={e=>{e.stopPropagation();}}>
                            <div style={{display:'flex',fontWeight:'500',height:'10%'}}> 
                                <div style={{width:'95%'}}>
                                    Please enter {popup.type} for {popup.e[popup.e.length-1].label}
                                </div>
                                <div style={{width:'5%'}} onClick={(e)=>{setShowPopup(false);deletedSelectedOption(e,popup.call_id,popup.keyDetails,"closeButton")}}>
                                    X
                                </div>
                            </div>
                            <div style={{marginTop:'2%',borderBottom:'1px solid '+ambientColor}}></div>
                            <div style={{height:'80%',display:'flex',justifyContent:'center',alignItems:'center',width:'100%'}}>
                                <div style={{display:'grid',width:'100%'}}>
                                    {popup.type==="length" && <div style={{marginTop:'4%',width:'100%',display:'flex',justifyContent:'center'}}>
                                        <TextField color='warning' id="textFieldInputLength" size="small" style={{width:'70%'}}  label={"Length"} onChange={(e)=>{setGeneratedValueLength(e.target.value)}} onKeyDown={(e)=>{if(e.key==="Enter"){updateGeneratedValues(e);}}}/>
                                    </div>}
                                    {popup.type==="range" && 
                                    <>
                                    <div style={{marginTop:'4%',width:'100%',display:'flex',justifyContent:'center'}}>
                                        <TextField color='warning' size="small" style={{width:'70%'}}  label={"Lower Limit"} onChange={(e)=>{setGeneratedValueLowerLimit(e.target.value)}}/>
                                    </div>
                                    <div style={{marginTop:'4%',width:'100%',display:'flex',justifyContent:'center'}}>
                                        <TextField color='warning' size="small" style={{width:'70%'}}  label={"Upper Limit"} onChange={(e)=>{setGeneratedValueUpperLimit(e.target.value)}} onKeyDown={(e)=>{if(e.key==="Enter"){updateGeneratedValues(e);}}}/>
                                    </div>
                                    </>
                                    }
                                    {popup.type==="date format" && 
                                        <div style={{marginTop:'4%',width:'100%',display:'flex',justifyContent:'center'}}>
                                            <TextField color='warning' size="small" style={{width:'70%'}}  label={"Date Format"} onChange={(e)=>{setGeneratedDate(e.target.value)}}/>
                                        </div>
                                    }
                                    <div style={{marginTop:'4%',display:'flex',justifyContent:'center'}}>
                                        <Button
                                            color='warning'
                                            variant='contained'
                                            disabled={((popup.type==='date' && (generatedDate!=="" ? false : true))) || ((popup.type==='length' && (parseInt(generatedValueLength) && parseInt(generatedValueLength)>0 ? false : true)) || (popup.type==='range' && (parseInt(generatedValueLowerLimit) && parseInt(generatedValueUpperLimit) && parseInt(generatedValueUpperLimit)>=parseInt(generatedValueLowerLimit) ? false : true)))}
                                            onClick={(e)=>{updateGeneratedValues(e)}}>Ok</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            { selectedTextCount!==0 &&
                <div style={{height:'25px',width:'100%',left:0,background:'rgb(200,200,200)',bottom:0,position:'fixed',alignItems:'center',fontSize:'13px',display:'flex',justifyContent:'flex-end'}}>
                        <div style={{paddingRight:'10%'}}>Selected Text : {selectedTextCount} </div>
                </div>
                }
        </div>
    )
}

export default NewKeys;
