import { Resizable } from 're-resizable';
import React, { useEffect, useState } from 'react';
import { sortingV2, sortingV2List, useForceUpdate } from '../../helper/util';
import './tablemodel.css';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Done, FilterAlt, Search } from '@mui/icons-material';
import { CssTextField } from '../../data/constants';
import { Button } from '@mui/material';

const TableModel = (props) => {
    const refreshComponent = useForceUpdate();
    var tempTableHeaders = []
    for(var index in props.tableHeaders){
        var item =  props.tableHeaders[index]
        tempTableHeaders.push({...item,minWidth:item.width})
    }
    const [tableHeaders, setTableHeaders] = useState(tempTableHeaders)
    const [tableValues, setTableValues] = useState(props.tableValues ? props.tableValues : [])
    const [sortingOrderKeyValue, setSortingOrderKeyValue] = useState({})
    const [originalTableValues, setOriginalTableValues] = useState(props.tableValues ? props.tableValues : [])
    
    const lockedItemCss = (item,header) => {
        if(item.locked){
            return {position:(item.locked ? 'sticky' : 'relative'),left:0,background:'white',zIndex:(header ? 10 : 1)}
        }
        return {}
    }
    
    const sortingFunction = (keyword,sortingOrder) => {
        setSortingOrderKeyValue({[keyword]:sortingOrder})
        var tempTableValues = tableValues
        
        sortingV2(tempTableValues,keyword,sortingOrder)
        
        setTableValues(tempTableValues)
    }
    const sortingComponent = (isChild,item,childItem) => {
        var sortingKey = childItem ? childItem.value : item.value
        var ascendingActive = Object.keys(sortingOrderKeyValue)[0]===sortingKey && sortingOrderKeyValue[Object.keys(sortingOrderKeyValue)[0]] === true
        var descendingActive = Object.keys(sortingOrderKeyValue)[0]===sortingKey && sortingOrderKeyValue[Object.keys(sortingOrderKeyValue)[0]] !== true
        return <div style={{position:'absolute',right:(isChild ? 0 : 5),cursor:'pointer',display:'flex',flexDirection:'column'}}>
                    <KeyboardArrowUpIcon className='sortingIcon' sx={{fontSize:(isChild ? '12px' : '14px'),height:'14px',marginBottom:'-5px',color:(ascendingActive ? 'rgb(10,10,10)' : 'rgb(170,170,170)')}} onClick={()=>{sortingFunction(sortingKey,true);}}/>
                    <KeyboardArrowDownIcon className='sortingIcon' sx={{fontSize:(isChild ? '12px' : '14px'),color:(descendingActive ? 'rgb(10,10,10)' : 'rgb(170,170,170)')}} onClick={()=>{sortingFunction(sortingKey,false);}}/>
                </div>
    }

    const [filterIconClicked, setFilterIconClicked] = useState(false)
    const [filterByValueSearched, setFilterByValueSearched] = useState('')
    const [filterByValueList, setFilterByValueList] = useState()
    const [filterDict, setFilterDict] = useState({})

    useEffect(() => {
        document.addEventListener('click',e=>{
            if(filterIconClicked){
                setFilterIconClicked(false)
                setFilterByValueSearched('')
                setFilterByValueList()
            }
        })
        
        return () => {
            document.addEventListener('click',e=>{
                if(filterIconClicked){
                    setFilterIconClicked(false)
                    setFilterByValueSearched('')
                    setFilterByValueList()
                }
            })
        }
    }, [filterIconClicked,setFilterIconClicked,setFilterByValueSearched,setFilterByValueList])
    
    const saveFilters = (columnName) => {
        var initialTableValues = props.tableValues;
        var tempFilterDict = filterDict;
        tempFilterDict[columnName] = filterByValueList
        initialTableValues = initialTableValues.filter(e => {
            var returnAllowed = true;
            for(var filterItem in tempFilterDict){
                if(!tempFilterDict[filterItem].includes(e[filterItem])){
                    returnAllowed = false
                    break
                }
            }
            return returnAllowed
        })
        setFilterDict(tempFilterDict)
        setTableValues(initialTableValues)
        setFilterIconClicked(false);
    }
    const filterComponent = (isChild,item,index,childItem,childIndex) => {
        var sortingKey = childItem ? childItem.value : item.value
        if(document.getElementById(item.value+'filter')){
            var right = -120
            var width = 240
            var multiplier = tableHeaders.length - 1 - parseInt(index) 
            if(childIndex){
                multiplier = tableHeaders[index].child ? tableHeaders[index].child.length - 1 - parseInt(childIndex) : 0
            }
            if(document.getElementById(item.value+'filter').parentElement){
                if(item.value===filterIconClicked){
                    let boundingrect = document.getElementById(item.value+'filter').parentElement.getBoundingClientRect().right
                    let windowWidth = document.body.clientWidth;
                    if(boundingrect+width>windowWidth){
                        right = windowWidth - boundingrect - width - 10 - (multiplier*50)                        
                    }
                }
            }
        }
        if(item.value===filterIconClicked){
            var inittraversedValues = []
            if(filterDict[item.value]){
                inittraversedValues = [...filterDict[item.value]]
            }
            sortingV2List(inittraversedValues)

            var traversedValues = []
            for(var tableValueIndex in props.tableValues){
                var tableValueItem = props.tableValues[tableValueIndex]
                if(!inittraversedValues.includes(tableValueItem[item.value]) && !traversedValues.includes(tableValueItem[item.value])){
                    traversedValues.push(tableValueItem[item.value])
                }
            }
            sortingV2List(traversedValues)

            traversedValues = [...inittraversedValues,...traversedValues]
            if(!filterByValueList){
                setFilterByValueList(traversedValues)
            }
        }
        return <div id={item.value+'filter'} style={{position:'absolute',right:(isChild ? 0 : 5),display:'flex',flexDirection:'column'}}>
                    <div style={{position:'relative'}} onClick={(e)=>{e.stopPropagation()}}>
                        <FilterAlt sx={{fontSize:'12px',color:(filterDict[item.value] && filterDict[item.value].length>0 ? 'orange' : 'rgb(140,140,140)')}} onClick={(e)=>{e.stopPropagation();setFilterIconClicked(item.value);if(filterDict[item.value]){setFilterByValueList(filterDict[item.value]);}else{setFilterByValueList(traversedValues);}}}/>
                        {filterIconClicked===item.value && 
                            <div style={{minHeight:'200px',paddingTop:'10px',paddingBottom:'10px',boxShadow:'0 0 3px 1px rgb(240,240,240)',width,background:'white',position:'absolute',top:40,left:right,border:'1px solid rgb(220,220,220)'}}>
                                <div className='filterItem' onClick={()=>{sortingFunction(sortingKey,true);setFilterIconClicked(false);}}>
                                    {"Sort A -> Z"}
                                </div>
                                <div className='filterItem' onClick={()=>{sortingFunction(sortingKey,false);setFilterIconClicked(false);}}>
                                    {"Sort Z -> A"}
                                </div>
                                <div style={{borderBottom:'1px solid rgb(150,150,150)',margin:'10px'}}></div>
                                <div style={{padding:'10px',fontSize:'12px',paddingTop:'0px',marginLeft:'5px',fontWeight:'500'}}>Filter by Values</div>
                                <div style={{display:'flex',paddingLeft:'14px',fontSize:'11px'}}>
                                    <div style={{cursor:'pointer',color:'orange'}} onClick={()=>{setFilterByValueList(traversedValues)}}>
                                        Select All
                                    </div>
                                    <div style={{marginLeft:'10px',cursor:'pointer',color:'orange'}} onClick={()=>{setFilterByValueList([])}}>
                                        Clear
                                    </div>
                                </div>
                                <div style={{transform:'scale(0.9)',marginTop:'10px'}}>
                                    <CssTextField size='small' fullWidth 
                                        defaultValue={filterByValueSearched}
                                        onChange={e=>{setFilterByValueSearched(e.target.value);}}
                                        InputProps={{
                                            endAdornment: <Search/>
                                        }}/>
                                </div>
                                <div style={{marginLeft:'15px',marginRight:'15px',overflow:'auto',height:'200px'}}>
                                    {traversedValues.map((tableValueItem,tableValueIndex)=>{
                                        if(tableValueItem && tableValueItem.toString().toLowerCase().includes(filterByValueSearched.toString().toLowerCase())){
                                            return <div className='filterByValueItem' key={tableValueIndex} onClick={()=>{var temp = filterByValueList ? filterByValueList : traversedValues; if(temp.includes(tableValueItem)){temp = temp.filter(e => e!==tableValueItem);}else{temp = [...filterByValueList,tableValueItem]}; setFilterByValueList(temp);}}>
                                                    <div className='filterByValueItemIcon'>
                                                        {((typeof filterByValueList === 'string' && filterByValueList === 'all')
                                                            ||
                                                        (typeof filterByValueList === 'object' && filterByValueList.includes(tableValueItem)))
                                                            &&
                                                            <Done sx={{fontSize:'14px'}}/>
                                                        }
                                                    </div>
                                                    <div>
                                                        {tableValueItem}
                                                    </div>
                                                </div>
                                        }
                                    })}
                                </div>
                                <div style={{display:'flex',height:'40px',alignItems:'center'}}>
                                    <Button color='warning' variant='contained' sx={{transform:'scale(0.8)'}} onClick={()=>{saveFilters(item.value)}}>Save</Button>
                                    <Button color='warning' sx={{transform:'scale(0.8)'}} onClick={()=>{
                                                                                                    setFilterIconClicked(false);
                                                                                                    setFilterByValueSearched('');
                                                                                                    setFilterByValueList();
                                                                                                    }}>
                                        Cancel</Button>
                                </div>
                            </div>
                        }
                    </div>
                </div>
    }
    const tableHeaderFunction = (showChildOnly) => {
        return props.tableHeaders.map((item,index)=>{
            if(tableHeaders[index]){
                var width = tableHeaders[index].width
            }
            else{
                width = item.width
                var tempTableHeadersState = tableHeaders
                tempTableHeadersState.push(item)
                setTableHeaders(tempTableHeaders)
                refreshComponent();
            }

            if(width>item.maxWidth){
                width=item.maxWidth
            }
            return <td style={{...lockedItemCss(item,true),minWidth:width,width,background:'rgb(240,240,240)',fontSize:'14px',transition:'all 0.3s linear',position:'sticky',top:0,zIndex:(item.locked ? 10 : 9)}} key={index}>
                <Resizable
                    size={{width:'100%'}}
                    style={{transition:'all 0.3s linear',padding:'5px'}}
                    enable={{ top:false, right:true, bottom:false, left:false, topRight:false, bottomRight:false, bottomLeft:false, topLeft:false }}
                    onResizeStop={(e, direction, ref, d) => {
                        var tempTableHeader = tableHeaders
                        var tempColumnWidth = width
                        if(tempColumnWidth + d.width < item.width){
                            tempColumnWidth = item.width
                        }
                        else{
                            tempColumnWidth = tempColumnWidth + d.width
                        }
                        tempTableHeader[index].width = tempColumnWidth;
                        setTableHeaders(tempTableHeader);
                        refreshComponent(e);
                        }}
                        >
                            <div style={{display:'flex',alignItems:'center',minHeight:'40px'}}>
                                <div style={{width:'100%'}}>
                                    <div style={{textAlign:(item.child ? 'center' : 'left'),transition:'all 0.3s linear'}}>
                                        {item.label}
                                    </div>
                                    {item.child &&
                                        <div style={{display:'flex',alignItems:'center',fontSize:'12px',paddingTop:'10px',textAlign:'center',width:"100%"}}>
                                        {item.child.map((childItem,childIndex)=>{
                                                return <div key={childIndex} style={{display:"flex",justifyContent:'center',alignItems:'center',minWidth:'33%',maxWidth:'33%',width:'33%',overflow:'clip',position:'relative'}}>
                                                    {childItem.label}
                                                    {sortingComponent(true,item,childItem)}
                                                </div>
                                            })}
                                        </div>
                                    }
                                </div>
                                {!item.child
                                &&
                                filterComponent(false,item,index)
                                }
                            </div>
                        </Resizable>
            </td>
        })
    }
    return (
        <table style={{tableLayout:'auto',width:'100%',overflow:'auto'}}>
            <tbody>
                <tr>
                    {props.showSerial
                    &&
                    <td style={{...lockedItemCss({locked:true},true),background:'rgb(240,240,240)',fontSize:'14px',transition:'all 0.3s linear',position:'sticky',top:0,zIndex:10}}>#</td>
                    }
                    {tableHeaderFunction()}
                </tr>
                {}
                {tableValues.map((valueItem,valueIndex)=>{
                    return <tr key={valueIndex} className='hoverValueRow' onClick={()=>{if(props.tableRowClicked){props.tableRowClicked(valueItem,valueIndex)}}}>
                        {props.showSerial
                        &&
                        <td>{valueIndex+1}</td>
                        }
                        {props.tableHeaders.map((item,index)=>{
                            if(tableHeaders[index]){
                                var width = tableHeaders[index].width
                            }
                            else{
                                width = item.width
                                var tempTableHeadersState = tableHeaders
                                tempTableHeadersState.push(item)
                                setTableHeaders(tempTableHeaders)
                                refreshComponent();
                            }
                
                            if(width>item.maxWidth){
                                width=item.maxWidth
                            }
                            if(item.style){
                                var style=item.style
                            }
                            else{
                                style = {}
                            }
                            if(item.customComponent){
                                return<td style={{position:'sticky',left:0,zIndex:(item.locked ? 1 : 0),fontSize:'14px',padding:'5px',paddingTop:'10px',paddingBottom:'10px',maxWidth:width,overflow:'hidden',textOverflow:'ellipsis',whiteSpace:'nowrap',...style}} key={index}>    
                                    {item.customComponent(valueItem,item.customStates,item,index,valueIndex)}
                                </td>
                            }
                            return <td style={{position:'sticky',left:0,zIndex:(item.locked ? 1 : 0),background:(item.locked ? 'white' : ''),fontSize:'14px',padding:'5px',paddingTop:'10px',paddingBottom:'10px',maxWidth:width,overflow:'hidden',textOverflow:'ellipsis',whiteSpace:'nowrap',...style}} key={index}>                                    
                                {!item.child && valueItem[item.value]}
                                {item.child &&
                                    <div style={{display:'flex',alignItems:'center',fontSize:'12px',paddingTop:'2px',textAlign:'center'}}>
                                        {item.child.map((childItem,childIndex)=>{
                                                return <div key={childIndex} style={{display:"flex",justifyContent:'center',alignItems:'center',minWidth:'33%',maxWidth:'33%',width:'33%',overflow:'clip'}}>
                                                    {valueItem[childItem.value]}
                                                </div>
                                            })}
                                    </div>
                                }
                            </td>
                        })}
                    </tr>
                })}
            </tbody>
        </table>
    )
}

export default TableModel