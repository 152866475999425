import React, { useState } from 'react'
import TopbarV2 from '../../../components/topbarv2/topbarv2'
import { Tree,TreeNode } from 'react-organizational-chart';
import './level1.css';
import EventList from './eventList';
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeItem from '@mui/lab/TreeItem';

const Level1 = () => {
    
    var tempeventNameList =  [
        {"eventName": "af_complete_registration_C", "eventValue": {"af_date_a": 1680596604758, "af_customer_user_id": "afe0d5e45f50493da472ba715e8747c4", "appsFlyerId": "1680596491696-6716418633447298780", "af_content_type": "regist_complete"}, "fiddlerCallId": 202},
        {"eventName": "af_complete_registration", "eventValue": {"af_date_a": 1680596604758, "af_customer_user_id": "afe0d5e45f50493da472ba715e8747c4", "appsFlyerId": "1680596491696-6716418633447298780", "af_content_type": "regist_complete"}, "fiddlerCallId": 205},
        {"eventName": "af_content_view", "eventValue": {"af_currency": "USD", "af_content_type": "page_view", "appsFlyerId": "1680596491696-6716418633447298780", "cid": "101001", "af_content_id": "808783217", "af_customer_user_id": "afe0d5e45f50493da472ba715e8747c4", "af_date_a": 1680596658551}, "fiddlerCallId": 310},
        {"eventName": "af_content_view_C", "eventValue": {"af_currency": "USD", "af_content_type": "page_view", "appsFlyerId": "1680596491696-6716418633447298780", "cid": "101001", "af_content_id": "808783217", "af_customer_user_id": "afe0d5e45f50493da472ba715e8747c4", "af_date_a": 1680596658551}, "fiddlerCallId": 311},
        {"eventName": "af_add_to_cart", "eventValue": {"af_currency": "USD", "af_content_type": "add_to_cart", "appsFlyerId": "1680596491696-6716418633447298780", "cid": "101001", "af_quantity": 1, "af_content_id": "808783217", "af_customer_user_id": "afe0d5e45f50493da472ba715e8747c4", "af_price": 28.31, "af_date_a": 1680596745748}, "fiddlerCallId": 353},
        {"eventName": "af_add_to_cart_C", "eventValue": {"af_currency": "USD", "af_content_type": "add_to_cart", "appsFlyerId": "1680596491696-6716418633447298780", "cid": "101001", "af_quantity": 1, "af_content_id": "808783217", "af_customer_user_id": "afe0d5e45f50493da472ba715e8747c4", "af_price": 28.31, "af_date_a": 1680596745748}, "fiddlerCallId": 354},
        {"eventName": "af_content_view_C", "eventValue": {"af_currency": "USD", "af_content_type": "page_view", "appsFlyerId": "1680596491696-6716418633447298780", "cid": "011019001", "af_content_id": "856035239", "af_customer_user_id": "afe0d5e45f50493da472ba715e8747c4", "af_date_a": 1680596811537}, "fiddlerCallId": 498},
        {"eventName": "af_content_view", "eventValue": {"af_currency": "USD", "af_content_type": "page_view", "appsFlyerId": "1680596491696-6716418633447298780", "cid": "011019001", "af_content_id": "856035239", "af_customer_user_id": "afe0d5e45f50493da472ba715e8747c4", "af_date_a": 1680596811537}, "fiddlerCallId": 499},
        {"eventName": "af_add_to_cart_C", "eventValue": {"af_currency": "USD", "af_content_type": "add_to_cart", "appsFlyerId": "1680596491696-6716418633447298780", "cid": "011019001", "af_quantity": 30, "af_content_id": "856035239", "af_customer_user_id": "afe0d5e45f50493da472ba715e8747c4", "af_price": 0.94, "af_date_a": 1680596821075}, "fiddlerCallId": 527},
        {"eventName": "af_add_to_cart", "eventValue": {"af_currency": "USD", "af_content_type": "add_to_cart", "appsFlyerId": "1680596491696-6716418633447298780", "cid": "011019001", "af_quantity": 30, "af_content_id": "856035239", "af_customer_user_id": "afe0d5e45f50493da472ba715e8747c4", "af_price": 0.94, "af_date_a": 1680596821075}, "fiddlerCallId": 529},
        {"eventName": "af_content_view_C", "eventValue": {"af_currency": "USD", "af_content_type": "page_view", "appsFlyerId": "1680596491696-6716418633447298780", "cid": "011019001", "af_content_id": "750423394", "af_customer_user_id": "afe0d5e45f50493da472ba715e8747c4", "af_date_a": 1680596839660}, "fiddlerCallId": 583},
        {"eventName": "af_content_view", "eventValue": {"af_currency": "USD", "af_content_type": "page_view", "appsFlyerId": "1680596491696-6716418633447298780", "cid": "011019001", "af_content_id": "750423394", "af_customer_user_id": "afe0d5e45f50493da472ba715e8747c4", "af_date_a": 1680596839660}, "fiddlerCallId": 582},
        {"eventName": "af_content_view", "eventValue": {"af_currency": "USD", "af_content_type": "page_view", "appsFlyerId": "1680596491696-6716418633447298780", "cid": "011019004", "af_content_id": "674684101", "af_customer_user_id": "afe0d5e45f50493da472ba715e8747c4", "af_date_a": 1680596919375}, "fiddlerCallId": 743},
        {"eventName": "af_content_view_C", "eventValue": {"af_currency": "USD", "af_content_type": "page_view", "appsFlyerId": "1680596491696-6716418633447298780", "cid": "011019004", "af_content_id": "674684101", "af_customer_user_id": "afe0d5e45f50493da472ba715e8747c4", "af_date_a": 1680596919375}, "fiddlerCallId": 744},
        {"eventName": "af_content_view", "eventValue": {"af_currency": "USD", "af_content_type": "page_view", "appsFlyerId": "1680596491696-6716418633447298780", "cid": "019030009012", "af_content_id": "856242571", "af_customer_user_id": "afe0d5e45f50493da472ba715e8747c4", "af_date_a": 1680596956065}, "fiddlerCallId": 853},
        {"eventName": "af_content_view_C", "eventValue": {"af_currency": "USD", "af_content_type": "page_view", "appsFlyerId": "1680596491696-6716418633447298780", "cid": "019030009012", "af_content_id": "856242571", "af_customer_user_id": "afe0d5e45f50493da472ba715e8747c4", "af_date_a": 1680596956065}, "fiddlerCallId": 852},
        {"eventName": "af_content_view_C", "eventValue": {"af_currency": "USD", "af_content_type": "page_view", "appsFlyerId": "1680596491696-6716418633447298780", "cid": "019030009008", "af_content_id": "842048338", "af_customer_user_id": "afe0d5e45f50493da472ba715e8747c4", "af_date_a": 1680597012779}, "fiddlerCallId": 1091},
        {"eventName": "af_content_view", "eventValue": {"af_currency": "USD", "af_content_type": "page_view", "appsFlyerId": "1680596491696-6716418633447298780", "cid": "019030009008", "af_content_id": "842048338", "af_customer_user_id": "afe0d5e45f50493da472ba715e8747c4", "af_date_a": 1680597012779}, "fiddlerCallId": 1092},
        {"eventName": "af_content_view", "eventValue": {"af_currency": "USD", "af_content_type": "page_view", "appsFlyerId": "1680596491696-6716418633447298780", "cid": "109011", "af_content_id": "771567886", "af_customer_user_id": "afe0d5e45f50493da472ba715e8747c4", "af_date_a": 1680597026718}, "fiddlerCallId": 1162},
        {"eventName": "af_content_view_C", "eventValue": {"af_currency": "USD", "af_content_type": "page_view", "appsFlyerId": "1680596491696-6716418633447298780", "cid": "109011", "af_content_id": "771567886", "af_customer_user_id": "afe0d5e45f50493da472ba715e8747c4", "af_date_a": 1680597026718}, "fiddlerCallId": 1163},
        {"eventName": "af_content_view_C", "eventValue": {"af_currency": "USD", "af_content_type": "page_view", "appsFlyerId": "1680596491696-6716418633447298780", "cid": "011019001", "af_content_id": "815394215", "af_customer_user_id": "afe0d5e45f50493da472ba715e8747c4", "af_date_a": 1680597031673}, "fiddlerCallId": 1226},
        {"eventName": "af_content_view", "eventValue": {"af_currency": "USD", "af_content_type": "page_view", "appsFlyerId": "1680596491696-6716418633447298780", "cid": "011019001", "af_content_id": "815394215", "af_customer_user_id": "afe0d5e45f50493da472ba715e8747c4", "af_date_a": 1680597031673}, "fiddlerCallId": 1228},
        {"eventName": "af_content_view", "eventValue": {"af_currency": "USD", "af_content_type": "page_view", "appsFlyerId": "1680596491696-6716418633447298780", "cid": "002006", "af_content_id": "761232586", "af_customer_user_id": "afe0d5e45f50493da472ba715e8747c4", "af_date_a": 1680597062589}, "fiddlerCallId": 1340},
        {"eventName": "af_content_view_C", "eventValue": {"af_currency": "USD", "af_content_type": "page_view", "appsFlyerId": "1680596491696-6716418633447298780", "cid": "002006", "af_content_id": "761232586", "af_customer_user_id": "afe0d5e45f50493da472ba715e8747c4", "af_date_a": 1680597062589}, "fiddlerCallId": 1339},
        {"eventName": "af_content_view_C", "eventValue": {"af_currency": "USD", "af_content_type": "page_view", "appsFlyerId": "1680596491696-6716418633447298780", "cid": "018008024003", "af_content_id": "527018666", "af_customer_user_id": "afe0d5e45f50493da472ba715e8747c4", "af_date_a": 1680597077948}, "fiddlerCallId": 1397},
        {"eventName": "af_content_view", "eventValue": {"af_currency": "USD", "af_content_type": "page_view", "appsFlyerId": "1680596491696-6716418633447298780", "cid": "018008024003", "af_content_id": "527018666", "af_customer_user_id": "afe0d5e45f50493da472ba715e8747c4", "af_date_a": 1680597077948}, "fiddlerCallId": 1396},
        {"eventName": "af_content_view", "eventValue": {"af_currency": "USD", "af_content_type": "page_view", "appsFlyerId": "1680596491696-6716418633447298780", "cid": "117004002", "af_content_id": "856100596", "af_customer_user_id": "afe0d5e45f50493da472ba715e8747c4", "af_date_a": 1680597196341}, "fiddlerCallId": 1582},
        {"eventName": "af_content_view_C", "eventValue": {"af_currency": "USD", "af_content_type": "page_view", "appsFlyerId": "1680596491696-6716418633447298780", "cid": "117004002", "af_content_id": "856100596", "af_customer_user_id": "afe0d5e45f50493da472ba715e8747c4", "af_date_a": 1680597196341}, "fiddlerCallId": 1581},
        {"eventName": "af_add_to_cart_C", "eventValue": {"af_currency": "USD", "af_content_type": "add_to_cart", "appsFlyerId": "1680596491696-6716418633447298780", "cid": "117004002", "af_quantity": 1, "af_content_id": "856100596", "af_customer_user_id": "afe0d5e45f50493da472ba715e8747c4", "af_price": 10.87, "af_date_a": 1680597207915}, "fiddlerCallId": 1596},
        {"eventName": "af_add_to_cart", "eventValue": {"af_currency": "USD", "af_content_type": "add_to_cart", "appsFlyerId": "1680596491696-6716418633447298780", "cid": "117004002", "af_quantity": 1, "af_content_id": "856100596", "af_customer_user_id": "afe0d5e45f50493da472ba715e8747c4", "af_price": 10.87, "af_date_a": 1680597207915}, "fiddlerCallId": 1597},
        {"eventName": "af_content_view", "eventValue": {"af_currency": "USD", "af_content_type": "page_view", "appsFlyerId": "1680596491696-6716418633447298780", "cid": "011013016004", "af_content_id": "487699357", "af_customer_user_id": "afe0d5e45f50493da472ba715e8747c4", "af_date_a": 1680597294635}, "fiddlerCallId": 1796},
        {"eventName": "af_content_view_C", "eventValue": {"af_currency": "USD", "af_content_type": "page_view", "appsFlyerId": "1680596491696-6716418633447298780", "cid": "011013016004", "af_content_id": "487699357", "af_customer_user_id": "afe0d5e45f50493da472ba715e8747c4", "af_date_a": 1680597294635}, "fiddlerCallId": 1795},
        {"eventName": "af_content_view_C", "eventValue": {"af_currency": "USD", "af_content_type": "page_view", "appsFlyerId": "1680596491696-6716418633447298780", "cid": "011013016004", "af_content_id": "470304091", "af_customer_user_id": "afe0d5e45f50493da472ba715e8747c4", "af_date_a": 1680597356741}, "fiddlerCallId": 1878},
        {"eventName": "af_content_view", "eventValue": {"af_currency": "USD", "af_content_type": "page_view", "appsFlyerId": "1680596491696-6716418633447298780", "cid": "011013016004", "af_content_id": "470304091", "af_customer_user_id": "afe0d5e45f50493da472ba715e8747c4", "af_date_a": 1680597356741}, "fiddlerCallId": 1879},
        {"eventName": "af_add_to_cart_C", "eventValue": {"af_currency": "USD", "af_content_type": "add_to_cart", "appsFlyerId": "1680596491696-6716418633447298780", "cid": "011013016004", "af_quantity": 5, "af_content_id": "470304091", "af_customer_user_id": "afe0d5e45f50493da472ba715e8747c4", "af_price": 0.7, "af_date_a": 1680597360275}, "fiddlerCallId": 1894},
        {"eventName": "af_add_to_cart", "eventValue": {"af_currency": "USD", "af_content_type": "add_to_cart", "appsFlyerId": "1680596491696-6716418633447298780", "cid": "011013016004", "af_quantity": 5, "af_content_id": "470304091", "af_customer_user_id": "afe0d5e45f50493da472ba715e8747c4", "af_price": 0.7, "af_date_a": 1680597360275}, "fiddlerCallId": 1895},
        {"eventName": "af_place_order_C", "eventValue": {"isNewBuyer": "NB", "af_content_type": "purchase", "appsFlyerId": "1680596491696-6716418633447298780", "UserType": "C", "af_quantity": "[5]", "oidList": "3572190183", "af_content_id": "470304091", "af_customer_user_id": "afe0d5e45f50493da472ba715e8747c4", "af_receipt_id": "3572190183", "cid": "", "fb_order_id": "", "af_price": "[0.7]", "af_date_a": 1680597540597, "af_revenue": "9.21"}, "fiddlerCallId": 2088},
        {"eventName": "af_place_order", "eventValue": {"isNewBuyer": "NB", "af_content_type": "purchase", "appsFlyerId": "1680596491696-6716418633447298780", "UserType": "C", "af_quantity": "[5]", "oidList": "3572190183", "af_content_id": "470304091", "af_customer_user_id": "afe0d5e45f50493da472ba715e8747c4", "af_receipt_id": "3572190183", "cid": "", "fb_order_id": "", "af_price": "[0.7]", "af_date_a": 1680597540597, "af_revenue": "9.21"}, "fiddlerCallId": 2089},
        {"eventName": "af_place_order_C", "eventValue": {"isNewBuyer": "NB", "af_content_type": "purchase", "appsFlyerId": "1680596491696-6716418633447298780", "UserType": "C", "af_quantity": "[5]", "oidList": "3572190183", "af_content_id": "470304091", "af_customer_user_id": "afe0d5e45f50493da472ba715e8747c4", "af_receipt_id": "3572190183", "cid": "", "fb_order_id": "", "af_price": "[0.7]", "af_date_a": 1680597641043, "af_revenue": "9.21"}, "fiddlerCallId": 2116},
        {"eventName": "af_place_order", "eventValue": {"isNewBuyer": "NB", "af_content_type": "purchase", "appsFlyerId": "1680596491696-6716418633447298780", "UserType": "C", "af_quantity": "[5]", "oidList": "3572190183", "af_content_id": "470304091", "af_customer_user_id": "afe0d5e45f50493da472ba715e8747c4", "af_receipt_id": "3572190183", "cid": "", "fb_order_id": "", "af_price": "[0.7]", "af_date_a": 1680597641043, "af_revenue": "9.21"}, "fiddlerCallId": 2115},
        {"eventName": "af_purchase", "eventValue": {"isNewBuyer": "NB", "af_content_type": "purchase", "appsFlyerId": "1680596491696-6716418633447298780", "UserType": "C", "af_quantity": "[5]", "oidList": "3572190183", "af_content_id": "470304091", "af_customer_user_id": "afe0d5e45f50493da472ba715e8747c4", "af_receipt_id": "3572190183", "cid": "", "fb_order_id": "20230404164049_900382", "af_price": "[0.7]", "af_date_a": 1680597777544, "af_revenue": "9.21"}, "fiddlerCallId": 2348},
        {"eventName": "af_purchase_C", "eventValue": {"isNewBuyer": "NB", "af_content_type": "purchase", "appsFlyerId": "1680596491696-6716418633447298780", "UserType": "C", "af_quantity": "[5]", "oidList": "3572190183", "af_content_id": "470304091", "af_customer_user_id": "afe0d5e45f50493da472ba715e8747c4", "af_receipt_id": "3572190183", "cid": "", "fb_order_id": "20230404164049_900382", "af_price": "[0.7]", "af_date_a": 1680597777544, "af_revenue": "9.21"}, "fiddlerCallId": 2349},
        {"eventName": "af_purchase_NB", "eventValue": {"isNewBuyer": "NB", "af_content_type": "purchase", "appsFlyerId": "1680596491696-6716418633447298780", "UserType": "C", "af_quantity": "[5]", "oidList": "3572190183", "af_content_id": "470304091", "af_customer_user_id": "afe0d5e45f50493da472ba715e8747c4", "af_receipt_id": "3572190183", "cid": "", "fb_order_id": "20230404164049_900382", "af_price": "[0.7]", "af_date_a": 1680597777544, "af_revenue": "9.21"}, "fiddlerCallId": 2352},
        {"eventName": "yf_suborder", "eventValue": {"af_currency": "USD", "appsFlyerId": "1680596491696-6716418633447298780", "COUPON_DHCOUPON_DH": "S8J8QOL8ARTLE1Z2KC7Z", "af_customer_user_id": "afe0d5e45f50493da472ba715e8747c4", "af_date_a": 1680597777546, "SUB_ORDER": "3572190183", "SUB_ORDER_TOTAL": "9.21", "SUB_ORDER_PRODUCT_SUBTOTAL": "2.5"}, "fiddlerCallId": 2353},
        {"eventName": "af_login_C", "eventValue": {"af_date_a": 1680597908247, "af_customer_user_id": "afe0d5e45f50493da472ba715e8747c4", "appsFlyerId": "1680596491696-6716418633447298780", "af_content_type": "login"}, "fiddlerCallId": 2581},
        {"eventName": "af_login", "eventValue": {"af_date_a": 1680597908247, "af_customer_user_id": "afe0d5e45f50493da472ba715e8747c4", "appsFlyerId": "1680596491696-6716418633447298780", "af_content_type": "login"}, "fiddlerCallId": 2579},
    ]
    const map = {
        "6e49ee91420bb26dd34b3e5f300a86a0": {
          "open_day_wise": {
            "unique": [
              1,
              0.6,
              0.4,
              0.2,
              0.3,
              0.6,
              0.5
            ],
            "overall": [
              2,
              1.2,
              0.8,
              0.4,
              0.6,
              1.2,
              1
            ]
          },
          "open_day_wise_range": {
            "unique": [
              [
                1,
                1
              ],
              [
                0.6,
                0.6
              ],
              [
                0.4,
                0.4
              ],
              [
                0.2,
                0.2
              ],
              [
                0.3,
                0.3
              ],
              [
                0.6,
                0.6
              ],
              [
                0.5,
                0.5
              ]
            ],
            "overall": [
              [
                2,
                2
              ],
              [
                1.2,
                1.2
              ],
              [
                0.8,
                0.8
              ],
              [
                0.4,
                0.4
              ],
              [
                0.6,
                0.6
              ],
              [
                1.2,
                1.2
              ],
              [
                1,
                1
              ]
            ]
          },
          "total_range": {
            "unique": [
              5,
              5
            ],
            "overall": [
              10,
              10
            ]
          },
          "event_name": "af_purchase",
          "event_count_per_user": [
            2,
            2
          ],
          "open_range": {
            "unique": [
              3.6,
              3.6
            ],
            "overall": [
              7.2,
              7.2
            ]
          },
          "source": "sdk",
          "tracker": "appsflyer",
          "event_values": [
            "{\"af_currency\":\"RUB\",\"okko_payment_method\":\"CP_CARD_AND_LINK\",\"af_content\":\"jolki-9\",\"af_revenue\":\"49\",\"af_content_id\":\"2669b96e-cf66-464c-9289-c9f824e896f7\",\"af_content_type\":\"MOVIE\"}"
          ],
          "install": {
            "unique": 1.4,
            "overall": 2.8
          },
          "total": {
            "unique": 5,
            "overall": 10
          },
          "open": {
            "unique": 3.6,
            "overall": 7.2
          },
          "install_range": {
            "unique": [
              1.4,
              1.4
            ],
            "overall": [
              2.8,
              2.8
            ]
          },
          "time_from_install": [
            34,
            147
          ]
        },
        "8b74b472ec97ac45f8d4bbf0a0759c21": {
          "open_day_wise": {
            "unique": [
              15.4,
              16.5,
              15.3,
              15.3,
              15.3,
              13,
              13.4
            ],
            "overall": [
              16.6,
              17.7,
              16.3,
              16.5,
              15.9,
              14.2,
              14.3
            ]
          },
          "open_day_wise_range": {
            "unique": [
              [
                15.4,
                15.4
              ],
              [
                16.5,
                16.5
              ],
              [
                15.3,
                15.3
              ],
              [
                15.3,
                15.3
              ],
              [
                15.3,
                15.3
              ],
              [
                13,
                13
              ],
              [
                13.4,
                13.4
              ]
            ],
            "overall": [
              [
                16.6,
                16.6
              ],
              [
                17.7,
                17.7
              ],
              [
                16.3,
                16.3
              ],
              [
                16.5,
                16.5
              ],
              [
                15.9,
                15.9
              ],
              [
                14.2,
                14.2
              ],
              [
                14.3,
                14.3
              ]
            ]
          },
          "total_range": {
            "unique": [
              140.2,
              140.2
            ],
            "overall": [
              149.8,
              149.8
            ]
          },
          "event_name": "af_initiated_checkout",
          "event_count_per_user": [
            1,
            7
          ],
          "open_range": {
            "unique": [
              104.2,
              104.2
            ],
            "overall": [
              111.5,
              111.5
            ]
          },
          "source": "sdk",
          "tracker": "appsflyer",
          "event_values": [
            "{\"af_currency\":\"RUB\",\"af_content\":\"manjunja\",\"af_content_id\":\"9b202b16-55bf-418a-b01d-9f0329b18790\",\"af_price\":\"69\",\"af_content_type\":\"SERIAL\"}"
          ],
          "install": {
            "unique": 36,
            "overall": 38.3
          },
          "total": {
            "unique": 140.2,
            "overall": 149.8
          },
          "open": {
            "unique": 104.2,
            "overall": 111.5
          },
          "install_range": {
            "unique": [
              36,
              36
            ],
            "overall": [
              38.3,
              38.3
            ]
          },
          "time_from_install": [
            21,
            125
          ]
        },
        "72c848f7a5ee0881c0a74611d39543d1": {
          "open_day_wise": {
            "unique": [
              5.2,
              7.1,
              6.5,
              6.1,
              4.5,
              4.5,
              4.9
            ],
            "overall": [
              5.2,
              7.1,
              6.5,
              6.1,
              4.5,
              4.5,
              4.9
            ]
          },
          "open_day_wise_range": {
            "unique": [
              [
                5.2,
                5.2
              ],
              [
                7.1,
                7.1
              ],
              [
                6.5,
                6.5
              ],
              [
                6.1,
                6.1
              ],
              [
                4.5,
                4.5
              ],
              [
                4.5,
                4.5
              ],
              [
                4.9,
                4.9
              ]
            ],
            "overall": [
              [
                5.2,
                5.2
              ],
              [
                7.1,
                7.1
              ],
              [
                6.5,
                6.5
              ],
              [
                6.1,
                6.1
              ],
              [
                4.5,
                4.5
              ],
              [
                4.5,
                4.5
              ],
              [
                4.9,
                4.9
              ]
            ]
          },
          "total_range": {
            "unique": [
              58.599999999999994,
              58.599999999999994
            ],
            "overall": [
              58.599999999999994,
              58.599999999999994
            ]
          },
          "event_name": "af_add_to_wishlist",
          "event_count_per_user": [
            1,
            4
          ],
          "open_range": {
            "unique": [
              38.8,
              38.8
            ],
            "overall": [
              38.8,
              38.8
            ]
          },
          "source": "sdk",
          "tracker": "appsflyer",
          "event_values": [
            "{\"af_content_id\":\"b5f01d35-abaf-4e0f-95b7-2acc4bdd51c5\",\"af_content_type\":\"SUBSCRIPTION\"}"
          ],
          "install": {
            "unique": 19.8,
            "overall": 19.8
          },
          "total": {
            "unique": 58.599999999999994,
            "overall": 58.599999999999994
          },
          "open": {
            "unique": 38.8,
            "overall": 38.8
          },
          "install_range": {
            "unique": [
              19.8,
              19.8
            ],
            "overall": [
              19.8,
              19.8
            ]
          },
          "time_from_install": [
            66,
            96
          ]
        },
        "fcfd36bb7d4488dbed8756310a89b48e": {
          "open_day_wise": {
            "unique": [
              23.8,
              24,
              23.8,
              22.4,
              22,
              21.1,
              21.1
            ],
            "overall": [
              27.8,
              27,
              26.9,
              26.7,
              23.8,
              24.5,
              23.9
            ]
          },
          "open_day_wise_range": {
            "unique": [
              [
                23.8,
                23.8
              ],
              [
                24,
                24
              ],
              [
                23.8,
                23.8
              ],
              [
                22.4,
                22.4
              ],
              [
                22,
                22
              ],
              [
                21.1,
                21.1
              ],
              [
                21.1,
                21.1
              ]
            ],
            "overall": [
              [
                27.8,
                27.8
              ],
              [
                27,
                27
              ],
              [
                26.9,
                26.9
              ],
              [
                26.7,
                26.7
              ],
              [
                23.8,
                23.8
              ],
              [
                24.5,
                24.5
              ],
              [
                23.9,
                23.9
              ]
            ]
          },
          "total_range": {
            "unique": [
              205.7,
              205.7
            ],
            "overall": [
              237.39999999999998,
              237.39999999999998
            ]
          },
          "event_name": "af_add_to_cart",
          "event_count_per_user": [
            1,
            11
          ],
          "open_range": {
            "unique": [
              158.2,
              158.2
            ],
            "overall": [
              180.6,
              180.6
            ]
          },
          "source": "sdk",
          "tracker": "appsflyer",
          "event_values": [
            "{\"af_content\":\"jolki-igolki\",\"af_content_id\":\"3e46c235-9d2b-4697-b234-c6e920da860f\",\"af_content_type\":\"MOVIE\"}"
          ],
          "install": {
            "unique": 47.5,
            "overall": 56.8
          },
          "total": {
            "unique": 205.7,
            "overall": 237.39999999999998
          },
          "open": {
            "unique": 158.2,
            "overall": 180.6
          },
          "install_range": {
            "unique": [
              47.5,
              47.5
            ],
            "overall": [
              56.8,
              56.8
            ]
          },
          "time_from_install": [
            10,
            109
          ]
        },
        "93777a49eca78aa507227178130a3fa2": {
          "open_day_wise": {
            "unique": [
              0.6,
              0.2,
              0.4,
              0.3,
              0.3,
              0.2,
              0.2
            ],
            "overall": [
              1.2,
              0.4,
              0.8,
              0.6,
              0.6,
              0.4,
              0.4
            ]
          },
          "open_day_wise_range": {
            "unique": [
              [
                0.6,
                0.6
              ],
              [
                0.2,
                0.2
              ],
              [
                0.4,
                0.4
              ],
              [
                0.3,
                0.3
              ],
              [
                0.3,
                0.3
              ],
              [
                0.2,
                0.2
              ],
              [
                0.2,
                0.2
              ]
            ],
            "overall": [
              [
                1.2,
                1.2
              ],
              [
                0.4,
                0.4
              ],
              [
                0.8,
                0.8
              ],
              [
                0.6,
                0.6
              ],
              [
                0.6,
                0.6
              ],
              [
                0.4,
                0.4
              ],
              [
                0.4,
                0.4
              ]
            ]
          },
          "total_range": {
            "unique": [
              10.2,
              10.2
            ],
            "overall": [
              20.4,
              20.4
            ]
          },
          "event_name": "af_okko_trial",
          "event_count_per_user": [
            2,
            2
          ],
          "open_range": {
            "unique": [
              2.2,
              2.2
            ],
            "overall": [
              4.4,
              4.4
            ]
          },
          "source": "sdk",
          "tracker": "appsflyer",
          "event_values": [
            "{\"af_currency\":\"RUB\",\"okko_payment_method\":\"CP_CARD_AND_LINK\",\"af_content\":\"67888\",\"af_revenue\":1,\"af_content_id\":\"6c61cac5-63ac-4b92-ba73-20239fbdb7dc\",\"af_content_type\":\"SUBSCRIPTION\"}"
          ],
          "install": {
            "unique": 8,
            "overall": 16
          },
          "total": {
            "unique": 10.2,
            "overall": 20.4
          },
          "open": {
            "unique": 2.2,
            "overall": 4.4
          },
          "install_range": {
            "unique": [
              8,
              8
            ],
            "overall": [
              16,
              16
            ]
          },
          "time_from_install": [
            117,
            238
          ]
        }
      }
    const testjson = {
        "#AUTOLABS_APP_LAUNCHED_D6#": [
          "fcfd36bb7d4488dbed8756310a89b48e",
          "72c848f7a5ee0881c0a74611d39543d1"
        ],
        "#AUTOLABS_APP_LAUNCHED_D7#": [
          "72c848f7a5ee0881c0a74611d39543d1",
          "fcfd36bb7d4488dbed8756310a89b48e"
        ],
        "72c848f7a5ee0881c0a74611d39543d1": [
          "fcfd36bb7d4488dbed8756310a89b48e",
          "72c848f7a5ee0881c0a74611d39543d1"
        ],
        "#AUTOLABS_APP_LAUNCHED_D4#": [
          "fcfd36bb7d4488dbed8756310a89b48e",
          "72c848f7a5ee0881c0a74611d39543d1"
        ],
        "fcfd36bb7d4488dbed8756310a89b48e": [
          "fcfd36bb7d4488dbed8756310a89b48e",
          "8b74b472ec97ac45f8d4bbf0a0759c21",
          "72c848f7a5ee0881c0a74611d39543d1"
        ],
        "93777a49eca78aa507227178130a3fa2": [
          "72c848f7a5ee0881c0a74611d39543d1",
          "fcfd36bb7d4488dbed8756310a89b48e"
        ],
        "#AUTOLABS_APP_LAUNCHED#": [
          "#AUTOLABS_APP_LAUNCHED_D0#",
          "#AUTOLABS_APP_LAUNCHED_D1#",
          "#AUTOLABS_APP_LAUNCHED_D2#",
          "#AUTOLABS_APP_LAUNCHED_D3#",
          "#AUTOLABS_APP_LAUNCHED_D4#",
          "#AUTOLABS_APP_LAUNCHED_D5#",
          "#AUTOLABS_APP_LAUNCHED_D6#",
          "#AUTOLABS_APP_LAUNCHED_D7#"
        ],
        "#AUTOLABS_APP_LAUNCHED_D0#": [
          "fcfd36bb7d4488dbed8756310a89b48e",
          "72c848f7a5ee0881c0a74611d39543d1"
        ],
        "#AUTOLABS_APP_LAUNCHED_D1#": [
          "fcfd36bb7d4488dbed8756310a89b48e",
          "72c848f7a5ee0881c0a74611d39543d1"
        ],
        "8b74b472ec97ac45f8d4bbf0a0759c21": [
          "72c848f7a5ee0881c0a74611d39543d1",
          "fcfd36bb7d4488dbed8756310a89b48e",
          "6e49ee91420bb26dd34b3e5f300a86a0",
          "93777a49eca78aa507227178130a3fa2"
        ],
        "#AUTOLABS_APP_LAUNCHED_D5#": [
          "fcfd36bb7d4488dbed8756310a89b48e",
          "72c848f7a5ee0881c0a74611d39543d1"
        ],
        "6e49ee91420bb26dd34b3e5f300a86a0": [
          "fcfd36bb7d4488dbed8756310a89b48e",
          "72c848f7a5ee0881c0a74611d39543d1"
        ],
        "#AUTOLABS_APP_LAUNCHED_D2#": [
          "fcfd36bb7d4488dbed8756310a89b48e",
          "72c848f7a5ee0881c0a74611d39543d1"
        ],
        "#AUTOLABS_APP_LAUNCHED_D3#": [
          "fcfd36bb7d4488dbed8756310a89b48e",
          "72c848f7a5ee0881c0a74611d39543d1"
        ]
      }

    var test_list = [{ 'Name': '#AUTOLABS_APP_LAUNCHED#', 'fillColor': '#916DAF' }]
    for(var index in testjson['#AUTOLABS_APP_LAUNCHED#']){
        var item = testjson['#AUTOLABS_APP_LAUNCHED#'][index]
        test_list.push({ 'Name': item, 'Category': '#AUTOLABS_APP_LAUNCHED#' })
    }
    const treeStruct = {}
    const responseToTree = (treeStruct,response,eventId,traversedList = []) => {
        var eventDict = {}
        if(response[eventId]){
            eventDict['name'] = map[eventId] ? map[eventId].event_name : eventId
            eventDict['children'] = []
            if(!traversedList.includes(eventId)){
                traversedList.push(eventId)
                if(response[eventId].length>0){
                    for(var index in response[eventId]){
                        var item = response[eventId][index]
                        eventDict['children'].push(responseToTree(eventDict,response,item,traversedList))
                    }
                }
            }
        }
        else{
            eventDict['name'] = map[eventId] ? map[eventId].event_name : eventId
            eventDict['children'] = []
        }
        return eventDict
    }
    const responseToTreeBFS = (startnode,queue) => {
        queue = [startnode,...[testjson[startnode]]]
        var tempqueue = []
        for(var index in queue){
            var item = queue[index]

        }
    }

    const [eventNameList, setEventNameList] = useState(tempeventNameList)
    const showTree = (list_of_dict) => {
        return list_of_dict.map((item,index)=>{
            return <TreeItem nodeId={index.toString()} label={<div>{map[item.name] ? map[item.name].event_name : item.name}</div>}>
                {item.children && showTree(item.children)}
            </TreeItem>
        })
    }


    var test_list = [{ 'Name': '#AUTOLABS_APP_LAUNCHED#', 'fillColor': '#916DAF' }]
    for(var index in testjson['#AUTOLABS_APP_LAUNCHED#']){
        var item = testjson['#AUTOLABS_APP_LAUNCHED#'][index]
        test_list.push({ 'Name': item, 'Category': '#AUTOLABS_APP_LAUNCHED#' })
    }
    return (
        <div>
            <TopbarV2 title={'Level 1'}/>
            <div className='level1Container'>
            <EventList eventNameList={eventNameList} setEventNameList={setEventNameList}/>
            <div style={{width:'100%',overflow:'auto',position:'relative'}}>
                <TreeView
                    aria-label="file system navigator"
                    defaultCollapseIcon={<ExpandMoreIcon />}
                    defaultExpandIcon={<ChevronRightIcon />}
                    sx={{ height: 240, flexGrow: 1, maxWidth: 400, overflowY: 'auto' }}
                    >
                    {showTree([responseToTree(treeStruct,testjson,"#AUTOLABS_APP_LAUNCHED_D0#")])}
                </TreeView>

            </div>
            </div>
        </div>
    )
}

export default Level1