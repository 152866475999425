import React, { useEffect, useState } from 'react'
import './textfieldComponent.css';
import { Close, Done } from '@mui/icons-material';
const TextfieldComponent = ({text,setText}) => {
    const [textAreaFocus, setTextAreaFocus] = useState(false)
    const [textValue, setTextValue] = useState(text)
    const [tempTextValue, setTempTextValue] = useState(text)
    
    useEffect(() => {
        document.addEventListener('click',e=>{
            setTextAreaFocus(false)
        })
        
        return () => {
            document.removeEventListener('click',e=>{
                setTextAreaFocus(false)
        })
      }
    }, [])
    
    return (
        <div style={{position:'relative',display:'flex'}}>
            <input className='textContainer' value={tempTextValue}  onClick={(e)=>{e.stopPropagation();setTextAreaFocus(true);}} onChange={(e)=>{setTempTextValue(e.target.value)}}>
            </input>
            {
                textAreaFocus 
                &&
                <div className='changesRow'>
                    <div className='doneChange' onClick={(e)=>{setText(tempTextValue);setTextValue(tempTextValue);setTextAreaFocus(false);}}><Done sx={{fontSize:'18px'}}/></div>
                    <div className='cancelChange' onClick={(e)=>{setTempTextValue(textValue);setTextAreaFocus(false);}}><Close sx={{fontSize:'18px'}}/></div>
                </div>
            }
        </div>
    )
}

export default TextfieldComponent