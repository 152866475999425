import { useState } from "react";
import {withStyles} from '@mui/styles';
import TextField from '@mui/material/TextField';

var CryptoJS = require("crypto-js");

export const CssTextField = withStyles({
    root: {
        '& label.Mui-focused': {
            color: 'black',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'black',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'black',
            },
            '&:hover fieldset': {
                borderColor: 'black',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'black',
            },
        },
    },
})(TextField);

export function capitalize(word) {
    if (word!==""){
        return word[0].toUpperCase() + word.substring(1).toLowerCase();
    }
    return word
}

export function encryptData(message){
    var encrypted = CryptoJS.AES.encrypt(message, 'taste').toString()
    return encrypted;
}
export function decryptData(message){
    var bytes = CryptoJS.AES.decrypt(message, 'taste');
    var decryptedData = bytes.toString(CryptoJS.enc.Utf8);
    return decryptedData;
}

export function getLocalStorageValue(key){
    try{
        var keyEncrypted = CryptoJS.SHA256(key).toString(CryptoJS.enc.Hex)
        var encryptedValue = localStorage.getItem(keyEncrypted)
        var decryptedData = decryptData(encryptedValue)
        try{
            decryptedData = JSON.parse(decryptedData)
        }
        catch{
            decryptedData = decryptedData.toString()
        }
        return decryptedData
    }
    catch{
        if(localStorage.getItem(key)){
            decryptedData = localStorage.getItem(key)
            try{
                decryptedData = JSON.parse(decryptedData)
            }
            catch{
                decryptedData = decryptedData.toString()
            } 
            return decryptedData
        }
        return false
    }
}

export function removeLocalStorageValue(key){
    var keyEncrypted = CryptoJS.SHA256(key).toString(CryptoJS.enc.Hex)
    if(!localStorage.getItem(keyEncrypted)){
        keyEncrypted = key
    }
    localStorage.removeItem(keyEncrypted)
}

export function setLocalStorageValue(key,data,notEncrypt=false){
    if(window.location.protocol==='http:' || notEncrypt){
        localStorage.setItem(key,JSON.stringify(data))
    }
    else{
        var keyEncrypted = CryptoJS.SHA256(key).toString(CryptoJS.enc.Hex)
        if(typeof data === 'object'){
            data = JSON.stringify(data)
        }
        var dataEncrypted = encryptData(data)
        localStorage.setItem(keyEncrypted,dataEncrypted)
    }
}

export const increase_brightness = function(color,percent){

    var ctx = document.createElement('canvas').getContext('2d');

    ctx.fillStyle = color;
    ctx.fillRect(0,0,1,1);

    color = ctx.getImageData(0,0,1,1);
    var r = color.data[0] + Math.floor( percent / 100 * 255 );
    var g = color.data[1] + Math.floor( percent / 100 * 255 );
    var b = color.data[2] + Math.floor( percent / 100 * 255 );

    return 'rgb('+r+','+g+','+b+')';
}

export function useForceUpdate(){
    const [value, setValue] = useState(0); // integer state
    if(false){
        console.log(value)
    }
    return () => setValue(value => value + 1); // update the state to force render
}


export function getElementCoords(elem) { // crossbrowser version
    var box = elem.getBoundingClientRect();

    var body = document.body;
    var docEl = document.documentElement;

    var scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
    var scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

    var clientTop = docEl.clientTop || body.clientTop || 0;
    var clientLeft = docEl.clientLeft || body.clientLeft || 0;

    var top  = box.top +  scrollTop - clientTop;
    var left = box.left + scrollLeft - clientLeft;

    return { top: Math.round(top), left: Math.round(left) };
}


export function sortingV2(list_of_dictionary,keyword,sortingOrder=true,key) {
    list_of_dictionary.sort((a, b) => {
        var previousValue = a[keyword]
        var nextValue = b[keyword]
        if(key){
            previousValue = previousValue[key]
            nextValue = nextValue[key]
        }
        if (sortingOrder===true){
            var reg = /^\d*\.?\d*$/;
            if(reg.test(previousValue) && reg.test(nextValue)){
                previousValue = parseFloat(previousValue)
                nextValue = parseFloat(nextValue)
            }
            if(typeof previousValue ==='number'){
                var temp = previousValue - nextValue;
                return temp
            }
            else{
                if(!previousValue && previousValue!==0){
                    previousValue = ''
                }
                if(!nextValue && nextValue!==0){
                    nextValue = ''
                }
                if ((previousValue ? previousValue.toString().toLowerCase() : previousValue) < (nextValue ? nextValue.toString().toLowerCase() : nextValue)) return -1
                return (previousValue ? previousValue.toString().toLowerCase() : previousValue) > (nextValue ? nextValue.toString().toLowerCase() : nextValue) ? 1 : 0
            }
        }
        else{
            var reg = /^\d*\.?\d*$/;
            if(reg.test(previousValue) && reg.test(nextValue)){
                previousValue = parseFloat(previousValue)
                nextValue = parseFloat(nextValue)
            }
            if(typeof previousValue ==='number'){
                temp = nextValue - previousValue;
                return temp
            }
            else{
                if(!previousValue && previousValue!==0){
                    previousValue = ''
                }
                if(!nextValue && nextValue!==0){
                    nextValue = ''
                }
                if ((nextValue ? nextValue.toString().toLowerCase() : nextValue) < (previousValue ? previousValue.toString().toLowerCase() : previousValue)) return -1
                return (nextValue ? nextValue.toString().toLowerCase() : nextValue) > (previousValue ? previousValue.toString().toLowerCase() : previousValue) ? 1 : 0
            }
            
        }
    })
}

export function sortingV2List(list_of_dictionary,sortingOrder=true) {
    list_of_dictionary.sort((a, b) => {
        var previousValue = a
        var nextValue = b
        if (sortingOrder===true){
            var reg = /^\d*\.?\d*$/;
            if(reg.test(previousValue) && reg.test(nextValue)){
                previousValue = parseFloat(previousValue)
                nextValue = parseFloat(nextValue)
            }
            if(typeof previousValue ==='number'){
                var temp = previousValue - nextValue;
                return temp
            }
            else{
                if(!previousValue && previousValue!==0){
                    previousValue = ''
                }
                if(!nextValue && nextValue!==0){
                    nextValue = ''
                }
                if ((previousValue ? previousValue.toString().toLowerCase() : previousValue) < (nextValue ? nextValue.toString().toLowerCase() : nextValue)) return -1
                return (previousValue ? previousValue.toString().toLowerCase() : previousValue) > (nextValue ? nextValue.toString().toLowerCase() : nextValue) ? 1 : 0
            }
        }
        else{
            var reg = /^\d*\.?\d*$/;
            if(reg.test(previousValue) && reg.test(nextValue)){
                previousValue = parseFloat(previousValue)
                nextValue = parseFloat(nextValue)
            }
            if(typeof previousValue ==='number'){
                temp = nextValue - previousValue;
                return temp
            }
            else{
                if(!previousValue && previousValue!==0){
                    previousValue = ''
                }
                if(!nextValue && nextValue!==0){
                    nextValue = ''
                }
                if ((nextValue ? nextValue.toString().toLowerCase() : nextValue) < (previousValue ? previousValue.toString().toLowerCase() : previousValue)) return -1
                return (nextValue ? nextValue.toString().toLowerCase() : nextValue) > (previousValue ? previousValue.toString().toLowerCase() : previousValue) ? 1 : 0
            }
            
        }
    })
}
export function bytesToSize(bytes) {
    var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return '0 Byte';
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
 }


export function getLighterColor(originalColor, percentLighter) {
    // Ensure the percentLighter is between 0 and 100
    percentLighter = Math.min(100, Math.max(0, percentLighter));
  
    // Convert the hexadecimal color to RGB
    let r = parseInt(originalColor.substring(1, 3), 16);
    let g = parseInt(originalColor.substring(3, 5), 16);
    let b = parseInt(originalColor.substring(5, 7), 16);
  
    // Calculate the new RGB values for a lighter color
    r = Math.round(r + (255 - r) * (percentLighter / 100));
    g = Math.round(g + (255 - g) * (percentLighter / 100));
    b = Math.round(b + (255 - b) * (percentLighter / 100));
  
    // Convert the new RGB values back to hexadecimal format
    const lighterColor = `#${(1 << 24 | r << 16 | g << 8 | b).toString(16).slice(1)}`;
  
    return lighterColor;
}
export function rgbToHex(rgbString) {
    // Extract the RGB values using a regular expression
    const rgbValues = rgbString.match(/\d+/g);
  
    // Ensure three values are present (for red, green, and blue)
    if (rgbValues.length !== 3) {
      throw new Error("Invalid RGB string");
    }
  
    // Convert each RGB value to hexadecimal and concatenate them
    const hexColor = rgbValues
      .map(value => {
        const hex = parseInt(value, 10).toString(16);
        return hex.length === 1 ? "0" + hex : hex;
      })
      .join("");
  
    // Prepend the hexadecimal color with a hash symbol
    return `#${hexColor}`;
  }
  