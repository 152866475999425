import React, { useEffect, useRef, useState } from 'react'
import { useForceUpdate } from '../../../helper/util'
import '../../../components/historyComponent/historyComponent.css';
import './eventList.css';

import { Resizable } from "re-resizable";
import { Info, Search } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { CssTextField } from '../../../data/constants';
const EventList = (props) => {
    const [showRightMenu, setShowRightMenu] = useState(false)
    const [eventListWidth, setEventListWidth] = useState(300)
    const refreshComponent = useForceUpdate();
    var eventNameList = props.eventNameList
    var setEventNameList = props.setEventNameList

    useEffect(() => {
        document.addEventListener('click',e=>{
            e.stopPropagation()
            setShowRightMenu(false);
        })
        
        return () => {
            document.removeEventListener('click',e=>{
                e.stopPropagation()
                setShowRightMenu(false);
            })
        }
    }, [])
    
    
    const dragItem = useRef();
    const dragOverItem = useRef();
    const dragStart = (e, position) => {
        dragItem.current = position;
        console.log(e.dataTransfer)

    };

    const dragEnter = (e, position) => {
        dragOverItem.current = position;
        for(var i=0; i<=eventNameList.length-1;i++){
            if(parseInt(position)===parseInt(i)){
                document.getElementById('eventWrapper'+position).style='padding-top:100px;'
            }
            else{
                document.getElementById('eventWrapper'+i).style=''
            }
        }
        // console.log(document.getElementById('eventWrapper'+position).style)
        // console.log(e.target.innerHTML);
    };

    const drop = (e,index) => {
        const copyListItems = [...eventNameList];
        const dragItemContent = copyListItems[dragItem.current];
        copyListItems.splice(dragItem.current, 1);
        copyListItems.splice(dragOverItem.current, 0, dragItemContent);
        dragItem.current = null;
        dragOverItem.current = null;
        for(var i=0; i<=eventNameList.length-1;i++){
            document.getElementById('eventWrapper'+i).style=''
        }
        setEventNameList(copyListItems);
        refreshComponent(12)
    };
    return (
        <Resizable
            size={{width:eventListWidth}}
            style={{transition:'all 0.1s linear'}}
            enable={{ top:false, right:true, bottom:false, left:false, topRight:false, bottomRight:false, bottomLeft:false, topLeft:false }}
            onResizeStop={(e, direction, ref, d) => {
                var tempHistoryWindowWidth = eventListWidth
                if(tempHistoryWindowWidth + d.width < 250){
                    tempHistoryWindowWidth = 250
                }
                else{
                    tempHistoryWindowWidth = tempHistoryWindowWidth + d.width
                    if(parseInt(tempHistoryWindowWidth)>500){
                        tempHistoryWindowWidth = 500
                    }
                }
                setEventListWidth(tempHistoryWindowWidth)
                localStorage.setItem(window.location.pathname+'eventListWindowWidth',tempHistoryWindowWidth)
                // setLocalStorageValue(window.location.pathname+'historyWindowWidth',tempHistoryWindowWidth)
                refreshComponent(e);
                }}
                >
                <div className='eventListContainer' style={{position:'relative',height:'calc(100vh - 61px)'}}>
                    <div className='eventLabelWrapper'>
                        Event List
                        <Tooltip title={'Below is the list for all the events that occurred while taking logs.'}>
                            <Info className='infoIcon' sx={{fontSize:'15px',marginLeft:'5px'}}/>
                        </Tooltip>
                    </div>
                    <div className='eventSearchWrapper'>
                        <CssTextField 
                            size='small'
                            placeholder='Search Event'
                            sx={{width:'90%'}}
                            InputProps={{
                                startAdornment: <Search position="start" sx={{paddingRight:'10px',color:'rgb(150,150,150)'}}/>,
                            }}
                        />
                    </div>
                    <div className='eventListWrapper'>
                        {eventNameList.map((item,index)=>{
                            return <div id={'eventWrapper'+index} className='eventWrapper' 
                                        draggable 
                                        key={index}
                                        onDragStart={(e) => dragStart(e, index)}
                                        onDragEnter={(e) => dragEnter(e, index)}
                                        onDragEnd={(e) => drop(e, index)}
                                    >
                                    {item.eventName}
                                </div>
                        })}
                    </div>
                </div>
        </Resizable>
    )
}

export default EventList;