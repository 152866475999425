import { Button, CircularProgress, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react'
import HistoryComponent from '../../../components/historyComponent/historyComponent'
import StepsComponent from '../../../components/stepsComponent/stepsComponent';
import TopbarV2 from '../../../components/topbarv2/topbarv2'
import '../../../css/pages/v1/newapp.css';
import * as Constants from '../../../data/constants';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import PianoIcon from '@mui/icons-material/Piano';
import PianoOffIcon from '@mui/icons-material/PianoOff';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import InfoIcon from '@mui/icons-material/Info';
import { Store } from 'react-notifications-component';
import axios from 'axios';
import { capitalize, getLocalStorageValue, useForceUpdate } from '../../../helper/util';
import NewKeys from './newkeys.js';
import DownloadIcon from '@mui/icons-material/Download';
import ErrorComponent from '../../../components/error/error';
import Popup from '../../../components/popup/popup';
import ReactSelect from 'react-select';

const NewappPage = () => {
    const refreshComponent = useForceUpdate()
    if(localStorage.getItem(window.location.pathname+'historyWindowWidth')){
        var tempHistoryWindowWidth = localStorage.getItem(window.location.pathname+'historyWindowWidth')
    }
    else{
        tempHistoryWindowWidth = 300
    }

    useEffect(() => {
        document.addEventListener('keydown',(e)=>{
            if(e.key==='Escape'){
                setLoaderPopup(false);
            }
        })
        
        return () => {
            document.removeEventListener('keydown',(e)=>{
                if(e.key==='Escape'){
                    setLoaderPopup(false);
                }
            })
        }
    }, [])
    
    const [useEffectManual, setUseEffectManual] = useState(false)
    const decrypterPage = window.location.href.includes('decrypter') ? true : false
    const [historyWindowWidth, setHistoryWindowWidth] = useState(tempHistoryWindowWidth)
    const [historyPage, setHistoryPage] = useState(1)
    const [historyPages, setHistoryPages] = useState(0)
    
    const [historyResponse, setHistoryResponse] = useState([])
    const [historyResponseLoader, setHistoryResponseLoader] = useState(false)
    const [selectedLogFile, setSelectedLogFile] = useState(false)
    const [scriptName, setScriptName] = useState(false)
    const [devkey, setDevkey] = useState(false)
    const [appSize, setAppSize] = useState(20)
    const [selectedStep, setSelectedStep] = useState(0)
    const [loaderPopup, setLoaderPopup] = useState(false)
    const [fromError, setFromError] = useState(false)
    const [activeTab, setActiveTab] = useState(false)
    const [backupData, setBackupData] = useState(false)
    const [openTabsList, setOpenTabsList] = useState([])
    const [addNewLogs, setAddNewLogs] = useState(false)
    const [adjustSecret, setAdjustSecret] = useState('')
    const [adjustSecretId, setAdjustSecretId] = useState('')
    const [appID, setAppID] = useState('')
    
    var getLogsUploadHistory = async(push) => {
        setHistoryResponseLoader(true);
        await axios({
            method: "GET",
            url: Constants.BACKENDHOST+"scriptcreator/campaignlogs",
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token,
            },
        })
        .then(resp => {
            if(resp.data.response_code===200){
                setHistoryResponse(resp.data.data.items)
                setHistoryPages(resp.data.data.pages)
            }
        })
        .catch(err=>{
            if(err.response && err.response.data){
                Store.addNotification({
                    title: "Error!",
                    message: err.response.data.message,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });
            }
        })
        setHistoryResponseLoader(false);
    }
    // const [selectOptionsNewKeys, setSelectOptionsNewKeys] = useState([])
    
    if(!useEffectManual){
        setUseEffectManual(true)
        getLogsUploadHistory()
    }
    
	const handleChooseLogFile = (event) => {
        if(event.target && event.target.files.length>0){
            setSelectedLogFile(event.target.files[0]);
            if(event.target.files[0]){
                if(event.target.files[0].name.includes("_")){
                    setScriptName(event.target.files[0].name.split("_")[0])
                }
            }
        }
	};

    var pushLogsApiRequest = async() => {
        setHistoryResponseLoader(true);
        const formData = new FormData();
        formData.append("log_file", selectedLogFile);
        formData.append("script_name", scriptName);
        formData.append("new_keys", 0);
        await axios({
            method: "POST",
            url: Constants.BACKENDHOST+"scriptcreator/campaignlogs",
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token,
                "Content-Type": "multipart/form-data",
            },
            data:formData
        })
        .then(resp => {
        })
        .catch(err=>{
            if(err.response && err.response.data){
                Store.addNotification({
                    title: "Error!",
                    message: err.response.data.message,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });
            }
        })
        getLogsUploadHistory()
        setHistoryResponseLoader(false);
    }

    var generateNewAppApiRequest = async(item,isDecrypt,forced) => {
        var foundFlag = false;
        for(var index in openTabsList){
            if(openTabsList[index].logsserial === item.logsserial){
                foundFlag = true;
                break;
            }
        }
        if(foundFlag){
            setActiveTab(parseInt(index))
        }
        if(!foundFlag || forced){
            if(item.uploadstatus==='NotUploaded'){
                Store.addNotification({
                    title: "Warning!",
                    message: item.comment,
                    type: "warning",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                      duration: 6000,
                      onScreen: true
                    }
                });
            }
            else{
                var lastIndex = openTabsList.length
                openTabs({...item,loader:true,stepIndex:1})
                setFromError(false);
                if(isDecrypt){
                    var url = Constants.BACKENDHOST+"scriptcreator/decrypt/"+item.logsserial
                }
                else{
                    url = Constants.BACKENDHOST+"scriptcreator/create"
                }
                await axios({
                    method: "GET",
                    url ,
                    headers:{
                        Authorization:getLocalStorageValue('adminPanelLoginData').token,
                        "Content-Type": "multipart/form-data",
                    },
                    params:{
                        'logs_serial':item.logsserial,
                        'script_name':item.logsname.split('_')[0],
                        'app_size':appSize,
                        'new_keys':true,
                        'secret_key':adjustSecret ? adjustSecret : null,
                        'secret_id':adjustSecretId ? adjustSecretId : null,
                        'app_id':appID ? appID : null,
                    }
                })
                .then(resp => {
                    if(loaderPopup){
                        setLoaderPopup(false);
                    }
                    if(resp.data.response_code===201 || resp.data.data.new_keys){ // newkeys
                        var tempOpenTabs = openTabsList;
                        tempOpenTabs[lastIndex] = {...tempOpenTabs[lastIndex],...resp.data.data}
                        setOpenTabsList(tempOpenTabs)
                    }
                    else if(resp.data.response_code===200){
                        tempOpenTabs = openTabsList;
                        tempOpenTabs[lastIndex].stepIndex = 2
                        tempOpenTabs[lastIndex] = {...tempOpenTabs[lastIndex],...resp.data.data}
                        setOpenTabsList(tempOpenTabs)
                    }
                })
                .catch(err=>{
                    if(err.response && err.response.data){
                        if(err.response.data.message.includes('Script Name')){
                            setLoaderPopup('scriptName')
                            setFromError(item)
                        }
                        if(err.response.data.message.includes('Adjust secret key and secret_id required.')){
                            setLoaderPopup('adjustSecret')
                            setFromError(item)
                        }
                        if(err.response.data.message.includes('app_id required.')){
                            setLoaderPopup('iosId')
                            setFromError(item)
                        }
                        var tempOpenTabs = openTabsList;
                        tempOpenTabs[lastIndex] = {...tempOpenTabs[lastIndex],...err.response.data}
                        setOpenTabsList(tempOpenTabs)

                        Store.addNotification({
                            title: "Error!",
                            message: err.response.data.message,
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            animationIn: ["animate__animated", "animate__fadeIn"],
                            animationOut: ["animate__animated", "animate__fadeOut"],
                            dismiss: {
                                duration: 5000,
                                onScreen: true
                            }
                        });
                    }
                })
                setHistoryResponseLoader(false);
            }
            var tempOpenTabs = openTabsList;
            tempOpenTabs[lastIndex].loader = false
            setOpenTabsList(tempOpenTabs)
            refreshComponent(1);
        }
    }

    const newKeysSubmit = async(newKeys) => {
        var tempOpenTabs = openTabsList;
        tempOpenTabs[activeTab].loader = true
        setOpenTabsList(tempOpenTabs)
        var url = Constants.BACKENDHOST+"scriptcreator/newkeys/"+openTabsList[activeTab].scriptserial
       
        await axios({
            method: "POST",
            url,
            headers:{
                "Content-Type": "application/json",
                Authorization:getLocalStorageValue('adminPanelLoginData').token,
            },
            data: {
                // script_path:responseNewKeysData.data.script_path,
                // logs_path:responseNewKeysData.data.logs_path,
                new_keys:newKeys,
            },
        }).then(resp => {
            console.log(resp.data)
        })
        .catch(err=>{
            console.log(err.response)
            console.log(err.response)
            Store.addNotification({
                title: "Error!",
                message: err.response.data.error_message ? err.response.data.error_message : err.message === "Network Error" ? "Bad Request, App size more than 10MB" : "Server Error",
                type: "warning",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                  duration: 2000,
                  onScreen: true
                }
              });
        })
        tempOpenTabs = openTabsList;
        tempOpenTabs[activeTab].loader = false
        setOpenTabsList(tempOpenTabs)
    }

    const handleDownloadFile = async(e,type) => {
        e.stopPropagation();
        await axios({
            method: "GET",
            url: Constants.BACKENDHOST + (type === 'script' ? ('scriptcreator/download/script/'+openTabsList[activeTab].scriptserial) : ('scriptcreator/download/difference/'+openTabsList[activeTab].differences_dirs[type])), //Constants.BACKENDHOST+"tools/create_flow_report",
            headers:{
                "Content-Type": "multipart/form-data",
                Authorization:getLocalStorageValue('adminPanelLoginData').token,
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache',
                'Expires': '0',
            },
            params:{
                // comment:commitMessage.value,
            },
            // responseType:'blob'
        })
        .then(response => {
            
            if(response.data.data && response.data.data.error){
                Store.addNotification({
                    title: "Warning!",
                    message:  file + ' ' + response.data.data.error.toLowerCase(),
                    type: "warning",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                      duration: 5000,
                      onScreen: true
                    }
                });
            }
            else{
                if(type === 'script'){
                    var file = openTabsList[activeTab].scriptname
                    if(!file.endsWith('.py')){
                        file+='.py'
                    }
                }
                else{
                    file = type+'.txt'
                }
                const urldownload = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = urldownload;
                link.setAttribute('download', file); //or any other extension
                document.body.appendChild(link);
                link.click();
            }
        })
        .catch(err=>{
            console.log(err)
            if(err.response && err.response.data){
                Store.addNotification({
                    title: "Error!",
                    message:  err.response.data.message,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                      duration: 5000,
                      onScreen: true
                    }
                });
            }
        });
    }


    const openTabs = (item) => {
        var tempTabsList = openTabsList;
        tempTabsList.push(item)
        setActiveTab(tempTabsList.length-1)
        setOpenTabsList(tempTabsList);
        refreshComponent(1);
    }

    const closeTab = (item) => {
        var tempTabsList = openTabsList
        for(var index in tempTabsList){
            if(tempTabsList[index].logsname === item.logsname && tempTabsList[index].logsserial === item.logsserial){
                break;
            }
        }
        tempTabsList.splice(index,1)
        setOpenTabsList(tempTabsList);
        if(tempTabsList.length>0){
            setActiveTab(tempTabsList.length-1);
        }
        else{
            setActiveTab(false);
        }
        refreshComponent(1);
    }

    const uploadLogs = () => {
        return <div style={{display:'flex',alignItems:'center',justifyContent:'center',flexDirection:'column'}}>
            <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',marginTop:'5%',minWidth:'80%',padding:'30px',border:'1px dashed rgb(200,200,200)'}}>
                <div>
                    <div>
                        {!scriptName && 'Import Logs'}
                        {selectedLogFile && selectedLogFile.name}
                    </div>
                </div>
                <div style={{display:'flex',alignItems:'center'}}>
                    {selectedLogFile
                    &&
                    <Tooltip title={'Script-name by default is '+scriptName+'. In order to change the script-name please click on this button.'}>
                        <InfoIcon sx={{fontSize:'20px',marginRight:'10px',color:'rgb(150,150,150)'}} onClick={()=>{setLoaderPopup('scriptName')}}/>
                    </Tooltip>
                    }
                    <Button sx={{...Constants[selectedLogFile ? 'OUTLINEDBUTTON' : 'CONTAINEDBUTTON'],fontSize:'12px',textTransform:'none'}} onClick={(e)=>{document.getElementById('uploadLogFileInput').click();}}>
                        <input accept='.saz' id='uploadLogFileInput' type="file" name="file" onChange={handleChooseLogFile} hidden />
                        {!scriptName && '+ Add Log File'}
                        {selectedLogFile && 'Change Log File'}
                    </Button>
                    {selectedLogFile && 
                    <Button sx={{...Constants['CONTAINEDBUTTON'],marginLeft:'20px',fontSize:'12px',minWidth:'100px',minHeight:'35px',textTransform:'none'}} onClick={(e)=>{pushLogsApiRequest()}}>
                        {historyResponseLoader && <CircularProgress size={'14px'} sx={{color:'white',fontSize:'12px'}}/>}
                        {!historyResponseLoader && 'Upload Logs'}
                    </Button>
                    }

                </div>
            </div>
        </div>
    }

    const newKeysComponent = () => {
        return <div style={{display:'flex',alignItems:'center',justifyContent:'center',flexDirection:'column',width:'100%'}}>
            {(activeTab || activeTab===0)
            && 
            openTabsList[activeTab]
            &&
            !loaderPopup
            &&
            <NewKeys newKeysSubmit={newKeysSubmit} activeTab={activeTab} backupData={backupData} setBackupData={setBackupData} respData={(activeTab || activeTab===0) ? openTabsList[activeTab] : {}}/>
            }
        </div>
    }

    const downloadScriptComponent = () => {
        if((activeTab || activeTab===0)
        && 
        openTabsList[activeTab]
        &&
        openTabsList[activeTab].scriptserial)
        {
            return <div style={{display:'flex',flexDirection:'column',padding:'5%',paddingTop:'1%',paddingBottom:'1%'}}>
                {
                <div>
                    {/* {} */}
                    <div style={{display:'flex',justifyContent:'space-between',alignItems:'center',paddingBottom:'5px',borderBottom:'1px solid rgb(220,220,220)',marginBottom:'10px'}}>
                        <div >
                            Script - {openTabsList[activeTab].scriptname}
                            <br/>
                            <label style={{fontSize:'12px'}}>{openTabsList[activeTab].scriptserial}</label>
                        </div>
                        <div style={{display:'flex',alignItems:'center',color:'rgb(150,150,150'}}>
                            {/* <div className='iconHover'><FileOpenIcon sx={{fontSize:'18px',marginRight:'5px'}}/></div> */}
                            <div className='iconHover' onClick={(e)=>{handleDownloadFile(e,'script')}}><DownloadIcon sx={{fontSize:'20px'}}/></div>
                        </div>
                    </div>
                    {/* {Object.keys(openTabsList[activeTab].differences_dirs).map((item,index)=>{
                        return <div style={{display:'flex',justifyContent:'space-between',alignItems:'center',paddingBottom:'5px',borderBottom:'1px solid rgb(220,220,220)',marginBottom:'10px'}} key={index}>
                                    <div >
                                        {item}
                                        <br/>
                                        <label style={{fontSize:'12px'}}>{openTabsList[activeTab].differences_dirs[item]}</label>
                                    </div>
                                    <div style={{display:'flex',alignItems:'center',color:'rgb(150,150,150'}}>
                                        <div className='iconHover'><FileOpenIcon sx={{fontSize:'18px',marginRight:'5px'}}/></div>
                                        <div className='iconHover' onClick={(e)=>{handleDownloadFile(e,item)}}><DownloadIcon sx={{fontSize:'20px'}}/></div>
                                    </div>
                            </div>
                    })} */}
                </div>
                }
            </div>
        }
    }
    const loaderPopupDetails = {
        'scriptName':{
            header:'Script Name?',
            popupValue: <div>
                <div style={{display:'flex',alignItems:'center',marginBottom:'10px'}}>
                    <div style={{width:'200px'}}>
                        Script Name
                    </div>
                    <div style={{width:'calc(100% - 200px)'}}>
                        <Constants.CssTextField fullWidth defaultValue={scriptName ? scriptName : ''} id='scriptNameField' size='small'/>
                    </div>
                    <div style={{width:'200px'}}>
                        App Size
                    </div>
                    <div style={{width:'calc(100% - 200px)'}}>
                        <Constants.CssTextField fullWidth defaultValue={appSize ? appSize : ''} id='appSizeField' size='small'/>
                    </div>
                    <div style={{width:'200px'}}>
                        DevKey
                    </div>
                    <div style={{width:'calc(100% - 200px)'}}>
                        <Constants.CssTextField fullWidth defaultValue={devkey ? devkey : ''} id='devkeyField' size='small'/>
                    </div>
                </div>
                <div style={{display:'flex',alignItems:'center',marginTop:'30px'}}>
                    <Button sx={{...Constants.CONTAINEDBUTTON,fontSize:'12px',transform:'scale(0.8)'}} onClick={()=>{setScriptName(document.getElementById('scriptNameField').value);setAppSize(document.getElementById('appSizeField').value);setDevkey(document.getElementById('devkeyField').value);setLoaderPopup(false);if(fromError){generateNewAppApiRequest(fromError)}}}>Save</Button>
                </div>
            </div>
        },
        'appSize':{
            header:'App Size?',
            popupValue: <div>
                <div style={{display:'flex',alignItems:'center',marginBottom:'10px'}}>
                    <div style={{width:'200px'}}>
                        App Size
                    </div>
                    <div style={{width:'calc(100% - 200px)'}}>
                        <Constants.CssTextField fullWidth defaultValue={appSize ? appSize : ''} id='scriptNameField' size='small'/>
                    </div>
                    
                </div>
                <div style={{display:'flex',alignItems:'center',marginTop:'30px'}}>
                    <Button sx={{...Constants.CONTAINEDBUTTON,fontSize:'12px',transform:'scale(0.8)'}} onClick={()=>{setAppSize(document.getElementById('scriptNameField').value);setLoaderPopup(false);if(fromError){generateNewAppApiRequest(fromError)}}}>Save</Button>
                </div>
            </div>
        },

        'adjustSecret':{
            header:'Adjust Secret And Id?',
            popupValue: <div>
                <div style={{display:'flex',justifyContent:'center',flexDirection:'column',marginBottom:'10px'}}>
                    <div style={{width:'200px'}}>
                        App ID
                    </div>
                    <div style={{width:'calc(100% - 200px)'}}>
                        <Constants.CssTextField fullWidth defaultValue={appID ? appID : ''} onChange={e=>{setAppID(e.target.value)}} size='small'/>
                    </div>
                    <div style={{width:'200px'}}>
                        Adjust Secret
                    </div>
                    <div style={{width:'calc(100% - 200px)'}}>
                        <Constants.CssTextField fullWidth defaultValue={adjustSecret ? adjustSecret : ''} onChange={e=>{setAdjustSecret(e.target.value)}} size='small'/>
                    </div>
                    <div style={{width:'200px'}}>
                        Adjust Secret Id
                    </div>
                    <div style={{width:'calc(100% - 200px)'}}>
                        <Constants.CssTextField fullWidth defaultValue={adjustSecretId ? adjustSecretId : ''} onChange={e=>{setAdjustSecretId(e.target.value)}} size='small'/>
                    </div>
                </div>
                <div style={{display:'flex',alignItems:'center',marginTop:'30px'}}>
                    <Button sx={{...Constants.CONTAINEDBUTTON,fontSize:'12px',transform:'scale(0.8)'}} onClick={()=>{generateNewAppApiRequest(fromError,false,true)}}>Save</Button>
                </div>
            </div>
        },

        'iosId':{
            header:'App Id?',
            popupValue: <div>
                <div style={{display:'flex',justifyContent:'center',flexDirection:'column',marginBottom:'10px'}}>
                    <div style={{width:'200px'}}>
                        App ID
                    </div>
                    <div style={{width:'calc(100% - 200px)'}}>
                        <Constants.CssTextField fullWidth defaultValue={appID ? appID : ''} onChange={e=>{setAppID(e.target.value)}} size='small'/>
                    </div>
                </div>
                <div style={{display:'flex',alignItems:'center',marginTop:'30px'}}>
                    <Button sx={{...Constants.CONTAINEDBUTTON,fontSize:'12px',transform:'scale(0.8)'}} onClick={()=>{generateNewAppApiRequest(fromError,false,true)}}>Save</Button>
                </div>
            </div>
        },
        'devkey':{
            header:'Developer Key?',
            popupValue: <div>
                <div style={{display:'flex',alignItems:'center',marginBottom:'10px'}}>
                    <div style={{width:'200px'}}>
                        DevKey
                    </div>
                    <div style={{width:'calc(100% - 200px)'}}>
                        <Constants.CssTextField fullWidth defaultValue={devkey ? devkey : ''} id='scriptNameField' size='small'/>
                    </div>
                </div>
                <div style={{display:'flex',alignItems:'center',marginTop:'30px'}}>
                    <Button sx={{...Constants.CONTAINEDBUTTON,fontSize:'12px',transform:'scale(0.8)'}} onClick={()=>{setDevkey(document.getElementById('scriptNameField').value);setLoaderPopup(false);if(fromError){generateNewAppApiRequest(fromError)}}}>Save</Button>
                </div>
            </div>
        },
    }

    var statusHistoryDict = {
        'Uploaded':{
            color:'orange'
        },
        'NotUploaded':{
            color:'red'
        },
        'Processed':{
            color:'green'
        },
        undefined:{
            color:'red'
        }
    }

    const newkeysClickable = () => {
        if((activeTab || activeTab===0) && openTabsList[activeTab] && openTabsList[activeTab].new_keys){
            return true
        }
        return false
    }
    var newappSteps = [
        {label:'Upload Logs',showPage:uploadLogs(),notDoneIcon:CloudUploadIcon,doneIcon:CloudDoneIcon},
        {label:'New Keys',showPage:newKeysComponent(),isClickable:newkeysClickable(),notDoneIcon:PianoIcon,doneIcon:PianoOffIcon},
        {label:'Download Data',showPage:downloadScriptComponent(),notDoneIcon:CloudDownloadIcon,doneIcon:CloudDownloadIcon},
    ]
    if(decrypterPage){
        var tempNewappSteps = []
        tempNewappSteps.push(newappSteps[0])
        tempNewappSteps.push(newappSteps[2])
        newappSteps = tempNewappSteps
    }

    const statusHistoryComponent = (item,index,tableHead,tableHeadIndex) => {
        if(item.uploadstatus){
            var status = item.uploadstatus
            var bgColor = statusHistoryDict[status] ? statusHistoryDict[status].color : 'red'
        }
        return <div style={{width:'8px',height:'8px',borderRadius:'4px',marginTop:'2px',background:bgColor}}></div>
    }
    const requestedAtComponent = (item,index,tableHead,tableHeadIndex) => {
        var dateOptions = { year: 'numeric', month: 'short', day: 'numeric' ,hour:'numeric',minute:'numeric',second:'numeric'};
        return new Date(item.created_at).toLocaleDateString("en-US", dateOptions)
    }

    var historyHeaders = [
        {label:'',value:'reportstatus',width:'5%',customComponent:statusHistoryComponent},
        {label:'Logs Name',value:'logsname',width:'45%'},
        {label:'Requested At',value:'created_at',width:'50%',customComponent:requestedAtComponent},
    ]

    const [shareReportPopup, setShareReportPopup] = useState(false)
    const [shareWithName, setShareWithName] = useState(false)
    const [shareFromHistoryComponent, setShareFromHistoryComponent] = useState(false)
    var sharePopupData = () => {
        return <div style={{height:'200px',display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column'}}>
            <div style={{transform:'scale(0.8)',width:'100%'}}>
                <ReactSelect
                    placeholder={'Share Report With...'}
                    value={shareWithName ? {label:capitalize(shareWithName.label),value:shareWithName.value} : false}
                    options={getLocalStorageValue('userData')}
                    onChange={(e)=>{setShareWithName(e);}}
                    menuPortalTarget={document.body} 
                    styles={{...Constants.ReactSelectStyles}}
                />
            </div>
            <Button disabled={!shareWithName} onClick={shareUserData} sx={{...Constants.CONTAINEDBUTTON,textTransform:'capitalize',marginTop:'20px',}}>
                Share
            </Button>
        </div>
    };
    const shareUserData = async() => {
        await axios({
            method: 'GET',
            url: "".concat(Constants.BACKENDHOST,"scriptcreator/share_campaignlogs"),
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token,
            },
            params:{
                serial:shareFromHistoryComponent,
                share_with:shareWithName.value
            }
        })
        .then(resp => {
            console.log(resp)
            Store.addNotification({
                title: "Success!",
                message: resp.data.message,
                type: "success",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                  duration: 5000,
                  onScreen: true
                }
            });
            setShareReportPopup(false);
            setShareWithName(false);
            setShareFromHistoryComponent(false);
        })
        .catch(err => {
            console.error(err);
            if(err.response && err.response.data){
                Store.addNotification({
                    title: "Error!",
                    message:  err.response.data.message,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                      duration: 5000,
                      onScreen: true
                    }
                });
            }
        })
    };
    return (
        <div>
            <TopbarV2 title='Newapp'/>
            <div style={{display:'flex'}}>
                <HistoryComponent
                    historyWindowWidth={historyWindowWidth}
                    setHistoryWindowWidth={setHistoryWindowWidth}
                    openTabs={(item) => {if(decrypterPage){generateNewAppApiRequest(item,'decrypt')}else{generateNewAppApiRequest(item)}}}
                    historyResponse={historyResponse}
                    setHistoryResponse={setHistoryResponse}
                    historyResponseLoader={historyResponseLoader}
                    setHistoryResponseLoader={setHistoryResponseLoader}
                    getDebugHistory={getLogsUploadHistory}
                    historyPage={historyPage}
                    setHistoryPage={setHistoryPage}
                    historyPages={historyPages}
                    historyFor={'Log'}
                    historyHeaders={historyHeaders}
                    hintVariable={'Red: Not Uploaded, Green: Uploaded.'}
                    rightClicked={[{label:'Share Item',onClick:(e,item)=>{
                        setShareReportPopup(true);
                        setShareFromHistoryComponent(item.logsserial);
                    }}]}
                />
                {
                    shareReportPopup
                    &&
                    <Popup head={'Share Report'} data={sharePopupData()} handleClose={(e)=>{setShareReportPopup(false);}}/>
                }
                <div style={{position:'relative',width:'calc(100% - '+historyWindowWidth+'px - 20px)'}}>
                    <div className='debugHistoryHead' >
                        <div style={{position:'absolute',width:'100%',display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                            <div style={{width:'90%',display:'flex',alignItems:'center',overflowX:'auto'}}>
                                {openTabsList.map((item,index)=>{
                                    return <div id='tabsContainer' className='openTabContainer' style={{height:(index===activeTab ? 'calc(40px - 5px)' : '40px'),color:(index===activeTab ? 'black' : 'rgb(100,100,100 )'),borderBottom:(index===activeTab ? '5px solid orange' : '1px solid rgb(200,200,200)')}} key={index} onClick={()=>{setActiveTab(index);}}>
                                            <div id='tabsContainer' style={{width:'90%',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',fontSize:'12px',fontWeight:'500'}}>
                                                {item.logsname}
                                            </div>
                                            <div id='tabsContainer' style={{cursor:'pointer'}} onClick={(e)=>{e.stopPropagation();closeTab(item)}}>
                                                X
                                            </div>
                                        </div>
                                })}
                            </div>
                            <div className='addBoxContainer' style={{cursor:'pointer'}} onClick={()=>{setAddNewLogs(true);setActiveTab(false);}}>
                                Add
                            </div>
                        </div>
                    </div>
                    {   openTabsList.length>0
                            &&
                        openTabsList[activeTab] 
                            && 
                        openTabsList[activeTab].response_code===553 
                            &&
                        <ErrorComponent data = {openTabsList[activeTab].message}/>
                        }
                    {
                        (activeTab || activeTab === 0)
                            &&
                            openTabsList.length>0
                            &&
                            openTabsList[activeTab] 
                            && 
                            openTabsList[activeTab].response_code!==553
                            &&
                        <div style={{overflow:'auto',height:'calc(100vh - 40px - 90px)'}}>
                            {
                                <StepsComponent
                                    showLoader={openTabsList[activeTab] && openTabsList[activeTab].loader}
                                    steps={newappSteps}
                                    selectedStep={openTabsList[activeTab] && openTabsList[activeTab].stepIndex}
                                    setSelectedStep={(index)=>{var tempOpenTabsList = openTabsList; tempOpenTabsList[activeTab].stepIndex = index; setOpenTabsList(tempOpenTabsList); refreshComponent(123);}}
                                />
                            }
                        </div>
                    }
                    {   
                        (!activeTab && activeTab!==0)
                        &&
                        (addNewLogs || openTabsList.length===0)
                        &&
                        <div style={{overflow:'auto',height:'calc(100vh - 40px - 90px)'}}>
                            
                            <StepsComponent
                                steps={newappSteps}
                                selectedStep={selectedStep}
                                setSelectedStep={setSelectedStep}
                            />
                        </div>
                    }
                </div>
                {loaderPopup
                    &&
                <div style={{position:'fixed',top:0,left:0,width:'100vw',height:'100vh',backdropFilter:'blur(2px)'}} onClick={()=>{setLoaderPopup(false);}}>
                    <div style={{display:"flex",justifyContent:'center'}}>
                        <div style={{minHeight:'30vh',minWidth:'50vw',background:'white',border:'1px solid rgb(200,200,200)',boxShadow:'0 0 5px 1px rgb(200,200,200)',marginTop:'15vh'}} onClick={(e)=>{e.stopPropagation();}}>
                            <div style={{display:'flex',height:'30px',alignItems:'center',justifyContent:'space-between',paddingTop:'15px',paddingBottom:'15px',paddingLeft:'20px',paddingRight:'20px',fontWeight:'500'}}>
                                <div>{loaderPopupDetails[loaderPopup].header}</div>
                                <div style={{cursor:'pointer'}} onClick={()=>{setLoaderPopup(false);}}>X</div>
                            </div>
                            <div style={{paddingTop:'10px',paddingBottom:'15px',paddingLeft:'25px',paddingRight:'25px'}}>
                                {loaderPopupDetails[loaderPopup].popupValue}
                            </div>
                        </div>
                    </div>
                </div>
                }
            </div>
        </div>
    )
}

export default NewappPage;