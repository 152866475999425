import React from 'react'
import { getLocalStorageValue } from '../../helper/util'
import { useSearchParams } from 'react-router-dom';

const RedirectToken = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    if(getLocalStorageValue('adminPanelLoginData')){
        window.location.href = searchParams.get('callback_host')+'?token='+getLocalStorageValue('adminPanelLoginData').token
    }
    return (
        <div>
            RedirectToken
        </div>
    )
}

export default RedirectToken