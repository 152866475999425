import { increase_brightness } from "../helper/util"

import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';

/// STYLING
var ambientColor = localStorage.getItem('ambientColor')  ? localStorage.getItem('ambientColor') : "rgb(0, 166, 178)"
export const CONTAINEDBUTTON = {
                                  backgroundColor:ambientColor,
                                  fontWeight:'800',
                                  color:'white',
                                  '&:hover': {
                                              backgroundColor: increase_brightness(ambientColor,10),
                                              color:"white",
                                              boxShadow: 'none',
                                            },
                                }
export const OUTLINEDBUTTON = {
                                outline:"1px solid "+ambientColor,
                                color:ambientColor,
                                fontWeight:'800',
                                '&:hover': {
                                            backgroundColor: increase_brightness(ambientColor,95),
                                            color:ambientColor,
                                            // boxShadow: 'none',
                                          },
                              }        

export const CssTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: localStorage.getItem('ambientColor'),
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: localStorage.getItem('ambientColor'),
    },
    '& .MuiOutlinedInput-root': {
        backgroundColor: "white !important",
        fontSize: "14px !important",
        '& fieldset': {
        borderColor: 'rgb(200,200,200)',
        // backgroundColor:'white',
        // zIndex:'0',
        },
        '&:hover fieldset': {
        borderColor: localStorage.getItem('ambientColor'),
        },
        '&.Mui-focused fieldset': {
        borderColor: localStorage.getItem('ambientColor'),
        },
    },
});

export const ReactSelectStyles ={    menuPortal: base => ({ ...base, zIndex: 9999 }),
                                    control: (base,state) => ({
                                        ...base,
                                        // maxHeight: 100,
                                        minHeight: 30,
                                        overflowY: "auto",
                                        border: state.isFocused ? '1px solid '+localStorage.getItem('ambientColor') : '1px solid rgb(200,200,200)',
                                        boxShadow: state.isFocused ? '0 0 3px 1px '+localStorage.getItem('ambientColor') : 0,
                                        '&:hover': {
                                            border: state.isFocused ? '1px solid '+localStorage.getItem('ambientColor') : '1px solid '+localStorage.getItem('ambientColor'),
                                            boxShadow: state.isFocused ? '0 0 1px 1px '+localStorage.getItem('ambientColor') : '0 0 1px 1px '+localStorage.getItem('ambientColor'),
                                        }
                                    }),
                                    option: (styles, {isFocused, isSelected}) => ({
                                        ...styles,
                                        fontSize:'11px',
                                        background: isFocused
                                            ? localStorage.getItem('ambientColor')
                                            : isSelected
                                                ? increase_brightness(localStorage.getItem('ambientColor'),10)
                                                : undefined,
                                        zIndex: 1
                                    }),
                                  }

export const ReactSelectStylesStint ={    menuPortal: base => ({ ...base, zIndex: 9999 }),
                                  control: (base,state) => ({
                                      ...base,
                                      maxHeight: 30,
                                      fontSize:'13px',
                                      overflowY: "auto",
                                      border: state.isFocused ? '1px solid '+localStorage.getItem('ambientColor2') : '1px solid rgb(200,200,200)',
                                      boxShadow: state.isFocused ? '0 0 3px 1px '+localStorage.getItem('ambientColor2') : 0,
                                      '&:hover': {
                                          border: state.isFocused ? '1px solid '+localStorage.getItem('ambientColor2') : '1px solid '+localStorage.getItem('ambientColor2'),
                                          boxShadow: state.isFocused ? '0 0 1px 1px '+localStorage.getItem('ambientColor2') : '0 0 1px 1px '+localStorage.getItem('ambientColor2'),
                                      }
                                  }),
                                  option: (styles, {isFocused, isSelected}) => ({
                                      ...styles,
                                      fontSize:'11px',
                                      background: isFocused
                                          ? localStorage.getItem('ambientColor2')
                                          : isSelected
                                              ? increase_brightness(localStorage.getItem('ambientColor2'),10)
                                              : undefined,
                                      zIndex: 1
                                  }),
                                }
export const BACKENDHOST = process.env.REACT_APP_PROTOCOL + process.env.REACT_APP_BACKEND_SERVER_DOMAIN + process.env.REACT_APP_API_USER_PATH
export const BACKENDHOST2 = process.env.REACT_APP_PROTOCOL + process.env.REACT_APP_BACKEND_SERVER_DOMAIN2 + process.env.REACT_APP_API_USER_PATH
export const LEVELCREATOR_BACKENDHOST = process.env.REACT_APP_PROTOCOL + process.env.REACT_APP_BACKEND_LEVEL_CREATOR_SERVER_DOMAIN+'/api/'
// export const BACKENDHOST = "http://localhost:5009/autolabs/"

export const UploadCommitMessages = [   "Detailed",
                                        "Detailed CT install",
                                        "Detailed CT open",
                                        "Error Resolved",
                                        "Level 1",
                                        "Level 2",
                                        "Minor Update",
                                        "Major Update",
                                        "Modification/Customization",
                                        "Recheck",
                                        "Traffic Issues",
                                        "Newapp",
                                    ]

export const LVL_NOTE = [
    "KPI event action could not perform, Issue - | KPI event not added",
    "KPI event action could not perform, Issue - | KPI event added manually as S2S/SDK provided by BT Team",
    "KPI event action could not perform, Issue - | KPI event added manually as S2S provided by BT Team",
    "KPI event action could not perform, Issue - | KPI event added manually as SDK provided by BT Team",
    "KPI event action could not perform, Issue - | KPI event as S2S/SDK with event values from prev event added manually | Event by BT",
    "KPI event action could not perform, Issue - | KPI event as S2S with event values from prev event added manually | Event by BT",
    "KPI event action could not perform, Issue - | KPI event as SDK with event values from prev event added manually | Event by BT",
    "KPI event action could not perform, Issue - | KPI event as S2S/SDK with blank event values added manually | Event by BT",
    "KPI event action could not perform, Issue - | KPI event as S2S with blank event values added manually | Event by BT",
    "KPI event action could not perform, Issue - | KPI event as SDK with blank event values added manually | Event by BT",
    "KPI event action could not perform, Issue - | KPI event added manually as S2S/SDK found in Jave/Smali",
    "KPI event action could not perform, Issue - | KPI event added manually as S2S found in Jave/Smali",
    "KPI event action could not perform, Issue - | KPI event added manually as SDK found in Jave/Smali",
    "KPI event action performed but Event didn't occur, so added manually as S2S/SDK provided by BT Team",
    "KPI event action performed but Event didn't occur, so added manually as S2S provided by BT Team",
    "KPI event action performed but Event didn't occur, so added manually as SDK provided by BT Team",
    "KPI event action performed but Event didn't occur, so added manually as S2S/SDK found in Java Code/Smali",
    "KPI event action performed but Event didn't occur, so added manually as S2S found in Java Code/Smali",
    "KPI event action performed but Event didn't occur, so added manually as SDK found in Java Code/Smali",
    "KPI event action performed and got Event as S2S, and BT Team couldn't provide Event Value Report, so added manually with Blank Event Values",
    "KPI event action performed and got Event as S2S, and BT Team also provided Event Value Report, so added in the same way",
]

export const COUNTRIES_LIST = [
    "Algeria", 
    "Argentina", 
    "Australia", 
    "Austria", 
    "Bahrain", 
    "Bangladesh", 
    "Belarus", 
    "Belgium", 
    "Bhutan", 
    "Bolivia", 
    "Brasil", 
    "Brazil", 
    "Cambodia", 
    "Cameroon", 
    "Canada", 
    "Chile", 
    "China", 
    "Colombia", 
    "CostaRica", 
    "Croatia", 
    "CzechRepublic", 
    "Denmark", 
    "Ecuador", 
    "Egypt", 
    "Finland", 
    "France", 
    "Germany", 
    "Ghana", 
    "Greece", 
    "HongKong", 
    "Iceland", 
    "India", 
    "Indonesia", 
    "Iran", 
    "Iraq", 
    "Ireland", 
    "Israel", 
    "Italy", 
    "IvoryCoast", 
    "Japan", 
    "Jordan", 
    "Kazakhstan", 
    "Kenya", 
    "Kuwait", 
    "Latvia", 
    "Lebanon", 
    "Luxembourg", 
    "Macau", 
    "Malaysia", 
    "Mexico", 
    "Mongolia", 
    "Morocco", 
    "Myanmar", 
    "Netherlands", 
    "NewZealand", 
    "Nigeria", 
    "Norway", 
    "Oman", 
    "Pakistan", 
    "Peru", 
    "Philippines", 
    "Poland", 
    "Portugal", 
    "Qatar", 
    "Romania", 
    "Russia", 
    "SaudiArabia", 
    "Senegal", 
    "Singapore", 
    "Slovakia", 
    "Slovenia", 
    "SouthAfrica", 
    "SouthKorea", 
    "Spain", 
    "SriLanka", 
    "Sweden", 
    "Switzerland", 
    "Syria", 
    "Taiwan", 
    "Tanzania", 
    "Thailand", 
    "Tunisia", 
    "Turkey", 
    "UK", 
    "USA", 
    "Uganda", 
    "Ukraine", 
    "UnitedArabEmirates", 
    "Venezuela", 
    "Vietnam", 
    "Yemen", 
    "Zambia"
]

export const REASON_FOR_DELETING_FILE_ENTRY = ['Downloaded by mistake','No Change Found','Invalid Entry']

export const teamValues = () => {
    var list = []
    for(var i=1; i<=3; i++){
        list.push({
            label:'Tech Team '+i,
            value: i
        })
    }
    for(var i=11; i<=13; i++){
        list.push({
            label:'BT Team '+i%10,
            value: i
        })
    }
    return list;
}


export const COUNTRIES_LIST_WITH_CODE = {
    "AF": "Afghanistan",
    "AL": "Albania",
    "DZ": "Algeria",
    "AS": "American Samoa",
    "AD": "Andorra",
    "AO": "Angola",
    "AI": "Anguilla",
    "AQ": "Antarctica",
    "AG": "Antigua and Barbuda",
    "AR": "Argentina",
    "AM": "Armenia",
    "AW": "Aruba",
    "AU": "Australia",
    "AT": "Austria",
    "AZ": "Azerbaijan",
    "BS": "Bahamas (the)",
    "BH": "Bahrain",
    "BD": "Bangladesh",
    "BB": "Barbados",
    "BY": "Belarus",
    "BE": "Belgium",
    "BZ": "Belize",
    "BJ": "Benin",
    "BM": "Bermuda",
    "BT": "Bhutan",
    "BO": "Bolivia (Plurinational State of)",
    "BQ": "Bonaire, Sint Eustatius and Saba",
    "BA": "Bosnia and Herzegovina",
    "BW": "Botswana",
    "BV": "Bouvet Island",
    "BR": "Brazil",
    "IO": "British Indian Ocean Territory (the)",
    "BN": "Brunei Darussalam",
    "BG": "Bulgaria",
    "BF": "Burkina Faso",
    "BI": "Burundi",
    "CV": "Cabo Verde",
    "KH": "Cambodia",
    "CM": "Cameroon",
    "CA": "Canada",
    "KY": "Cayman Islands (the)",
    "CF": "Central African Republic (the)",
    "TD": "Chad",
    "CL": "Chile",
    "CN": "China",
    "CX": "Christmas Island",
    "CC": "Cocos (Keeling) Islands (the)",
    "CO": "Colombia",
    "KM": "Comoros (the)",
    "CD": "Congo (the Democratic Republic of the)",
    "CG": "Congo (the)",
    "CK": "Cook Islands (the)",
    "CR": "Costa Rica",
    "HR": "Croatia",
    "CU": "Cuba",
    "CW": "Curaçao",
    "CY": "Cyprus",
    "CZ": "Czechia",
    "CI": "Côte d'Ivoire",
    "DK": "Denmark",
    "DJ": "Djibouti",
    "DM": "Dominica",
    "DO": "Dominican Republic (the)",
    "EC": "Ecuador",
    "EG": "Egypt",
    "SV": "El Salvador",
    "GQ": "Equatorial Guinea",
    "ER": "Eritrea",
    "EE": "Estonia",
    "SZ": "Eswatini",
    "ET": "Ethiopia",
    "FK": "Falkland Islands (the) [Malvinas]",
    "FO": "Faroe Islands (the)",
    "FJ": "Fiji",
    "FI": "Finland",
    "FR": "France",
    "GF": "French Guiana",
    "PF": "French Polynesia",
    "TF": "French Southern Territories (the)",
    "GA": "Gabon",
    "GM": "Gambia (the)",
    "GE": "Georgia",
    "DE": "Germany",
    "GH": "Ghana",
    "GI": "Gibraltar",
    "GR": "Greece",
    "GL": "Greenland",
    "GD": "Grenada",
    "GP": "Guadeloupe",
    "GU": "Guam",
    "GT": "Guatemala",
    "GG": "Guernsey",
    "GN": "Guinea",
    "GW": "Guinea-Bissau",
    "GY": "Guyana",
    "HT": "Haiti",
    "HM": "Heard Island and McDonald Islands",
    "VA": "Holy See (the)",
    "HN": "Honduras",
    "HK": "Hong Kong",
    "HU": "Hungary",
    "IS": "Iceland",
    "IN": "India",
    "ID": "Indonesia",
    "IR": "Iran (Islamic Republic of)",
    "IQ": "Iraq",
    "IE": "Ireland",
    "IM": "Isle of Man",
    "IL": "Israel",
    "IT": "Italy",
    "JM": "Jamaica",
    "JP": "Japan",
    "JE": "Jersey",
    "JO": "Jordan",
    "KZ": "Kazakhstan",
    "KE": "Kenya",
    "KI": "Kiribati",
    "KP": "Korea (the Democratic People's Republic of)",
    "KR": "Korea (the Republic of)",
    "KW": "Kuwait",
    "KG": "Kyrgyzstan",
    "LA": "Lao People's Democratic Republic (the)",
    "LV": "Latvia",
    "LB": "Lebanon",
    "LS": "Lesotho",
    "LR": "Liberia",
    "LY": "Libya",
    "LI": "Liechtenstein",
    "LT": "Lithuania",
    "LU": "Luxembourg",
    "MO": "Macao",
    "MG": "Madagascar",
    "MW": "Malawi",
    "MY": "Malaysia",
    "MV": "Maldives",
    "ML": "Mali",
    "MT": "Malta",
    "MH": "Marshall Islands (the)",
    "MQ": "Martinique",
    "MR": "Mauritania",
    "MU": "Mauritius",
    "YT": "Mayotte",
    "MX": "Mexico",
    "FM": "Micronesia (Federated States of)",
    "MD": "Moldova (the Republic of)",
    "MC": "Monaco",
    "MN": "Mongolia",
    "ME": "Montenegro",
    "MS": "Montserrat",
    "MA": "Morocco",
    "MZ": "Mozambique",
    "MM": "Myanmar",
    "NA": "Namibia",
    "NR": "Nauru",
    "NP": "Nepal",
    "NL": "Netherlands (the)",
    "NC": "New Caledonia",
    "NZ": "New Zealand",
    "NI": "Nicaragua",
    "NE": "Niger (the)",
    "NG": "Nigeria",
    "NU": "Niue",
    "NF": "Norfolk Island",
    "MP": "Northern Mariana Islands (the)",
    "NO": "Norway",
    "OM": "Oman",
    "PK": "Pakistan",
    "PW": "Palau",
    "PS": "Palestine, State of",
    "PA": "Panama",
    "PG": "Papua New Guinea",
    "PY": "Paraguay",
    "PE": "Peru",
    "PH": "Philippines (the)",
    "PN": "Pitcairn",
    "PL": "Poland",
    "PT": "Portugal",
    "PR": "Puerto Rico",
    "QA": "Qatar",
    "MK": "Republic of North Macedonia",
    "RO": "Romania",
    "RU": "Russian Federation (the)",
    "RW": "Rwanda",
    "RE": "Réunion",
    "BL": "Saint Barthélemy",
    "SH": "Saint Helena, Ascension and Tristan da Cunha",
    "KN": "Saint Kitts and Nevis",
    "LC": "Saint Lucia",
    "MF": "Saint Martin (French part)",
    "PM": "Saint Pierre and Miquelon",
    "VC": "Saint Vincent and the Grenadines",
    "WS": "Samoa",
    "SM": "San Marino",
    "ST": "Sao Tome and Principe",
    "SA": "Saudi Arabia",
    "SN": "Senegal",
    "RS": "Serbia",
    "SC": "Seychelles",
    "SL": "Sierra Leone",
    "SG": "Singapore",
    "SX": "Sint Maarten (Dutch part)",
    "SK": "Slovakia",
    "SI": "Slovenia",
    "SB": "Solomon Islands",
    "SO": "Somalia",
    "ZA": "South Africa",
    "GS": "South Georgia and the South Sandwich Islands",
    "SS": "South Sudan",
    "ES": "Spain",
    "LK": "Sri Lanka",
    "SD": "Sudan (the)",
    "SR": "Suriname",
    "SJ": "Svalbard and Jan Mayen",
    "SE": "Sweden",
    "CH": "Switzerland",
    "SY": "Syrian Arab Republic",
    "TW": "Taiwan",
    "TJ": "Tajikistan",
    "TZ": "Tanzania, United Republic of",
    "TH": "Thailand",
    "TL": "Timor-Leste",
    "TG": "Togo",
    "TK": "Tokelau",
    "TO": "Tonga",
    "TT": "Trinidad and Tobago",
    "TN": "Tunisia",
    "TR": "Turkey",
    "TM": "Turkmenistan",
    "TC": "Turks and Caicos Islands (the)",
    "TV": "Tuvalu",
    "UG": "Uganda",
    "UA": "Ukraine",
    "AE": "United Arab Emirates (the)",
    "GB": "United Kingdom of Great Britain and Northern Ireland (the)",
    "UM": "United States Minor Outlying Islands (the)",
    "US": "United States of America (the)",
    "UY": "Uruguay",
    "UZ": "Uzbekistan",
    "VU": "Vanuatu",
    "VE": "Venezuela (Bolivarian Republic of)",
    "VN": "Viet Nam",
    "VG": "Virgin Islands (British)",
    "VI": "Virgin Islands (U.S.)",
    "WF": "Wallis and Futuna",
    "EH": "Western Sahara",
    "YE": "Yemen",
    "ZM": "Zambia",
    "ZW": "Zimbabwe",
    "AX": "Åland Islands",
};


export const teamStructure = {
    '1A':'Pushkal',
    '1B':'Pawan',
    '2A':'Udit',
    '2B':'Umair',
    '3A':'Shikha',
    '3B':'Aniket',
}