import React from 'react';
import Login from '../pages/login/login';
import { getLocalStorageValue } from '../helper/util';
import RouterConf from './router';
import LoginV2 from '../pages/login/loginv2';

export default function CheckLogin(){
    

    // if (loginExpired || !token || (new Date().getTime() - lastLoginTime) > expireTime){
    //     return <>
    //                 <LoginV2/>
    //             </>
    // }
    // else{
        return <div className="main">
                    <RouterConf/>
                </div>
    // }
}