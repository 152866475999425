import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import './kanbanboard.css';
// import { columnsFromBackend, apidata } from './KanbanData';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import TaskCard from './TaskCard';
import { Add, AddTask, CloseTwoTone, MoreHoriz, RemoveRedEye, ShareOutlined, ThumbUpSharp, Warning } from '@mui/icons-material';
import { Button, CircularProgress, IconButton, TextField, Tooltip } from '@mui/material';
import { getLocalStorageValue, useForceUpdate } from '../../../helper/util';
import TextfieldComponent from '../../../components/textFieldComponent/textfieldComponent';
import * as Constants from '../../../data/constants';
import axios from 'axios';
import ReactSelect from 'react-select';

import { assigneeLabel, getOptions, getOptionsLabelValue, priorityOptions, tagOptions, tagOptionsComponent } from '../genericOptionsStint';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import parse from 'html-react-parser';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SubtaskListComponent from './subtaskListComponent';
import AssigneeOptions from './assigneeOptions';
import PriorityOptions from './priorityOptions';
import NewTask from '../newTask';
import AddIcon from '@mui/icons-material/Add';
import DateRangePickerComponent from '../../../components/dateRangePicker/dateRangePicker';

const Container = styled.div`
  display: flex;
`;

const TaskList = styled.div`
  min-height: 100px;
  display: flex;
  flex-direction: column;
  min-width: 200px;
  width:25%;
  border-radius: 5px;
  padding: 15px 15px;
  margin-right: 45px;
  padding-top:30px;
  max-height: 80vh;
  overflow: auto;
`;

const TaskColumnStyles = styled.div`
  margin: 8px;
  padding-left: 38px;
  margin-right: 0px;
  margin-left: 0px;
  background:rgb(240,240,240);
  display: flex;
  width: calc(100vw - 140px);
  min-height: 80vh;
`;

const Title = styled.span`
  color: rgb(50,50,50);
  font-weight: 600;
  letter-spacing: 1px;
  padding: 2px 10px;
  border-radius: 5px;
  align-self: flex-start;
  position: sticky;
  top:0;
`;

export const KanbanBoard = (props) => {
	const {assigneeOptions,
			productManagers,
			usersDict,
			detailData,
			setDetailData,
			subtaskList,
			setSubtaskList,
			data,
			setData,
			taskHistoryDict,
			setTaskHistoryDict,
			commentsDict,
			setCommentsDict,
			columns,
			setColumns,
			loader,
			setLoader,
			openCard,
			setOpenCard,
			getTaskDetails,
			getComments,
			getTaskHistory,
			taskDetailLoader
		} = props
	const refreshComponent = useForceUpdate()
	
	const [manualUseState, setManualUseState] = useState(false);
	const [activeCommentHistoryState, setActiveCommentHistoryState] = useState({});
	const [subtaskNew, setSubtaskNew] = useState(false);
	const [newDescription, setNewDescription] = useState('');
	const [enableTextEditor, setEnableTextEditor] = useState(0);
	const [newComment, setNewComment] = useState('');
	const [enableNewComment, setEnableNewComment] = useState(false);
	const [openCardDetails, setOpenCardDetails] = useState(['Details']);
	const [showAssigneeOptions, setShowAssigneeOptions] = useState(false);
    const [addNewTask, setAddNewTask] = useState(false)
    const [tasksList, setTaskList] = useState([])


	const addComment = async(cardid,type='edit') => {
        await axios({
            method: 'POST',
            url: "".concat(Constants.BACKENDHOST,"task_tracker/addComment"),
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token,
				'Content-Type':'application/json'
            },
			data:{
				task_id:cardid,
				type,
				comment:newComment,
			}
        })
        .then(resp => {
			var data = resp.data.data
			var tempCommentsList = commentsDict[cardid]
			tempCommentsList.push(
				{
					comment:newComment,
					user_id:getLocalStorageValue('adminPanelLoginData').id
				}
			)
			setCommentsDict({...commentsDict,[cardid]:tempCommentsList})
		})
        .catch(err => {
            console.error(err);
        })
    };
	
	const updateStintData = async(id,key,value,old_value=null) => {
        await axios({
            method: 'POST',
            url: "".concat(Constants.BACKENDHOST,"task_tracker/editTask"),
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token,
				'Content-Type':'application/json'
            },
			data:{
				task_id:parseInt(id),
				[key]:value,
				old_value
			}
        })
        .then(resp => {
			var tempHistoryDict = taskHistoryDict
			if(tempHistoryDict[id]){
				tempHistoryDict[id].unshift({
					'created_at':new Date().toString(),
					'old_val':old_value,
					'current_val':value,
					'user_id':getLocalStorageValue('adminPanelLoginData').id,
					'updated_key':key
				})
			}
			setTaskHistoryDict(tempHistoryDict);
			refreshComponent(123);
        })
        .catch(err => {
            console.error(err);
        })
    };

	const onDragEnd = (result, columns, setColumns) => {
		if (!result.destination) return;
		const { source, destination } = result;
		if (source.droppableId !== destination.droppableId) {
			const sourceColumn = columns[source.droppableId];
			const destColumn = columns[destination.droppableId];
			const sourceItems = [...sourceColumn.items];
			const destItems = [...destColumn.items];
			const [removed] = sourceItems.splice(source.index, 1);
			destItems.splice(destination.index, 0, removed);
			setColumns({
				...columns,
				[source.droppableId]: {
				...sourceColumn,
				items: sourceItems,
				},
				[destination.droppableId]: {
				...destColumn,
				items: destItems,
				},
			});
		} 
		else {
			const column = columns[source.droppableId];
			const copiedItems = [...column.items];
			const [removed] = copiedItems.splice(source.index, 1);
			copiedItems.splice(destination.index, 0, removed);
			setColumns({
				...columns,
				[source.droppableId]: {
				...column,
				items: copiedItems,
				},
			});
		}
		changeCardDetails('status',result.destination.droppableId,result.draggableId)
	};
	const updateUrl = (updateUrl) => {
		var url = window.location.href;
		url+=updateUrl
		window.history.pushState('Offers', 'Offers', url);
	}

	if(!manualUseState){
		
		setManualUseState(true)
	}
	const cardClicked = (item,index) => {
		if(!openCard){
			setOpenCard(item.id)
			updateUrl('?cardid='+item.id)
			getTaskDetails(item.id,data)
			getComments(item.id)
			getTaskHistory(item.id)
		}
		else{
			setOpenCard(false);
		}
	}
	const closeCard = (e) => {
		setOpenCard(false);
		var url = window.location.href;
		url = url.split('?')[0]
		window.history.pushState('Offers', 'Offers', url);
	}

	useEffect(() => {
		document.addEventListener('click',e=>{
			setEnableTextEditor(0);
			setNewDescription('');
			setNewComment('');
			setEnableNewComment(false);
			setShowAssigneeOptions(false);
		})
		document.addEventListener('keydown',e=>{
			if(e.key==='Escape'){
				closeCard(e)
			}
		})
		return () => {
			document.removeEventListener('click',e=>{
				setEnableTextEditor(0);
				setNewDescription('');
				setNewComment('');
				setEnableNewComment(false);	
				setShowAssigneeOptions(false);
			})
			document.removeEventListener('keydown',e=>{
				if(e.key==='Escape'){
					closeCard(e)
				}
			})
		}
	}, [])

	const changeCardDetails = (key,value,dataIndex,old_value=null) => {
		if(dataIndex || dataIndex===0){
			if(data[dataIndex]){
				var tempData = data
				tempData[dataIndex][key] = value
			}
			else{
				tempData = detailData
				tempData[dataIndex][key] = value
			}
			setData(tempData)
			setDetailData(tempData)
			updateStintData(tempData[dataIndex].id,key,value,old_value)
			refreshComponent(123)
		}
	}

	var childComponent = (childItem,cardData) => {
		if(childItem.value==='tag'){
			var value = getOptionsLabelValue(tagOptions,cardData.tag,{},{fontSize:'17px',marginRight:'3px'}).label
		}
		if(childItem.value==='priority'){
			var value = getOptionsLabelValue(priorityOptions,cardData.priority).label
		}
		if(childItem.value==='reporter'){
			var value = assigneeLabel(cardData.assignee)
		}
		if(cardData[childItem.value]){
			return value ? value : cardData[childItem.value]
		}
		return '-'
	}

	var assigneeReporterComponent = (childItem,cardData,dataIndex) => {
		var value = assigneeLabel(assigneeOptions,cardData[childItem.value])
		if(!cardData[childItem.value]){
			cardData[childItem.value] = 'Not Assigned'
		}
		if(cardData[childItem.value]){
			return <div style={{position:'relative'}}>
				<div onClick={(e)=>{e.stopPropagation();setShowAssigneeOptions(childItem.value)}}>
					{value ? value : cardData[childItem.value]}
				</div>
				{
					showAssigneeOptions===childItem.value
					&&
					<div style={{position:'absolute',left:0,top:0,marginTop:'35px',zIndex:10}} onClick={e=>{e.stopPropagation();}}>
						<AssigneeOptions column={childItem.value} data={data} detailData={detailData} setData={setData} setDetailData={setDetailData} dataIndex={dataIndex} updateStintData={updateStintData} assigneeOptions={assigneeOptions} setShowAssigneeOptions={setShowAssigneeOptions}/>
					</div>
				}
			</div>
		}
	}

	var priorityComponent = (childItem,cardData,dataIndex) => {
		var value = getOptionsLabelValue(priorityOptions,cardData[childItem.value]).label
		if(cardData[childItem.value]){
			return <div style={{position:'relative'}}>
				<div onClick={(e)=>{e.stopPropagation();setShowAssigneeOptions(childItem.value)}}>
					{value ? value : cardData[childItem.value]}
				</div>
				{
					showAssigneeOptions===childItem.value
					&&
					<div style={{position:'absolute',left:0,top:0,marginTop:'35px',zIndex:10}} onClick={e=>{e.stopPropagation();}}>
						<PriorityOptions data={data} detailData={detailData} setData={setData} setDetailData={setDetailData} dataIndex={dataIndex} updateStintData={updateStintData} priorityOptions={getOptions(priorityOptions)} setShowPriorityOptions={setShowAssigneeOptions}/>
					</div>
				}
			</div>
		}
	}
	var rightbarComponents = [
		{
			label:'Details',
			childItems:[
				{label:'Tag',value:'tag',component:childComponent},
				{label:'Reporter',value:'reporter',component:assigneeReporterComponent},
				{label:'Assignee',value:'assignee',component:assigneeReporterComponent},
				{label:'Priority',value:'priority',component:priorityComponent},
			]
		},
		{
			label:'Last Work Details',
			childItems:[
				{label:'App Version Name',value:'app_version_name',component:childComponent},
				{label:'App Version Code',value:'app_version_code',component:childComponent},
				{label:'Last Worked By',value:'last_worked_by',component:childComponent},
				{label:'Last Worked Date',value:'last_worked_date',component:childComponent},
			]
		}
	]
	const showTicketBody = () => {
		var cardData = false
		for(var dataIndex in detailData){
			var dataItem = detailData[dataIndex]
			if(dataItem.id.toString() === openCard.toString()){
				cardData = dataItem
				break;
			}
		}
		if(!cardData || taskDetailLoader){
			return <div className='ticketBodyWrapper'>
				<div style={{display:'flex',alignItems:'center',justifyContent:'center',width:'100%'}}>
					{taskDetailLoader && 
						<CircularProgress sx={{color:localStorage.getItem('ambientColor')}}/>
					}
					{
						!taskDetailLoader
						&&
						<div style={{display:'flex',alignItems:'center',justifyContent:'center',width:'100%'}}>
							<Warning sx={{paddingRight:'20px'}}/>
							Seems like you are looking for a Stint which does not exist.
						</div>
					}
				</div>
			</div>
		}
		
		else{
			return <div id='ticketbodywrapper' className='ticketBodyWrapper'>
				<div className='ticketLeftBodyWrapper'>
					<div style={{width:'100%'}} onClick={()=>{setEnableTextEditor(0);setNewDescription('');setNewComment('');setEnableNewComment(false);}}>
						<TextfieldComponent text={cardData.title && cardData.title !=='None' ? '['+cardData.script_name+'] '+ cardData.title  : cardData.script_name} setText={(value)=>{changeCardDetails('script_name',value,dataIndex)}}/>
					</div>
					<div style={{marginLeft:'10px'}}>
						{
							cardData.is_parent
							&&
							<div className='createSubTaskButton' onClick={()=>{setSubtaskNew(true);if(document.getElementById('subtask_'+(Object.keys(cardData.sub_task).length-1).toString())){document.getElementById('subtask_'+(Object.keys(cardData.sub_task).length-1).toString()).scrollIntoView({behavior: 'smooth'});}}}>
								<AddTask sx={{fontSize:'12px',padding:'0px',marginRight:'5px',color:'rgb(100,100,100)'}}/>
								<div style={{fontSize:'10px'}}>Create Subtask</div>
							</div>
						}
						<div className='taskSubheading' style={{marginTop:'20px'}}>
							Description
						</div>
						{enableTextEditor<=1
						&&
						<div style={{fontSize:'12px',width:'80%',wordBreak:'break-all'}} onClick={(e)=>{e.stopPropagation();setEnableTextEditor(enableTextEditor+1)}}>
							{(!cardData.description || cardData.description=== '<p><br></p>') && <div style={{marginTop:'5px',fontSize:'11px'}}><i>Click to add description...</i></div>}
							{cardData.description!== '<p><br></p>' && <div style={{marginTop:'5px'}}>{parse(cardData.description)}</div>}
						</div>
						}
						{
							enableTextEditor>=2
							&&
							<div style={{transform:'scale(0.8)',transformOrigin:'0% 0%',marginBottom:'-5vh',marginTop:'5px'}} onClick={e=>{e.stopPropagation()}}>
								<CKEditor
									editor={ ClassicEditor }
									data={cardData.description}
									onInit={(editor) => {
										// You can store the "editor" and use when it is needed.
										console.log("Editor is ready to use!", editor);
									}}
									onReady={ editor => {
										// You can store the "editor" and use when it is needed.
										console.log( 'Editor is ready to use!', editor );
									} }
									onChange={ ( event, editor ) => {
										const data = editor.getData();
										console.log(data)
										setNewDescription(data)
									} }
									onBlur={ ( event, editor ) => {
										console.log( 'Blur.', editor );
									} }
									onFocus={ ( event, editor ) => {
										console.log( 'Focus.', editor );
									} }
								/>
								<div style={{display:'flex',justifyContent:'flex-end',marginTop:'10px'}}>
									<div><Button sx={{...Constants.CONTAINEDBUTTON,transform:'scale(0.8)'}} onClick={()=>{changeCardDetails('description',newDescription ? newDescription : '<p><br></p>',dataIndex,cardData.description);setNewDescription('');setEnableTextEditor(0);}}>Save</Button></div>
									<div><Button color={'warning'} sx={{transform:'scale(0.8)'}} onClick={()=>{setNewDescription('');setEnableTextEditor(0);}}>Cancel</Button></div>
								</div>
							</div>
						}
						{
							(subtaskNew || subtaskList.length>0)
							&&
							<div>
								<div style={{display:'flex',justifyContent:'space-between',alignItems:'center',marginTop:'20px',marginBottom:'5px'}}>
									<div className='taskSubheading'>
										Subtasks
									</div>
									<div>
										{(!subtaskNew || !subtaskList.length===0)
										&&
										<Tooltip title={'Add Subtask'}>
											<IconButton>
												<Add sx={{fontSize:'15px',color:'rgb(100,100,100)'}}/>
											</IconButton>
										</Tooltip>
										}
									</div>
								</div>
								<SubtaskListComponent data={data} openCard={openCard} parentData={detailData[openCard]} subtaskList={subtaskList} setSubtaskList={setSubtaskList} subtaskNew={subtaskNew} setSubtaskNew={setSubtaskNew} updateStintData={updateStintData} assigneeOptions={assigneeOptions}/>
							</div>
						}
						<div className='taskSubheading' style={{marginTop:'20px'}}>
							Activity
						</div>
						<div style={{display:'flex',alignItems:'center',marginTop:'5px'}}>
							<div style={{fontSize:'12px'}}>Show : &nbsp;</div>
							<div className={!activeCommentHistoryState[cardData.id] ? 'activityItem activityItemActive' : 'activityItem'} onClick={()=>{setActiveCommentHistoryState({...activeCommentHistoryState,[cardData.id]:false})}}>Comments</div>
							<div className={activeCommentHistoryState[cardData.id] ? 'activityItem activityItemActive' : 'activityItem'} style={{marginLeft:'10px'}} onClick={()=>{setActiveCommentHistoryState({...activeCommentHistoryState,[cardData.id]:true})}}>History</div>
						</div>
						{
							!activeCommentHistoryState[cardData.id]
							&&
							<div className='addCommentRow' style={{justifyContent:enableNewComment ? 'space-between' : 'flex-start'}}>
								<div className='userLogo'>
									{getLocalStorageValue('adminPanelLoginData').username[0]}
								</div>
								{
								!enableNewComment
								&&
								<div className='commentTextField' onClick={(e)=>{e.stopPropagation();setEnableNewComment(true)}}>
									Tap to add comment...
								</div>
								}
								{
								enableNewComment
								&&
								<div style={{transform:'scale(0.8)',transformOrigin:'0% 0%',marginBottom:'-5vh',marginLeft:'10px',width:'95%'}} onClick={e=>{e.stopPropagation()}}>
									<CKEditor
										editor={ ClassicEditor }
										data={''}
										onInit={(editor) => {
											// You can store the "editor" and use when it is needed.
											console.log("Editor is ready to use!", editor);
										}}
										onReady={ editor => {
											// You can store the "editor" and use when it is needed.
											console.log( 'Editor is ready to use!', editor );
										} }
										onChange={ ( event, editor ) => {
											const data = editor.getData();
											setNewComment(data)
										} }
										onBlur={ ( event, editor ) => {
											console.log( 'Blur.', editor );
										} }
										onFocus={ ( event, editor ) => {
											console.log( 'Focus.', editor );
										} }
									/>
									<div style={{display:'flex',justifyContent:'flex-start',marginTop:'5px'}}>
										<div><Button sx={{...Constants.CONTAINEDBUTTON,transform:'scale(0.8)'}} onClick={()=>{addComment(cardData.id,'add');setNewComment('');setEnableNewComment(false);}}>Save</Button></div>
										<div><Button color={'warning'} sx={{transform:'scale(0.8)'}} onClick={()=>{setNewComment('');setEnableNewComment(false);}}>Cancel</Button></div>
									</div>
								</div>
								}
							</div>
						}
						{	
							!activeCommentHistoryState[cardData.id]
							&&
							commentsDict[cardData.id]
							&&
							<div style={{marginTop:'10px'}}>
								{commentsDict[cardData.id].map((commentItem,commentIndex)=>{
									return <div key={commentIndex} className='commentContainer'>
											<div className='userLogo'>
												{assigneeOptions[commentItem.user_id][0]}
											</div>
											<div style={{fontSize:'12px',marginTop:'10px',marginLeft:'10px'}}>
												<div style={{fontSize:'13px',fontWeight:'500',display:'flex',alignItems:'center'}}>
													{assigneeOptions[commentItem.user_id]}
													&nbsp;
													&nbsp;
													<div style={{color:'rgb(140,140,140)',fontSize:'12px'}}>
														{(new Date(commentItem.created_at)).toLocaleDateString('en-GB',{year:'numeric',month:'short',day:'numeric',hour:'numeric',minute:'numeric'})}
													</div>
														
												</div>
												<div>
													{parse(commentItem.comment)}
												</div>
											</div>
										</div>
								})}
							</div>
						}
						{	
							activeCommentHistoryState[cardData.id]
							&&
							taskHistoryDict[cardData.id]
							&&
							<div style={{marginTop:'10px'}}>
								{taskHistoryDict[cardData.id].length===0 &&
								<div style={{display:'flex',fontSize:'13px',paddingTop:'10px',paddingBottom:'10px'}}>
									No History Found
								</div>
								}
								{taskHistoryDict[cardData.id].map((commentItem,commentIndex)=>{
									return <div key={commentIndex} className='commentContainer'>
											<div className='userLogo'>
												{assigneeOptions[commentItem.user_id][0]}
											</div>
											<div style={{fontSize:'12px',marginTop:'10px',marginLeft:'10px'}}>
												<div style={{fontSize:'13px',display:'flex',alignItems:'center'}}>
													<div style={{fontWeight:'500'}}>{assigneeOptions[commentItem.user_id]} </div>
													<div style={{marginLeft:'5px',marginRight:'5px',fontSize:'12px'}}>
														updated
													</div>
													<div style={{fontWeight:'500'}}>
														{commentItem.updated_key}
													</div>
													&nbsp;
													&nbsp;
													<div style={{color:'rgb(140,140,140)',fontSize:'12px'}}>
														{(new Date(commentItem.created_at)).toLocaleDateString('en-GB',{year:'numeric',month:'short',day:'numeric',hour:'numeric',minute:'numeric'})}
													</div>
												</div>
												<div style={{display:'flex',alignItems:'center',flexWrap:'wrap',marginTop:'5px',marginBottom:'10px'}}>
													<div>
														{commentItem.old_val}
													</div>
													<div style={{marginLeft:'7px',marginRight:'7px'}}>
														->
													</div>
													<div>
														{commentItem.current_val}
													</div>
												</div>
											</div>
										</div>
								})}
							</div>
						}
					</div>
				</div>	
				{/* <div style={{height:'calc(100% - 10px)',border:'1px solid rgb(200,200,200)'}}></div> */}
				<div className='ticketRightBodyWrapper'>
					<div style={{transform:'scale(0.8)',transformOrigin:'0% 0%',marginTop:'2px',marginLeft:'2px',width:'50%'}}>
						<ReactSelect
							placeholder={'Status'}
							value={cardData.status ? {label:columns[cardData.status].title,value:cardData.status} : false}
							options={Object.keys(columns).map((item,index)=>{return {label:columns[item].title,value:item}})}
							onChange={(e)=>{
								var tempcolumns = columns;
								tempcolumns[cardData.status].items = tempcolumns[cardData.status].items.filter(e => e!== cardData.id)
								tempcolumns[e.value].items = [cardData.id,...tempcolumns[e.value].items]
								setColumns(tempcolumns)
								var tempData = data;
								tempData[cardData.id]['status'] = e.value
								setData(tempData);
								setDetailData(tempData);
								changeCardDetails('status',e.value,dataIndex);
							}}
							menuPortalTarget={document.body} 
							styles={{...Constants.ReactSelectStyles}}
						/>
					</div>
					<div>
						{rightbarComponents.map((item,index)=>{
							return <div key={index} className='cardDetails'>
								<div style={{display:'flex',justifyContent:'space-between',background:'rgb(255, 244, 224)',alignItems:'center',borderBottom:'1px solid rgb(200,200,200)'}}
									onClick={()=>{
										if(openCardDetails.includes(item.label)){
											var tempOpenCardDetails = openCardDetails
											tempOpenCardDetails = tempOpenCardDetails.filter(e=>e!==item.label)
											setOpenCardDetails(tempOpenCardDetails)
										}
										else{
											setOpenCardDetails([...openCardDetails,item.label])
										}
									}}
								>
									<div className='cardDetailsLabel'>{item.label}</div>
									<div style={{paddingRight:'10px'}}>
										<KeyboardArrowDownIcon/>
									</div>
								</div>
								{openCardDetails.includes(item.label) && 
									<div style={{marginTop:'10px',marginBottom:'10px'}}>
										{item.childItems.map((childItem,childIndex)=>{
											return <div className='cardDetailChildWrapper' key={childIndex}>
												<div style={{display:'flex',alignItems:'center',flexWrap:'wrap'}}>
													<div className='cardDetailChildLabel' style={{width:'40%'}}>{childItem.label}</div>
													<div>
														{childItem.component(childItem,cardData,dataIndex)}
													</div>
												</div>
											</div>
										})}
									</div>
								}
								</div>
						})}
					</div>
				</div>
			</div>
		}
	}


	return (
		<div>
			{/* <div style={{display:'flex',width:'calc(100vw - 120px)',position:'absolute',marginLeft:'20px',justifyContent:'flex-start'}}>
				<DateRangePickerComponent
					filterData={filterData} setFilterData={setFilterData}
				/>
			</div> */}
			{
				loader
				&&
				<div style={{minHeight:'60vh',width:'calc(100vw - 100px)',display:'flex',alignItems:'center',justifyContent:'center'}}>
					<CircularProgress sx={{color:localStorage.getItem('ambientColor')}}/>
				</div>
			}
			<DragDropContext
				onDragEnd={(result) => onDragEnd(result, columns, setColumns)}
				>
				{(openCard || openCard===0)
				&&
				<div className='fullScreenContainer'>
					<div className='fullScreenWrapper'>
						<div className='ticketHeaderRow'>
							<div style={{padding: "20px",fontSize:'14px'}}>
								Team {detailData && detailData[openCard] ? detailData[openCard].team : ''} - Task {openCard}
							</div>
							{/* Top bar of Ticket Popup */}
							<div style={{paddingRight: "10px"}}>
								{/* <Tooltip title={'2 Watchers'}>
									<IconButton sx={{color:'rgb(85, 55, 255)',marginRight:'5px'}}>
										<div style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
											<RemoveRedEye sx={{fontSize:'25px'}}/>
											<div style={{paddingLeft:'5px',fontSize:'13px'}}>2</div>
										</div>
									</IconButton>
								</Tooltip>
								<Tooltip title={'Give Like'}>
									<IconButton style={{marginRight:'5px'}}>
										<ThumbUpSharp sx={{fontSize:'25px'}}/>
									</IconButton>
								</Tooltip>
								<Tooltip title={'Share'}>						
									<IconButton style={{marginRight:'5px'}}>
										<ShareOutlined sx={{fontSize:'25px'}}/>
									</IconButton>
								</Tooltip>
								<Tooltip title={'Actions'}>						
									<IconButton style={{marginRight:'5px'}}>
										<MoreHoriz sx={{fontSize:'25px'}}/>
									</IconButton>
								</Tooltip> */}
								<IconButton style={{marginRight:'5px'}} onClick={closeCard}>
									<CloseTwoTone sx={{fontSize:'17px'}}/>
								</IconButton>
							</div>
						</div>
						{/* Body of Ticket Popup */}
						{!loader && showTicketBody()}
					</div>
				</div>}
				{!loader
				&&
				<Container>
					<TaskColumnStyles>
					{Object.entries(columns).map(([columnId, column], index) => {
						return (
						<Droppable key={columnId} droppableId={columnId}>
							{(provided, snapshot) => (
							<TaskList
								ref={provided.innerRef}
								{...provided.droppableProps}
							>
								<Title>{column.title}</Title>
								{column.items.map((item, index) => {
									item = data[item]
									return <TaskCard onClick={(e)=>{cardClicked(item,index);}} key={index} item={item} index={index} assigneeOptions={assigneeOptions}/>
								})}
								{provided.placeholder}
							</TaskList>
							)}
						</Droppable>
						);
					})}
					</TaskColumnStyles>
				</Container>
				}
			</DragDropContext>
			{addNewTask && 
				<NewTask 
					addNewTask={addNewTask} 
					setAddNewTask={setAddNewTask} 
					tasksList={tasksList} 
					setTaskList={setTaskList}
					assigneeOptions={assigneeOptions}
					data={data}
					setData={setData}
					setDetailData={setDetailData}
					columns={columns}	
					setColumns={setColumns}
					assigneeOptions={assigneeOptions}
					productManagers={productManagers}
					users={usersDict}
				/>
			}
            <div className='addStintIcon' onClick={()=>{setAddNewTask('parent')}}>
                <AddIcon sx={{fontSize:'30px'}} onClick={()=>{setAddNewTask('parent');}}/>
            </div>
		</div>
		
	);
};

