import React, { useEffect, useState } from 'react'
import { capitalize, getLocalStorageValue, setLocalStorageValue, useForceUpdate } from '../../helper/util'
import './historyComponent.css';

import { Resizable } from "re-resizable";
import { IconButton, Tooltip } from '@mui/material';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import ReplayIcon from '@mui/icons-material/Replay';
import Pagination from '@mui/material/Pagination';

import { Rings } from  'react-loader-spinner'
import { CssTextField } from '../../data/constants';
import { SearchOutlined } from '@mui/icons-material';
import { Store } from 'react-notifications-component';

const HistoryComponent = (props) => {
    const [showRightMenu, setShowRightMenu] = useState(false)
    const refreshComponent = useForceUpdate();
    var historyWindowWidth = props.historyWindowWidth
    var setHistoryWindowWidth = props.setHistoryWindowWidth
    var historyResponse = props.historyResponse
    var historyResponseLoader = props.historyResponseLoader
    var getDebugHistory = props.getDebugHistory
    var page = props.historyPage
    var setPage = props.setHistoryPage
    var pages = props.historyPages

    var statusHistoryDict = {
        'processed':{
            color:'green'
        },
        'pending':{
            color:'orange'
        },
        'error':{
            color:'red'
        }
    }
    const statusHistoryComponent = (item,index,tableHead,tableHeadIndex) => {
        if(item.reportstatus){
            var status = item.reportstatus.toLowerCase()
            var bgColor = statusHistoryDict[status].color
        }
        return <div style={{width:'8px',height:'8px',borderRadius:'4px',marginTop:'2px',background:bgColor}}></div>
    }
    const requestedAtComponent = (item,index,tableHead,tableHeadIndex) => {
        var dateOptions = { year: 'numeric', month: 'short', day: 'numeric' ,hour:'numeric',minute:'numeric',second:'numeric'};
        return new Date(item.created_at).toLocaleDateString("en-US", dateOptions)
    }
    var historyHeaders = [
        {label:'',value:'reportstatus',width:'5%',customComponent:statusHistoryComponent},
        {label:props.historyFor + ' Name',value:'filename',width:'45%'},
        {label:'Requested At',value:'created_at',width:'50%',customComponent:requestedAtComponent},
    ]

    const generatedByComponent = (item,index,tableHead,tableHeadIndex) => {
        return capitalize(item.generated_by.split(',')[0])
    }
    var historySharedHeaders = [
        {label:'',value:'reportstatus',width:'5%',customComponent:statusHistoryComponent},
        {label:props.historyFor + ' Shared',value:'filename',width:'45%'},
        {label:'Generated By',value:'generated_by',width:'50%',customComponent:generatedByComponent},
    ]
    
    if(props.historyHeaders){
        historyHeaders = props.historyHeaders
    }
    if(historyResponse && !Object.keys(historyResponse).includes('user_created')){
        var tempHistoryResp = historyResponse
        historyResponse = {}
        historyResponse['user_created'] = tempHistoryResp
    }
    const handleClick = (e,index,type) => {
        if(props.rightClicked){
            e.preventDefault();
            e.stopPropagation();
            setShowRightMenu(type+index);
        }
    }
    useEffect(() => {
        document.addEventListener('click',e=>{
            e.stopPropagation()
            setShowRightMenu(false);
        })
        
        return () => {
            document.removeEventListener('click',e=>{
                e.stopPropagation()
                setShowRightMenu(false);
            })
        }
    }, [])
    
    return (
        <Resizable
            size={{width:historyWindowWidth}}
            style={{transition:'all 0.1s linear'}}
            enable={{ top:false, right:true, bottom:false, left:false, topRight:false, bottomRight:false, bottomLeft:false, topLeft:false }}
            onResizeStop={(e, direction, ref, d) => {
                var tempHistoryWindowWidth = historyWindowWidth
                if(tempHistoryWindowWidth + d.width < 250){
                    tempHistoryWindowWidth = 250
                }
                else{
                    tempHistoryWindowWidth = tempHistoryWindowWidth + d.width
                    if(parseInt(tempHistoryWindowWidth)>500){
                        tempHistoryWindowWidth = 500
                    }
                }
                setHistoryWindowWidth(tempHistoryWindowWidth)
                localStorage.setItem(window.location.pathname+'historyWindowWidth',tempHistoryWindowWidth)
                // setLocalStorageValue(window.location.pathname+'historyWindowWidth',tempHistoryWindowWidth)
                refreshComponent(e);
                }}
                >
                <div className='debugHistoryContainer' style={{position:'relative',height:(historyResponse && historyResponse.user_shared ? 'calc(60vh - 30px)' : 'calc(100vh - 61px)')}}>
                    <div className='debugHistoryHead' style={{justifyContent:'center'}}>
                        History
                        <ReplayIcon className='reloadButton' sx={{fontSize:'18px'}} 
                        onClick={()=>{
                            if(!getLocalStorageValue('history_url'+props.historyFor) || (getLocalStorageValue('history_url'+props.historyFor) && getLocalStorageValue('history_url'+props.historyFor)<(new Date().getTime() - 30000))){
                                getDebugHistory();
                                setLocalStorageValue('history_url'+props.historyFor,new Date().getTime(),true)
                            }
                            else{
                                Store.addNotification({
                                    title: "Warning!",
                                    message: 'Please wait sometime before refreshing. Do not click spam please.',
                                    type: "warning",
                                    insert: "top",
                                    container: "top-right",
                                    animationIn: ["animate__animated", "animate__fadeIn"],
                                    animationOut: ["animate__animated", "animate__fadeOut"],
                                    dismiss: {
                                      duration: 6000,
                                      onScreen: true
                                    }
                                });
                            }}}
                        />
                    </div>
                    <div className='debugHistoryHead'>
                        {historyHeaders.map((item,index)=>{
                            return <div className='debugHistoryHeadItem' style={{width:item.width,minWidth:item.width,maxWidth:item.width}} key={index}>
                                        {item.label}
                                    </div>
                        })}
                        <Tooltip title={props.hintVariable ? props.hintVariable : 'Green : Proccessed, Orange : Processing, Red : Error Occured'}>
                            <IconButton  sx={{position:'absolute',right:'0px'}}>
                                <QuestionMarkIcon sx={{fontSize:'13px'}}/>
                            </IconButton>
                        </Tooltip>
                    </div>
                    {(props.query || props.query==='')
                    &&
                    <div style={{height:'50px',borderBottom:'1px solid rgb(220,220,220)',display:'flex',justifyContent:'center',alignItems:'center',paddingLeft:'10px',paddingRight:'10px'}}>
                        <CssTextField value={props.query} onKeyDown={e=>{if(e.key==='Enter'){getDebugHistory();}}} onChange={e=>{props.setQuery(e.target.value);}} placeholder='Search Debugged Script' size="small" fullWidth sx={{transform:'scale(0.8)',transformOrigin:'left'}}/>
                        <IconButton  onClick={()=>{getDebugHistory();}}>
                            <SearchOutlined sx={{fontSize:'20px'}}/>
                        </IconButton>
                    </div>
                    }
                    <div className='debugHistoryValueContainer' style={(props.query || props.query==='') ? {height:'calc(100% - 135px)'} : {}}>
                        {historyResponseLoader && 
                        <div style={{display:'flex',alignItems:'center',justifyContent:'center',height:'30vh'}}>
                            <Rings
                                height = "80"
                                width = "80"
                                radius = "9"
                                color = 'orange'
                                ariaLabel = 'three-dots-loading'     
                            />
                        </div>
                        }
                        {!historyResponseLoader && historyResponse && historyResponse.user_created && historyResponse['user_created'].length===0 && 
                        <div style={{fontSize:'13px',marginTop:'10px',textAlign:'center'}}>
                            No {props.historyFor} found.
                            </div>}
                        {!historyResponseLoader && historyResponse && historyResponse.user_created && historyResponse['user_created'].map((item,index)=>{
                            return <div key={index} className='debugHistoryValue' style={{position:'relative',backgroundColor:(showRightMenu === ('user_created'+index) ? 'rgb(255, 241, 215)' : '')}} onContextMenu={(e)=>{handleClick(e,index,'user_created')}}>
                                        {historyHeaders.map((tableHead,tableHeadIndex)=>{
                                            return <div className='debugHistoryValueItem' style={{width:tableHead.width,minWidth:tableHead.width,maxWidth:tableHead.width}} key={tableHeadIndex} onClick={()=>{props.openTabs(item)}}>
                                                        {tableHead.customComponent && 
                                                            tableHead.customComponent(item,index,tableHead,tableHeadIndex)
                                                        }
                                                        {!tableHead.customComponent && item[tableHead.value]}
                                                    </div>
                                        })}
                                        {showRightMenu === ('user_created'+index)
                                            &&
                                            <div className='rightMenu'>
                                                {
                                                    props.rightClicked.map((rightClickItem,rightClickIndex)=>{
                                                        return <div className='rightMenuDetailContainer' key={rightClickIndex} onClick={e=>{if(rightClickItem.onClick){rightClickItem.onClick(e,item);}}}>
                                                            {rightClickItem.label}
                                                        </div>
                                                    })
                                                }
                                            </div>
                                        }
                                    </div>
                            })}
                    </div>
                    <div style={{bottom:0,position:'sticky',display:'flex',justifyContent:'center',alignItems:'center',height:'40px',width:'100%',marginTop:'-30px',background:'white'}}>
                        <Pagination count={pages} page={page} onChange={(e,p)=>{setPage(p);getDebugHistory(p);}} color="warning" sx={{position:'absolute'}}  size='small'/>
                    </div>
                </div>
                {historyResponse && historyResponse.user_shared
                &&
                <div className='debugHistoryContainer' style={{position:'relative',height:'calc(40vh - 41px)',paddingTop:'10px'}}>
                    <div className='debugHistoryHead'>
                        {historySharedHeaders.map((item,index)=>{
                            return <div className='debugHistoryHeadItem' style={{width:item.width,minWidth:item.width,maxWidth:item.width}} key={index}>
                                        {item.label}
                                    </div>
                        })}
                        <Tooltip title={props.hintVariable ? props.hintVariable : 'Green : Proccessed, Orange : Processing, Red : Error Occured'}>
                            <IconButton  sx={{position:'absolute',right:'0px'}}>
                                <QuestionMarkIcon sx={{fontSize:'13px'}}/>
                            </IconButton>
                        </Tooltip>
                    </div>
                    <div className='debugHistoryValueContainer' style={(props.query || props.query==='') ? {height:'calc(100% - 42px)'} : {}}>
                        {historyResponseLoader && 
                        <div style={{display:'flex',alignItems:'center',justifyContent:'center',height:'30vh'}}>
                            <Rings
                                height = "80"
                                width = "80"
                                radius = "9"
                                color = 'orange'
                                ariaLabel = 'three-dots-loading'     
                            />
                        </div>
                        }
                        {!historyResponseLoader && historyResponse.user_shared && historyResponse.user_shared.length===0 && 
                        <div style={{fontSize:'13px',marginTop:'10px',textAlign:'center'}}>
                            No {props.historyFor} found.
                            </div>}
                        {!historyResponseLoader && historyResponse.user_shared && historyResponse.user_shared.map((item,index)=>{
                            return <div key={index} className='debugHistoryValue' style={{position:'relative',backgroundColor:(showRightMenu === ('user_shared'+index) ? 'rgb(255, 241, 215)' : '')}} onContextMenu={(e)=>{handleClick(e,index,'user_shared')}}>
                                        {historySharedHeaders.map((tableHead,tableHeadIndex)=>{
                                            return <div className='debugHistoryValueItem' style={{width:tableHead.width,minWidth:tableHead.width,maxWidth:tableHead.width}} key={tableHeadIndex} onClick={()=>{props.openTabs(item)}}>
                                                        {tableHead.customComponent && 
                                                            tableHead.customComponent(item,index,tableHead,tableHeadIndex)
                                                        }
                                                        {!tableHead.customComponent && item[tableHead.value]}
                                                    </div>
                                        })}
                                        {showRightMenu === 'user_shared'+index
                                            &&
                                            <div className='rightMenu'>
                                                {
                                                    props.rightClicked.map((rightClickItem,rightClickIndex)=>{
                                                        return <div className='rightMenuDetailContainer' key={rightClickIndex} onClick={e=>{if(rightClickItem.onClick){rightClickItem.onClick(e,item);}}}>
                                                            {rightClickItem.label}
                                                        </div>
                                                    })
                                                }
                                            </div>
                                        }
                                    </div>
                            })}
                    </div>
                    {/* <div style={{bottom:0,position:'sticky',display:'flex',justifyContent:'center',alignItems:'center',height:'30px',width:'100%',background:'white',marginTop:'-30px'}}>
                        <Pagination count={pages} page={page} onChange={(e,p)=>{setPage(p);getDebugHistory(p);}} color="warning" sx={{position:'absolute'}}  size='small'/>
                    </div> */}
                </div>
                }
        </Resizable>
    )
}

export default HistoryComponent;