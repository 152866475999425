import React from 'react'
import TopbarV2 from '../../components/topbarv2/topbarv2'
import '../../css/pages/v1/accountPermissions.css'
import SkateboardingIcon from '@mui/icons-material/Skateboarding';
import { useState } from 'react';
import { Button, CircularProgress, Switch } from '@mui/material';
import CreatableSelect from 'react-select/creatable';
import * as Constants from '../../data/constants';
import { SidebarData } from '../../data/sidebarTabs';
import { capitalize, getLocalStorageValue, setLocalStorageValue, sortingV2, useForceUpdate } from '../../helper/util';
import axios from 'axios';
import { Store } from 'react-notifications-component';
import { Rings } from  'react-loader-spinner'
import { Search } from '@mui/icons-material';
import ReactSelect from 'react-select';

const AccountPermissionsPage = () => {
    const refreshComponent = useForceUpdate()
    const [manualUseEffect, setManualUseEffect] = useState(false)
    const [response, setResponse] = useState([])
    const [selectedUser, setSelectedUser] = useState(0)
    const [searchedUser, setSearchedUser] = useState('')
    const [showLoader, setShowLoader] = useState(false)
    
    const otherPermissions = [
        {
        title :  "Can Edit Campaign Data Customizations",
        link  : "can_edit_customizations",
        },
        {
        title :  "Can Perform Debug Above 250 I1",
        link  : "debug_perform_above_250",
        },
        {
        title :  "Can Perform Debug Above 15 Days",
        link  : "debug_perform_above_15_days",
        },
        {
        title :  "Can Minor Update From Data Difference",
        link  : "can_update_from_dd",
        },
        {
        title :  "Can Push On Behalf Of",
        link  : "can_push_on_behalf_of",
        },
        {
        title :  "Can Delete Downloaded Script Entries",
        link  : "can_delete_download_script_entries",
        },
        {
        title :  "Can Get Debug Tree",
        link  : "can_get_debug_tree",
        },
        {
        title :  "Can Compare Old Scripts",
        link  : "can_compare_old_scripts",
        },
        {
        title :  "Can Access Local Fire Panel",
        link  : "can_access_local_fire",
        },
    ]
    var SidebarDataTemp = [...SidebarData,...otherPermissions]

    var getUserPermissions = async(item) => {
        setShowLoader(true)
        await axios({
            method: "GET",
            url: Constants.BACKENDHOST+"accounts/permissions",
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token,
            },
        })
        .then(resp => {
            var response = resp.data.data
            sortingV2(response,'name',true)
            setResponse(response)
        })
        .catch(err=>{
            if(err.response && err.response.data){
                Store.addNotification({
                    title: "Error!",
                    message: err.response.data.message,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });
            }
        })
        setShowLoader(false)
    }

    if(!manualUseEffect){
        setManualUseEffect(true)
        getUserPermissions()
    }
    
    const [saveButtonLoader, setSaveButtonLoader] = useState(false)

    var setUserPermissions = async(item) => {
        setSaveButtonLoader(true)
        await axios({
            method: "POST",
            url: Constants.BACKENDHOST+"accounts/permissions",
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token,
            },
            data:{
                ...response[selectedUser]
            }
        })
        .then(resp => {
            // setResponse(resp.data.data)
            console.log(resp.data)
            Store.addNotification({
                title: "Success!",
                message: resp.data.message,
                type: "success",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 5000,
                    onScreen: true
                }
            });
        })
        .catch(err=>{
            if(err.response && err.response.data){
                Store.addNotification({
                    title: "Error!",
                    message: err.response.data.message,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });
            }
        })
        setSaveButtonLoader(false)
    }

    const getTeamValue = (teamValue) => {
        if(teamValue<9){
            return {label : 'Tech Team '+teamValue,value:teamValue}
        }
        else{
            return {label : 'BT Team '+teamValue,value:teamValue%10}
        }
    }
    const getManagedBy = (managed_by) => {
        var managerByList = []
        for(var index in response){
            var item = response[index]
            for(var respIndex in managed_by){
                if(item.id === managed_by[respIndex]){
                    managerByList.push({label:item.name,value:item.id})
                }
            }
        }
        return managerByList
    }


    async function loginUser(credentials) {
        return await axios({
            method: 'POST',
            url: "".concat(Constants.BACKENDHOST,'loginas'),
            headers: {
                'Content-Type': "application/json",
                Authorization:getLocalStorageValue('adminPanelLoginData').token,
            },
            data: {
                "name": credentials.username,
                }
        })
        .then(async(resp) => {
            return resp.data;
        })
        .catch(error => {
        })
    }

    const getUserData = async(manualPage,query_=null) => {
        await axios({
            method: 'GET',
            url: "".concat(Constants.BACKENDHOST,"getusers"),
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token,
            },
        })
        .then(resp => {
            if(resp.data.response_code===200){
                var tempusers = []
                for(var index in resp.data.data){
                    var item = resp.data.data[index];
                    if(item['name']!==getLocalStorageValue('adminPanelLoginData').username){
                        tempusers.push({label:capitalize(item['name']),value:item['id']})
                    }
                }
                setLocalStorageValue('userData',tempusers)
            }
        })
        .catch(err => {
            console.error(err);
        })
    };
    const loginInAsThisUser = async(userdata) => {
        var username = userdata.name
        var response = await loginUser({
        username,
        });

        if (response){
            var allowedPages = response.data.access_dns_path
            
            var userDetails = {
                username,
                allowedPages,
                token:response.data.token,
                loginTime:new Date().getTime(),
                ...response.data
            }

            setLocalStorageValue('adminPanelLoginData',userDetails)
            await getUserData()
            localStorage.setItem('adminloginTime',new Date().getTime());
            
            if (allowedPages.includes("/home")){
                if (window.location.href.includes("login")){
                    window.location="/home"
                }
                else{
                    window.location=window.location.href
                }
            }
            else{
                window.location=allowedPages[0]
            }
        }
    }
    return (
        <div>
            <TopbarV2 title={'Account Permissions'}/>
            <div style={{display:'flex'}}>
                <div className='accountPermissionUsersContainers'>
                    <div className='accountPermissionLabel'>
                        Users
                    </div>
                    {
                        showLoader
                        &&
                        <div style={{display:'flex',alignItems:'center',justifyContent:'center',height:'60vh'}}>
                            <Rings
                                height = "80"
                                width = "80"
                                radius = "9"
                                color = 'orange'
                                ariaLabel = 'three-dots-loading'     
                            />
                        </div>
                    }
                    {
                        !showLoader
                        &&
                        <div style={{height:'calc(100vh - 110px)',overflow:'auto',position:'relative'}}>
                            <div style={{position:'sticky',top:0,display:'flex',alignItems:'center',padding:'10px',justifyContent:'center',background:'white'}}>
                                <Constants.CssTextField size='small' fullWidth
                                    onChange={(e)=>{setSearchedUser(e.target.value)}}
                                    InputProps={{
                                        startAdornment: <Search position="start" sx={{paddingRight:'10px'}}/>,
                                    }}
                                />
                            </div>
                            <div>
                                {response.map((item,index)=>{
                                    if(item.name.toLowerCase().includes(searchedUser.toLowerCase()) || searchedUser === ''){
                                        return <div className='accountPermissionUserWrapper' style={{background:(selectedUser===index ? 'rgb(255, 201, 92)' : '')}} key={index} onClick={()=>{setSelectedUser(index)}}>
                                                <div style={{marginRight:'13px',width:'30px',height:'30px',borderRadius:'15px',border:'1px solid black',background:'rgb(220,220,220)',display:'flex',alignItems:'center',justifyContent:'center'}}>
                                                    <SkateboardingIcon sx={{fontSize:'20px'}}/>
                                                </div>
                                                {item.name  }
                                            </div>
                                    }
                                })}
                            </div>
                        </div>
                    }
                </div>
                
                {
                    response.length>0
                    &&
                    <div className='accountPermissionPermissionContainers'>
                        <div className='accountPermissionLabel'>
                            Access
                        </div>
                        <div style={{padding:'20px',paddingBottom:'0px',overflow:'auto'}}>

                            <div className='accountPermissionAccessFlex'>
                                <div className='accountPermissionAccessFlexLabel'>
                                    User Active
                                </div>
                                <div>
                                    <Switch size='small' color='warning' checked={response.length>0 && response[selectedUser].is_active} onChange={(e)=>{var tempResponse = response; tempResponse[selectedUser]['is_active'] = e.target.checked; setResponse(tempResponse);refreshComponent(123);}}/>
                                </div>
                            </div>
                            {
                                getLocalStorageValue('adminPanelLoginData').rolename === 'Admin'
                                &&
                                <div className='accountPermissionAccessFlex'>
                                    <div className='accountPermissionAccessFlexLabel'>
                                        Login in as this user
                                    </div>
                                    <div style={{minWidth:'500px',transform:'scale(0.8)',transformOrigin:'left'}}>
                                        <div style={{color:getLocalStorageValue('ambientColor'),cursor:'pointer'}} onClick={()=>{loginInAsThisUser(response[selectedUser])}}>Login</div>
                                    </div>
                                </div>
                            }
                            <div className='accountPermissionAccessFlex'>
                                <div className='accountPermissionAccessFlexLabel'>
                                    Team
                                </div>
                                <div style={{minWidth:'500px',transform:'scale(0.8)',transformOrigin:'left'}}>
                                    <ReactSelect                                                
                                        placeholder={'Select Team'}
                                        value={getTeamValue(response[selectedUser].teamname)}
                                        options={Constants.teamValues()}
                                        onChange={(e)=>{var tempResponse = response; tempResponse[selectedUser]['teamname'] = e.value; setResponse(tempResponse);refreshComponent(123);}}
                                        menuPortalTarget={document.body} 
                                        styles={{...Constants.ReactSelectStyles}}
                                    />
                                </div>
                            </div>
                            <div className='accountPermissionAccessFlex'>
                                <div className='accountPermissionAccessFlexLabel'>
                                    Managed By
                                </div>
                                <div style={{minWidth:'500px',transform:'scale(0.8)',transformOrigin:'left'}}>
                                    <ReactSelect                                                
                                        placeholder={'Select Manager.'}
                                        isMulti
                                        options={response.length>0 ? response.map((item,index)=>{return {label:item.name,value:item.id}}) : [] }
                                        value={getManagedBy(response[selectedUser].managed_by)}
                                        onChange={(e)=>{var tempResponse = response; tempResponse[selectedUser]['managed_by'] = e.map((i,j)=>{return i.value;}); setResponse(tempResponse);refreshComponent(123);}}
                                        menuPortalTarget={document.body} 
                                        styles={{...Constants.ReactSelectStyles}}
                                    />
                                </div>
                            </div>
                            <div className='accountPermissionAccessFlex'>
                                <div className='accountPermissionAccessFlexLabel'>
                                    Allowed IPs
                                </div>
                                <div style={{minWidth:'500px',transform:'scale(0.8)',transformOrigin:'left'}}>
                                    <CreatableSelect                                                
                                        placeholder={'Enter IPs to be allowed.'}
                                        isMulti
                                        options={response.length>0 ? response[selectedUser].allowed_ips.map((item,index)=>{return {label:item,value:item}}) : [] }
                                        value={response.length>0 ? response[selectedUser].allowed_ips.map((item,index)=>{return {label:item,value:item}}) : []}
                                        onChange={(e)=>{var tempResponse = response; tempResponse[selectedUser]['allowed_ips'] = e.map((i,j)=>{return i.value;}); setResponse(tempResponse);refreshComponent(123);}}
                                        menuPortalTarget={document.body} 
                                        styles={{...Constants.ReactSelectStyles}}
                                    />
                                </div>
                            </div>

                            <div className='accountPermissionAccessFlex' style={{alignItems:'flex-start'}}>
                                <div className='accountPermissionAccessFlexLabel'>
                                    Access Permissions
                                </div>
                                <div style={{minWidth:'500px',maxHeight:'calc(100vh - 460px)',overflow:'auto'}}>
                                    {SidebarDataTemp.map((item,index)=>{
                                        return <div style={{display:'flex',width:'80%',justifyContent:'space-between',paddingTop:(index===0 ? '0px' : '15px'),flexDirection:(item.link ? 'row' : 'column')}} key={index}>
                                                    <div style={{fontSize:'15px',fontWeight:'500',color:'rgb(100,100,100)'}}>{item.title}</div>
                                                {item.link
                                                &&
                                                <Switch size='small' color='warning' checked={response.length>0 && response[selectedUser].access_dns_path.includes(item.link)} onChange={()=>{var tempResponse = response; if(tempResponse[selectedUser]['access_dns_path'].includes(item.link)){tempResponse[selectedUser]['access_dns_path'] = tempResponse[selectedUser]['access_dns_path'].filter(e => e!==item.link)}else{tempResponse[selectedUser]['access_dns_path'].push(item.link)}; setResponse(tempResponse);refreshComponent(123);}}/>
                                                }
                                                {item.subNav && item.subNav.map((subnavItem,subnavIndex)=>{
                                                    if((subnavItem.link && !subnavItem.link.startsWith('/team2')) || (subnavItem.link && subnavItem.link.startsWith('/team2') && getLocalStorageValue('adminPanelLoginData')  && getLocalStorageValue('adminPanelLoginData').teamname.includes('2'))){
                                                        return <div key={subnavIndex} style={{display:'flex',width:'96%',fontSize:'14px',paddingTop:'10px',paddingLeft:'15px',justifyContent:'space-between',alignItems:'center'}}>
                                                                {subnavItem.title}
                                                                <Switch size='small' color='warning' checked={response.length>0 && response[selectedUser].access_dns_path.includes(subnavItem.link)} onChange={()=>{var tempResponse = response;if(tempResponse[selectedUser]['access_dns_path'].includes(subnavItem.link)){tempResponse[selectedUser]['access_dns_path'] = tempResponse[selectedUser]['access_dns_path'].filter(e => e!==subnavItem.link)}else{tempResponse[selectedUser]['access_dns_path'].push(subnavItem.link)}; setResponse(tempResponse);refreshComponent(123);}}/>
                                                            </div>
                                                    }
                                                })}
                                        </div>
                                        
                                    })}
                                </div>
                            </div>
                        </div>
                        <div style={{position:'sticky',bottom:'20px',height:'50px',background:'white'}}>
                            
                            <Button sx={{...Constants.CONTAINEDBUTTON,borderRadius:'0px',minWidth:'80px',minHeight:'40px',transform:'scale(0.9)',marginLeft:'15px'}} onClick={setUserPermissions}> 
                                {saveButtonLoader && <CircularProgress size={'16px'} sx={{color:'white'}}/>}
                                {!saveButtonLoader && 'Save'} 
                            </Button>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export default AccountPermissionsPage