import { Button, IconButton, Switch, Tooltip } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ErrorComponent from '../../components/error/error'
import { ThreeDots } from  'react-loader-spinner'
import { capitalize, getLocalStorageValue, sortingV2, useForceUpdate } from '../../helper/util'
import { ArrowRight, CopyAllOutlined } from '@mui/icons-material'
import Zoom from '@mui/material/Zoom';
import CompareWithAppFlow from './compareWithAppFlow'
import * as Constants from '../../data/constants';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import TableModel from '../../components/tableModel/tablemodel'
import ReactSelect from 'react-select';
import RevenueData from './revenueData'

const DebugFileData = (props) => {
    const refreshComponent = useForceUpdate();
    const [showDaysData, setShowDaysData] = useState(false)
    const [showRanges, setShowRanges] = useState(false)
    const [response, setResponse] = useState([])
    const [responseBackup, setResponseBackup] = useState([])
    const [responseSerial, setResponseSerial] = useState(false)
    const [sortingOrder, setSortingOrder] = useState(false)
    const [uniqueEvents, setUniqueEvents] = useState(false)
    const [showCompareWithAppFlow, setShowCompareWithAppFlow] = useState(false)
    const [eventValuePopup, setEventValuePopup] = useState(false)
    const [showInDifferentTablesData, setShowInDifferentTablesData] = useState({})
    const [showAppTreeDiagram, setShowAppTreeDiagram] = useState(false)
    const [showRevenueDataPopup, setShowRevenueDataPopup] = useState(false)
    const showInDifferentTables = props.showInDifferentTables
    const setShowInDifferentTables = props.setShowInDifferentTables
    // const shareReportPopup = props.shareReportPopup
    const setShareReportPopup = props.setShareReportPopup
    
    var showRangesButtonView = false;
    const average = array => array.reduce((a, b) => a + b) / array.length;

    var availableTrackers = []
    if(props.fileData && props.fileData.response && props.fileData.response.events_randomness){
        var debugFileData = props.fileData.response.events_randomness
        var tempResponse = []
        for(var eventName in debugFileData){
            var eventData = debugFileData[eventName]
            if(!availableTrackers.includes(eventData.tracker)){
                availableTrackers.push(eventData.tracker)
            }
            var updatedData = {}
            var keyList = ['event_count_per_user','time_from_install','install','open','total','open_day_wise']
            for(var keyIndex in keyList){
                var key = keyList[keyIndex]

                if(['install','open','total'].includes(key)){
                    var typeList = ['unique','overall']
                    for(var typeIndex in typeList){
                        var type = typeList[typeIndex]
                        updatedData[key+'_'+type] = eventData[key][type]
                        if(eventData[key+'_range']){
                            showRangesButtonView = true;
                            updatedData['avg_'+key+'_'+type] = average(eventData[key+'_range'][type]).toFixed(2)
                            updatedData['min_'+key+'_'+type] = Math.min(...eventData[key+'_range'][type]).toFixed(2)
                            updatedData['max_'+key+'_'+type] = Math.max(...eventData[key+'_range'][type]).toFixed(2)
                        }
                    }
                }
                else if(key === 'open_day_wise'){
                    var typeList = ['unique','overall']
                    for(var typeIndex in typeList){
                        var type = typeList[typeIndex]
                        for(var dayIndex in eventData[key][type]){
                            var day = 'day'+(parseInt(dayIndex)+1)
                            updatedData[day+'_'+type] = eventData[key][type][dayIndex]
                            if(eventData[key+'_range']){
                                showRangesButtonView = true;
                                updatedData['avg_'+day+'_'+type] = average(eventData[key+'_range'][type][dayIndex]).toFixed(2)
                                updatedData['min_'+day+'_'+type] = Math.min(...eventData[key+'_range'][type][dayIndex]).toFixed(2)
                                updatedData['max_'+day+'_'+type] = Math.max(...eventData[key+'_range'][type][dayIndex]).toFixed(2)
                            }
                        }
                    }
                }
                else{
                    updatedData['avg_'+key] = average(eventData[key]).toFixed(2)
                    updatedData['min_'+key] = Math.min(...eventData[key]).toFixed(2)
                    updatedData['max_'+key] = Math.max(...eventData[key]).toFixed(2)
                }

            }
            tempResponse.push(
                {
                    ...debugFileData[eventName],
                    ...updatedData,
                    event_name:debugFileData[eventName].event_name,
                }
            )
        }
        if(responseSerial!==props.fileData.serial){
            setResponse(tempResponse)
            setResponseBackup(tempResponse)
            setResponseSerial(props.fileData.serial)
            setShowDaysData(false);
            setShowRanges(false);
            setSortingOrder(false);
            setUniqueEvents(false);
        }
    }
    const [selectedTracker, setSelectedTracker] = useState(availableTrackers)

    useEffect(() => {
        document.addEventListener('click',e=>{
            e.stopPropagation();
            if(document.getElementById('threeDotMenuDebug')){
                document.getElementById('threeDotMenuDebug').style.animation = 'threeDotClosed 0.5s forwards';
            }
        })
        document.addEventListener('keydown',e=>{
            if(e.key === 'Escape'){
                setEventValuePopup(false);
            }
        })
        return () => {
            document.removeEventListener('click',e=>{
                e.stopPropagation();
                if(document.getElementById('threeDotMenuDebug')){
                    document.getElementById('threeDotMenuDebug').style.animation = 'threeDotClosed 0.5s forwards';
                }
            })
            document.removeEventListener('keydown',e=>{
                if(e.key === 'Escape'){
                    setEventValuePopup(false);
                }
            })
      }
    }, [setEventValuePopup])
    
    const [showTooltip,setShowToolTip] = React.useState(false);

    const handleCopyContent = (value) => {
        navigator.clipboard.writeText(value)
        setShowToolTip(true)
    }

    const eventValuesFunction = () => {
        if(eventValuePopup.event_values && eventValuePopup.event_values.length>0){
            return <div style={{maxWidth:'70vw',wordBreak:'break-all',minHeight:'30vh',maxHeight:'60vh',overflow:'auto'}}>
                {eventValuePopup.event_values.map((item,index)=>{
                    return <div style={{fontSize:'14px',marginBottom:'20px'}}>
                                <div style={{display:'flex',justifyContent:'flex-start',width:'100%',marginBottom:'10px'}} onClick={()=>{var tempeventValuePopup = eventValuePopup; if(!tempeventValuePopup['eventValuesOpened']){tempeventValuePopup['eventValuesOpened'] = []}; if(tempeventValuePopup['eventValuesOpened'].includes(index)){var tempList = tempeventValuePopup['eventValuesOpened']; tempList = tempList.filter(e=>e!==index);tempeventValuePopup['eventValuesOpened'] = tempList;}else{tempeventValuePopup['eventValuesOpened'].push(index);};setEventValuePopup(tempeventValuePopup);refreshComponent(123);}}>
                                    <div style={{wordBreak:'normal',minWidth:'150px',fontWeight:'500'}}>Event Value String:</div>
                                    <div style={{cursor:'pointer'}}>{item}</div>
                                </div>
                                {eventValuePopup.eventValuesOpened &&
                                eventValuePopup.eventValuesOpened.includes(index) &&
                                <div>
                                    <div style={{fontWeight:'500'}}>Event Value Dict</div>
                                    <div>
                                        <table style={{tableLayout:'fixed',width:'100%',borderSpacing:'0px'}}>
                                            <thead>
                                                <tr>
                                                    <td width={'30%'} style={{minWidth:'30%',fontWeight:'500',padding:'5px',border:'1px solid rgb(220,220,220)'}}>Key</td>
                                                    <td width={'70%'} style={{minWidth:'70%',fontWeight:'500',padding:'5px',border:'1px solid rgb(220,220,220)'}}>Value</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {Object.keys(JSON.parse(item)).map((keyItem,keyIndex)=>{
                                                    return <tr>
                                                        <td style={{fontSize:"12px",padding:'2px',border:'1px solid rgb(220,220,220)'}}>{keyItem}</td>
                                                        <td style={{fontSize:"12px",padding:'2px',border:'1px solid rgb(220,220,220)'}}>{JSON.stringify(JSON.parse(item)[keyItem])}</td>
                                                    </tr>
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div style={{borderTop:'1px solid orange',marginTop:'30px'}}></div>
                                </div>
                                }
                            </div>
                })}
                
            </div>

        }
        else{
            return <div>
                No Event Values for {eventValuePopup.event_name}
            </div>
        }
    }

    const appDataErrorFunction = () => {
        if(eventValuePopup.app_data_error && eventValuePopup.app_data_error.length>0){
            return <div style={{maxWidth:'70vw',wordBreak:'break-all',height:'60vh',overflow:'auto'}}>
                {eventValuePopup.app_data_error.map((item,index)=>{
                    return <div style={{fontSize:'14px',marginBottom:'20px'}}>
                                <div style={{display:'flex',justifyContent:'space-between',width:'100%',marginBottom:'10px'}} onClick={()=>{var tempeventValuePopup = eventValuePopup; if(!tempeventValuePopup['eventValuesOpened']){tempeventValuePopup['eventValuesOpened'] = []}; if(tempeventValuePopup['eventValuesOpened'].includes(index)){var tempList = tempeventValuePopup['eventValuesOpened']; tempList = tempList.filter(e=>e!==index);tempeventValuePopup['eventValuesOpened'] = tempList;}else{tempeventValuePopup['eventValuesOpened'].push(index);};setEventValuePopup(tempeventValuePopup);refreshComponent(123);}}>
                                    <div style={{wordBreak:'normal',minWidth:'130px',fontWeight:'500'}}>AppData String:</div>
                                    <div style={{cursor:'pointer'}}>{JSON.stringify(item)}</div>
                                </div>
                                {eventValuePopup.eventValuesOpened &&
                                eventValuePopup.eventValuesOpened.includes(index) &&
                                <div>
                                    <div style={{fontWeight:'500'}}>AppData Dict</div>
                                    <div>
                                        <table style={{tableLayout:'fixed',width:'100%',borderSpacing:'0px'}}>
                                            <thead>
                                                <tr>
                                                    <td width={'30%'} style={{minWidth:'30%',fontWeight:'500',padding:'5px',border:'1px solid rgb(220,220,220)'}}>Key</td>
                                                    <td width={'70%'} style={{minWidth:'70%',fontWeight:'500',padding:'5px',border:'1px solid rgb(220,220,220)'}}>Value</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {Object.keys(item).map((keyItem,keyIndex)=>{
                                                    return <tr key={keyIndex}>
                                                        <td style={{fontSize:"12px",padding:'2px',border:'1px solid rgb(220,220,220)'}}>{keyItem}</td>
                                                        <td style={{fontSize:"12px",padding:'2px',border:'1px solid rgb(220,220,220)'}}>{JSON.stringify(item[keyItem])}</td>
                                                    </tr>
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div style={{borderTop:'1px solid orange',marginTop:'30px'}}></div>
                                </div>
                                }
                            </div>
                })}
                
            </div>

        }
        else{
            return <div>
                No Event Values for {eventValuePopup.event_name}
            </div>
        }
    }
    const showInDifferentTablesFunction = e => {
        var tempTable = {}
        if(e.target.checked){
            for(var index in response){
                var item = response[index]
                if(!tempTable[item.tracker]){
                    tempTable[item.tracker] = []
                }
                tempTable[item.tracker].push(item)
            }
        }
        setShowInDifferentTablesData(tempTable)
    }
    const showInstallToEventTime = (item) => {
            if((item.time_from_install[0]+item.time_from_install[1])/2 === -1){
                return 'Not in Install'
            }
            if(showRanges){
                return item.time_from_install[0] + ' - ' + item.time_from_install[1]
            }
            else{
                return (item.time_from_install[0]+item.time_from_install[1])/2
            }
    }

    const showEventCountPerUser = (item) => {
        if(showRanges){
            return item.event_count_per_user[0] + ' - ' + item.event_count_per_user[1]
        }
        else{
            return (item.event_count_per_user[0]+item.event_count_per_user[1])/2
        }
    }

    const getChild = (keyList,maxWidth) => {
        if(showRanges){
            return {child:keyList} 
        }
        else{
            return {maxWidth}
        }
    }
    const eventValueComponent = (item,customStates) => {

        return <div style={{fontSize:'12px',color:'orange',cursor:'pointer',padding:'5px'}} onClick={()=>{customStates.setEventValuePopup(item)}}>
                    <Tooltip title={'Unique event values.'}> 
                        <div>
                            Show Values{'('+item.event_values.length+")"}
                        </div>
                    </Tooltip>
                </div>
    }
    const tableHeaders = [
        {label:'Event Name',value:'event_name',width:150,locked:true,showTooltip:true},
        {label:'Event Value',value:'event_value',width:110,customComponent:eventValueComponent,customStates:{eventValuePopup,setEventValuePopup}},
        {label:'Tracker',value:'tracker',style:{textTransform:'capitalize'},width:90},
        {label:'Source',value:'source',style:{textTransform:'uppercase'},width:90},
        {label:'Event Count',value:'avg_event_count_per_user',width:150,
            ...getChild([
                {label:'Min',value:'min_event_count_per_user',width:33},
                {label:'Max',value:'max_event_count_per_user',width:33},
                {label:'Avg',value:'avg_event_count_per_user',width:33},
            ],100) 
        },
        {label:'I2E',value:'avg_time_from_install',width:150,
            ...getChild([
                {label:'Min',value:'min_time_from_install',width:33},
                {label:'Max',value:'max_time_from_install',width:33},
                {label:'Avg',value:'avg_time_from_install',width:33},
            ],90)    
        },
        {label:'Total',value:'total_overall',width:150,
            ...getChild([
                {label:'Min',value:'min_total_overall',width:33},
                {label:'Max',value:'max_total_overall',width:33},
                {label:'Avg',value:'avg_total_overall',width:33},
            ],90)
        },
        {label:'Install',value:'install_overall',width:150,
            ...getChild([
                {label:'Min',value:'min_install_overall',width:33},
                {label:'Max',value:'max_install_overall',width:33},
                {label:'Avg',value:'avg_install_overall',width:33},
            ],90)
        },
        {label:'Open',value:'open_overall',width:150,
            ...getChild([
                {label:'Min',value:'min_open_overall',width:33},
                {label:'Max',value:'max_open_overall',width:33},
                {label:'Avg',value:'avg_open_overall',width:33},
            ],90)
        },
    ]

    if(props.fileData && props.fileData.response && props.fileData.response.open && showDaysData){
        for(var i=1; i<=parseInt(props.fileData.response.open);i++){
            tableHeaders.push(
                {label:'Day '+i,value:'day'+i+'_overall',width:150,
                ...getChild([
                    {label:'Min',value:'min_day'+i+'_overall',width:33},
                    {label:'Max',value:'max_day'+i+'_overall',width:33},
                    {label:'Avg',value:'avg_day'+i+'_overall',width:33},
                ],80)
                }
            )
        }
    }

    const map = props.fileData &&  props.fileData.response ?  props.fileData.response.events_randomness : {}
    const testjson = props.fileData && props.fileData.response ? props.fileData.response.user_flow : {}

    const treeStruct = {}
    const responseToTree = (treeStruct,response,eventId,traversedList = []) => {
        var eventDict = {}
        if(response[eventId]){
            eventDict['name'] = eventId
            eventDict['children'] = []
            if(!traversedList.includes(eventId)){
                traversedList.push(eventId)
                if(response[eventId].length>0){
                    for(var index in response[eventId]){
                        var item = response[eventId][index]
                        eventDict['children'].push(responseToTree(eventDict,response,item,traversedList))
                    }
                }
            }
        }
        else{
            eventDict['name'] = map[eventId] ? map[eventId].event_name : eventId
            eventDict['children'] = []
        }
        return eventDict
    }
    function getRandomColor() {
        var letters = 'BCDEF'.split('');
        var color = '#';
        for (var i = 0; i < 6; i++ ) {
            color += letters[Math.floor(Math.random() * letters.length)];
        }
        return color;
    }
    var traversedEvent = []
    const [hiddenTree, setHiddenTree] = useState([])
    const showTree = (list_of_dict,traversedEvent=[]) => {
        return list_of_dict.map((item,index)=>{
            if(!traversedEvent.includes(item.name)){
                    
                if(selectedTracker.length==0 || !map[item.name] || (selectedTracker.includes(map[item.name].tracker))){
                    // traversedEvent.push(item.name)
                    return <div key={index} style={{paddingLeft:'50px',width:'max-content',position:'relative',borderLeft:'1px solid rgb(200,200,200)'}}>
                        
                        <div style={{padding:'10px',borderLeft:'1px solid rgb(220,220,220)',display:'flex',alignItems:'center'}}>
                            {item.children && item.children.length>0 && 
                                <Tooltip title='Close List'>
                                    <ArrowRight sx={{transform:hiddenTree.includes(item.name) ? '' : 'rotate(90deg)',transition:'all 0.2s ease-in-out'}} onClick={()=>{if(hiddenTree.includes(item.name)){setHiddenTree(hiddenTree.filter(e=>e!==item.name))}else{setHiddenTree([...hiddenTree,item.name])}}}/>        
                                </Tooltip>
                            }
                            <div style={{cursor:'pointer',display:'flex',alignItems:'center',paddingLeft:(item.children && item.children.length>0 ? '0px' : '10px')}}>
                                {map[item.name] ? map[item.name].event_name : item.name === '#AUTOLABS_APP_LAUNCHED_D0#'? '#### APP INSTALLED / APP OPENED ####' : item.name}
                                <div style={{paddingLeft:'10px',textTransform:'capitalize'}}>{map[item.name] ? '   ('+map[item.name].tracker + (map[item.name].source ? ' - ' +  map[item.name].source.toUpperCase() : '') +')' : ''}</div>
                            </div>
                        </div>
                        {!hiddenTree.includes(item.name) && item.children && showTree(item.children,traversedEvent)}
                    </div>
                }
                else{
                    if(!hiddenTree.includes(item.name) && item.children ){
                        return showTree(item.children,traversedEvent)
                    }
                }
            }
        })
    }
    return (
        <div style={{overflow:'auto',height:'calc(100vh - 40px - 90px)'}}>
            <div style={{position:'sticky',left:0,width:'100%'}}>
                <div style={{display:"flex",flexWrap:'wrap',alignItems:'center',justifyContent:'space-between'}}>
                    {props.fileData &&
                        <div style={{padding:'20px',fontSize:'13px'}}>
                            <label style={{fontWeight:'500'}}>Filename : </label>
                            <label>{ props.fileData.filename}</label>
                        </div>
                    }
                    {props.fileData && props.fileData.response && props.fileData.response.generated_by &&
                        <div style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                            <div style={{paddingLeft:'20px',fontSize:'13px'}}>
                                <label style={{fontWeight:'500'}}>Generated By : </label>
                                <label>{props.fileData.response.generated_by.includes(',') ? props.fileData.response.generated_by.split(',')[0] : props.fileData.response.generated_by }</label>
                            </div>
                            <div style={{marginLeft:'20px',marginRight:'20px',position:'relative'}}>
                                <IconButton onClick={(e)=>{e.stopPropagation();document.getElementById('threeDotMenuDebug').style.animation = 'threeDotOpened 0.3s forwards';}}>
                                    <MoreVertIcon/>
                                </IconButton>
                                
                                <div id='threeDotMenuDebug' className='threeDotMenuDebug'>
                                    <div className='threeDotMenuDebugItem' onClick={e=>{e.stopPropagation();setShareReportPopup(true);}}>Share Report</div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
                {props.fileData &&
                    <div style={{paddingLeft:'20px',paddingBottom:'20px',fontSize:'13px'}}>
                        <label style={{fontWeight:'500'}}>Requested At : </label>
                        <label>{props.fileData && props.fileData.created_at ?  new Date(props.fileData.created_at).toLocaleDateString("en-US", { year: 'numeric', month: 'short', day: 'numeric' ,hour:'numeric',minute:'numeric',second:'numeric'}) : ''}</label>
                    </div>
                }
                {props.fileData && props.fileData.response && props.fileData.response.install &&
                    <div style={{paddingLeft:'20px',paddingBottom:'10px',fontSize:'13px'}}>
                        <label style={{fontWeight:'500'}}>I1 Count : </label>
                        <label>{ props.fileData.response.install }</label>
                    </div>
                }
                
                {props.loader &&
                <div style={{marginTop:'30px',marginLeft:'30px'}}>
                    <ThreeDots 
                        height="20" 
                        width="40" 
                        radius="9"
                        color="orange" 
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                    />
                </div>
                }
                <div style={{display:'flex',alignItems:'flex-start',justifyContent:'space-between',flexWrap:'wrap'}}>

                    {props.fileData && props.fileData.response && props.fileData.response.open &&
                        <div style={{paddingLeft:'20px',paddingTop:'10px',fontSize:'13px'}}>
                            <label style={{fontWeight:'500'}}>Retention Days : </label>
                            <label>{props.fileData.response.open }</label>
                        </div>
                    }
                    {props.fileData && props.fileData.response && props.fileData.response.events_randomness && !props.fileData.response.error &&
                        <div style={{position:'relative',display:'flex',alignItems:'center',justifyContent:'flex-end',width:'70%',flexWrap:'wrap'}}>
                                    
                            {props.fileData && props.fileData.response && props.fileData.response.trello_report &&
                                <div style={{paddingLeft:'15px',fontSize:'13px',display:'flex',alignItems:'center'}}>
                                    <label style={{fontWeight:'500'}}>Trello Report </label>
                                    <div style={{marginRight:'10px'}}>
                                        <IconButton onClick={()=>{handleCopyContent(props.fileData.response.trello_report)}} onMouseLeave={()=>{setShowToolTip(false);}}>
                                            <Tooltip  TransitionComponent={Zoom} title={showTooltip ? 'Copied' : 'Copy'}>
                                                <CopyAllOutlined sx={{fontSize:'15px'}}/>
                                            </Tooltip>
                                        </IconButton>
                                    </div>
                                </div>
                            }
                            {
                                debugFileData[Object.keys(debugFileData)[0]] && debugFileData[Object.keys(debugFileData)[0]].open_day_wise && debugFileData[Object.keys(debugFileData)[0]].open_day_wise.unique &&
                                <div style={{paddingLeft:'15px',fontSize:'13px',display:'flex',alignItems:'center',justifyContent:'flex-end'}}>
                                    <label style={{fontWeight:'500'}}>Show Unique Events </label>
                                    <div style={{transform:'scale(0.8)'}}><Switch color='warning' checked={uniqueEvents} onChange={(e)=>{setUniqueEvents(e.target.checked);setResponse(responseBackup);refreshComponent(123);}}/></div>
                                </div>
                            }
                            <div style={{paddingLeft:'15px',fontSize:'13px',display:'flex',alignItems:'center',justifyContent:'flex-end'}}>
                                <label style={{fontWeight:'500'}}>Show in Different Tables </label>
                                <div style={{transform:'scale(0.8)'}}><Switch color='warning' checked={showInDifferentTables} onChange={(e)=>{showInDifferentTablesFunction(e);setShowInDifferentTables(e.target.checked);refreshComponent(123);}}/></div>
                            </div>
                            <div style={{paddingLeft:'15px',fontSize:'13px',display:'flex',alignItems:'center',justifyContent:'flex-end'}}>
                                <label style={{fontWeight:'500'}}>Show Days </label>
                                <div style={{transform:'scale(0.8)'}}><Switch color='warning' checked={showDaysData} onChange={(e)=>{setShowDaysData(e.target.checked);refreshComponent(123);}}/></div>
                            </div>
                            {
                                showRangesButtonView &&
                                <div style={{paddingLeft:'15px',fontSize:'13px',display:'flex',alignItems:'center',justifyContent:'flex-end'}}>
                                    <label style={{fontWeight:'500'}}>Show Ranges </label>
                                    <div style={{transform:'scale(0.8)'}}><Switch color='warning' checked={showRanges} onChange={(e)=>{setShowRanges(e.target.checked);refreshComponent(123);}}/></div>
                                </div>
                            }
                        </div>
                    }
                </div>
                {!props.loader && debugFileData &&
                    <div style={{paddingLeft:'20px',paddingTop:'5px',paddingBottom:'10px',transform:'scale(0.8)',transformOrigin:'left'}}>
                        <Button sx={{...Constants.CONTAINEDBUTTON,fontSize:'14px',marginRight:'20px',textTransform:'none'}} size='small' onClick={()=>{props.performAnotherDebug(true);props.performAnotherDebugReportNo(props.fileData.serial);}}>
                            Perform Another Debug
                        </Button>
                        <Button sx={{...Constants.CONTAINEDBUTTON,fontSize:'14px',marginRight:'20px',textTransform:'none'}} size='small' onClick={()=>{setShowCompareWithAppFlow(true);}}>
                            Compare With App Flow
                        </Button>
                        {props.fileData && props.fileData.response && props.fileData.response.debug_revenue
                        &&
                        <Button sx={{...Constants.CONTAINEDBUTTON,fontSize:'14px',textTransform:'none'}} size='small' onClick={()=>{setShowRevenueDataPopup(true);}}>
                            Revenue Data
                        </Button>
                        }
                        {
                            props.fileData && props.fileData.response && props.fileData.response.user_flow
                            &&
                            getLocalStorageValue('adminPanelLoginData').allowedPages.includes('can_get_debug_tree')
                            &&
                            <Button sx={{...Constants.CONTAINEDBUTTON,marginLeft:'10px',fontSize:'14px',textTransform:'none'}} size='small' onClick={()=>{setShowAppTreeDiagram(!showAppTreeDiagram);}}>
                                {showAppTreeDiagram ? 'Show in Table Form' : 'Show in tree Diagram'}
                            </Button>
                        }
                        {props.fileData.response && props.fileData.response.app_data_error && props.fileData.response.app_data_error.length>0
                        &&
                        <Button sx={{...Constants.CONTAINEDBUTTON,fontSize:'14px',textTransform:'none'}} size='small' onClick={()=>{setEventValuePopup({app_data_error:props.fileData.response.app_data_error});}}>
                            Show App Data Error
                        </Button>
                        }
                    </div>
                }
                {
                props.fileData && props.fileData.response && props.fileData.response.events_randomness && Object.keys(props.fileData.response.events_randomness).length === 0 
                    &&
                    <div style={{paddingLeft:'5%',paddingTop:'3%',fontSize:'14px'}}>
                        No data for this script.
                    </div>
                }
                {props.fileData && props.fileData.response && Object.keys(props.fileData.response).length===0 &&
                    <div style={{paddingLeft:'20px',paddingRight:'10px',fontSize:'13px'}}>
                        <label style={{fontWeight:'500'}}>Response : </label>
                        <label style={{color:'red'}}>Unable to track appflow.</label>
                    </div>
                }
                {props.fileData && props.fileData.response && props.fileData.response.error &&
                    <ErrorComponent data = {props.fileData.response.error}/>
                    }
            </div>
            {
                props.fileData && props.fileData.response 
                &&
                showAppTreeDiagram
                &&
                <div>
                    <div style={{marginLeft:'20px',width:'300px'}}>
                        <ReactSelect
                            isMulti
                            placeholder={'Select Tracker or CRM'}
                            value={selectedTracker.length>0 ? selectedTracker.map((item)=>{return {label:capitalize(item),value:item}}) : false}
                            options={availableTrackers.map((item,index)=>{return {label:capitalize(item),value:item}})}
                            onChange={(e)=>{setSelectedTracker(e.map((item)=>{return item.value;}))}}
                            menuPortalTarget={document.body} 
                            styles={{...Constants.ReactSelectStyles}}
                        />
                    </div>
                    {showTree([responseToTree(treeStruct,testjson,"#AUTOLABS_APP_LAUNCHED_D0#")],traversedEvent)}
                </div>
            }
            {   
                eventValuePopup
                &&
                eventValuePopup.event_values
                &&
                eventValuePopup.event_values.length>0
                &&
                <div style={{position:'fixed',top:0,left:0,width:'100vw',height:'100vh',backdropFilter:'blur(2px)',zIndex:11}} onClick={(e)=>{e.stopPropagation();setEventValuePopup(false);}}>
                    <div style={{display:"flex",justifyContent:'center'}}>
                        <div style={{minHeight:'20vh',minWidth:'50vw',maxWidth:'70vw',background:'white',border:'1px solid rgb(200,200,200)',boxShadow:'0 0 5px 1px rgb(200,200,200)',marginTop:'15vh'}} onClick={(e)=>{e.stopPropagation();}}>
                            <div style={{display:'flex',height:'30px',alignItems:'center',justifyContent:'space-between',paddingTop:'15px',paddingBottom:'15px',paddingLeft:'20px',paddingRight:'20px',fontWeight:'500'}}>
                                <div>Event Values for {eventValuePopup.event_name}</div>
                                <div style={{cursor:'pointer'}} onClick={()=>{setEventValuePopup(false);}}>X</div>
                            </div>
                            <div style={{paddingTop:'10px',paddingBottom:'15px',paddingLeft:'25px',paddingRight:'25px'}}>
                                {eventValuesFunction()}
                            </div>
                        </div>
                    </div>
                </div>
            }
            {!showAppTreeDiagram && debugFileData && !showInDifferentTables && Object.keys(debugFileData).length>0 &&
                <TableModel
                    tableHeaders={tableHeaders}
                    tableValues={response}
                />
            }
            {debugFileData && showInDifferentTables && Object.keys(showInDifferentTablesData).length>0 &&
                Object.keys(showInDifferentTablesData).map((trackerName,trackerIndex)=>{
                    return <TableModel
                        tableHeaders={tableHeaders}
                        tableValues={showInDifferentTablesData[trackerName]}
                    />
                })
            }
            {
                showCompareWithAppFlow &&
                <CompareWithAppFlow setShowCompareWithAppFlow={setShowCompareWithAppFlow} fileData={response}/>
            }

            {
                showRevenueDataPopup
                &&
                <RevenueData setShowRevenueDataPopup={setShowRevenueDataPopup} debugData={props.fileData.response} totalUsers={props.fileData.response.install} data={props.fileData.response.debug_revenue}/>
            }
        </div>
    )
}

export default DebugFileData