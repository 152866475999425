import React from 'react';
import './filterComponent.css';
import { getThisWeek,lastThisWeek, thisMonth, lastMonth } from '../../components/dateRangePicker/dateRangePicker'
import { Tooltip } from '@mui/material';

const FilterRow = ({filterData,setFilterData,filterList}) => {

    const getLabel = (startDate,endDate) => {
        var startDate = new Date(startDate).toLocaleString('en-GB',{year:'2-digit',month:'short',day:'numeric'})
        var endDate = new Date(endDate).toLocaleString('en-GB',{year:'2-digit',month:'short',day:'numeric'})
        var today = new Date().toLocaleString('en-GB',{year:'2-digit',month:'short',day:'numeric'})
        var yesterday = new Date(new Date().getTime()-86400000*1).toLocaleString('en-GB',{year:'2-digit',month:'short',day:'numeric'})
        var {firstDayOfThisWeek,lastDayOfThisWeek} = getThisWeek('en-GB',{year:'2-digit',month:'short',day:'numeric'})
        var {firstDayOfLastWeek,lastDayOfLastWeek} = lastThisWeek('en-GB',{year:'2-digit',month:'short',day:'numeric'})
        var {firstDayOfThisMonth,lastDayOfThisMonth} = thisMonth('en-GB',{year:'2-digit',month:'short',day:'numeric'})
        var {firstDayOfLastMonth,lastDayOfLastMonth} = lastMonth('en-GB',{year:'2-digit',month:'short',day:'numeric'})
        if(startDate===endDate){
            if(today === startDate){
                return 'Today'
            }
            if(yesterday === startDate){
                return 'Yesterday'
            }
        }
        if(firstDayOfThisWeek===startDate && lastDayOfThisWeek===endDate){
            return 'This Week'
        }
        if(firstDayOfLastWeek===startDate && lastDayOfLastWeek===endDate){
            return 'Last Week'
        }
        if(firstDayOfThisMonth===startDate && lastDayOfThisMonth===endDate){
            return 'This Month'
        }
        if(firstDayOfLastMonth===startDate && lastDayOfLastMonth===endDate){
            return 'Last Month'
        }
        return startDate + ' -> ' + endDate
    }   
    return (
        <div style={{display:'flex'}}>
            {filterList.map((item,index)=>{
                var filterValue = false
                if(item.type==='daterange' && filterData[item.value+'_from_date'] && filterData[item.value+'_end_date']){
                    filterValue = getLabel(filterData[item.value+'_from_date'],filterData[item.value+'_end_date'])
                    var filterValueComponent = <div className='filterRowItem'>
                                                {filterValue}
                                            </div>
                }
                else if(item.type==='select'){
                    if(item.multiple && filterData[item.value] && filterData[item.value].length>0){
                        filterValue = ''
                        filterValueComponent = []
                        for(var filterDataIndex in filterData[item.value]){
                            var filterDataItem = filterData[item.value][filterDataIndex]
                            filterValueComponent.push(
                                <Tooltip title={filterDataItem.label}>
                                    <div className='filterRowItem' style={{borderRadius:'50%',marginRight:'2px',width:'10px',textAlign:'center',display:'flex',alignItems:'center',justifyContent:'center',height:'10px'}}>
                                        {filterDataItem.label[0]}
                                    </div>
                                </Tooltip>
                            )
                        }
                        if(filterValue.length>0){
                            filterValue = filterValue.slice(0,-1)
                        }
                    }
                }
                if(filterValueComponent){
                    return <div key={index} style={{display:'flex',alignItems:'center',marginRight:'10px'}}>
                            <div style={{fontSize:'12px',fontWeight:'500',marginRight:'3px'}}>{item.label}{' : '}</div>
                            {filterValueComponent}
                        </div>
                }
            })}
        </div>
    )
}

export default FilterRow