import React,{useState} from 'react';
import './dragDropFile.css';

export function DragDropFile({handleChangeMethod,fileSelected}) {
    const [dragActive, setDragActive] = useState(false);
    const inputRef = React.useRef(null);
    
    // handle drag events
    const handleDrag = function(e) {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true);
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
    };
    
    // triggers when file is dropped
    const handleDrop = function(e) {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            // handleFiles(e.dataTransfer.files);
            handleChangeMethod(e.dataTransfer.files[0])

        }
    };
    
    // triggers when file is selected with click
    const handleChange = function(e) {
        e.stopPropagation();
        e.preventDefault();
        if (e.target.files && e.target.files[0]) {
            handleChangeMethod(e.target.files[0])
        }
    };
    
  // triggers the input when the button is clicked
    const onButtonClick = (e) => {
        e.stopPropagation();
        document.getElementById('input-file-upload').click()
    };
    
    return (
        <form id="form-file-upload" onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}>
            <input type="file" id="input-file-upload" multiple={true} onChange={handleChange} />
            <div htmlFor="input-file-upload" className={dragActive ? "drag-active" : "" }>
            
            {!fileSelected
                &&
                <div onClick={onButtonClick}>
                    <p>Drag and drop your script here
                        <br/>
                        <br/>
                        --- or ---
                        <br/>
                        <br/>
                    Click here to select script.</p>
                </div> 
            }
            {
                fileSelected
                &&
                <div style={{minHeight:'100px',display:'flex',alignItems:'center',justifyContent:'center'}}>
                    {fileSelected.name}
                </div>
            }
            </div>
            { dragActive && <div id="drag-file-element" onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}>
                
            </div> }
        </form>
    );
  };