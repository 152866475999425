import React, { useState, useEffect } from 'react';
import './login.css';
import loginImage from '../../img/login.avif';
import companyLogo from '../../img/AppanalyticsLogo.png';
import { CssTextField, capitalize, getLocalStorageValue, setLocalStorageValue } from '../../helper/util';
import axios from 'axios';
import * as Constants from '../../data/constants';
import { useNavigate } from "react-router-dom";
import {useRive, useStateMachineInput} from 'rive-react';
import { CircularProgress } from '@mui/material';

const STATE_MACHINE_NAME = "State Machine 1";

const AuthorizedLogin = (props) => {
    const [credentials, setCredentials] = useState(getLocalStorageValue('keepLoggedIn') ? getLocalStorageValue('keepLoggedIn') : {});
    
    const [user, setUser] = useState(null);
    const [error, setError] = useState(null);
    const [loader, setLoader] = useState(false);

    const {rive, RiveComponent} = useRive({
        src: "login_screen_character.riv",
        autoplay: true,
        stateMachines: STATE_MACHINE_NAME
    })


    useEffect(() => {
        setLook();
    }, [user])

    const stateSuccess = useStateMachineInput(
        rive,
        STATE_MACHINE_NAME,
        'success'
    )
    const stateFail = useStateMachineInput(
        rive,
        STATE_MACHINE_NAME,
        'fail'
    )
    const stateHandUp = useStateMachineInput(
        rive,
        STATE_MACHINE_NAME,
        'hands_up'
    )

    const stateCheck = useStateMachineInput(
        rive,
        STATE_MACHINE_NAME,
        'Check'
    )
    const stateLook = useStateMachineInput(
        rive,
        STATE_MACHINE_NAME,
        'Look'
    )

    const triggerSuccess = () => {
        stateSuccess && stateSuccess.fire();
    }
    const triggerFail = () => {
        stateFail && stateFail.fire();
    }

    const setHangUp = (hangUp) => {
        stateHandUp && (stateHandUp.value = hangUp);
    }

    const setLook = () => {
        if (!stateLook || !stateCheck || !setHangUp) {
            return;
        }
        setHangUp(false)
        setCheck(true);
        let nbChars = 0;
        if (user) {
            nbChars = parseFloat(user.split('').length);
        }

        let ratio = nbChars / parseFloat(41);

        let lookToSet = ratio * 100
        stateLook.value = Math.round(lookToSet);
    }
    const setCheck = (check) => {
        if (stateCheck) {
            stateCheck.value = check;
        }
    }

    const navigate = useNavigate();

    const getUserData = async(manualPage,query_=null) => {
        await axios({
            method: 'GET',
            url: "".concat(Constants.BACKENDHOST,"getusers"),
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token,
            },
        })
        .then(resp => {
            if(resp.data.response_code===200){
                var tempusers = []
                for(var index in resp.data.data){
                    var item = resp.data.data[index];
                    if(item['name']!==getLocalStorageValue('adminPanelLoginData').username){
                        tempusers.push({label:capitalize(item['name']),value:item['id']})
                    }
                }
                setLocalStorageValue('userData',tempusers)
            }
        })
        .catch(err => {
            console.error(err);
        })

    };
    const loginUser = async() => {
        setLoader(true)
        await axios({
            method: 'POST',
            url: "".concat(Constants.BACKENDHOST,'login'),
            headers: {
                'Content-Type':'application/json',
                'docket': 'username='+credentials.username+"&password="+credentials.password
            },
            data:{
                "name": credentials.username,
                "password": credentials.password,
                "g_token" : getLocalStorageValue('firebaseToken')
            }
        })
        .then(async(resp) => {
            if(resp.data.response_code===200){
                var response = resp.data
            }
            else{
                setError({data:resp.data.message,status:resp.data.response_code})
            }
            if (response){
                var allowedPages = response.data.access_dns_path
                
                var userDetails = {
                    username:credentials.username,
                    allowedPages,
                    token:response.data.token,
                    loginTime:new Date().getTime(),
                    ...response.data
                    
                }

                setLocalStorageValue('adminPanelLoginData',userDetails)
                await getUserData()
                localStorage.setItem('adminloginTime',new Date().getTime());
                var move_to = "/home"
                if (allowedPages.includes("/home")){
                    if (window.location.href.includes("login")){
                        move_to="/home"
                    }
                    else{
                        move_to=window.location.pathname+window.location.search
                    }
                }
                else{
                    move_to=allowedPages[0]
                }
                triggerSuccess();
                props.setLoginData(userDetails);
                navigate(move_to);
            }
            else{
                triggerFail()
            }
        })
        .catch(err=>{
            if(err.response && err.response.data){
                setError(err.response.data.message)
            }
        })
        setLoader(false)
    }

    if(getLocalStorageValue('keepLoggedIn')){
        loginUser()
    }
    
    return (
        <div className='loginContainer'>
            <div className='loginWrapper'>
                <div className='loginImageWrapper'>
                    <img src={loginImage} alt='' className='loginImage'/>
                </div>
                <div className='loginDataWrapper'>
                    <div className='loginDataLogo'>
                        <img src={companyLogo} className='companyLogo' alt=''/>
                    </div>

                    <div className='loginFormRiv'>
                        <RiveComponent style={{width:'300px', height:'300px'}} src="login_screen_character.riv"/>
                    </div>
                    <div className='loginDataLabel'>
                        Welcome Back,
                    </div>
                    <div className='loginDataLabelShadow'>
                        Please enter your credentials.
                    </div>
                    
                    <div className='loginFormWrapper'>
                        <CssTextField  onBlur={()=>{setHangUp(false);setCheck(false);}} onFocus={()=>{setHangUp(false);}} onChange={(e)=>{setCredentials({...credentials,username:e.target.value});setUser(e.target.value);}} name='uname' type='uname' id="username" label="Username" variant="standard" />
                        <br/>
                        <CssTextField onBlur={()=>{setHangUp(false);}} onFocus={()=>{setHangUp(true);}} onChange={(e)=>{setCredentials({...credentials,password:e.target.value})}} name='password' type='password' id="password" label="Password" variant="standard" />
                        <br/>
                        <div style={{color:'red',fontSize:'13px',height:'20px'}}>
                            {
                                loader
                                &&
                                <CircularProgress size={'19px'} sx={{color:'orange'}}/>
                            }
                            {error ? error : ''}
                        </div>
                        <br/>
                        <div>
                            <input id='keep_logged_in' type='checkbox' onChange={(e)=>{if(e.target.checked){setCredentials({...credentials,keepLoggedIn:true})}}}/>
                            <label htmlFor='keep_logged_in'>Keep me logged in.</label>
                        </div>
                        <br/>
                        <button onClick={()=>{if(!loader){loginUser()}}} className="button-48">
                            <span className="text">
                                Login
                            </span>
                        </button>
                    </div>
                    <br/>
                    <br/>
                    <br/>
                    <div className='loginCopyright'>
                        <p>&copy; Appsuccessor, 2023</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AuthorizedLogin