import { CircularProgress } from '@mui/material';
import React from 'react'
import './stepsComponent.css';

const StepsComponent = (props) => {
    if(props.steps)
    return (
        <div style={{width:'100%',position:'relative'}}>
            <div className='stepsComponentContainer'>
                {props.steps.map((item,index)=>{
                    return <div className='stepsComponentWrapper' style={{width: index!==0 ? 100/props.steps.length+1+"%" : '36px'}} key={index}>
                        <div style={{display:'flex',alignItems:'center',width:'100%'}}>
                            <div style={{borderTop:'3px solid '+(props.selectedStep === index ? 'orange' : props.selectedStep > index ? 'rgb(0,200,0)' : 'rgb(200,200,200)'),width:'100%'}}>
                            </div>
                            <div style={{cursor:(item.isClickable ? 'pointer' : 'not-allowed'),display:'flex',flexDirection:'column',alignItems:'center',minWidth:'30px',width:'30px',height:'30px',minHeight:'30px',borderRadius:'50%',border:'3px solid '+(props.selectedStep === index ? 'orange' : props.selectedStep > index ? 'rgb(0,200,0)' : 'rgb(200,200,200)'),position:'relative'}} onClick={()=>{if(item.isClickable){props.setSelectedStep(index)}}}>
                                <div style={{marginTop:'4px',marginBottom:'13px'}}>
                                    {props.selectedStep > index ? <item.doneIcon sx={{fontSize:'20px',color:'rgb(0,200,0)'}}/> : <item.notDoneIcon sx={{fontSize:'20px',color:(props.selectedStep === index ? 'orange' : 'rgb(200,200,200)')}}/>}
                                </div>
                                <div style={{textAlign:'center',fontSize:'14px'}}>
                                    {item.label}
                                </div>
                            </div>
                        </div>
                    </div>
                })}
            </div>
            {props.showLoader
            &&
            <div style={{minHeight:'30vh',display:'flex',justifyContent:'center',alignItems:'center'}}>
                <CircularProgress size={'40px'} sx={{color:'orange'}}/>
            </div>
            }
            {!props.showLoader && props.selectedStep<props.steps.length && props.steps[props.selectedStep].showPage}
            {!props.showLoader && props.selectedStep>=props.steps.length && props.steps[props.steps.length-1].showPage}
        </div>
    )
}

export default StepsComponent