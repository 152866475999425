import React, { useEffect, useState } from 'react'
import { CssTextField, useForceUpdate } from '../../helper/util';
import TableModelV2 from '../../components/tableModelV2/tablemodel';
import ReactSelect from 'react-select';
import * as Constants from '../../data/constants';


const RevenueData = (props) => {
    const refreshComponent = useForceUpdate()
    const [manualUseEffect, setManualUseEffect] = useState(false)
    const [tableValues, setTableValues] = useState([])
    const [payoutState, setPayoutState] = useState(0.1)
    const [eventSelected, setEventSelected] = useState(undefined)
    
    var eventList = []
    for(var key in props.debugData.events_randomness){
        var value2 = props.debugData.events_randomness[key]
        eventList.push({label:value2.event_name,value:key})
    }

    const tableHeaders = [
        {label:'Item',value:'item',locked:true},
        {label:'Total',value:'total_revenue'},
        {label:'Install',value:'install_revenue'},
        {label:'Day 1',value:'day1_revenue'},
        {label:'Day 2',value:'day2_revenue'},
        {label:'Day 3',value:'day3_revenue'},
        {label:'Day 4',value:'day4_revenue'},
        {label:'Day 5',value:'day5_revenue'},
        {label:'Day 6',value:'day6_revenue'},
        {label:'Day 7',value:'day7_revenue'},
        // {label:'Day 8',value:'day8_revenue'},
        // {label:'Day 9',value:'day9_revenue'},
        // {label:'Day 10',value:'day10_revenue'},
    ]
    var tableValuesFunction = (payout=null,eventSelectedManual=null) => {
        var payoutValue = parseFloat(payout ? payout : payoutState)
        var tablev = []
        
        var eventData = eventSelectedManual ? props.debugData.events_randomness[eventSelectedManual] : props.debugData.events_randomness[eventSelected]
        var item_list = ['Revenue From Script','ARPU','Max-OV','ROAS']
        if(eventData){
            item_list = ['Revenue From Script','ARPU',"AOV",'Max-OV','ROAS']
        }
        for(var index in item_list){
            if(eventData===undefined && item==='AOV'){
                
            }
            else{
                var item = item_list[index]
                var revenue_script_dict = {
                    'item':item,
                }
                var total_revenue = 0
                for(var i=0; i<=10; i++){
                    if(i===0){
                        revenue_script_dict['install_revenue'] = props.data['d'+i] ? props.data['d'+i].toFixed(2) : '-'
                        if(item==='ARPU' && revenue_script_dict['install_revenue']!=='-'){
                            revenue_script_dict['install_revenue'] = (revenue_script_dict['install_revenue']/props.totalUsers).toFixed(2)
                        }
                        if((item==='ROAS' || item==='ROI') && revenue_script_dict['install_revenue']!=='-'){
                            revenue_script_dict['install_revenue'] = (revenue_script_dict['install_revenue']*100/(props.totalUsers*payoutValue)).toFixed(2)+"%"
                        }
                        if((item==='AOV') && revenue_script_dict['install_revenue']!=='-'){
                            var users_purchase = eventData.install ? eventData.install.overall : 0
                            revenue_script_dict['install_revenue'] = (revenue_script_dict['install_revenue']/((props.totalUsers*users_purchase)/100)).toFixed(2)
                        }
                        if((item==='Max-OV')){
                            revenue_script_dict['install_revenue'] = props.data['d'+i] ? props.data['max_d'+i].toFixed(2) : '-'
                        }
                    }
                    else{
                        revenue_script_dict['day'+i+'_revenue'] = props.data['d'+i] ? props.data['d'+i].toFixed(2) : '-'
                        if(item==='ARPU' && revenue_script_dict['day'+i+'_revenue']!=='-'){
                            revenue_script_dict['day'+i+'_revenue'] = (revenue_script_dict['day'+i+'_revenue']/props.totalUsers).toFixed(2)
                        }
                        if((item==='ROAS' || item==='ROI') && revenue_script_dict['day'+i+'_revenue']!=='-'){
                            revenue_script_dict['day'+i+'_revenue'] = (revenue_script_dict['day'+i+'_revenue']*100/(props.totalUsers*payoutValue)).toFixed(2)+"%"
                        }
                        if((item==='AOV') && revenue_script_dict['day'+i+'_revenue']!=='-'){
                            var users_purchase = eventData.open_day_wise ? eventData.open_day_wise.overall[i-1] : 0
                            revenue_script_dict['day'+i+'_revenue'] = (revenue_script_dict['day'+i+'_revenue']/((props.totalUsers*users_purchase)/100)).toFixed(2)
                        }
                        if((item==='Max-OV')){
                            revenue_script_dict['day'+i+'_revenue'] = props.data['d'+i] ? props.data['max_d'+i].toFixed(2) : '-'
                        }
                    }
                    total_revenue += parseFloat(props.data['d'+i] ? props.data['d'+i] : 0)
                }
                revenue_script_dict['total_revenue'] = total_revenue.toFixed(2)
                if(item==='ARPU'){
                    revenue_script_dict['total_revenue'] = (revenue_script_dict['total_revenue']/props.totalUsers).toFixed(2)
                }
                if(item==='ROAS'){
                    revenue_script_dict['total_revenue'] = (revenue_script_dict['total_revenue']*100/(props.totalUsers*payoutValue)).toFixed(2)+"%"
                }
                if((item==='AOV')){
                    var users_purchase = eventData.total ? eventData.total.overall : 0
                    revenue_script_dict['total_revenue'] = (revenue_script_dict['total_revenue']/((props.totalUsers*users_purchase)/100)).toFixed(2)
                }
                if((item==='Max-OV')){
                    revenue_script_dict['total_revenue'] = '-'
                }
                tablev.push(revenue_script_dict) 
            }
        }
        setTableValues(tablev)
        refreshComponent(123)
    }


    if(!manualUseEffect){
        setManualUseEffect(true)
        tableValuesFunction()
    }
    useEffect(() => {
        document.addEventListener('keydown',e=>{
            if(e.key=='Escape'){
                props.setShowRevenueDataPopup(false);
            }
        })
        
        return () => {
            document.removeEventListener('keydown',e=>{
                if(e.key=='Escape'){
                    props.setShowRevenueDataPopup(false);
                }
            })
        }
    }, [])

    return (
        <div style={{position:'fixed',top:0,left:0,height:'100vh',width:'100vw',display:'flex',justifyContent:'center',alignItems:'center',zIndex:11}} onClick={()=>{props.setShowRevenueDataPopup(false);}}>
            <div style={{width:'50vw',minHeight:'30vh',height:'max-content',background:'white',border:'1px solid rgb(220,220,220)',boxShadow:'0 0 5px 2px rgb(220,220,220)',padding:'20px'}} onClick={(e)=>{e.stopPropagation();}}>
                <div style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
                    <div>Revenue Data</div>
                    <div style={{cursor:'pointer'}} onClick={()=>{props.setShowRevenueDataPopup(false)}}>X</div>
                </div>
                <div style={{marginTop:'20px',display:'flex',alignItems:'center',transform:'scale(0.8)',transformOrigin:'left'}}>
                    <div style={{width:'30%'}}>
                        <CssTextField
                            defaultValue={payoutState}
                            size='small'
                            label='Payout'
                            fullWidth
                            onChange={(e)=>{tableValuesFunction(e.target.value);setPayoutState(e.target.value)}}
                        />
                    </div>
                    <div style={{width:'30%',marginLeft:'20px',transform:'scale(1.12)',transformOrigin:'left'}}>
                        <ReactSelect                                               
                            placeholder={'Event Name For AOV'}
                            options={eventList}
                            isClearable
                            onChange={(e)=>{if(e){tableValuesFunction(payoutState,e.value);setEventSelected(e.value)}else{tableValuesFunction(payoutState,undefined);setEventSelected(undefined)}}}
                            menuPortalTarget={document.body} 
                            styles={{...Constants.ReactSelectStyles}}
                        />
                    </div>
                </div>
                <div style={{marginTop:'20px',width:'100%',overflow:'auto'}}>
                    <TableModelV2
                        tableHeaders={tableHeaders}
                        tableValues={tableValues}
                        setTableValues={setTableValues}
                    />
                </div>
            </div>
        </div>
    )
}

export default RevenueData