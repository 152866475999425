import React, { useEffect } from 'react'
// import Sidebar from './sidebar';
// import Topbar from './Topbar';
import {BrowserRouter as Router,Routes,Route,Navigate} from 'react-router-dom';
import { useState } from 'react';
import "./router.css";
import {SidebarData,HelperTabs} from "../data/sidebarTabs";
import SidebarV2 from './sidebarv2/sidebarv2.js';
import { getLocalStorageValue } from '../helper/util';
import NotAllowedPage from './notAllowed';
import LoginV2 from '../pages/login/loginv2';

import NotFoundPage from './notFound';
import Login from '../pages/login/login';
// import { onMessageListener } from './firebase/firebase';
// import { Store } from 'react-notifications-component';

function getToken() {
    const tokenString = getLocalStorageValue('adminPanelLoginData');
    return tokenString?.token
}

export default function RouterConf(){
    const [sideBarClose,setSideBarClose] = useState(localStorage.getItem('sidebarClose') ? localStorage.getItem('sidebarClose') : true);
    const [loginData,setLoginData] = useState(getLocalStorageValue('adminPanelLoginData'))
    const handleCollapseSideBar = () => {
        setSideBarClose(!sideBarClose)
        if(localStorage.getItem('sidebarClose')){
            localStorage.removeItem('sidebarClose')
        }
        else{
            localStorage.setItem('sidebarClose',!sideBarClose)
        }
    }
    var allowedPages = getLocalStorageValue('adminPanelLoginData').allowedPages ? getLocalStorageValue('adminPanelLoginData').allowedPages : []
    
    const token = getToken();
    const lastLoginTime = parseInt(localStorage.getItem("adminloginTime")) || 0;
    const expireTime = 1000*60*60*12;

    const [loginExpired,setLoginExpired] = React.useState(false);
    
    if(!((new Date().getTime() - lastLoginTime) > expireTime) && loginExpired){
        setLoginExpired(true)
    }

    const loginCheck = () => {
        if (!token || (new Date().getTime() - lastLoginTime) > expireTime){
            setLoginExpired(true)
        }
    }

    const isOldLogin = false;
    return (
        <Router>
            {
                (loginExpired || !token || (new Date().getTime() - lastLoginTime) > expireTime) && (!loginData || (new Date().getTime() - lastLoginTime) > expireTime)
                &&
                <Routes>
                    <Route path="*" element={isOldLogin ? <Login/> : <LoginV2 setLoginData={setLoginData} currentUrl={window.location.pathname}/>}/>
                </Routes>

            }
            {
                !(loginExpired || !token || (new Date().getTime() - lastLoginTime) > expireTime) && loginData
                &&
                <>
                    <SidebarV2 sidebarClose={sideBarClose} setSidebarClose={handleCollapseSideBar}/>
                    <div className={sideBarClose ? 'navBarClosed' : 'navBarOpened'} onClick={(e)=>{loginCheck(e);if(window.screen.width<600){setSideBarClose(true);localStorage.setItem('sidebarClose',true);} }} onTouchStart={()=>{if(window.screen.width<600){setSideBarClose(true);localStorage.setItem('sidebarClose',true);} }}>
                        <Routes>
                            <Route exact path="/" element={<Navigate to="/home" />}/>
                            <Route path="/login" element={isOldLogin ? <Login/> : <LoginV2 setLoginData={setLoginData} currentUrl={window.location.pathname}/>}/>

                            {SidebarData.map((item,index) => {
                                    
                                    if (item.subNav){
                                        return item.subNav.map((subNavItem,index)=>{
                                            if(allowedPages.includes(subNavItem.link)){
                                                return (
                                                    <Route exact key={subNavItem.link} path={subNavItem.link} element={subNavItem.page}/>
                                                )
                                            }
                                            return <Route exact key={subNavItem.link} path={subNavItem.link} element={<NotAllowedPage/>}/>;
                                            }

                                        )}
                                    if(allowedPages.includes(item.link)){
                                        return (
                                                <Route key={item.link} path={item.link} element={item.page}/>
                                                )    
                                        }
                                    return <Route exact path={item.link} element={<NotAllowedPage/>}/>;
                            })}

                            {HelperTabs.map((item,index) => {
                                    return (
                                        <Route key={item.link} path={item.link} element={<item.page/>}/>
                                    )    
                            })}     
                                <Route path='*' exact={true} element={<NotFoundPage/>} ></Route>   
                        </Routes>
                    </div>
                </>
            }
        </Router>        
    )
}
