import axios from 'axios';
import React, { useEffect, useState } from 'react'
// import PopupGeneric from '../../components/popupGeneric/popupGeneric'
import TopbarV2 from '../../components/topbarv2/topbarv2'
import { capitalize, getLocalStorageValue } from '../../helper/util';
import * as Constants from '../../data/constants';
import { BoxesLoader } from "react-awesome-loaders"
import { DeleteOutline } from '@mui/icons-material';
import { Button, CircularProgress, IconButton, Tooltip } from '@mui/material';
import CreatableSelect from 'react-select/creatable';

const HomePage = () => {
	const [manualUseState, setManualUseState] = useState(false)
	const [currentlyWorkingOn, setCurrentlyWorkingOn] = useState([])
	const [currentlyWorkingOnLoader, setCurrentlyWorkingOnLoader] = useState(true)
	const [showPopup, setShowPopup] = useState(false)
	const [deleteMessage, setDeleteMessage] = useState(false)
	const [workingMembers, setWorkingMembers] = useState(false)

	var dateOptions = {
		weekday: "long",
		year: "numeric",
		month: "long",
		day: "numeric",
	};

	const getUserHistory = async() => {
		setCurrentlyWorkingOnLoader(true)
        await axios({
            method: 'GET',
            url: "".concat(Constants.BACKENDHOST,"campaigns/currently-working"),
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token,
            },
        })
        .then(resp => {
            setCurrentlyWorkingOn(resp.data.data)
			var tempWorkingMembers = []
			var tempWorkingMembers2= []
			for(var index in resp.data.data){
				if(index.toLowerCase() === getLocalStorageValue('adminPanelLoginData').username.toLowerCase()){
					tempWorkingMembers.push(index)
				}
			}
			for(var index in resp.data.data){
				if(index.toLowerCase() !== getLocalStorageValue('adminPanelLoginData').username.toLowerCase()){
					tempWorkingMembers2.push(index)
				}
			}
			tempWorkingMembers2.sort()
			setWorkingMembers([...tempWorkingMembers,...tempWorkingMembers2])
        })
        .catch(err => {
            console.error(err);
        })
		setCurrentlyWorkingOnLoader(false)

    };

	if(!manualUseState){
		setManualUseState(true)
		getUserHistory()
	}
	
	const deleteTheFile = async(serial) => {
		setCurrentlyWorkingOnLoader(true)
        await axios({
            method: 'GET',
            url: "".concat(Constants.BACKENDHOST,"accounts/reset-scriptlock/"+serial),
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token,
            },
			params:{
				delete_message:deleteMessage ? deleteMessage.value : undefined,
			}
        })
        .then(resp => {
			getUserHistory()
			setShowPopup(false);
        })
        .catch(err => {
            console.error(err);
        })
	}
	 
    const showPopupFunction = () => {
        var body = {
            'deleteFileEntry':{
                title:'Are you sure you want to delete this Entry?',
                body:<div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',marginTop:'30px'}}> 
                        <div style={{width:'100%',transform:"scale(0.8)"}}>
							<CreatableSelect                                                
								placeholder={'Select Reason for Deleting this Entry.'}
								options={Constants.REASON_FOR_DELETING_FILE_ENTRY.map((item,index)=>{return {label:item,value:item}})}
								value={deleteMessage ? deleteMessage : false}
								onChange={(e)=>{setDeleteMessage(e)}}
								menuPortalTarget={document.body} 
								styles={{...Constants.ReactSelectStyles}}
							/>
						</div>
                        <div style={{display:'flex',alignItems:'center',fontSize:'14px',marginTop:'20px'}}>
                            <Button disabled={!deleteMessage} color='warning' variant='outlined' size='small' sx={{minHeight:'35px',minWidth:'120px'}} onClick={()=>{deleteTheFile(showPopup.value)}}>
                                Delete This Entry
                            </Button>
                        </div>
                    </div>
            },
        }

		return <div className='addTabContainer'>
                    <div className='addTabWrapper' style={{minHeight:'100px'}}  onClick={(e)=>{e.stopPropagation();}}>
                        <div style={{display:'flex',justifyContent:'space-between',alignItems:'center',cursor:'default'}}>
                            <div>{body[showPopup.label].title}</div>
                            <div style={{cursor:'pointer'}} onClick={()=>{setShowPopup(false);setDeleteMessage(false);}}>X</div>
                        </div>
                        {body[showPopup.label].body}
                        <div style={{display:'flex',justifyContent:'center'}}>
                            {/* <Button disabled={!addTabData.file || !addTabData.noOfDays || !addTabData.noOfUser} sx={{...Constants.CONTAINEDBUTTON,textTransform:'none',fontWeight:'400',marginTop:'20px'}} onClick={()=>{addDebug()}}> 
                                {addLoader && <CircularProgress sx={{color:'white'}} size={'18px'}/>}
                                {!addLoader && "Submit"}
                            </Button> */}
                        </div>
                    </div>
                </div>
    }
	useEffect(() => {
		document.addEventListener('click',e=>{
			setShowPopup(false);
		})
		document.addEventListener('keydown',e=>{
			if(e.key==='Escape'){
				setShowPopup(false);
			}
		})
		  return () => {
			document.removeEventListener('click',e=>{
				setShowPopup(false);
			})
			document.removeEventListener('keydown',e=>{
				if(e.key==='Escape'){
					setShowPopup(false);
				}
			})
		}
	}, [])
	return (
		<div style={{minHeight:'100vh'}}>
			<TopbarV2 title={'Home'}/>
			<div style={{padding:'15px'}}>
				<div style={{fontSize:'28px',fontWeight:'500'}}>
					Welcome {capitalize(getLocalStorageValue('adminPanelLoginData').username)}!
				</div>
				<div style={{paddingTop:'10px',paddingBottom:'10px',position:'relative'}}>
					{currentlyWorkingOnLoader
					&&
					<div style={{height:'150px'}}>
						<div style={{display:'flex',justifyContent:'center',alignItems:'center',height:'150px',position:'absolute',top:57,left:'39%'}}>
							<BoxesLoader
								boxColor={"#ffa500"}
								desktopSize={"30px"}
								mobileSize={"20px"}
							/>
						</div>
					</div>
					}
					{!currentlyWorkingOnLoader
					&&
					<div style={{padding:'5px'}}>
						{	
							currentlyWorkingOn.items
							&&
							currentlyWorkingOn.items.length===0
							&&
							<div style={{margin:'5px',height:'150px',width:'150px',background:'rgb(250,250,250)',display:'flex',alignItems:'center',justifyContent:'center',textAlign:'center',fontSize:'12px'}}>
								Currently Not Working On Anything.
							</div>
						}
						{
							showPopup &&
							showPopupFunction()
						}
						{
							currentlyWorkingOn && Object.keys(currentlyWorkingOn).length>0 &&
							workingMembers.map((user,userIndex)=>{
								if(user!=='items'){
									return <div style={{border:'1px solid rgb(220,220,220)',margin:'10px',marginTop:'20px',paddingTop:'10px',paddingBottom:'10px',paddingLeft:'10px',paddingRight:'10px',position:'relative',display:'flex',flexWrap:'wrap'}}>
												<div style={{position:'absolute',top:-10,left:10,background:'white',paddingLeft:'10px',paddingRight:'10px',fontSize:'14px'}}>
													{user.toLowerCase()===getLocalStorageValue('adminPanelLoginData').username.toLowerCase() ? 'You are' : (user + ' is')} currently working on
												</div>
												{currentlyWorkingOn[user].map((file,fileIndex)=>{
													return <div key={userIndex} style={{position:'relative',margin:'5px',height:'80px',minWidth:'150px',background:'rgb(250,250,250)',padding:'25px',display:'flex',flexDirection:'column',justifyContent:'center',fontSize:'12px'}}>
													{getLocalStorageValue('adminPanelLoginData').allowedPages.includes('can_delete_download_script_entries')
													&& 
													<div style={{position:'absolute',top:5,right:5}}>
														<Tooltip title={'Delete this entry.'}>
															<IconButton className='deleteIcon' onClick={(e)=>{e.stopPropagation();setShowPopup({label:'deleteFileEntry',value:file.serial});}}>
																<DeleteOutline sx={{fontSize:'14px'}}/>
															</IconButton>
														</Tooltip>
													</div>
													}
													<div style={{fontSize:'17px',fontWeight:'500'}}>
														{file.script_name}
													</div>
													<div style={{fontSize:'13px',marginTop:'2px'}}>
														{file.work_type}
													</div>
													<div style={{fontSize:'12px',marginTop:'5px'}}>
														{new Date(file.since).toLocaleDateString("en-US", dateOptions)}
													</div>
													<div style={{fontSize:'12px',marginTop:'5px'}}>
														{new Date(file.since).toLocaleTimeString("en-US")}
													</div>
												</div>
												})}
												
									</div>
								}
								return ;
							})
						}
						{/* {
							currentlyWorkingOn.items
							&&
							currentlyWorkingOn.items.length!==0
							&&
							currentlyWorkingOn.items.map((item,index)=>{

								return <div key={index} style={{margin:'5px',height:'80px',minWidth:'150px',background:'rgb(250,250,250)',padding:'15px',display:'flex',flexDirection:'column',justifyContent:'center',fontSize:'12px'}}>
									<div style={{fontSize:'17px',fontWeight:'500'}}>
										{item.script_name}
									</div>
									<div style={{fontSize:'13px',marginTop:'2px'}}>
										{item.work_type}
									</div>
									<div style={{fontSize:'12px',marginTop:'5px'}}>
										{new Date(item.since).toLocaleDateString("en-US", dateOptions)}
									</div>
									<div style={{fontSize:'12px',marginTop:'5px'}}>
										{new Date(item.since).toLocaleTimeString("en-US")}
									</div>
								</div>
							})
						} */}
					</div>
					}
				</div>
			</div>
			
		</div>
	)
}

export default HomePage