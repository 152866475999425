import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import styled from '@emotion/styled';
import { getOptionsLabelValue, priorityOptions, tagOptions, teamInfo } from '../genericOptionsStint';
import './taskcard.css';
import { Tooltip } from '@mui/material';

const TaskInformation = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 15px;
    min-height: 106px;
    border-radius: 5px;
    max-width: 311px;
    /* background: ${({ isDragging }) =>
        isDragging ? 'rgba(255, 59, 59, 0.15)' : 'white'}; */
    background: white;
    margin-top: 15px;

    .secondary-details {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        font-size: 12px;
        font-weight: 400px;
        color: #7d7d7d;
    }
`;
const TaskCard = (props) => {
    var item = props.item
    var index = props.index
    var onClick = props.onClick
    var PriorityIcon = priorityOptions[item.priority].icon
    var PriorityColor = priorityOptions[item.priority].color
    var PriorityLabel = 'Priority : '+priorityOptions[item.priority].label
    var AssigneeLabel = props.assigneeOptions[item.assignee]
    var AssigneeIcon = <div style={{background:'rgb(220,220,220)',height:'20px',width:'20px',fontSize:'15px',padding:'5px',display:'flex',alignItems:'center',justifyContent:'center',borderRadius:'50%',marginRight:'5px'}}>
                            {AssigneeLabel ? AssigneeLabel[0] : 'UA'}
                        </div>
    return (
        <Draggable key={item.id} draggableId={item.id} index={index}>
            {(provided) => (
                <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    onClick={onClick}
                    >
                        <TaskInformation>
                            <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',width:'100%'}}>
                                <div>
                                    <div>{item.script_name}</div>
                                    <div className='cardTag'>{getOptionsLabelValue(tagOptions,item.tag).label}</div>
                                </div>
                                <div>
                                    <div>
                                        <Tooltip title={PriorityLabel}>
                                            <PriorityIcon sx={{color:PriorityColor}}/>
                                        </Tooltip>
                                    </div>
                                </div>
                            </div>
                            <div style={{display:'flex',width:'calc(100% - 30px)',alignItems:'center',position:'absolute',justifyContent:'space-between',bottom:20}}>
                                <div style={{fontSize:'12px'}}>
                                    {new Date(item.created_at).toLocaleDateString('en-us', {
                                        month: 'short',
                                        day: '2-digit',
                                        year: '2-digit'
                                    })}
                                </div>
                                <div>
                                    <Tooltip title={'Assigned To: '+AssigneeLabel}>
                                        {AssigneeIcon}
                                    </Tooltip>
                                </div>
                            </div>
                        </TaskInformation>
                </div>
            )}
        </Draggable>
    );
};

export default TaskCard;

// <span className="priority">
// {item.Priority === 'High' ? (<RedArrow />) : item.Priority === 'Medium' ? (<YellowArrow />) : (<BlueArrow />)}
// </span>
// <div><CustomAvatar name={item.Assignee} isTable={false} size={16} /></div>
