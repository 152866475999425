import React, { useEffect, useState } from 'react'
import TopbarV2 from '../../../components/topbarv2/topbarv2'
import TableModel from '../../../components/tableModel/tablemodel'
import axios from 'axios'
import * as Constants from '../../../data/constants'
import { getLocalStorageValue, setLocalStorageValue, sortingV2, useForceUpdate } from '../../../helper/util'
import FilterRow from '../../../components/filterV2/filtersRow'
import { AirlineSeatReclineExtra, Assignment, BugReport, CalendarMonth, Close, Description, Edit, HowToReg } from '@mui/icons-material'
import { Button, CircularProgress, TextField } from '@mui/material'
import { IconButton, Tooltip } from '@mui/material';
import ReactSelect from 'react-select'
import './stintCustomization.css'
import CreatableSelect from 'react-select/creatable';
import { Cross } from '../../../data/icons'

export const isUserAdmin = () => {
    var rolename = getLocalStorageValue('adminPanelLoginData').newrolename 
    if(['Admin'].includes(rolename)){
        return true
    }
    return false
}

export const isUserBT = () => {
    var teamname = getLocalStorageValue('adminPanelLoginData').teamname 
    if(['BT-1','BT-2','BT-3'].includes(teamname)){
        return true
    }
    return false
}

export const isUserBTAM = () => {
    var rolename = getLocalStorageValue('adminPanelLoginData').newrolename 
    if(rolename === 'BT-AM'){
        return true
    }
    return false
}
export const isUserBTManager = () => {
    var teamname = getLocalStorageValue('adminPanelLoginData').teamname 
    var rolename = getLocalStorageValue('adminPanelLoginData').newrolename 
    if(['BT-1','BT-2','BT-3'].includes(teamname) && rolename === 'Manager'){
        return true
    }
    return false
}

export const isUserBTTeamLeader = () => {
    var teamname = getLocalStorageValue('adminPanelLoginData').teamname 
    var rolename = getLocalStorageValue('adminPanelLoginData').newrolename 
    if(['BT-1','BT-2','BT-3'].includes(teamname) && rolename === 'BT-TL'){
        return true
    }
    return false
}

export const getTeamName = () => {
    var teamStructure = Constants.teamStructure
    var teamname = getLocalStorageValue('adminPanelLoginData').teamname
    if(teamname.length===1){
        teamname = teamname+'A'
    }
    return {label:'Team '+teamname,value:'team'+ teamname.slice(0,-1)+'-'+teamStructure[teamname]}
}


export const getTeamLeadersSubteams = () => {
    var teamStructure = Constants.teamStructure
    var teamname = getLocalStorageValue('adminPanelLoginData').teamname
    if(teamname.length>1){
        teamname = teamname.slice(0,-1)
    }
    return [
        {label:'Team '+teamname+'A',value:'team'+ teamname+'-'+teamStructure[teamname+'A']},
        {label:'Team '+teamname+'B',value:'team'+ teamname+'-'+teamStructure[teamname+'B']},
            ]
}

export const allowedToSeeDevTeams = () => {
    var rolename = getLocalStorageValue('adminPanelLoginData').newrolename 
    if(['BT-TL','AM','BT-AM'].includes(rolename)){
        return false
    }
    return true
}

export const isUserTeamLeader = () => {
    var rolename = getLocalStorageValue('adminPanelLoginData').newrolename 
    if(['TL-DEV','TL-QA'].includes(rolename)){
        return true
    }
    return false
}


const StintCustomizations = () => {
    var rolename = getLocalStorageValue('adminPanelLoginData').newrolename 

    setLocalStorageValue('ambientColor','rgb(255, 166, 0)')
    var title = isUserBTAM() ? 'Optimizations' : 'Stint (Customizations)'

    var defaultFilterData = {
        from_date:new Date(new Date().getTime() - 86400000*2),
        end_date:new Date(new Date().getTime()),
        // status:isUserBT() || isUserAdmin() ? {label:'',value:undefined} : isUserTeamLeader() ? {label:'Need to be Assigned',value:1} : {label:'Task Assigned',value:2}
    }
    if(!isUserBT() && !isUserTeamLeader() && !isUserAdmin()){
        defaultFilterData['developer_name']={label:getLocalStorageValue('adminPanelLoginData').name,value:getLocalStorageValue('adminPanelLoginData').name}
        defaultFilterData['dev_team']=getTeamName()
    }

    if(isUserTeamLeader()){
        defaultFilterData['dev_team']=getTeamName()
    }
    const [searchScriptName, setSearchScriptName] = useState('')

    const getScriptListfromDict = (scriptDict) => {
        var scriptList = []
        for(var scriptname in scriptDict){
            var scriptDictData = scriptDict[scriptname]
            scriptList.push({
                label:scriptname,
                value:scriptname,
                ...scriptDictData
            })
        }
        sortingV2(scriptList,'value',true)
        return scriptList
    }
    const [filterData, setFilterData] = useState({...defaultFilterData})

    const [customizationData, setCustomizationData] = useState([])
    const [manualUseEffect, setManualUseEffect] = useState(false)
    const [newCustomization, setNewCustomization] = useState({})
    const [updateCustomizationLoader, setUpdateCustomizationLoader] = useState(false)
    const [newCustomizationPopup, setNewCustomizationPopup] = useState(false)
    const [loader, setLoader] = useState(false)
    const [scriptList, setScriptList] = useState(getScriptListfromDict(getLocalStorageValue('scriptCombinedDict') ? getLocalStorageValue('scriptCombinedDict') : {}))

    var statusOptions = [
        {label:'Not Approved',value:0},
        {label:'Not Assigned',value:1},
        {label:'Not Done',value:2},
        {label:'Task Done',value:3},
        {label:'Wrong Entry',value:4},
    ]
    if(isUserBT() || isUserBTManager()){
        statusOptions = [
            {label:'Not Approved',value:0},
            {label:'Not Done',value:'1,2'},
            {label:'Task Done',value:3},
            {label:'Wrong Entry',value:4},
        ]
    }
    if(!isUserAdmin() && !isUserBT()){
        if(isUserTeamLeader()){
            statusOptions = [
                {label:'Not Assigned',value:1},
                {label:'Not Done',value:2},
                {label:'Task Done',value:3},
                {label:'Wrong Entry',value:4},
            ]
        }
        else{
            statusOptions = [
                {label:'Not Done',value:2},
                {label:'Task Done',value:3},
            ]
        }
    }
    var developerOptions = () => {
        var list___ = getLocalStorageValue('adminPanelLoginData').managing.map((item,index)=>{
            return {label:item,value:item}
        })
        if(!getLocalStorageValue('adminPanelLoginData').managing.includes(getLocalStorageValue('adminPanelLoginData').name)){
            list___.push({label:getLocalStorageValue('adminPanelLoginData').name,value:getLocalStorageValue('adminPanelLoginData').name})
        }
        // list___.sort()
        return list___
    }
    var teamOptionsList = [
        {label:'Team 1A',value:'team1-Pushkal'},
        {label:'Team 1B',value:'team1-Pawan'},
        {label:'Team 2A',value:'team2-Udit'},
        {label:'Team 2B',value:'team2-Umair'},
        {label:'Team 3A',value:'team3-Shikha'},
        {label:'Team 3B',value:'team3-Aniket'},
    ]

    var filterList = [
        {label:'Assigned At',value:'created_at',type:'daterangepicker',icon:CalendarMonth},
        {label:'Search',value:'search_anything',type:'textfield',icon:Description},
        {label:'Status',value:'status',type:'select',hideValueInLabel:true,options:statusOptions,icon:BugReport,isSingle:true},
        {label:'Dev Team',value:'dev_team',type:'select',hideValueInLabel:true,options:(!isUserBT() && !isUserTeamLeader() && !isUserAdmin()) ? [getTeamName()] : isUserTeamLeader() ? getTeamLeadersSubteams() : teamOptionsList,icon:Description,isSingle:true},
        {label:'Developer',value:'developer_name',type:'select',hideValueInLabel:true,options:developerOptions(),icon:AirlineSeatReclineExtra,isSingle:true},
    ]

    const getCustomizationsData = async(tempFilterData) => {
        if(!tempFilterData){
            tempFilterData = filterData
        }
        setLoader(true)

        var from_date = tempFilterData.from_date.toLocaleString('fr-CA',{year:'numeric',month:'numeric',day:'numeric'})
        var end_date = tempFilterData.end_date.toLocaleString('fr-CA',{year:'numeric',month:'numeric',day:'numeric'})
        
        var filter_dict = {
            from_date,
            end_date
        }
        for(var index in filterList){
            var item = filterList[index]
            if(item.type==='textfield'){
                if(tempFilterData[item.value]){
                    filter_dict[item.value] = tempFilterData[item.value]
                }
            }
            if(item.type==='select' && !item.isSingle){
                var value = ''
                for(var xIndex in tempFilterData[item.value]){
                    var xItem = tempFilterData[item.value][xIndex]
                    if(parseInt(xIndex)===tempFilterData[item.value].length-1){
                        value+= xItem.value
                    }
                    else{
                        value+= xItem.value + ','
                    }
                }
                if(value || value===0 || value ==="0"){
                    filter_dict[item.value] = value
                }
            }
            if(item.type==='select' && item.isSingle){
                if(tempFilterData[item.value] && (tempFilterData[item.value].value || tempFilterData[item.value].value===0)){
                    filter_dict[item.value] = tempFilterData[item.value].value
                }
            }
        }
        
        await axios({
            method:'GET',
            url: Constants.BACKENDHOST+"task_tracker/viewCustomizations",
            headers:{
                "Content-Type": "application/json",
                Authorization:getLocalStorageValue('adminPanelLoginData').token,
            },
            params:filter_dict
        })
        .then(resp=>{
            var data = resp.data.data.data
            var notCompletedList = []
            var completedList = []
            for(var index in data){
                var item = data[index]
                if(item.status!==3){
                    notCompletedList.push(item)
                }
                else{
                    completedList.push(item)
                }
            }
            var data_list = [...notCompletedList,...completedList]
            setCustomizationData(data_list)
        })
        .catch(err=>{
            console.log(err)
        })
        setLoader(false)
    }

    const channelOptions = (getall=false) => {
        var options = [
            ...(isUserAdmin() ? [{label:'All',value:'all'}] : []),
            {label:'Mobiaviator',value:'mobiaviator'},
            {label:'Offersinfinite',value:'offersinfinite'},
            {label:'Quasarmobi',value:'quasarmobi'},
            {label:'Adshustle',value:'adshustle'},
            {label:'Appsfollowing',value:'appsfollowing'},
            {label:'Vestaapps',value:'vestaapps'},
            {label:'Mobpine',value:'mobpine'},
            {label:'AppAmplify',value:'appamplify'},
            {label:'77Ads',value:'77ads'},
        ]
        if(!getall){
            if(getLocalStorageValue('adminPanelLoginData').allowed_channels && getLocalStorageValue('adminPanelLoginData').allowed_channels.length>0){
                var allowed_channels = getLocalStorageValue('adminPanelLoginData').allowed_channels
                var map__ = []
                for(var index in options){
                    var item = options[index]
                    if(allowed_channels.includes(item.value)){
                        map__.push(item)
                    }
                }
                return map__
            }
        }
        var returnOptions = []
        if(isUserBTManager()){
            returnOptions.push({label:'All',value:'all'})
        }
        if(getLocalStorageValue('adminPanelLoginData').teamname==='BT-1'){
            return [
                ...returnOptions,
                options[0],
                options[1],
                options[2],
            ]
        }
        if(getLocalStorageValue('adminPanelLoginData').teamname==='BT-2'){
            return [
                ...returnOptions,
                options[3],
                options[4],
                options[5],
            ]
        }
        if(getLocalStorageValue('adminPanelLoginData').teamname==='BT-3'){
            return [
                ...returnOptions,
                options[6],
                options[7],
                options[8],
            ]
        }
        return options
    }

    const [taskSummary, setTaskSummary] = useState({})
    const getCustomizationsSummary = async() => {
        var filter_dict = {}
        if(isUserBT() || isUserBTManager()){
            var channel_list = []
            var all_channels = channelOptions(true).map((item)=>{return item.value})
            if(all_channels.length===3){
                channel_list.push(
                    all_channels[0]+','+all_channels[1]+','+all_channels[2]
                )
            }
            if(isUserBTManager()){
                var channel_two_combi = [].concat(...all_channels.map( 
                    (v, i) => all_channels.slice(i+1).map( w => v + ',' + w ))
                );
            }
            else{
                var allowedChannels = channelOptions().map((item)=>{return item.value})
                var channel_two_combi_ref = [].concat(...all_channels.map( 
                    (v, i) => all_channels.slice(i+1).map( w => v + ',' + w ))
                );
                channel_two_combi = []
                for(var index in channel_two_combi_ref){
                    var item = channel_two_combi_ref[index]
                    for(var allowedChannelIndex in allowedChannels){
                        var allowedChannelItem = allowedChannels[allowedChannelIndex]
                        if(item.includes(allowedChannelItem)){
                            channel_two_combi.push(item)
                        }
                    }
                } 
                all_channels = allowedChannels
            }
            channel_list = [...channel_list,...channel_two_combi,...all_channels]
            filter_dict = {...filter_dict,channel_list}
        }
        if(!isUserBT() && !isUserBTManager() && !isUserAdmin()){
            filter_dict['dev_team'] = getTeamName().value
        }
        await axios({
            method:'POST',
            url: Constants.BACKENDHOST+"task_tracker/customizationsummary",
            headers:{
                "Content-Type": "application/json",
                Authorization:getLocalStorageValue('adminPanelLoginData').token,
            },
            data:filter_dict
        })
        .then(resp=>{
            setTaskSummary(resp.data.data.data)
        })
        .catch(err=>{
            console.log(err)
        })
    }

    const [addCustomizationLoader, setAddCustomizationLoader] = useState(false)
    const autoApproveCustomization = async(id__) => {
        await axios({
            method:'POST',
            url: Constants.BACKENDHOST+"task_tracker/editCustomization",
            headers:{
                "Content-Type": "application/json",
                Authorization:getLocalStorageValue('adminPanelLoginData').token,
            },
            data:{
                id:id__,
                status:1
            }
        })
        .then(resp=>{
            
        })
        .catch(err=>{
            console.log(err)
        })
    }
    const addCustomizationData = async() => {
        setAddCustomizationLoader(true)
        try{

            var channel = ''
            for(var index in newCustomization.channel){
                var item = newCustomization.channel[index]
                channel+=item.value+','
            }
            channel = channel.slice(0,-1)
            if(newCustomization.script_name.devteam){
                var dev_team = newCustomization.script_name.devteam+'-'+newCustomization.script_name.qa_upperlevel
            }
            else{
                dev_team = newCustomization.dev_team.value + '-' + newCustomization.dev_sub_team.value
            }
            
            var customization_type = newCustomization.customization_type.value
            await axios({
                method:'POST',
                url: Constants.BACKENDHOST+"task_tracker/addCustomization",
                headers:{
                    "Content-Type": "application/json",
                    Authorization:getLocalStorageValue('adminPanelLoginData').token,
                },
                data:{
                    channel:channel,
                    network:newCustomization.network,
                    offer_id:newCustomization.offer_id,
                    script_name:newCustomization.script_name.value,
                    customization_type:customization_type,
                    customization_details:newCustomization.customization_details,
                    dev_team:dev_team,
                    assigned_by:getLocalStorageValue('adminPanelLoginData').name,
                    developer_name:newCustomization.developer_name,
                    note:newCustomization.note,
                }
            })
            .then(async(resp)=>{
                var id__ = resp.data.data.task_id
                if(isUserAdmin() || isUserBTManager() || isUserBTTeamLeader() || isUserBTAM()){
                    await autoApproveCustomization(id__)
                }
                getCustomizationsData()
                setNewCustomization({})
                setNewCustomizationPopup(false)
            })
            .catch(err=>{
                console.log(err)
            })
        }
        catch{

        }
        setAddCustomizationLoader(false)

    }
    
    const getScriptsList = async() => {
        var scriptListExpireTime = getLocalStorageValue('scriptListExpireTime')
        if(!scriptListExpireTime){
            scriptListExpireTime = new Date().getTime()-19800
        }
        if(scriptListExpireTime<(new Date().getTime())){
            await axios({
                method:'GET',
                url: Constants.BACKENDHOST+"task_tracker/get_all_combined_scripts",
                headers:{
                    "Content-Type": "application/json",
                    Authorization:getLocalStorageValue('adminPanelLoginData').token,
                },
            })
            .then(resp=>{
                setLocalStorageValue('scriptListExpireTime',(new Date().getTime()+19800000),true)
                setLocalStorageValue('scriptCombinedDict',resp.data.data,true)
                setScriptList(getScriptListfromDict(resp.data.data))
            })
            .catch(err=>{
                console.log(err)
            })
        }
    }
    if(!manualUseEffect){
        setManualUseEffect(true)
        getScriptsList()
        getCustomizationsData(defaultFilterData)
        getCustomizationsSummary()
    }
    const [statusAssignLoader, setStatusAssignLoader] = useState([])
    const [popupContainer, setPopupContainer] = useState(false)

    const refreshComponent = useForceUpdate()
    const updateCustomization = async(valueIndex,developer_name,status,updatedData={}) => {
        setUpdateCustomizationLoader(true)
        if(updatedData.channel){
            var channel = ''
            for(var index in updatedData.channel){
                var item = updatedData.channel[index]
                channel+=item.value+','
            }
            channel = channel.slice(0,-1)
            updatedData['channel']=channel
        }
        if(updatedData.dev_team){
            var dev_team = updatedData.dev_team.value+'-'+updatedData.dev_sub_team.value
            updatedData['dev_team'] = dev_team
        }
        if(updatedData.customization_type){
            updatedData['customization_type'] = updatedData.customization_type.value
        }
        if(updatedData.script_name){
            updatedData['script_name'] = updatedData.script_name.value
        }
        if(status===2 || status===1){
            var tempTableValues = customizationData
            tempTableValues[valueIndex] = {...tempTableValues[valueIndex],status:'loader'}
            setCustomizationData(tempTableValues)
            setStatusAssignClicked(false)
            refreshComponent(132);
        }
        if(status===3){
            var tempTableValues = customizationData
            tempTableValues[valueIndex] = {...tempTableValues[valueIndex],updated_at:'loader'}
            setCustomizationData(tempTableValues)
            setStatusAssignClicked(false)
            refreshComponent(132);
        }
        var data = {...updatedData}
        data['id'] = customizationData[valueIndex].id
        if(developer_name){
            data['developer_name'] = developer_name
        }
        if(status){
            data['status'] = status
        }
        await axios({
            method:'POST',
            url: Constants.BACKENDHOST+"task_tracker/editCustomization",
            headers:{
                "Content-Type": "application/json",
                Authorization:getLocalStorageValue('adminPanelLoginData').token,
            },
            data
        })
        .then(resp=>{
            var tempTableValues = customizationData
            if(status===2){
                tempTableValues[valueIndex] = {...tempTableValues[valueIndex],...data,assigned_by:getLocalStorageValue('adminPanelLoginData').name,assigned_at:new Date()}
            }
            else if(status===3){
                tempTableValues[valueIndex] = {...tempTableValues[valueIndex],...data,updated_at:new Date()}
            }
            else{
                tempTableValues[valueIndex] = {...tempTableValues[valueIndex],...data}
            }
            setCustomizationData(tempTableValues)

            var tempStatusAssignLoader = statusAssignLoader
            tempStatusAssignLoader.filter(e=>e!==valueIndex)
            setStatusAssignLoader(tempStatusAssignLoader)
            refreshComponent(132);
            if(updatedData.isEdit){
                setNewCustomizationPopup(false)
                setNewCustomization({})
            }
        })
        .catch(err=>{
            console.log(err)
        })
        setPopupContainer(false)
        setUpdateCustomizationLoader(false)
    }
    const [statusAssignClicked, setStatusAssignClicked] = useState(false)
    function padTwoDigits(num) {
        return num.toString().padStart(2, "0");
      }
      
    function dateInYyyyMmDdHhMmSs(date, dateDiveder) {
        
        return (
          [
            date.getFullYear(),
            padTwoDigits(date.getMonth() + 1),
            padTwoDigits(date.getDate()),
          ].join(dateDiveder) +
          " " +
          [
            padTwoDigits(date.getHours()),
            padTwoDigits(date.getMinutes()),
            padTwoDigits(date.getSeconds()),
          ].join(":")
        );
    }
    const statusAssignComponent = (valueItem,zozo,item,index,valueIndex) => {
        var status = {
            '0':{label:'Not Approved',styles:{color:'red'}},
            '1':{label:isUserBT() ? 'Not Done' : 'Need to be Assigned',pseudoLabel:'Approved',styles:{color:isUserBT() ? 'orange' : 'blue',cursor:'pointer'}},
            '2':{label:'Not Done',pseudoLabel:'Task Assigned',styles:{color:'orange',cursor:'default'}},
            '3':{label:'Task Done',styles:{color:'green',cursor:'default'}},
            '4':{label:'Wrong Entry',styles:{color:'red',cursor:'default'}},
        }
        if(valueItem['status']==='loader' || item.statusAssignLoader.includes(valueIndex)){
            return <CircularProgress size='14px' sx={{color:getLocalStorageValue('ambientColor2')}}/>
        }
        if(valueItem['status']===0){
            return <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',...(status[valueItem['status']].styles ? status[valueItem['status']].styles : {})}}>
                <div>{status[valueItem['status']].label}</div>
                {
                    (isUserAdmin() || isUserBTManager() || isUserBTTeamLeader() || isUserBTAM())
                    &&
                    <div onClick={()=>{setPopupContainer({valueIndex,developer_name:'',status:1,updatedData:{}});}}>
                    {/* setStatusAssignLoader([...item.statusAssignLoader,valueIndex]);updateCustomization(valueIndex,'',1)}}> */}
                        <Tooltip title={'Approve Customization'}>
                            <HowToReg sx={{color:getLocalStorageValue('ambientColor'),fontSize:'15px'}}/>
                        </Tooltip>
                    </div>
                }
            </div>
        }
        var tooltipTitle = valueItem.history.map((itm,ind)=>{
            return <div>{itm.status && status[itm.status] ? status[itm.status].pseudoLabel ? status[itm.status].pseudoLabel : status[itm.status].label : 'Created'} : {itm.timestamp ? dateInYyyyMmDdHhMmSs(new Date(itm.timestamp*1000),'-') : valueItem.created_at}({itm.changed_by ? itm.changed_by : itm.assigned_by})</div>
        })

        return <div onClick={(e)=>{e.stopPropagation();if(!isUserBT() && (valueItem['status']!==3)){setStatusAssignClicked({valueIndex,gc:e.target.getBoundingClientRect()})}}} style={{...(status[valueItem['status']].styles ? status[valueItem['status']].styles : {})}}>
                {(isUserAdmin() || isUserBTManager())
                &&
                <Tooltip title={<div>{tooltipTitle}</div>}>
                    {status[valueItem['status']].label}
                </Tooltip>
                }
                {
                !(isUserAdmin() || isUserBTManager())
                &&
                status[valueItem['status']].label
                }

            </div>
    }
    var tableStyles = {
        fontSize:'12px'
    }
    const datetimeComponent = (valueItem,xoxo,item,index,valueIndex) => {
        if(valueItem[item.value] && valueItem[item.value]!=='None'){
            return new Date(valueItem[item.value]).toLocaleString()
        }
        return '-'
    }
    const updateTimeComponent = (valueItem,xoxo,item,index,valueIndex)=>{

        if(valueItem[item.value]==='loader'){
            return <CircularProgress size='14px' sx={{color:getLocalStorageValue('ambientColor2')}}/>
        }

        if(valueItem['status']===0){
            return <div>
                Not assigned
            </div>
        }
        if(!valueItem[item.value] || valueItem[item.value] === '0000-00-00 00:00:00'){
            return <div style={{color:getLocalStorageValue('ambientColor2'),cursor:'pointer'}} onClick={(e)=>{e.stopPropagation();
            // updateCustomization(valueIndex,valueItem.developer_name,3)}}>
                setPopupContainer({valueIndex,developer_name:valueItem.developer_name,status:3,updatedData:{}})}}>
                Set Done
            </div>
        }
        return datetimeComponent(valueItem,xoxo,item,index,valueIndex)
    }

    
    const nullComponent = (valueItem,xoxo,item,index,valueIndex) => {
        if(valueItem[item.value] || valueItem[item.value]===0){
            return valueItem[item.value]
        }
        return '-'
    }

    const channelComponent = (valueItem,xoxo,item)=>{
        var channels_list = valueItem[item.value].split(',')
        if(channels_list.includes('mobiaviator') || channels_list.includes('quasarmobi') || channels_list.includes('offersinfinite')){
            return <div style={{display:'flex',alignItems:'center'}}>

                {channels_list.map((item,index)=>{
                    var neededComma = false
                    if(channels_list.length-1!==parseInt(index)){
                        neededComma = true
                    }
                    if(item==='mobiaviator'){
                        return <div key={index} style={{color:'orange'}}>
                            {item}{neededComma ? <label>,</label> : ""}
                        </div>
                    }
                    if(item==='quasarmobi'){
                        return <div key={index} style={{color:'darkgrey'}}>
                            {item}{neededComma ? <label>,</label> : ""}
                        </div>
                    }
                    if(item==='offersinfinite'){
                        return <div key={index} style={{color:'blue'}}>
                            {item}{neededComma ? <label>,</label> : ""}
                        </div>
                    }
                    return item
                })}
            </div>
        }
        else{
            return valueItem[item.value]
        }
    }

    const editDeleteComponent =  (valueItem,xoxo,item,index,valueIndex) => {
        return <div style={{display:'flex',justifyContent:'center'}}>
            <div style={{padding:'5px',paddingBottom:'0px',paddingTop:'0px'}} onClick={()=>{tableRowClickedFunction(valueItem,valueIndex)}}><Edit sx={{fontSize:'15px'}}/></div>
            {(isUserAdmin() || isUserBTManager()) && <div style={{padding:'5px',paddingBottom:'0px',paddingTop:'0px'}} onClick={()=>{
                setPopupContainer({valueIndex,developer_name:valueItem.developer_name,status:4,updatedData:{}})
                // updateCustomization(valueIndex,valueItem.developer_name,4)
                }}><Close sx={{fontSize:'15px'}}/></div>}
        </div>
    }
    var tableHeaders = [
        {label:'App Name',value:'script_name',width:150,locked:true,style:{...tableStyles}},
        {label:'Edit',value:'',width:50,style:{...tableStyles},customComponent:editDeleteComponent,updateCustomizationLoader},
        {label:'Channel',value:'channel',width:100,style:{...tableStyles},customComponent:channelComponent},
        {label:'Network',value:'network',width:100,style:{...tableStyles}},
        {label:'Offer ID',value:'offer_id',width:100,style:{...tableStyles}},
        {label:'Created By',value:'created_by',width:100,style:{...tableStyles}},
        {label:'Created At',value:'created_at',width:150,customComponent:datetimeComponent,style:{...tableStyles}},
        {label:'Status',value:'status',width:120,customComponent:statusAssignComponent,style:{...tableStyles},statusAssignLoader,setStatusAssignLoader},
        {label:'Assigned By',value:'assigned_by',width:120,customComponent:nullComponent,style:{...tableStyles}},
        {label:'Assigned At',value:'assigned_at',width:180,customComponent:datetimeComponent,style:{...tableStyles}},
        {label:'Tech Team',value:'dev_team',width:140,style:{...tableStyles}},
        {label:'Developer',value:'developer_name',width:140,style:{...tableStyles}},
        {label:isUserBTAM() ?  'Optimization Type' : 'Customization Type',value:'customization_type',width:180,style:{...tableStyles}},
        {label:isUserBTAM() ?  'Optimization Details' : 'Customization Details',value:'customization_details',width:180,style:{...tableStyles}},
        {label:isUserBTAM() ?  'Optimization Note' : 'Customization Note',value:'note',type:'textfield',maxRows:2},
        ...(isUserBT() ? [] : [{label:'Done At',value:'updated_at',width:130,customComponent:updateTimeComponent,style:{...tableStyles}}]),
    ]
    if(!allowedToSeeDevTeams()){
        tableHeaders.splice(8,1)
        tableHeaders.splice(8,1)
        tableHeaders.splice(8,1)
        tableHeaders.splice(8,1)
    }
    const overrideSetFilterData = (value,filterItem) => {
        if(filterItem==='dateRangePicker'){
            setFilterData({...filterData,...value})
        }
        else{
            if(filterItem.value === 'dev_team'){
                if(isUserBT() || isUserAdmin()){
                    setFilterData({...filterData,[filterItem.value]:value})
                }
                else{
                    if(value.value!==undefined){
                        setFilterData({...filterData,[filterItem.value]:value})
                    }
                }
            }
            else{
                setFilterData({...filterData,[filterItem.value]:value})
            }
        }
    }

    const canSeeAddButton = () => {
        if(isUserBT() || isUserAdmin()){
            return true
        }
        return false
    }

    if(!allowedToSeeDevTeams()){
        filterList.splice(2,1)
        filterList.splice(2,1)
        filterList.splice(2,1)
        filterList.push({label:'App Name',value:'script_name',type:'textfield',icon:Description})
        filterList.push({label:'Channel',value:'channel',type:'select',hideValueInLabel:true,options:channelOptions(),icon:AirlineSeatReclineExtra,isSingle:true})
    }
    const teamOptions = () => {
        var teamOptions = [
            {label:'Team 1',value:'team1'},
            {label:'Team 2',value:'team2'},
            {label:'Team 3',value:'team3'},
        ]
        return teamOptions
    }
    const subteamOptions = () => {
        var subteamOptions = {
            'team1':[
                {label:'Pushkal',value:'Pushkal'},
                {label:'Pawan',value:'Pawan'},
            ],
            'team2':[
                {label:'Udit',value:'Udit'},
                {label:'Umair',value:'Umair'},
            ],
            'team3':[
                {label:'Shikha',value:'Shikha'},
                {label:'Aniket',value:'Aniket'},
            ]
        }
        if(newCustomization.dev_team){
            return subteamOptions[newCustomization.dev_team.value]
        }
    }

    const customizationTypeOptions = () => {
        var list_ = [
            'Disabled Attributions',
            'Geo-Targetting',
        ]
        if(allowedToSeeDevTeams() || isUserBTTeamLeader()){
            list_ = [...list_,
                'Proxy',
                'Referrer Check For Organic',
                'Only Install Needed',
                'TZ',
                'RR',
                'App-Flow',
                'Remove-Customization',
                'Match Type(appsflyer)',
            ]
        }
        return list_.map((item,index)=>{
            return {label:item,value:item}
        })
    }
    var addCustomizationList = [
        {label:'Channel',value:'channel',type:'select',options:channelOptions(),isMultiple:true},
        {label:'Network',value:'network',type:'textfield'},
        {label:'Offer ID',value:'offer_id',type:'textfield'},
        {label:'App Name',value:'script_name',type:(isUserAdmin() || isUserBTManager() ? 'creatableSelect' : 'select'),searchSelectOptions:true,options:scriptList},
        {label:'Dev Team',value:'dev_team',type:'select',options:teamOptions()},
        {label:'Dev Sub Team',value:'dev_sub_team',type:'select',options:subteamOptions()},
        {label:isUserBTAM() ? 'Optimization Type' : 'Customization Type',value:'customization_type',type:'select',options:customizationTypeOptions()},
        {label:isUserBTAM() ? 'Optimization Details' : 'Customization Details',value:'customization_details',type:'textfield',maxRows:5},
        {label:isUserBTAM() ? 'Optimization Note' : 'Customization Note',value:'note',type:'textfield',maxRows:2},
    ]
    if(!allowedToSeeDevTeams()){
        addCustomizationList.splice(4,1)
        addCustomizationList.splice(4,1)
    }

    filterList.push(
        {label:'Submit Reset Button',type:'submit_reset_button',renameSubmit:'Search',onSubmit:()=>{getCustomizationsData()},onReset:()=>{setFilterData(defaultFilterData)}}
    )

    const submitButtonDisabled = () => {
        if(!newCustomization.script_name || !newCustomization.channel || (newCustomization.channel && newCustomization.channel.length===0) || !newCustomization.offer_id || !newCustomization.network || !newCustomization.customization_type || !newCustomization.customization_details){
            return true
        }
        if(newCustomization.customization_type && newCustomization.customization_type.value === 'Disabled Attributions' && !newCustomization.note){
            return true
        }
        return false
    }

    const tableRowClickedFunction = (valueItem,valueIndex) => {
        var dict__ = {...valueItem,'isEdit':true,'valueIndex':valueIndex}
        for(var customizationDetailsIndex in addCustomizationList){
            var customizationDetailsItem = addCustomizationList[customizationDetailsIndex]
            if(customizationDetailsItem.value !== 'dev_team' && customizationDetailsItem.value !== 'dev_sub_team' ){
                if((customizationDetailsItem.type ==='select' || customizationDetailsItem.type ==='creatableSelect') && !customizationDetailsItem.isMultiple){
                    dict__[customizationDetailsItem.value] = {label:valueItem[customizationDetailsItem.value],value:valueItem[customizationDetailsItem.value]}
                }
                else if((customizationDetailsItem.type ==='select' || customizationDetailsItem.type ==='creatableSelect')  && customizationDetailsItem.isMultiple){
                    var data = valueItem[customizationDetailsItem.value] ? valueItem[customizationDetailsItem.value].split(',') : []
                    var value = []
                    for(var dataIndex in data){
                        var dataItem = data[dataIndex]
                        value.push({label:dataItem,value:dataItem})
                    }
                    dict__[customizationDetailsItem.value] = value
                }
            }
            else if(customizationDetailsItem.value === 'dev_team'){
                dict__['dev_team'] = {label:valueItem['dev_team'].split('-')[0],value:valueItem['dev_team'].split('-')[0]}
                dict__['dev_sub_team'] = {label:valueItem['dev_team'].split('-')[1],value:valueItem['dev_team'].split('-')[1]}
            }
        }
        if(!isUserAdmin() && !isUserBTManager()){
            dict__['isEdit'] = false
            dict__['readableOnly'] = true
        }
        setNewCustomization(dict__);
        setNewCustomizationPopup(true);
    
    }

    return (
        <div id='stintCustomizations' onClick={()=>{setStatusAssignClicked(false);}}>
            <TopbarV2 title={title}/>
            {
                canSeeAddButton()
                &&
                <div style={{display:'flex',alignItems:'center',justifyContent:'flex-end',marginTop:'20px',marginBottom:'0px',marginRight:'15px'}}>
                    <Button
                        color='warning'
                        variant='outlined'
                        onClick={()=>{setNewCustomizationPopup(true)}}
                    > + Add { isUserBTAM() ? 'Optimization' : 'Customization'}</Button>
                </div>
            }
            {
                newCustomizationPopup
                &&
                <div style={{position:'fixed',height:'100vh',width:'100vw',display:'flex',alignItems:'center',justifyContent:'center',top:0,left:0,background:'rgba(50,50,50,0.1)',zIndex:11}}>
                    <div style={{position:'absolute',height:'60vh',width:'40vw',background:'white',border:'1px solid rgb(200,200,200)',boxShadow:'-2px 10px 10px 2px rgba(200,200,200,0.5)'}}>
                        <div style={{display:'flex',justifyContent:'space-between',alignItems:'center',padding:'20px'}}>
                            <div style={{cursor:'default',fontSize:'17px',fontWeight:'500'}}> {newCustomization.readableOnly ? 'View' : newCustomization.isEdit ? 'Edit' : 'Add'} Customization</div>
                            <div style={{cursor:'pointer'}} onClick={()=>{setNewCustomizationPopup(false);setNewCustomization({});}}> X</div>
                        </div>
                        <div style={{paddingLeft:'20px',paddingRight:'20px',overflow:'auto',height:'45vh'}}>
                            {
                                addCustomizationList.map((item,index)=>{
                                    if(item.type==='textfield'){
                                        var rightComponent = <Constants.CssTextField disabled={newCustomization.readableOnly} value={newCustomization[item.value]} multiline={item.maxRows ? true : false} rows={item.maxRows ? item.maxRows : 1} color='warning' size='small' placeholder={item.label} onChange={(e)=>{setNewCustomization({...newCustomization,[item.value]:e.target.value})}} fullWidth sx={{transform:'scale(0.8)',transformOrigin:'left'}}/>
                                    }
                                    if(item.type==='creatableSelect'){
                                        var rightComponent = <div style={{transform:'scale(0.8)',transformOrigin:'left'}}>
                                                            <CreatableSelect              
                                                                onInputChange={(e)=>{setSearchScriptName(e);refreshComponent(123);}}                                  
                                                                placeholder={item.label}
                                                                options={item.options}
                                                                isMulti={item.isMultiple}
                                                                isDisabled={newCustomization.readableOnly}
                                                                isClearable={item.isClearable}
                                                                defaultValue={newCustomization[item.value] ? newCustomization[item.value] : false}
                                                                value={newCustomization[item.value] ? newCustomization[item.value] : false}
                                                                onChange={(e)=>{
                                                                    setNewCustomization({...newCustomization,[item.value]:e})
                                                                    if(item.value==='script_name'){
                                                                        setNewCustomization({...newCustomization,[item.value]:e,dev_team:{label:e.devteam,value:e.devteam},dev_sub_team:{label:e.qa_upperlevel,value:e.qa_upperlevel}})
                                                                    }
                                                                }}
                                                                menuPortalTarget={document.body} 
                                                                styles={{...Constants.ReactSelectStylesStint}}/>
                                                            </div>   
                                    }
                                    if(item.type==='select'){
                                        var rightComponent = <div style={{transform:'scale(0.8)',transformOrigin:'left'}}>
                                                            <ReactSelect              
                                                                onInputChange={(e)=>{setSearchScriptName(e);refreshComponent(123);}}                                  
                                                                placeholder={item.label}
                                                                options={item.options}
                                                                isMulti={item.isMultiple}
                                                                isClearable={item.isClearable}
                                                                isDisabled={newCustomization.readableOnly}
                                                                defaultValue={newCustomization[item.value] ? newCustomization[item.value] : false}
                                                                value={newCustomization[item.value] ? newCustomization[item.value] : false}
                                                                onChange={(e)=>{
                                                                    setNewCustomization({...newCustomization,[item.value]:e})
                                                                    if(item.value==='script_name'){
                                                                        setNewCustomization({...newCustomization,[item.value]:e,dev_team:{label:e.devteam,value:e.devteam},dev_sub_team:{label:e.qa_upperlevel,value:e.qa_upperlevel}})
                                                                    }
                                                                }}
                                                                menuPortalTarget={document.body} 
                                                                styles={{...Constants.ReactSelectStylesStint}}/>
                                                            </div>   
                                    }
                                    return <div key={index} style={{display:'flex',alignItems:'center',position:'relative',marginBottom:'0px'}}>
                                        <div style={{width:'30%',fontSize:'14px'}}> {item.label}</div>
                                        <div style={{width:'70%'}}>
                                            {rightComponent}
                                        </div>
                                    </div>
                                    
                                })
                            }
                        </div>
                        {
                            !newCustomization.readableOnly
                            &&
                            <div style={{paddingLeft:'20px',paddingRight:'20px',display:'flex',alignItems:'center',justifyContent:'flex-end',position:'absolute',bottom:0,background:'white',width:'calc(100% - 40px)',paddingBottom:'20px'}}>
                                <Button disabled={submitButtonDisabled() || addCustomizationLoader || updateCustomizationLoader} sx={{...Constants.CONTAINEDBUTTON,minWidth:'50px'}} variant='contained' size='small' color='warning' onClick={()=>{if(newCustomization.isEdit){
                                        // setPopupContainer({valueIndex:newCustomization.valueIndex,developer_name:null,status:1,updatedData:{...newCustomization}})
                                        updateCustomization(newCustomization.valueIndex,null,1,{...newCustomization})
                                    }else{addCustomizationData()}}}>
                                    {addCustomizationLoader || updateCustomizationLoader
                                    &&
                                    <CircularProgress size={'22px'} sx={{color:'white'}}/>
                                    }
                                    {(!addCustomizationLoader && !updateCustomizationLoader)
                                    &&
                                    (newCustomization.isEdit ? (newCustomization.status>=1 ? 'Edit' : 'Approve') : 'Submit')
                                    }
                                </Button>
                                &nbsp;
                                &nbsp;
                                <Button onClick={()=>{setNewCustomization({});setNewCustomizationPopup(false);}} sx={{...Constants.OUTLINEDBUTTON}}  variant='outlined' size='small' color='warning'>
                                    Cancel
                                </Button>
                            </div>
                        }
                    </div>
                </div>
            }
            {
                popupContainer
                &&
                <div style={{position:'fixed',height:'100vh',width:'100vw',display:'flex',alignItems:'center',justifyContent:'center',top:0,left:0,background:'rgba(50,50,50,0.1)',zIndex:11}}>
                    <div style={{position:'absolute',height:'20vh',width:'30vw',background:'white'}}>
                        <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',padding:'10px'}}>
                            <div>
                                Warning Popup
                            </div>
                            <div onClick={()=>{setPopupContainer(false)}}>
                                X
                            </div>
                        </div>      
                        <div style={{height:'calc(20vh - 40px)',display:'flex',flexDirection:'column',justifyContent:'center'}}>
                            <div style={{padding:'10px',fontSize:'14px',display:'flex',justifyContent:'center'}}>
                                Are you sure you want to {popupContainer.status === 0 ? 'Add New': popupContainer.status === 1 ? 'Approve' : popupContainer.status === 2 ? 'Assign' : popupContainer.status === 3 ? 'Set Done' : popupContainer.status === 4 ? 'Delete this' : 'Perform this action on given '} customization?
                            </div>
                            <div style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                                <Button sx={{transform:'scale(0.8)'}} variant='contained' color='warning' onClick={()=>{updateCustomization(popupContainer.valueIndex,popupContainer.developer_name,popupContainer.status,{...popupContainer.updatedData})}}>
                                    {updateCustomizationLoader && <CircularProgress sx={{color:'white'}} size={'14px'}/>}
                                    {!updateCustomizationLoader && "Yes"}
                                </Button>
                                <Button sx={{transform:'scale(0.8)'}} variant='outlined' color='warning'>Cancel</Button>
                            </div>                  
                        </div>                  
                    </div>
                </div>
            }
            <div style={{marginTop:'10px',marginBottom:'10px',padding:'10px'}}>
                <FilterRow
                    filtersList = {filterList}
                    filterData={filterData}
                    setFilterData={overrideSetFilterData}
                    filterItemWidth='14.28'
                />
            </div>
            <div style={{padding:'10px',display:'flex',width:'calc(100% - 20px)',fontSize:'14px',marginBottom:'10px'}}>
                <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',width:'100%',border:'1px solid rgb(220,220,220)',padding:'15px',position:'relative'}}>
                    <div style={{position:'absolute',top:'-8px',left:'20px',background:'white',fontSize:'12px'}}> Last 7 Days Summary</div>
                    <div>Task Pending : {taskSummary.uncompleted_task}</div>
                    <div>Task Completed : {taskSummary.completed_task}</div>
                </div>
            </div>
            {
                loader
                &&
                <div style={{display:'flex',alignItems:'center',justifyContent:'center',height:'30vh'}}>
                    <CircularProgress size={'22px'} sx={{color:getLocalStorageValue('ambientColor2')}}/>
                </div>
            }
            {
                !loader &&
                <div style={{width:'100%',overflow:'auto',minHeight:'70vh',fontSize:'12px'}}>
                    {customizationData
                    &&
                    <TableModel
                        showSerial={true}
                        tableHeaders={tableHeaders}
                        tableValues={customizationData}
                        // tableRowClicked={tableRowClickedFunction}
                        />
                    }
                </div>
            }
            {(statusAssignClicked)
                &&
                <div onClick={(e)=>{e.stopPropagation()}} style={{position:'absolute',height:'240px',width:'200px',marginLeft:'-40px',marginTop:'25px',zIndex:'8',overflow:'auto',left:document.getElementById('stintCustomizations').scrollLeft+statusAssignClicked.gc.left,top:document.getElementById('stintCustomizations').scrollTop+statusAssignClicked.gc.top,background:'white',boxShadow:'0px 5px 10px 5px rgba(200,200,200,0.4)'}}>
                    {
                        developerOptions().map((item,index)=>{
                            return <div className='devOptions' style={{padding:'10px'}} onClick={()=>{updateCustomization(statusAssignClicked.valueIndex,item.value,2)}}>
                                {item.label}
                                </div>
                        })
                    }
                </div>
            }
        </div>
    )
}

export default StintCustomizations