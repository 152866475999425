import React,{useState, useEffect, useRef} from 'react';
import * as Constants from '../../../data/constants';
// import { CssTextField, ReactSelectStyles } from '../../helper/util';
import './localfire.css';
import '../../../css/pages/v1/debugscript.css'
import axios from 'axios';
import { getLocalStorageValue, useForceUpdate } from '../../../helper/util';
import { DragDropFile } from '../../../components/dragDropFile/dragDropFile';
import ReactSelect from 'react-select';
import { Button, CircularProgress, TextField } from '@mui/material';
import TopbarV2 from '../../../components/topbarv2/topbarv2';
import { Store } from 'react-notifications-component';
import { CloseOutlined } from '@mui/icons-material';
import TableModel from '../../../components/tableModel/tablemodel'

const SingleLinkFire = (props) => {
    const refreshComponent = useForceUpdate();
    const [scriptSelected, setScriptSelected] = useState(null)
    const [linkData, setLinkData] = useState({})
    const uploadScriptResponse = useRef({})
    const [historyResponse, setHistoryResponse] = useState([])
    const [loader, setLoader] = useState(false)

    const scriptSelectedFunction = (file) => {
        setScriptSelected(file);
    };

    var getLogsUploadHistory = async() => {
        setLoader(true);
        await axios({
            method: "GET",
            url: Constants.BACKENDHOST+"local_install/history",
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token,
            },
        })
        .then(resp => {
            if(resp.data.response_code===200){
                setHistoryResponse(resp.data.data.items)
                // setHistoryPages(resp.data.data.pages)
            }
        })
        .catch(err=>{
            if(err.response && err.response.data){
                Store.addNotification({
                    title: "Error!",
                    message: err.response.data.message,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });
            }
        })
        setLoader(false);
    }

    const clearLogs = async(e) => {
        await axios({
            method: "GET",
            url: Constants.BACKENDHOST+"local_install/logscleaner",
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token,
            },
        })
        .then(resp => {
           
        })
        .catch(err=>{
            if(err.response && err.response.data){
                Store.addNotification({
                    title: "Error!",
                    message: err.response.data.message,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });
            }
        })
    }
    const uploadFile = async(e) => {
        var formData = new FormData();
        formData.append("script", scriptSelected);
        formData.append("scriptName", "test");
        await axios({
            method: "POST",
            url: Constants.BACKENDHOST + 'local_install/upload', //Constants.BACKENDHOST+"tools/create_flow_report",
            headers:{
                "Content-Type": "multipart/form-data",
                Authorization : getLocalStorageValue('adminPanelLoginData').token,
            },
            params:{
                country:linkData.country ? linkData.country.value : undefined,
                link:linkData.link,
            },
            data: formData,
        }).then(resp => {
            Store.addNotification({
                title: "Request Created!",
                message: 'Your firing request has been recorded, your request is added in queue.',
                type: "success",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 5000,
                    onScreen: true
                }
            });
            getLogsUploadHistory()
            
        })
    }

    const downloadFile = (data,file) => {
        file = file.split('.py')[0]
        if(!file.endsWith('.txt')){
            file+='.txt'
        }
        const urldownload = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = urldownload;
        link.setAttribute('download', file); //or any other extension
        document.body.appendChild(link);
        link.click();
    }
    const getLogs = async(dataItem) => {
        var extraDetails = JSON.parse(dataItem.extra_text)
        await axios({
            method: "GET",
            url: Constants.BACKENDHOST + 'local_install/getLogs', //Constants.BACKENDHOST+"tools/create_flow_report",
            headers:{
                Authorization : getLocalStorageValue('adminPanelLoginData').token,
            },
            params:{
                script_name:dataItem.script_name,
                log_file:extraDetails.log_file,
            },
        }).then(async(resp) => {
            downloadFile(resp.data,dataItem.script_name)
        })
    }
    
    useEffect(() => {
        getLogsUploadHistory()
    }, [])

    const FiredAtComponent = (item) => {
        return new Date(item.created_at).toLocaleDateString("en-IN",{year:'numeric',month:'2-digit',day:'2-digit',hour:'numeric',minute:'numeric',second:'numeric'})
    }
    const downloadLogComponent = (item) => {
        return <div style={{color:getLocalStorageValue('ambientColor')}} onClick={()=>{getLogs(item)}}>
            Download
        </div>
    }
    const tableHeaders = [
        {label:'Script Name',value:'script_name',width:130,locked:true,showTooltip:true},
        // {label:'Event Value',value:'event_value',width:110,customComponent:eventValueComponent,customStates:{eventValuePopup,setEventValuePopup}},
        {label:'Status',value:'status',style:{textTransform:'capitalize'},width:34},
        {label:'Fired At',value:'created_at',style:{textTransform:'uppercase'},width:90,customComponent:FiredAtComponent},
        {label:'Country',value:'country',style:{textTransform:'uppercase'},width:50},
        {label:'Link',value:'link',width:300},
        {label:'Logs',value:'Download',width:30,customComponent:downloadLogComponent},
    ]
    return (
        <div>
            <TopbarV2 title='Local Fire'/>
            
            <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',padding:'10px',paddingLeft:'10px',paddingRight:'10px',paddingBottom:'0px'}}>
                <div>Add Script For Local Fire</div>
            </div>
            <div className='singleLinkDataContainer' onClick={(e)=>{e.stopPropagation();}}>
                <div className='singleLinkTextfield'>
                    <Constants.CssTextField 
                        fullWidth
                        size='small'
                        value={linkData.link ? linkData.link : ''}
                        label={'Link'} 
                        onChange={e=>{setLinkData({...linkData,link:e.target.value})}}
                        placeholder='Link'/>
                </div>
                <div className='singleLinkFileContainer'>
                    <DragDropFile handleChangeMethod={scriptSelectedFunction} fileSelected={scriptSelected}/>
                </div>
                <div className='singleLinkTextfield' style={{marginTop:'20px'}}>
                    <ReactSelect
                        value={linkData.country ? linkData.country : null}
                        onChange={(e)=>{setLinkData({...linkData,country:e})}}
                        // styles={{...ReactSelectStyles}}
                        placeholder={'Select Country'}
                        options={Object.keys(Constants.COUNTRIES_LIST_WITH_CODE).map((item,index)=>{return {label:Constants.COUNTRIES_LIST_WITH_CODE[item],value:item}})}
                    />
                </div>
                <div style={{marginTop:'20px',display:'flex',alignItems:'center'}}>
                    <Button
                        color='warning'
                        variant='outlined'
                        sx={{textTransform:'none'}}
                        onClick={uploadFile}
                    >
                        Single Link Fire
                    </Button>
                    <Button
                        color='warning'
                        variant='outlined'
                        sx={{textTransform:'none',marginLeft:'20px'}}
                        onClick={()=>{setScriptSelected(null);setLinkData({});refreshComponent(123);}}
                    >
                        Reset
                        </Button>
                </div>

            </div>
            <div style={{fontWeight:500,padding:'10px',fontSize:'18px'}}>
                Fire History (Last 7 days)
            </div>
            {
                loader &&
                <CircularProgress size={20} sx={{color:getLocalStorageValue('ambientColor')}}/>
            }
            {!loader && historyResponse.length>0
            &&
            <div style={{height:'400px',overflow:'auto'}}>
                <TableModel
                    tableHeaders={tableHeaders}
                    tableValues={historyResponse}
                />
            </div>
            }
        </div>
    )
}

export default SingleLinkFire