import React from 'react';
import { useState } from 'react';
import TopbarV2 from '../../components/topbarv2/topbarv2';
import './stint.css';

import TocIcon from '@mui/icons-material/Toc';
import GridViewIcon from '@mui/icons-material/GridView';
import { Button, IconButton, Tooltip } from '@mui/material';
import { KanbanBoard } from './kanban/stintKanbanBoard';
import FilterComponent from './filterComponent';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import GroupRemoveIcon from '@mui/icons-material/GroupRemove';

import * as Constants from '../../data/constants';
import { getLocalStorageValue, setLocalStorageValue, useForceUpdate } from '../../helper/util';
import axios from 'axios';
import { useEffect } from 'react';
import FilterRow from './filterRow';
import { getThisWeek, thisMonth } from '../../components/dateRangePicker/dateRangePicker';

const StintPage = () => {
    var url = new URL(window.location.href)
	var tempUrlDetails = {}
	var urlParams = [
		{key:'cardid',defaultValue:false}
	]
	for(var urlParamIndex in urlParams){
		var urlParamItem = urlParams[urlParamIndex]
		if(url.searchParams.get(urlParamItem.key)){
			tempUrlDetails[urlParamItem.key] = url.searchParams.get(urlParamItem.key)
		}
		else{
			if(urlParamItem.defaultValue){
				tempUrlDetails[urlParamItem.key] = urlParamItem.defaultValue
			}
			else{
				tempUrlDetails[urlParamItem.key] = false
			}
		}
	}
    var resetTempFilterData ={
        'assigned_date_from_date':new Date(new Date().getTime()-86400000*15),
        'assigned_date_end_date':new Date()
    }
    var tempFilterData = {
        ...(getLocalStorageValue('stintFilter') ? getLocalStorageValue('stintFilter') : {}),
        ...resetTempFilterData
    }
    const [whichView, setWhichView] = useState('board')
	const [filterData, setFilterData] = useState(tempFilterData);
	const [assigneeOptions, setAssigneeOptions] = useState({});
    const [productManagers, setProductMangers] = useState({})
    const [usersDict, setUsersDict] = useState({})
	const [loader, setLoader] = useState(false);
	const [detailData, setDetailData] = useState({});
	const [subtaskList, setSubtaskList] = useState([]);
	const [data, setData] = useState({});
	const [taskHistoryDict, setTaskHistoryDict] = useState({});
	const [commentsDict, setCommentsDict] = useState({});
	const [taskDetailLoader, setTaskDetailLoader] = useState(false);
	const [openCard, setOpenCard] = useState(tempUrlDetails.cardid);

	const columnsFromBackend = {
		'Not Assigned': {
			title: 'To-do',
			items: [],
		},
		'in_progress': {
		  title: 'In Progress',
		  items: [],
		},
		'in_verification': {
		  title: 'In Verification',
		  items: [],
		},
		'done': {
		  title: 'Done',
		  items: [],
		},
		'hold_issue': {
            title: 'Hold/Issue',
            items: [],
          },
	};
	const [columns, setColumns] = useState(columnsFromBackend);
	const getTeamMembers = async() => {
        await axios({
            method: 'GET',
            url: "".concat(Constants.BACKENDHOST,"getteammembers"),
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token,
            },
        })
        .then(resp => {
			var data = resp.data.data
			var tempAssignee = {}
			for(var user_id in data.users){
				tempAssignee[user_id] = data.users[user_id].name
			}
			setAssigneeOptions(tempAssignee)
			setProductMangers(data.product_managers)
			setUsersDict(data.users)
        })
        .catch(err => {
            console.error(err);
        })
    };

	const getTaskHistory = async(cardid) => {
		// setLoader(true)
        await axios({
            method: 'GET',
            url: "".concat(Constants.BACKENDHOST,"task_tracker/getHistory"),
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token,
            },
			params:{
				task_id:cardid
			}
        })
        .then(resp => {
			var data = resp.data.data
			setTaskHistoryDict({...taskHistoryDict,[cardid]:data.comments})
        })
        .catch(err => {
            console.error(err);
        })
		// setLoader(false)
    };

	const getComments = async(cardid) => {
		// setLoader(true)
        await axios({
            method: 'GET',
            url: "".concat(Constants.BACKENDHOST,"task_tracker/getComments"),
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token,
            },
			params:{
				task_id:cardid
			}
        })
        .then(resp => {
			var data = resp.data.data.comments
			setCommentsDict({...commentsDict,[cardid]:data})
        })
        .catch(err => {
            console.error(err);
        })
		// setLoader(false)
    };
	
	const getTaskDetails = async(taskid,data={}) => {
        setTaskDetailLoader(true)
        await axios({
            method: 'GET',
            url: "".concat(Constants.BACKENDHOST,"task_tracker/taskDetails"),
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token,
				'Content-Type':'application/json'
            },
			params:{
				task_id:parseInt(taskid),
			}
        })
        .then(resp => {
			var data_ = resp.data.data
			var data_new = {...data,[data_.id]:data_}
			setDetailData(data_new)
			var subtaskDict = resp.data.data.sub_task
			var tempList = []
			for(var taskid in subtaskDict){
				if(subtaskDict[taskid].title){
					var title = subtaskDict[taskid].title.split('] ')[1]
					var subtask_tag = subtaskDict[taskid].title.split('] ')[0].split('[')[1]
				}
				tempList.push({...subtaskDict[taskid],id:taskid,title,subtask_tag})
			}
			setSubtaskList(tempList)
        })
        .catch(err => {
            console.error(err);
        })
        setTaskDetailLoader(false)
    };

    function getFilterDict(){
        var filter_dict = {}
        for(var index in filterList){
            var item = filterList[index]
            if(item.type==='daterange'){
                if(filterData[item.value+'_from_date']){
                    filter_dict[item.value+'_from_date'] = new Date(new Date(filterData[item.value+'_from_date']).getTime() + 19800000)
                }
                if(filterData[item.value+'_end_date']){
                    filter_dict[item.value+'_end_date'] = new Date(new Date(filterData[item.value+'_end_date']).getTime() + 86399999+19800000)
                }
            }
            if(item.type==='textfield'){
                if(filterData[item.value]){
                    filter_dict[item.value] = filterData[item.value]
                }
            }
            if(item.type==='select' && item.multiple){
                var value = ''
                for(var xIndex in filterData[item.value]){
                    var xItem = filterData[item.value][xIndex]
                    if(parseInt(xIndex)===filterData[item.value].length-1){
                        value+= xItem.value
                    }
                    else{
                        value+= xItem.value + ','
                    }
                }
                if(value || value===0){
                    filter_dict[item.value] = value
                }
            }
            if(item.type==='select' && item.isSingle){
                if(filterData[item.value] && filterData[item.value].value){
                    filter_dict[item.value] = filterData[item.value].value
                }
            }
        }
        return filter_dict
    }
	const getStintData = async(cardid) => {
		setLoader(true)
        var filterDict = getFilterDict()
        await axios({
            method: 'GET',
            url: "".concat(Constants.BACKENDHOST,"task_tracker/allTasks"),
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token,
            },
            params:{
                ...filterDict
            }
        })
        .then(resp => {
			var data = resp.data.data
			setData(data)
			if(cardid){
				getTaskDetails(cardid,data)
				getComments(cardid)
				getTaskHistory(cardid)
			}
			var tempColumns = columnsFromBackend
            for(var index in data){
				var item = data[index]
				item = {id:index,...item}
                if(!tempColumns[item.status].items.includes(index)){
                    tempColumns[item.status].items.push(index)
                }
			}
			setColumns(tempColumns)
        })
        .catch(err => {
            console.error(err);
        })
		setLoader(false)
    };

    const [manualUseState, setManualUseState] = useState(false)
    useEffect(() => {
        getTeamMembers()
        if(!manualUseState){
            setManualUseState()
            getStintData(openCard)
        }
    }, [])
    
    const overrideSetFilterData = (value,filterItem) => {
        if(filterItem==='dateRangePicker'){
            var new_dict = {...filterData,[filterItem.value]:value}
            setFilterData({...filterData,...value})
        }
        else{
            new_dict = {...filterData,[filterItem.value]:value}
            setFilterData(new_dict)
        }
        setLocalStorageValue('stintFilter',new_dict)
    }
    var assigneeOptionsList = Object.keys(assigneeOptions).map((item,index)=>{return {label:assigneeOptions[item],value:item}})
    var filterList = [
        {label:'Assigned Date',value:'assigned_date',type:'daterange'},
        {label:'Done Date',value:'done_date',type:'daterange',checkInFilterData:true},
        {label:'Assigned By',value:'assigned_by',type:'select',options:assigneeOptionsList,multiple:true,icon:GroupAddIcon},
        {label:'Assigned To',value:'assigned_to',type:'select',options:assigneeOptionsList,multiple:true,icon:GroupRemoveIcon},
    ]
    return (
        <>
            <TopbarV2 title={'Stint'}/>
            <div className='stintPage'>
                <div className='stintFirstRow'>
                    <div className='stingIconHolder'>
                        <Tooltip title='Switch To Board View'>
                            <IconButton size='small' onClick={()=>{setWhichView('board')}}>
                                <GridViewIcon fontSize='14px' sx={{color:whichView==='board' ? 'orange' : 'rgb(200,200,200)'}}/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title='Switch To Table View'>
                            <IconButton size='small' onClick={()=>{setWhichView('table')}}>
                                <TocIcon fontSize='14px' sx={{color:whichView==='table' ? 'orange' : 'rgb(200,200,200)'}}/>
                            </IconButton>
                        </Tooltip>
                    </div>
                    <div style={{fontWeight:'500'}}>
                        {whichView==='board' ? 'Kanban Board' : 'Table View'}
                    </div>
                    <div style={{marginLeft:'10px'}}>
                        <FilterComponent filterList={filterList} filterData={filterData} setFilterData={overrideSetFilterData} submitRequest={()=>{getStintData()}} resetRequest={()=>{setFilterData(resetTempFilterData);setLocalStorageValue('stintFilters',{})}}/>
                    </div>
                    <div style={{marginLeft:'10px'}}>
                        <FilterRow filterData={filterData} setFilterData={setFilterData} filterList={filterList}/>
                    </div>
                </div>
            </div>
            {/* KANBAN BOARD */}
            {whichView==='board'
                &&
                <div className='kanbanBoard'>
                    <KanbanBoard 
                    filterData={filterData} 
                    setFilterData={filterData} 
                    assigneeOptions={assigneeOptions}
                    productManagers={productManagers}
                    usersDict={usersDict}
                    detailData={detailData}
                    setDetailData={setDetailData}
                    subtaskList={subtaskList}
                    setSubtaskList={setSubtaskList}
                    data={data}
                    setData={setData}
                    taskHistoryDict={taskHistoryDict}
                    setTaskHistoryDict={setTaskHistoryDict}
                    commentsDict={commentsDict}
                    setCommentsDict={setCommentsDict}
                    columns={columns}
                    setColumns={setColumns}
                    loader={loader}
                    setLoader={setLoader}
                    openCard={openCard}
                    setOpenCard={setOpenCard}
                    getTaskDetails={getTaskDetails}
                    getComments={getComments}
                    getTaskHistory={getTaskHistory}
                    taskDetailLoader={taskDetailLoader}
                />
                </div>
            }
            {/* <div className='stintFooter'>
                Created by Team 2 with &#128420;
            </div> */}
        </>
    )
}

export default StintPage