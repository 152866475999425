import { useState, useEffect } from "react";
import React from "react";
import parse from "paste-from-excel";
import { Button, CircularProgress } from "@mui/material";
import { CssTextField } from "../../data/constants";
import axios from "axios";
import * as Constants from '../../data/constants';
import { Store } from "react-notifications-component";
import { getLocalStorageValue } from "../../helper/util";

const CompareWithAppFlow = (props) => {
    var label = { labels: ["EventName", "Install","Open"] }
    const [resultData, setResultData] = useState(false)
    const [urlForFlow, setUrlForFlow] = useState(false)
    const [loader,setLoader] = useState(false)
    const [dataFromFlow, setDataFromFlow] = useState({})
    var rows = []
    for(var i=0;i<=200;i++){
        rows.push({ "EventName": "", "Install": "", "Open" : "" })
    }
    const [inputvalue, setinputvalue] = useState({
        inputs: rows
      });
      
    const handlePaste = (index, elm, e, i) => {
        return parse(e);
    };

    useEffect(() => {
        document.addEventListener('keydown',e=>{
            if(e.key==='Escape'){
                setResultData(false);props.setShowCompareWithAppFlow(false);
            }
        })
        
        return () => {
            document.removeEventListener('keydown',e=>{
                if(e.key==='Escape'){
                    setResultData(false);props.setShowCompareWithAppFlow(false);
                }
            })
        }
    }, [])
    
    const handlePaste1 = (index, elm, e, i) => {
        setinputvalue((inputvalue) => ({
        ...inputvalue,
        inputs: inputvalue.inputs.map((item, i) =>
            index === i
            ? {
                ...item,
                [elm]: e.target.value
                }
            : item
        )
        }));
    };

    const compareFunction = (e,inputData) => {
        var fileData = props.fileData
        var comparedDictionary = {}
        var notFoundInScript = []
        var notFoundInFlow = []
        for(var index in inputData){
            var item = inputData[index]
            var eventFound = false
            if(item.EventName){
                for(var fileIndex in fileData){
                    var fileItem = fileData[fileIndex]
                    if(fileItem.event_name===item.EventName){
                        eventFound = true
                        comparedDictionary[item.EventName] = {
                            EventName:item.EventName,
                            InstallAppFlow : item.Install,
                            InstallScript : fileItem.install.overall,
                            OpenAppFlow : item.Open,
                            OpenScript : fileItem.open.overall,
                        }                        
                    }
                }
            }
            if(!eventFound){
                if(item.EventName!==''){
                    notFoundInScript.push(item.EventName)
                }
            }
        }
        for(fileIndex in fileData){
            eventFound = false
            var fileItem = fileData[fileIndex]
            for(index in inputData){
                var item = inputData[index]
                if(fileItem.event_name===item.EventName){
                    eventFound = true
                }
            }
            if(!eventFound){
                notFoundInFlow.push(fileItem.event_name)
            }
        }
        for(item in notFoundInFlow){
            comparedDictionary[notFoundInFlow[item]] = {
                EventName:notFoundInFlow[item],
                InstallAppFlow : 'Not Found In Flow',
                InstallScript : 'Not Found In Flow',
                OpenAppFlow : 'Not Found In Flow',
                OpenScript : 'Not Found In Flow',
            }  
        }
        for(item in notFoundInScript){
            comparedDictionary[notFoundInScript[item]] = {
                EventName:notFoundInScript[item],
                InstallAppFlow : 'Not Found In Script',
                InstallScript : 'Not Found In Script',
                OpenAppFlow : 'Not Found In Script',
                OpenScript : 'Not Found In Script',
            }  
        }
        console.log('herere')
        setResultData({
            notFoundInFlow,
            notFoundInScript,
            comparedDictionary
        })
    }

    const getStyles = (item,type) => {
        if(!type){
            if(item.InstallAppFlow==='Not Found In Flow' || item.InstallAppFlow==='Not Found In Script'){
                return {background:'rgb(255,100,100)'}
            }
        }
        else{
            if(type==='install'){
                if((item.InstallAppFlow-item.InstallScript > 5 && item.InstallAppFlow-item.InstallScript < 10) || (item.InstallAppFlow-item.InstallScript < -5 && item.InstallAppFlow-item.InstallScript > -10)){
                    return {background:'rgb(255, 192, 75)'}
                }
                if(item.InstallAppFlow-item.InstallScript > 10 || item.InstallAppFlow-item.InstallScript < -10){
                    return {background:'rgb(255, 100, 100)'}
                }
            }
            if(type==='open'){
                if((item.OpenAppFlow-item.OpenScript > 5 && item.OpenAppFlow-item.OpenScript < 10) || (item.OpenAppFlow-item.OpenScript < -5 && item.OpenAppFlow-item.OpenScript > -10)){
                    return {background:'rgb(255, 192, 75)'}
                }
                if(item.OpenAppFlow-item.OpenScript > 10 || item.OpenAppFlow-item.OpenScript < -10){
                    return {background:'rgb(255, 100, 100)'}
                }
            }
        }
        return {}
    }

    const getDataFromBackend = async(e) => {
        setLoader(true);
        await axios({
            method: "GET",
            url: Constants.BACKENDHOST+"tools/compare_flow_report",
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token,
            },
            params:{
                sheet_url:urlForFlow
            }
        })
        .then(resp => {
            var data = Object.keys(resp.data.data).map((item)=>{
                console.log(item)
                return {...resp.data.data[item],EventName:item}
            })
            compareFunction(e,data);
            setDataFromFlow(data);
        })
        .catch(err=>{
            console.log(err)
            if(err.response && err.response.data){
                Store.addNotification({
                    title: "Error!",
                    message: err.response.data.data.error,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });
            }
        })
        setLoader(false);

    }
    return (
        <div className='addTabContainer'>
            <div className='addTabWrapper' style={{minHeight:'20vh',width:'70vw'}}>
                <div style={{display:'flex',justifyContent:'space-between',alignItems:'center',cursor:'default'}}>
                    <div>Compare With App Flow</div>
                    <div style={{cursor:'pointer'}} onClick={()=>{setResultData(false);props.setShowCompareWithAppFlow(false);}}>X</div>
                </div>
                {
                    !resultData
                    &&
                    <div>
                        <div style={{display:'flex',justifyContent:'center',alignItems:'center',height:'15vh',overflow:'auto',marginTop:'20px'}}>
                            <CssTextField placeholder={'Appflow Link'} size={'small'} fullWidth value={urlForFlow ? urlForFlow : ''} onChange={e=>{setUrlForFlow(e.target.value);}}/>
                        </div>
                        <div style={{display:'flex',justifyContent:'center',marginTop:'20px'}}>
                            <Button sx={{minWidth:'80px',minHeight:'40px'}} disabled={urlForFlow===''} variant="outlined" color='warning' size='small' onClick={getDataFromBackend}>
                                {loader && 
                                <CircularProgress size={'15px'} sx={{fontSize:'15px',color:'orange'}}/>
                                }
                                {!loader
                                &&
                                'Submit'
                                }
                            </Button>
                        </div>
                    </div>
                }
                {
                    resultData
                    &&
                    <div>
                        <div style={{display:'flex',flexDirection:'column',height:'55vh',overflow:'auto',marginTop:'20px'}}>
                            <table style={{tableLayout:'fixed',borderSpacing:'0px'}}>
                                <thead>
                                    <tr style={{position:'sticky',top:'0px',background:'white',fontSize:'14px'}}>
                                        <td width={'35%'} className="comparedScriptHeader">EventName</td>
                                        <td width={'14%'} className="comparedScriptHeader">Install From AppFlow</td>
                                        <td width={'14%'} className="comparedScriptHeader">Install From Script</td>
                                        <td width={'14%'} className="comparedScriptHeader">Open From AppFlow</td>
                                        <td width={'14%'} className="comparedScriptHeader">Open From Script</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Object.keys(resultData.comparedDictionary).map((eventName,eventIndex)=>{
                                        var dict = resultData.comparedDictionary[eventName]
                                        return <tr style={{...getStyles(dict),fontSize:'13px'}}>
                                            <td className="comparedScriptValue">{dict.EventName}</td>
                                            <td style={{...getStyles(dict,'install')}} className="comparedScriptValue" colSpan={dict.InstallAppFlow!=='Not Found In Flow' && dict.InstallAppFlow!=='Not Found In Script' ? 0 : 4}>{dict.InstallAppFlow}</td>
                                            {
                                                dict.InstallAppFlow!=='Not Found In Flow' && dict.InstallAppFlow!=='Not Found In Script' && 
                                                <td style={{...getStyles(dict,'install')}} className="comparedScriptValue">{dict.InstallScript}</td>
                                            }
                                            {
                                                dict.InstallAppFlow!=='Not Found In Flow' && dict.InstallAppFlow!=='Not Found In Script' && 
                                                <td style={{...getStyles(dict,'open')}} className="comparedScriptValue">{dict.OpenAppFlow}</td>
                                            }
                                            {
                                                dict.InstallAppFlow!=='Not Found In Flow' && dict.InstallAppFlow!=='Not Found In Script' && 
                                                <td  style={{...getStyles(dict,'open')}} className="comparedScriptValue">{dict.OpenScript}</td>
                                            }
                                        </tr>
                                    })}
                                </tbody>
                            </table>
                        </div>
                        <Button variant="outlined" color='warning' size='small' onClick={()=>{setResultData(false)}}>
                            Back
                        </Button>
                    </div>
                }
            </div>
        </div>
    )
}

export default CompareWithAppFlow