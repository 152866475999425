import { Button, CircularProgress, Switch } from '@mui/material';
import React, { useEffect, useState } from 'react'
import HistoryComponent from '../../../components/historyComponent/historyComponent'
import TopbarV2 from '../../../components/topbarv2/topbarv2'
import '../../../css/pages/v1/newapp.css';
import * as Constants from '../../../data/constants';
import '../../../css/pages/v1/datadifference.css';
import { Store } from 'react-notifications-component';
import axios from 'axios';
import { bytesToSize, capitalize, getLocalStorageValue, useForceUpdate } from '../../../helper/util';
import FiddlerImg from '../../../img/fiddler.png'
import Popup from '../../../components/popup/popup';
import ReactSelect from 'react-select';
import MinorUpdatePopup from './minorUpdatePopup';
import ErrorComponent from '../../../components/error/error';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
const DataDifference = () => {
    const uploadFileSize = 25000000
    const refreshComponent = useForceUpdate()
    if(localStorage.getItem(window.location.pathname+'historyWindowWidth')){
        var tempHistoryWindowWidth = localStorage.getItem(window.location.pathname+'historyWindowWidth')
    }
    else{
        tempHistoryWindowWidth = 300
    }

    useEffect(() => {
        document.addEventListener('keydown',(e)=>{
            if(e.key==='Escape'){
                setShowPopup(false);
            }
        })
        
        return () => {
            document.removeEventListener('keydown',(e)=>{
                if(e.key==='Escape'){
                    setShowPopup(false);
                }
            })
        }
    }, [])
    
    const [useEffectManual, setUseEffectManual] = useState(false)
    const [historyWindowWidth, setHistoryWindowWidth] = useState(tempHistoryWindowWidth)
    const [historyPage, setHistoryPage] = useState(1)
    const [historyPages, setHistoryPages] = useState(0)
    
    const [historyResponse, setHistoryResponse] = useState([])
    const [historyResponseLoader, setHistoryResponseLoader] = useState(false)
    const [addLogsLoader, setAddLogsLoader] = useState(false)
    const [selectedLogFile, setSelectedLogFile] = useState(false)
    const [logFileName, setLogFileName] = useState(false)
    const [showPopup, setShowPopup] = useState(false)
    const [activeTab, setActiveTab] = useState(false)
    const [openTabsList, setOpenTabsList] = useState([])
    const [selectedItem, setSelectedItem] = useState({})
    const [scriptName, setSelectedScriptName] = useState(false)
    const [showMatchedData, setShowMatchedData] = useState(false)
    const [showMinorUpdate, setShowMinorUpdate] = useState(false)
    const [countrySelected, setCountrySelected] = useState(false)
    
    const [devkey, setDevkey] = useState(false)
    const [loaderPopup, setLoaderPopup] = useState(false)

    var getLogsUploadHistory = async(manualPage) => {
        setHistoryResponseLoader(true);
        await axios({
            method: "GET",
            url: Constants.BACKENDHOST+"scriptcreator/campaignlogs",
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token,
            },
            params:{
                page:manualPage ? manualPage : historyPage,
            }
        })
        .then(resp => {
            if(resp.data.response_code===200){
                setHistoryResponse(resp.data.data.items)
                setHistoryPages(resp.data.data.pages)
            }
        })
        .catch(err=>{
            if(err.response && err.response.data){
                Store.addNotification({
                    title: "Error!",
                    message: err.response.data.message,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });
            }
        })
        setHistoryResponseLoader(false);
    }
    
    if(!useEffectManual){
        setUseEffectManual(true)
        getLogsUploadHistory()
    }
    
	const handleChooseLogFile = (event) => {
        if(event.target && event.target.files.length>0){
            setSelectedLogFile(event.target.files[0]);
            if(event.target.files[0]){
                if(event.target.files[0].name){
                    setLogFileName(event.target.files[0].name)
                }
            }
        }
        event.target.files = null
        event.target.value = null
	};

    var pushLogsApiRequest = async() => {
        setAddLogsLoader(true);
        const formData = new FormData();
        formData.append("log_file", selectedLogFile);
        formData.append("purpose", 'datadifference');
        await axios({
            method: "POST",
            url: Constants.BACKENDHOST+"scriptcreator/campaignlogs",
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token,
                "Content-Type": "multipart/form-data",
            },
            params:{
                purpose:'datadifference',
            },
            data:formData
        })
        .then(resp => {
            
        })
        .catch(err=>{
            if(err.response && err.response.data){
                Store.addNotification({
                    title: "Error!",
                    message: err.response.data.message,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });
            }
        })
        getLogsUploadHistory()
        setAddLogsLoader(false);
        setLogFileName(false);
        setShowPopup(false);
    }

    var getDataDifference = async(item,isDecrypt,fromError) => {
        setAddLogsLoader(true);

        var url = Constants.BACKENDHOST+"scriptcreator/datadifference"
        var method = scriptName ? 'GET' : 'POST'
        const formData = new FormData();
        var params = {logs_serial : selectedItem.logsserial, 
                      ignore_matched_value : showMatchedData ? false : true,
                    }
        if(scriptName){
            params['script_name'] = scriptName
        }
        else{
            formData.append("campaign", selectedLogFile);
        }
        if(countrySelected){
            params['country'] = countrySelected
        }
        if(devkey){
            params['devkey'] = devkey
        }
        await axios({
            method,
            url ,
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token,
                "Content-Type": "multipart/form-data",
            },
            params,
            data:formData,
        })
        .then(resp => {
            // openTabs({...selectedItem,datadifference:resp.data.data.difference})
            // refreshComponent(1);
            getLogsUploadHistory()
            if(resp.data.message){
                Store.addNotification({
                    title: "Success!",
                    message: resp.data.message,
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });
            }
        })
        .catch(err=>{

            if(err.response && err.response.data){
                Store.addNotification({
                    title: "Error!",
                    message: err.response.data.message,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });
            }
        })
        setAddLogsLoader(false);
        setShowPopup(false);
        setSelectedScriptName(false);
        setLogFileName(false);
        setDevkey(false);
    }

    var getDataDifferenceDownload = async(item) => {
        setAddLogsLoader(true);
        openTabs({...item})
        var url = Constants.BACKENDHOST+"scriptcreator/v2/datadifference/download"
        var method ='GET'
        var params = {
            filename:JSON.parse(item.comment).file
        }
        await axios({
            method,
            url ,
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token,
            },
            params,
        })
        .then(resp => {
            openTabs({...item,datadifference:resp.data.data.difference,traceback:resp.data.data.traceback})
            refreshComponent(1);
        })
        .catch(err=>{

            if(err.response && err.response.data){
                Store.addNotification({
                    title: "Error!",
                    message: err.response.data.message,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });
            }
        })
        setAddLogsLoader(false);
    }

    const openTabs = (item) => {
        var itemPresent = false;
        for(var index in openTabsList){
            var tabItem = openTabsList[index]
            if(tabItem.logsserial === item.logsserial){
                itemPresent = parseInt(index)
            }
        }
        if(!itemPresent && itemPresent!==0){
            var tempTabsList = openTabsList;
            tempTabsList.push(item)
            setActiveTab(tempTabsList.length-1)
            setOpenTabsList(tempTabsList);
            refreshComponent(1);
            return true;
        }
        else{
            var tempTabsList = openTabsList;
            tempTabsList[itemPresent] = {...item,...tempTabsList[itemPresent]}
            setActiveTab(tempTabsList.length-1)
            setOpenTabsList(tempTabsList);
            refreshComponent(1);
        }
        return false;
    }

    const closeTab = (item) => {
        var tempTabsList = openTabsList
        for(var index in tempTabsList){
            if(tempTabsList[index].logsname === item.logsname && tempTabsList[index].logsserial === item.logsserial){
                break;
            }
        }
        tempTabsList.splice(index,1)
        setOpenTabsList(tempTabsList);
        if(tempTabsList.length>0){
            setActiveTab(tempTabsList.length-1);
        }
        else{
            setActiveTab(false);
        }
        refreshComponent(1);
    }

    const loaderPopupDetails = {
        'devkey':{
            header:'Developer Key?',
            popupValue: <div>
                <div style={{display:'flex',alignItems:'center',marginBottom:'10px'}}>
                    <div style={{width:'200px'}}>
                        DevKey
                    </div>
                    <div style={{width:'calc(100% - 200px)'}}>
                        <Constants.CssTextField fullWidth defaultValue={devkey ? devkey : ''} id='scriptNameField' size='small'/>
                    </div>
                </div>
                <div style={{display:'flex',alignItems:'center',marginTop:'30px'}}>
                    <Button sx={{...Constants.CONTAINEDBUTTON,fontSize:'12px',transform:'scale(0.8)'}} onClick={()=>{setDevkey(document.getElementById('scriptNameField').value);setLoaderPopup(false);setShowPopup('uploadOrSelectFile');}}>Save</Button>
                </div>
            </div>
        },
    }

    var statusHistoryDict = {
        'Uploaded':{
            color:'orange'
        },
        'NotUploaded':{
            color:'red'
        },
        'Processing':{
            color:'orange'
        },
        'Processed':{
            color:'green'
        },
        undefined:{
            color:'red'
        }
    }

    const statusHistoryComponent = (item,index,tableHead,tableHeadIndex) => {
        if(item.uploadstatus){
            var status = item.uploadstatus
            var bgColor = statusHistoryDict[status] ? statusHistoryDict[status].color : 'red'
        }
        return <div style={{width:'8px',height:'8px',borderRadius:'4px',marginTop:'2px',background:bgColor}}></div>
    }
    const requestedAtComponent = (item,index,tableHead,tableHeadIndex) => {
        var dateOptions = { year: 'numeric', month: 'short', day: 'numeric' ,hour:'numeric',minute:'numeric',second:'numeric'};
        return new Date(item.created_at).toLocaleDateString("en-US", dateOptions)
    }

    var historyHeaders = [
        {label:'',value:'reportstatus',width:'5%',customComponent:statusHistoryComponent},
        {label:'Logs Name',value:'logsname',width:'45%'},
        {label:'Requested At',value:'created_at',width:'50%',customComponent:requestedAtComponent},
    ]
    // var rightClicked = [
    //                     {label:'Upload Script and Compare'},
    //                     {label:'Compare with Server Script'}
    //                     ]
    
    const showPopupFunction = () => {
        var body = {
            'addLogs':{
                title:'Add Logs For Data Difference',
                body:<div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',marginTop:'30px',position:'relative'}}> 
                        <div className='logsIconContainer' onClick={(e)=>{document.getElementById('logsFileUpload').click(e);}}>
                            <input id='logsFileUpload' type='file' hidden onChange={handleChooseLogFile}/>
                            {logFileName
                            &&
                            <img src={FiddlerImg} alt='' width={'80px'}/>}
                             {!logFileName
                             &&
                            'Select Logs'}
                        </div>
                        {logFileName
                        &&
                        <div style={{fontSize:'12px',marginTop:'10px'}}>
                            {logFileName}
                        </div>
                        }
                        <div style={{fontSize:'12px',marginTop:'10px'}}>
                            {selectedLogFile.size>uploadFileSize 
                            && 
                            <label>
                                {'File size is '+bytesToSize(selectedLogFile.size)+'. Allowed Size is 25MB.'}
                            </label>
                            }
                        </div>
                        <div style={{display:'flex',alignItems:'center',fontSize:'14px',marginTop:'20px',width:'100%',justifyContent:'center'}}>
                            <Button id='uploadLogsButton' disabled={addLogsLoader || !logFileName} 
                                color='warning' 
                                variant='outlined' 
                                size='small' 
                                sx={{minHeight:'35px',minWidth:'120px',transition:'all 0.1s linear'}} 
                                onClick={(e)=>{
                                    if(selectedLogFile.size<uploadFileSize){
                                        pushLogsApiRequest(e);
                                    }
                                }}
                                onMouseOver={()=>{
                                    if(selectedLogFile.size>uploadFileSize){
                                        if(document.getElementById('uploadLogsButton').style.marginLeft==='200px'){
                                            document.getElementById('uploadLogsButton').style.marginLeft='-200px'
                                        }
                                        else{
                                            document.getElementById('uploadLogsButton').style.marginLeft='200px'
                                        }             
                                    }
                                }}
                            >
                                {addLogsLoader && <CircularProgress size={'14px'} sx={{color:'orange',fontSize:'12px'}}/>}
                                {!addLogsLoader && 'Upload Logs'}
                            </Button>
                        </div>
                    </div>
            },
            'uploadOrSelectFile':{
                title:'Upload Script Or Select Script',
                body:<div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',marginTop:'40px'}}> 
                        <div style={{display:'flex',alignItems:'center',justifyContent:'space-around'}}>
                            <div style={{width:'200px',fontSize:'14px',color:(logFileName ? 'black' : 'rgb(100,100,100)')}}>
                                {!logFileName && 'No File Selected'}
                                {logFileName && logFileName}
                            </div>
                            <input id='scriptFileUpload' hidden type='file' onChange={handleChooseLogFile}/>
                            <Button disabled={scriptName ? true : false} size='small' color='warning' sx={{transform:'scale(0.9)'}} onClick={()=>{document.getElementById('scriptFileUpload').click()}}>Choose Script</Button>
                            <div style={{fontSize:'12px',fontWeight:'500',marginLeft:'10px',cursor:'pointer'}} onClick={()=>{setLogFileName(false);}}>X</div>
                        </div>
                        <div style={{display:'flex',alignItems:'center',justifyContent:'space-around',width:'90%',marginTop:'20px',marginBottom:'20px'}}>
                            <div style={{width:'100px',borderTop:'1px solid rgb(200,200,200)'}}></div>
                            <div>OR</div>
                            <div style={{width:'100px',borderTop:'1px solid rgb(200,200,200)'}}></div>
                        </div>
                        <div>
                            <Constants.CssTextField disabled={logFileName ? true : false} size={'small'} placeholder={'Script Name'} onChange={(e)=>{setSelectedScriptName(e.target.value)}} onKeyDown={(e)=>{if(e.key === 'Enter'){getDataDifference(e)}}} sx={{transform:'scale(0.9)',width:'250px'}}/>
                        </div>
                        <div style={{display:'flex',alignItems:'center',justifyContent:'space-around',borderTop:'1px solid rgb(220,220,220)',paddingTop:'10px',width:'95%',fontSize:'14px',marginTop:'25px'}}>
                            <div>
                                <div style={{transform:'scale(0.75)',marginTop:'10px',marginBottom:'10px'}}>
                                    <ReactSelect                                                
                                            placeholder={'Country'}
                                            options={Constants.COUNTRIES_LIST.map((item,index)=>{return {label:item,value:item}})}
                                            value={countrySelected ? {label:countrySelected,value:countrySelected} : false}
                                            onChange={(e)=>{setCountrySelected(e.value)}}
                                            menuPortalTarget={document.body} 
                                            styles={{...Constants.ReactSelectStyles}}
                                        />
                                </div>
                                <div>
                                    <label>Show Matched Data</label>
                                    <Switch color='warning' size={'small'} checked={showMatchedData} onChange={(e)=>{setShowMatchedData(e.target.checked)}}/>
                                </div>
                            </div>
                            <Button disabled={addLogsLoader || !(logFileName||scriptName) } color='warning' variant='outlined' size='small' sx={{minHeight:'35px',minWidth:'120px'}} onClick={getDataDifference}>
                                {addLogsLoader && <CircularProgress size={'14px'} sx={{color:'orange',fontSize:'12px'}}/>}
                                {!addLogsLoader && 'Submit'}
                                
                            </Button>
                        </div>
                    </div>
            },
        }
        return <div className='addTabContainer' style={body[showPopup].containerProps ? {...body[showPopup].containerProps} : {}}>
                    <div className='addTabWrapper' style={body[showPopup].wrapperProps ? {...body[showPopup].wrapperProps} : {}}>
                        <div style={{display:'flex',justifyContent:'space-between',alignItems:'center',cursor:'default'}}>
                            <div>{body[showPopup].title}</div>
                            <div style={{cursor:'pointer'}} onClick={()=>{setShowPopup(false);}}>X</div>
                        </div>
                        {body[showPopup].body}
                        <div style={{display:'flex',justifyContent:'center'}}>
                            {/* <Button disabled={!addTabData.file || !addTabData.noOfDays || !addTabData.noOfUser} sx={{...Constants.CONTAINEDBUTTON,textTransform:'none',fontWeight:'400',marginTop:'20px'}} onClick={()=>{addDebug()}}> 
                                {addLoader && <CircularProgress sx={{color:'white'}} size={'18px'}/>}
                                {!addLoader && "Submit"}
                            </Button> */}
                        </div>
                    </div>
                </div>
    }

    const openLogsFile = (item) => {
        setShowMinorUpdate(false);
        setSelectedItem(item);
        try{
            var comment = JSON.parse(item.comment)
        }
        catch{
            comment = false;
        }
        if(comment){
            setAddLogsLoader(true);
            getDataDifferenceDownload(item);
        }
        else{
            if(item.uploadstatus==="Processed"){
                setShowPopup('uploadOrSelectFile');
            }
            else{
                
                if(item.comment.includes('Appsflyer Key')){
                    setLoaderPopup('devkey')
                }
                Store.addNotification({
                    title: "Error!",
                    message: item.comment,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });
            }
        }
    }

    const openUrl = (e,item,index,key='url',value=null)=>{
        var tempOpenTabs = openTabsList;
        var openUrlList = []
        if(tempOpenTabs[activeTab].openUrl){
            openUrlList = tempOpenTabs[activeTab].openUrl
        }
        if(!value){
            if(openUrlList.includes(index)){
                tempOpenTabs[activeTab].openUrl = tempOpenTabs[activeTab].openUrl.filter(e=>e!==index)
            }
            else{
                tempOpenTabs[activeTab].openUrl = [...openUrlList,index]
            }
        }
        if(value){
            if(openUrlList.includes(value)){
                tempOpenTabs[activeTab].openUrl = tempOpenTabs[activeTab].openUrl.filter(e=>e!==value)
            }
            else{
                tempOpenTabs[activeTab].openUrl = [...openUrlList,value]
            }
        }
        setOpenTabsList(tempOpenTabs)
        refreshComponent(123)
    }
    
    const [shareReportPopup, setShareReportPopup] = useState(false)
    const [shareWithName, setShareWithName] = useState(false)
    const [shareFromHistoryComponent, setShareFromHistoryComponent] = useState(false)
    var sharePopupData = () => {
        return <div style={{height:'200px',display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column'}}>
            <div style={{transform:'scale(0.8)',width:'100%'}}>
                <ReactSelect
                    placeholder={'Share Report With...'}
                    value={shareWithName ? {label:capitalize(shareWithName.label),value:shareWithName.value} : false}
                    options={getLocalStorageValue('userData')}
                    onChange={(e)=>{setShareWithName(e);}}
                    menuPortalTarget={document.body} 
                    styles={{...Constants.ReactSelectStyles}}
                />
            </div>
            <Button disabled={!shareWithName} onClick={shareUserData} sx={{...Constants.CONTAINEDBUTTON,textTransform:'capitalize',marginTop:'20px',}}>
                Share
            </Button>
        </div>
    };
    const shareUserData = async() => {
        await axios({
            method: 'GET',
            url: "".concat(Constants.BACKENDHOST,"scriptcreator/share_campaignlogs"),
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token,
            },
            params:{
                serial:shareFromHistoryComponent,
                share_with:shareWithName.value
            }
        })
        .then(resp => {
            console.log(resp)
            Store.addNotification({
                title: "Success!",
                message: resp.data.message,
                type: "success",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                  duration: 5000,
                  onScreen: true
                }
            });
            setShareReportPopup(false);
            setShareWithName(false);
            setShareFromHistoryComponent(false);
        })
        .catch(err => {
            console.error(err);
            if(err.response && err.response.data){
                Store.addNotification({
                    title: "Error!",
                    message:  err.response.data.message,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                      duration: 5000,
                      onScreen: true
                    }
                });
            }
        })
    };
    return (
        <div>
            <TopbarV2 title='Data Difference'/>
            <div style={{display:'flex'}}>
                <HistoryComponent
                    historyWindowWidth={historyWindowWidth}
                    setHistoryWindowWidth={setHistoryWindowWidth}
                    openTabs={openLogsFile}
                    historyResponse={historyResponse}
                    setHistoryResponse={setHistoryResponse}
                    historyResponseLoader={historyResponseLoader}
                    setHistoryResponseLoader={setHistoryResponseLoader}
                    getDebugHistory={getLogsUploadHistory}
                    historyPage={historyPage}
                    setHistoryPage={setHistoryPage}
                    historyPages={historyPages}
                    historyFor={'Log'}
                    historyHeaders={historyHeaders}
                    hintVariable={'Red: Not Uploaded, Green: Uploaded.'}
                    rightClicked={[{label:'Share Item',onClick:(e,item)=>{
                        setShareReportPopup(true);
                        setShareFromHistoryComponent(item.logsserial);
                    }}]}
                />
                {
                    shareReportPopup
                    &&
                    <Popup head={'Share Report'} data={sharePopupData()} handleClose={(e)=>{setShareReportPopup(false);}}/>
                }
                <div style={{position:'relative',width:'calc(100% - '+historyWindowWidth+'px - 00px)'}}>
                    <div className='debugHistoryHead' >
                        <div style={{position:'absolute',width:'100%',display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                            <div style={{width:'90%',display:'flex',alignItems:'center',overflowX:'auto'}}>
                                {openTabsList.map((item,index)=>{
                                    return <div id='tabsContainer' className='openTabContainer' style={{height:(index===activeTab ? 'calc(40px - 5px)' : '40px'),color:(index===activeTab ? 'black' : 'rgb(100,100,100 )'),borderBottom:(index===activeTab ? '5px solid orange' : '1px solid rgb(200,200,200)')}} key={index} onClick={()=>{setActiveTab(index);setShowMinorUpdate(false);}}>
                                            <div id='tabsContainer' style={{width:'90%',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',fontSize:'12px',fontWeight:'500'}}>
                                                {item.logsname}
                                            </div>
                                            <div id='tabsContainer' style={{cursor:'pointer'}} onClick={(e)=>{e.stopPropagation();closeTab(item)}}>
                                                X
                                            </div>
                                        </div>
                                })}
                            </div>
                            <div className='addBoxContainer' style={{cursor:'pointer',width:'70px'}} onClick={()=>{setShowPopup('addLogs');}}>
                                Add Logs
                            </div>
                        </div>
                    </div>
                    {
                        showPopup &&
                        showPopupFunction()
                    }
                    {   
                        openTabsList.length>0
                        &&
                        (activeTab || activeTab===0)
                        &&
                        <div style={{overflow:'auto',height:'calc(100vh - 40px - 90px)',paddingTop:'15px',paddingLeft:'20px',paddingRight:'20px'}}>
                            
                            <div style={{display:'flex',alignItems:'center',fontSize:'14px'}}>
                                <div style={{fontWeight:'500',marginRight:'5px'}}>Logsname : </div>
                                <div>{openTabsList[activeTab].logsname}</div>
                            </div>
                            <div style={{display:'flex',alignItems:'center',fontSize:'14px',marginTop:'5px'}}>
                                <div style={{fontWeight:'500',marginRight:'5px'}}>Created At : </div>
                                <div>{new Date(openTabsList[activeTab].created_at).toLocaleDateString('en-US',{ year: 'numeric', month: 'short', day: 'numeric' ,hour:'numeric',minute:'numeric',second:'numeric'})}</div>
                            </div>
                            <br/>
                            {openTabsList[activeTab].datadifference 
                                &&
                                <div>
                                    <Button
                                        sx={{...Constants.CONTAINEDBUTTON,fontSize:'12px',transform:'scale(0.8)',transformOrigin:'left'}}
                                        onClick={()=>{setShowPopup('uploadOrSelectFile')}}
                                    >
                                        Perform Another Data Difference
                                    </Button>
                                </div>
                                }
                            <br/>
                            <div style={{display:'flex',justifyContent:'space-between',flexWrap:'wrap',fontSize:'14px'}} >
                                <div style={{display:'flex',alignItems:'center',cursor:'pointer'}} onClick={e => {
                                    openUrl(e,{},0,'URL','CALLS NOT FOUND IN SCRIP')
                                    let hero = document.getElementById("callNotFoundInScript");
                                    e.preventDefault();  // Stop Page Reloading
                                    hero && hero.scrollIntoView({ behavior: "smooth"});
                                    }}>
                                    <div>
                                        Calls Not Found in Script :
                                    </div>
                                    <div style={{marginLeft:'5px',color:'red'}}>
                                        {openTabsList[activeTab].datadifference && openTabsList[activeTab].datadifference.calls_not_found_in_script_logs.length}
                                    </div>
                                </div>
                                <div style={{display:'flex',alignItems:'center',cursor:'pointer'}} onClick={e => {
                                    openUrl(e,{},0,'URL','CALLS NOT FOUND IN DEVICE')
                                    let hero = document.getElementById("callNotFoundInDevice");
                                    e.preventDefault();  // Stop Page Reloading
                                    hero && hero.scrollIntoView({ behavior: "smooth"});
                                    }}>
                                    <div>
                                        Calls Not Found in Device :
                                    </div>
                                    <div style={{marginLeft:'5px',color:'red'}}>
                                        {openTabsList[activeTab].datadifference && openTabsList[activeTab].datadifference.calls_not_found_in_device_logs.length}
                                    </div>
                                </div>
                            </div>
                            <br/>
                            {getLocalStorageValue('adminPanelLoginData').allowedPages.includes('can_update_from_dd')
                                &&
                                openTabsList[activeTab].datadifference 
                                &&
                                <div>
                                    <Button
                                        sx={{...Constants.OUTLINEDBUTTON,fontSize:'12px',transform:'scale(0.8)',transformOrigin:'left'}}
                                        onClick={()=>{setShowMinorUpdate(true)}}
                                    >
                                        Minor Update
                                    </Button>
                                </div>
                            }
                            <br/>
                            {(!openTabsList[activeTab].datadifference && !openTabsList[activeTab].traceback) && 
                            <div style={{display:'flex',alignItems:'center',justifyContent:'center',height:'50px'}}>
                                <CircularProgress size={'34px'} sx={{color:'orange'}}/>
                            </div>
                            }
                            {openTabsList[activeTab].traceback &&
                            <ErrorComponent data={openTabsList[activeTab].traceback}/>
                            }
                            {openTabsList[activeTab].datadifference && 
                                <div style={{display:'flex',justifyContent:'space-between',flexWrap:'wrap',fontSize:'14px'}} >
                                    <div style={{color:'orange',cursor:'pointer'}} onClick={()=>{var tempOpenTabs = openTabsList;tempOpenTabs[activeTab].openUrl = openTabsList[activeTab].datadifference.matched_calls.map((item,index)=>{return index}); setOpenTabsList(tempOpenTabs);refreshComponent(12);}}>
                                        Show All
                                    </div>
                                    <div style={{cursor:'pointer'}} onClick={()=>{var tempOpenTabs = openTabsList;tempOpenTabs[activeTab].openUrl = []; setOpenTabsList(tempOpenTabs);refreshComponent(12);}}>
                                        Hide All
                                    </div>
                                </div>
                            }
                            <div style={{position:'relative'}}>
                                {openTabsList[activeTab].datadifference && openTabsList[activeTab].datadifference.matched_calls.map((item,index)=>{
                                    return <div className='datadifferenceContainer' key={index}>
                                            <div className='datadifferenceUrlContainer' style={{display:'flex',alignItems:'center',justifyContent:'space-between'}} onClick={(e)=>{openUrl(e,item,index)}}>
                                                <div>
                                                    {item.url}
                                                </div>
                                                <div>
                                                    <ExpandMoreIcon style={{transition:'all 0.2s linear',transform:(openTabsList[activeTab].openUrl && openTabsList[activeTab].openUrl.includes(index) ? 'rotate(-180deg)' : '')}}/>
                                                </div>
                                            </div>
                                            {   
                                                openTabsList[activeTab].openUrl
                                                && 
                                                openTabsList[activeTab].openUrl.includes(index)
                                                &&
                                                <div style={{borderBottom:'1px solid orange'}}>
                                                    <table style={{borderSpacing:'0px',tableLayout:'fixed',width:'100%'}}>
                                                        <thead>
                                                            <tr>
                                                                <td className='datadifferenceHeader' width={'20%'}>
                                                                    Key
                                                                </td>
                                                                <td className='datadifferenceHeader'>
                                                                    Device Value
                                                                </td>
                                                                <td className='datadifferenceHeader'>
                                                                    Script Value
                                                                </td>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {['HEADERS','PARAMS','DATA'].map((apiDataType,apiDataIndex)=>{
                                                                if(item[apiDataType]){
                                                                    return Object.keys(item[apiDataType]).sort().map((key,keyIndex)=>{
                                                                        var mismatched = item[apiDataType][key].mismatched
                                                                        return <tr key={keyIndex} style={{fontSize:'12px',background:(mismatched==='not-found' ? 'rgb(255,230,230)' : '')}}>
                                                                                    <td className='datadifferenceKey'>
                                                                                        {key}
                                                                                    </td>
                                                                                    <td className='datadifferenceValue' style={{color:(mismatched==='type' ? 'rgb(255,0,0)' : mismatched==='validated' ? 'rgb(0,255,0)' : '')}}>
                                                                                        {mismatched==='not-found' && !item[apiDataType][key].device_value ? 'Not Found in Device Logs' : JSON.stringify(item[apiDataType][key].device_value,null,2)}
                                                                                    </td>
                                                                                    <td className='datadifferenceValue' style={{color:(mismatched==='type' ? 'rgb(255,0,0)' : mismatched==='validated' ? 'rgb(0,255,0)' : '')}}>
                                                                                        {mismatched==='not-found' && !item[apiDataType][key].script_value ? 'Not Found in Script Logs' : JSON.stringify(item[apiDataType][key].script_value,null,2)}
                                                                                    </td>
                                                                                </tr>
                                                                            })
                                                                }
                                                                return null
                                                            })}
                                                        </tbody>
                                                    </table>
                                                   
                                                </div>
                                            }
                                        </div>
                                })}
                            </div>
                            {openTabsList[activeTab].datadifference && 

                                <div id='callNotFoundInScript' style={{position:'relative',background:'rgb(255,200,200)',paddingLeft:'10px',paddingRight:'10px'}}>
                                    <div style={{fontWeight:500,cursor:'pointer',paddingTop:'15px',paddingBottom:'15px',position:'sticky',top:'-15px',background:'rgb(255,200,200)'}} onClick={(e)=>{openUrl(e,{},0,'URL','CALLS NOT FOUND IN SCRIPT')}}>Calls Not Found in Script </div>
                                    {
                                        openTabsList[activeTab].openUrl
                                        &&
                                        openTabsList[activeTab].openUrl.includes('CALLS NOT FOUND IN SCRIPT')
                                        &&
                                        openTabsList[activeTab].datadifference
                                        &&
                                        openTabsList[activeTab].datadifference.calls_not_found_in_script_logs.map((item,index)=>{
                                            return <div style={{padding:'5px'}} key={index}>
                                                    <div style={{fontSize:'14px'}}>
                                                        {item.URL}
                                                    </div>
                                                </div>
                                        })
                                    }
                                </div>
                            }
                            {openTabsList[activeTab].datadifference && 
                            <div id='callNotFoundInDevice' style={{position:'relative',background:'rgb(255,200,200)',paddingLeft:'10px',paddingRight:'10px',marginTop:'20px'}} >
                                <div style={{fontWeight:500,cursor:'pointer',paddingTop:'15px',paddingBottom:'15px',position:'sticky',top:'-15px',background:'rgb(255,200,200)'}} onClick={(e)=>{openUrl(e,{},0,'URL','CALLS NOT FOUND IN DEVICE')}}>Calls Not Found in Device </div>
                                {
                                    openTabsList[activeTab].openUrl
                                    &&
                                    openTabsList[activeTab].openUrl.includes('CALLS NOT FOUND IN DEVICE')
                                    &&
                                    openTabsList[activeTab].datadifference
                                    &&
                                    openTabsList[activeTab].datadifference.calls_not_found_in_device_logs.map((item,index)=>{
                                        return <div style={{padding:'5px'}} key={index}>
                                                <div style={{fontSize:'14px'}}>
                                                    {item.URL}
                                                </div>
                                            </div>
                                    })
                                }
                            </div>
                            }
                            <br/>
                            <br/>

                            {showMinorUpdate && 
                            <MinorUpdatePopup
                                fileData={openTabsList[activeTab]}
                                setShowMinorUpdate={setShowMinorUpdate}
                            />
                            }
                        </div>
                    }
                </div>
                
                {loaderPopup
                    &&
                <div style={{position:'fixed',top:0,left:0,width:'100vw',height:'100vh',backdropFilter:'blur(2px)',zIndex:10}} onClick={()=>{setLoaderPopup(false);}}>
                    <div style={{display:"flex",justifyContent:'center'}}>
                        <div style={{minHeight:'30vh',minWidth:'50vw',background:'white',border:'1px solid rgb(200,200,200)',boxShadow:'0 0 5px 1px rgb(200,200,200)',marginTop:'15vh'}} onClick={(e)=>{e.stopPropagation();}}>
                            <div style={{display:'flex',height:'30px',alignItems:'center',justifyContent:'space-between',paddingTop:'15px',paddingBottom:'15px',paddingLeft:'20px',paddingRight:'20px',fontWeight:'500'}}>
                                <div>{loaderPopupDetails[loaderPopup].header}</div>
                                <div style={{cursor:'pointer'}} onClick={()=>{setLoaderPopup(false);}}>X</div>
                            </div>
                            <div style={{paddingTop:'10px',paddingBottom:'15px',paddingLeft:'25px',paddingRight:'25px'}}>
                                {loaderPopupDetails[loaderPopup].popupValue}
                            </div>
                        </div>
                    </div>
                </div>
                }
            
            </div>
        </div>
    )
}

export default DataDifference;