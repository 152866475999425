
import LooksTwoIcon from '@mui/icons-material/LooksTwo';
import StarIcon from '@mui/icons-material/Star';
import TwoKIcon from '@mui/icons-material/TwoK';
import LooksOneIcon from '@mui/icons-material/LooksOne';
import Looks3Icon from '@mui/icons-material/Looks3';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DragHandleIcon from '@mui/icons-material/DragHandle';

export var tagOptions = {
    'goat_lvl2+crm':{
        label:'Goal Level 2 + CRM',
        icon: StarIcon
    },
    'repeat_lvl2+crm':{
        label:'Repeat Level 2 + CRM',
        icon:TwoKIcon
    },
    'level2+crm':{
        label:'Level 2 + CRM',
        icon:LooksTwoIcon
    }
}
export var teamOptions = {
    1:{
        label:'Team One',
        icon:LooksOneIcon
    },
    2:{
        label:'Team Two',
        icon:LooksTwoIcon
    },
    3:{
        label:'Team Three',
        icon:Looks3Icon
    },
}
export var priorityOptions = {
    highest:{
        label:'Highest',
        icon:KeyboardDoubleArrowUpIcon,
        color:'red'
    },
    high:{
        label:'High',
        icon:KeyboardArrowUpIcon,
        color:'orange'
    },
    medium:{
        label:'Medium',
        icon:DragHandleIcon,
        color:'blue'
    },
    low:{
        label:'Low',
        icon:KeyboardArrowDownIcon,
        color:'yellowgreen'
    },
    lowest:{
        label:'Lowest',
        icon:KeyboardDoubleArrowDownIcon,
        color:'green'
    },
}
export var teamInfo = {
    1:{
        '15':'Pushkal Pandey',
        '13':'Pawan Kumar'
    },
    2:{
        '10':'Udit Rastogi',
        '2':'Umair Idris'
    },
    3:{
        '6':'Altamash Asheer',
    }
}

export var assigneeLabel = (assigneeOptions,value) => {
    var label = assigneeOptions[parseInt(value)]
    if(label){
        return <div style={{display:'flex',alignItems:'center'}}>
                <div style={{background:'rgb(220,220,220)',height:'15px',width:'15px',fontSize:'12px',padding:'5px',display:'flex',alignItems:'center',justifyContent:'center',borderRadius:'50%',marginRight:'5px'}}>
                    {label[0]}
                </div>
                {label}
        </div>
    }
    return value
}
export var assigneeOptions = (newtaskDetails) => {
    if(newtaskDetails['team']){
        return Object.keys(teamInfo[newtaskDetails['team'].value]).map((item)=>{
            var label = <div style={{display:'flex',alignItems:'center'}}>
                            <div style={{background:'rgb(220,220,220)',height:'15px',width:'15px',fontSize:'12px',padding:'5px',display:'flex',alignItems:'center',justifyContent:'center',borderRadius:'50%',marginRight:'5px'}}>
                                {teamInfo[newtaskDetails['team'].value][item][0]}
                            </div>
                            {teamInfo[newtaskDetails['team'].value][item]}
                        </div>
            return {label,value:item}
        })
    }
}

export var getOptions = (dict_) => {
    return Object.keys(dict_).map((value)=>{
        var item = dict_[value]
        var label = <div style={{display:'flex',alignItems:'center'}}>
            {item.icon
            &&
            <item.icon sx={{marginRight:'5px',color:(item.color ? item.color : '')}}/>
            }
            {item.label}
        </div>
        return {label,value}
    })
}

export var getOptionsLabelValue = (dict_,value,labelstyle={},iconstyle={}) => {
    if(dict_,value){
        var item = dict_[value]
        var label = <div style={{...labelstyle,display:'flex',alignItems:'center',flexWrap:'wrap',wordWrap:'break-word',whiteSpace:'pre-wrap'}}>
            {item.icon
            &&
            <item.icon sx={{marginRight:'5px',color:(item.color ? item.color : ''),...iconstyle}}/>
            }
            {item.label}
        </div>
        return {label,value}
    }
}