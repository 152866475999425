import React from 'react'
import NotAllowedGif from '../img/notFound.png';
import TopbarV2 from './topbarv2/topbarv2';

const NotFoundPage = () => {
    return (
        <>
            <TopbarV2/>
            <div style={{height:'calc(100vh - 100px)',display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}>
                <img src={NotAllowedGif} alt='' width={'40%'}/>
                <div style={{fontSize:'25px',marginTop:'20px',fontFamily:'Helvetica, sans-serif'}}>
                    Oops, the page you are looking for does not exist.
                </div>
            </div>
        </>
    )
}

export default NotFoundPage