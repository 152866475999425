import React, { useEffect } from 'react'

const Popup = (props) => {
    var height = props.height ?  props.height : '40vh'
    var width =  props.width ?  props.width : '50vw'
    var marginLeft = props.marginLeft ?  props.marginLeft : '25%'
    var marginTop = props.marginTop ?  props.marginTop : '20vh'
    var head = props.head ?  props.head : 'Error'

    useEffect(() => {
        document.addEventListener('keydown', (e) => {
            if(e.key==='Escape'){
                if(props.handleClose){
                    props.handleClose();
                    // if(document.getElementById('threeDotMenuDebug')){
                    //     document.getElementById('threeDotMenuDebug').style.animation = 'threeDotClosed 0.5s forwards';
                    // }
                }
            }
        })
        
        return () => {
            document.addEventListener('keydown', (e) => {
                if(e.key==='Escape'){
                    if(props.handleClose){
                        props.handleClose();
                        // if(document.getElementById('threeDotMenuDebug')){
                        //     document.getElementById('threeDotMenuDebug').style.animation = 'threeDotClosed 0.5s forwards';
                        // }
                    }
                }
            })
        }
    }, [props])
    
    return (
        <div style={{position:'fixed',top:0,left:0,height:'100vh',width:'100vw',zIndex:'1000',backdropFilter:'blur(3px)',background:'rgb(100,100,100,0.1)'}}>
            <div style={{minHeight:height,width,marginLeft,marginTop,background:'white',border:'1px solid rgb(200,200,200)',boxShadow:'0 10px 10px 1px rgb(200,200,200)'}} onClick={(e)=>{e.stopPropagation()}} >
                <div style={{display:'flex',justifyContent:'space-between',padding:'10px',}}>
                    <div style={{fontWeight:'500',fontSize:'17px',cursor:'pointer'}}>{head}</div>
                    <div style={{fontWeight:'500',fontSize:'17px',cursor:'pointer'}} onClick={(e)=>{props.handleClose()}}>{'X'}</div>
                </div>
                <div style={{paddingLeft:'5%',paddingRight:'5%',paddingTop:'2%',paddingBottom:'4%'}}>
                    {props.data}
                </div>
            </div>
        </div>
    )
}

export default Popup