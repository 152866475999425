import React, { useEffect, useState } from 'react';
import './filterRow.css';
import ReactSelect from 'react-select';
import { CssTextField, ReactSelectStyles } from '../../data/constants';
import * as Constants from '../../data/constants';
import { Button} from '@mui/material';
import { CircularProgress } from '@mui/material'
import DateRangePickerComponent from './v2dateRangePicker';
import SelectComponent from './selectComponent';
import DatePicker from 'react-datepicker';


export const increase_brightness = function(color,percent){

    var ctx = document.createElement('canvas').getContext('2d');

    ctx.fillStyle = color;
    ctx.fillRect(0,0,1,1);

    color = ctx.getImageData(0,0,1,1);
    var r = color.data[0] + Math.floor( percent / 100 * 255 );
    var g = color.data[1] + Math.floor( percent / 100 * 255 );
    var b = color.data[2] + Math.floor( percent / 100 * 255 );

    return 'rgb('+r+','+g+','+b+')';
}

var selectStyles = { menuPortal: base => ({ ...base, zIndex: 9999 }),
control: (base,state) => ({
    ...base,
    width:'100%',
    height:'100%',
    boxSizing:'border-box',
    // maxHeight: 100,
    transform: 'scale(0.8)',
    transformOrigin:'left',

    minHeight: 30,
    overflowY: "auto",
    border: state.isFocused ? '1px solid '+localStorage.getItem('ambientColor') : '1px solid grey',
    boxShadow: state.isFocused ? '0 0 3px 1px '+localStorage.getItem('ambientColor') : 0,
    '&:hover': {
        border: state.isFocused ? '1px solid '+localStorage.getItem('ambientColor') : '1px solid '+localStorage.getItem('ambientColor'),
        boxShadow: state.isFocused ? '0 0 1px 1px '+localStorage.getItem('ambientColor') : '0 0 1px 1px '+localStorage.getItem('ambientColor'),
    }
}),
option: (styles, {isFocused, isSelected}) => ({
    ...styles,
    fontSize:'11px',
    background: isFocused
        ? localStorage.getItem('ambientColor')
        : isSelected
            ? increase_brightness(localStorage.getItem('ambientColor'),10)
            : undefined,
    zIndex: 1
}),
}

const FilterRow = (props) => {
    var filtersList = props.filtersList
    var filterData = props.filterData
    var setFilterData = props.setFilterData

    const [activeSelect, setActiveSelect] = useState(false)
    useEffect(() => {
        document.addEventListener('click',e=>{
            setActiveSelect(false);
        })
        
        return () => {
            document.removeEventListener('click',e=>{
                setActiveSelect(false);
            })
        }
    }, [])
    
    const buttonComponent = (filterItem,filterIndex) => {
        return <div className='v2FilterButtonContainer'>
                    <div className='v2FilterButtonItem' onClick={()=>{filterItem.onReset()}}>
                        Reset
                    </div>
                    <div className='v2FilterButtonItem' onClick={()=>{filterItem.onSubmit()}}>
                        {filterItem.renameSubmit ? filterItem.renameSubmit : 'Submit'}
                    </div>
                </div>
    }

    var filterItemWidth = props.filterItemWidth ? 'calc('+props.filterItemWidth+'% - 4px)' : 'calc(20% - 4px)'
    return (
        <div className='v2filterRowContainer'>
            {filtersList && filtersList.map((filterItem,filterIndex)=>{
                if(filterItem.customComponent){
                    return filterItem.customComponent(filterItem,filterIndex)
                }
                if(filterItem.type === 'submit_reset_button'){
                    return buttonComponent(filterItem,filterIndex)
                }
                return <div style={{width:filterItemWidth}} className={'v2filterItemContainer' + (activeSelect===filterItem.value && filterItem.type==='select' ? ' v2SelectComponentActive' : '')} id={'v2Filter'+filterItem.label} key={filterIndex}>
                        <div className='v2FilterIcon'>
                            {filterItem.icon && <filterItem.icon/>}
                        </div>
                        {
                            filterItem.type === 'monthPicker'
                            &&
                            <DatePicker
                                // selected={new Date(monthSelected)}
                                customInput={<input className='v2FilterTextFieldFilterRow'/>}
                                selectsRange
                                startDate={filterData.startMonth ? new Date(filterData.startMonth) : new Date()}
                                endDate={filterData.endMonth ? new Date(filterData.endMonth) : null}
                                // onCalendarClose={()=>{requestList()}}
                                onChange={(date)=>{
                                    setFilterData(date,filterItem)
                                }}
                                showMonthYearPicker={true}
                                dateFormat={'MMM, yy'}
                            />
                        }
                        {/* {filterItem.type !=='button' && <div className='filterItemLabel'>{filterItem.label}</div>} */}
                        {filterItem.type === 'daterangepicker'
                        &&
                        <DateRangePickerComponent propsParent={props} customDateRangeFunction={props.customDateRangeFunction}  filterData={filterData} setFilterData={setFilterData}/>
                        }
                        {filterItem.type === 'textfield'
                        &&
                        <input 
                            value={filterData[filterItem.value] ? filterData[filterItem.value] : ''} 
                            placeholder={filterItem.label} 
                            className='v2FilterTextFieldFilterRow'
                            onChange={(e)=>{setFilterData(e.target.value,filterItem)}}
                        />
                        }
                        {filterItem.type === 'checkbox'
                        &&
                        <div style={{display:'flex',alignItems:'center',width:'100%',height:'100%',boxSizing:'border-box'}}>
                            <input checked={filterData[filterItem.value]} onChange={()=>{setFilterData(filterData[filterItem.value] ? false : true,filterItem)}} id={'checkboxx'+filterItem.label} className='v2FilterIcon v2CheckboxIcon' type='checkbox'/>
                            <div className='v2CheckboxLabel' onClick={()=>{document.getElementById('checkboxx'+filterItem.label).click();}}>{filterItem.label}</div>
                        </div>
                        }
                        {filterItem.type === 'select'
                        &&
                        <SelectComponent activeSelect={activeSelect} setActiveSelect={setActiveSelect} filterItem={filterItem} propsParent={props} filterData={filterData} setFilterData={setFilterData}/>
                        }
                        {
                            filterItem.type === 'button'
                            &&
                            <div>
                                <Button id='testbutton' disabled={filterItem.disabled} onClick={filterItem.onSubmit} sx={{...(filterItem.variant && filterItem.variant==='outlined' ? Constants.OUTLINEDBUTTON : Constants.CONTAINEDBUTTON),fontSize:'14px',minWidth:'102px',minHeight:'40px'}}>
                                    {filterItem.disabled
                                    &&
                                    <CircularProgress size={'15px'} sx={{color:'white'}}/>
                                    }
                                    {!filterItem.disabled && filterItem.label}
                                </Button>
                            </div>
                        }
                    </div>
            })}
        </div>
    )
}

export default FilterRow