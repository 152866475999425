import React,{useState, useEffect} from 'react'
import '../../css/pages/v1/debugscript.css'

import TopbarV2 from '../../components/topbarv2/topbarv2'
import HistoryComponent from '../../components/historyComponent/historyComponent';
import { capitalize, getLocalStorageValue, useForceUpdate } from '../../helper/util'
import * as Constants from '../../data/constants';
import { Button, CircularProgress, IconButton, Tooltip } from '@mui/material';
import axios from 'axios';
import { Store } from 'react-notifications-component';
import DebugFileData from './debugFiledata';
import ReactSelect from 'react-select';
import { AddCircleOutline, CloseOutlined } from '@mui/icons-material';
import Popup from '../../components/popup/popup';
import { isUserBT } from '../stint/stintCustomizations/stintCustomizations';
// import CreatableSelect from 'react-select/creatable';


const DebugPage = () => {
    const refreshComponent = useForceUpdate();
    
    if(localStorage.getItem(window.location.pathname+'historyWindowWidth')){
        var tempHistoryWindowWidth = localStorage.getItem(window.location.pathname+'historyWindowWidth')
    }
    else{
        tempHistoryWindowWidth = 300
    }
    const [historyWindowWidth, setHistoryWindowWidth] = useState(tempHistoryWindowWidth)
    const [showAddTab, setShowAddTab] = useState(false)
    const [activeTab, setActiveTab] = useState(false)
    const [addTabData, setAddTabData] = useState({file:false,noOfDays:7,noOfUser:100})
    const [openTabsList, setOpenTabsList] = useState([])
    const [historyPage, setHistoryPage] = useState(1)
    const [historyPages, setHistoryPages] = useState(0)
    const [query, setQuery] = useState('')
    const [isKpiCheckPage, setIsKpiCheckPage] = useState(window.location.pathname==='/campaigns/kpicheck' ? false : true)
    const [customUrlList, setCustomUrlList] = useState([])
    const [showInDifferentTables, setShowInDifferentTables] = useState(false)
    const [shareReportPopup, setShareReportPopup] = useState(false)
    const [getAppData, setGetAppData] = useState(true)
    
    const [getEventValues, setGetEventValues] = useState(isUserBT() ? true :false)
    const [getRanges, setGetRanges] = useState(false)
    const [countrySelected,setCountrySelected] = useState(false)
    const [customizationDict,setCustomizationDict] = useState({})
    const [addCustomizationButton,setAddCustomizationButton] = useState(true)
    const [openedDebugReport,setOpenedDebugReport] = useState(false)
    
    const [getDataLoader, setGetDataLoader] = useState(false)
    var getDebugData = async(item) => {
        var foundFlag = false;
        for(var index in openTabsList){
            if(openTabsList[index].serial === item.serial){
                foundFlag = true;
                break;
            }
        }
        if(foundFlag){
            setActiveTab(parseInt(index))
        }
        if(!foundFlag){
            setGetDataLoader(true);
            if(item.reportstatus==='Pending'){
                Store.addNotification({
                    title: "Warning!",
                    message: 'Debug for this script is in under process, Please refresh and try again when it is processed.',
                    type: "warning",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                      duration: 6000,
                      onScreen: true
                    }
                });
            }
            else{
                openTabs({...item,loader:true})
                
                await axios({
                    method: "GET",
                    url: Constants.BACKENDHOST+"tools/fetch_flow_report",
                    headers:{
                        Authorization:getLocalStorageValue('adminPanelLoginData').token,
                    },
                    params:{
                        report_serial:item.serial,
                    },
                })
                .then(resp => {
                    if(resp.data.data){
                        var respItem = {...item,response:resp.data.data,loader:true}
                        var tempTabsList = openTabsList;
                        tempTabsList[tempTabsList.length-1] = respItem
                        setOpenTabsList(tempTabsList);
                    }
                })
                .catch(err=>{
                    var respItem = {...item,loader:false}
                    var tempTabsList = openTabsList;
                    tempTabsList[tempTabsList.length-1] = respItem
                    setOpenTabsList(tempTabsList);
                    console.log(err)
                    if(err.response && err.response.data){
                        Store.addNotification({
                            title: "Error!",
                            message: err.response.data.message,
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            animationIn: ["animate__animated", "animate__fadeIn"],
                            animationOut: ["animate__animated", "animate__fadeOut"],
                            dismiss: {
                              duration: 5000,
                              onScreen: true
                            }
                        });
                    }
                })
            }
            setGetDataLoader(false);
        }
    }

    const openTabs = (item) => {
        var tempTabsList = openTabsList;
        tempTabsList.push(item)
        setActiveTab(tempTabsList.length-1)
        setOpenTabsList(tempTabsList);
        setShowAddTab(false);
        setShowInDifferentTables(false);
        refreshComponent(1);
    }

    const closeTab = (item) => {
        var tempTabsList = openTabsList
        for(var index in tempTabsList){
            if(tempTabsList[index].filename === item.filename && tempTabsList[index].serial === item.serial){
                break;
            }
        }
        setOpenTabsList(tempTabsList);
        tempTabsList.splice(index,1)
        if(tempTabsList.length>0){
            setActiveTab(tempTabsList.length-1);
        }
        else{
            setActiveTab(false);
        }
        setShowInDifferentTables(false);

        refreshComponent(1);
    }
    
    const [historyResponse, setHistoryResponse] = useState({})
    const [historyResponseLoader, setHistoryResponseLoader] = useState(false)
    const [userData, setUserData] = useState([])
    const [shareFromHistoryComponent, setShareFromHistoryComponent] = useState(false)
    
    const getDebugHistory = async(manualPage,query_=null) => {
        setHistoryResponseLoader(true);
        await axios({
            method: 'GET',
            url: "".concat(Constants.BACKENDHOST,"tools/flow_report_status"),
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token,
            },
            params:{
                page:manualPage ? manualPage : historyPage,
                from_file:window.location.href.includes('/campaigns/kpicheck') ? false : true,
                q: query_===false ? null : query ? query : null
            }
        })
        .then(resp => {
            if(resp.data.response_code===200){
                var tempHistoryResp = {
                    'user_created':[],
                    'user_shared':[],
                }
                for(var index in resp.data.data.items){
                    var item = resp.data.data.items[index]
                    if(item.generated_by && item.generated_by.includes(',')){
                        var generated_by = item.generated_by.split(',')[0]
                        if(generated_by.toLowerCase() === getLocalStorageValue('adminPanelLoginData').username.toLowerCase()){
                            tempHistoryResp['user_created'].push(item)
                        }
                        else{
                            tempHistoryResp['user_shared'].push(item)
                        }
                    }
                    else{
                        tempHistoryResp['user_created'].push(item)
                    }
                }
                if(tempHistoryResp.user_shared.length===0){
                    delete tempHistoryResp.user_shared
                }
                setHistoryResponse(tempHistoryResp)
                setHistoryPages(resp.data.data.pages)
            }
        })
        .catch(err => {
            console.error(err);
        })
        setHistoryResponseLoader(false);
    };

    const getUserData = async(manualPage,query_=null) => {
        await axios({
            method: 'GET',
            url: "".concat(Constants.BACKENDHOST,"getusers"),
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token,
            },
        })
        .then(resp => {
            if(resp.data.response_code===200){
                var tempusers = []
                for(var index in resp.data.data){
                    var item = resp.data.data[index];
                    if(item['name']!==getLocalStorageValue('adminPanelLoginData').username){
                        tempusers.push(item['name'])
                    }
                }
                setUserData(tempusers);
            }
        })
        .catch(err => {
            console.error(err);
        })
    };
    const [manualUseState, setManualUseState] = useState(false)
    if(!manualUseState){
        setManualUseState(true)
        getDebugHistory()
        getUserData()
    }
    else{
        if(window.location.href.includes('/campaigns/kpicheck') && isKpiCheckPage){
            getDebugHistory(1,false);
            setIsKpiCheckPage(false);
            setOpenTabsList([]);
            setQuery('');
            setShowAddTab(false);
            setActiveTab(false);
            setShowInDifferentTables(false);
            setAddTabData({file:false,noOfDays:7,noOfUser:100});    
            setCountrySelected(false);
            // setCustomizationDict({})
        }
        else if(!isKpiCheckPage && window.location.href.includes('/tools/debugscript')){
            getDebugHistory(1,false)
            setOpenTabsList([])
            setIsKpiCheckPage(true)
            setQuery('');
            setShowAddTab(false);
            setActiveTab(false);
            setShowInDifferentTables(false);
            setAddTabData({file:false,noOfDays:7,noOfUser:100});
            setCountrySelected(false);
            // setCustomizationDict({})
        }
    }
    const changeHandler = (event) => {
        if(event.target && event.target.files.length>0){
            setAddTabData({...addTabData , file:event.target.files[0]});
        }
	};
    
    const [addLoader, setAddLoader] = useState(false)
    var addDebug = async() => {
        if(openedDebugReport){
            closeTab(openTabsList[activeTab])
        }
        setAddLoader(true)
        var formData = new FormData();
        formData.append("script", addTabData.file);
        await axios({
            method: !isKpiCheckPage ? 'GET' : "POST",
            url: Constants.BACKENDHOST+"tools/create_flow_report",
            headers:{
                "Content-Type": "multipart/form-data",
                Authorization:getLocalStorageValue('adminPanelLoginData').token,
            },
            data: formData,
            params:{
                install:addTabData.noOfUser,
                open:addTabData.noOfDays,
                version: getLocalStorageValue('adminPanelLoginData').allowedPages.includes('can_get_debug_tree') ? '2' : undefined,
                filename:!isKpiCheckPage ? addTabData.file : null,
                custom_url_list:JSON.stringify(customUrlList),
                show_app_data:isKpiCheckPage ? getAppData : false,
                show_ranges:getRanges,
                country:countrySelected ? countrySelected : undefined,
                cust_channel:customizationDict.channel ? customizationDict.channel : undefined,
                cust_network:customizationDict.network ? customizationDict.network : undefined,
                cust_offerid:customizationDict.offerid ? customizationDict.offerid : undefined,
                refresh_report:openedDebugReport ? openedDebugReport : undefined,
                show_event_values:getEventValues ? true : undefined,
            },
            // withCredentials:true,
        }).then(resp => {
            if(resp.data.response_code===201){
                getDebugHistory();
                setShowAddTab(false);
                setAddTabData({...addTabData,file:null});
                refreshComponent(true);
                Store.addNotification({
                    title: "Success!",
                    message: resp.data.message,
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                      duration: 5000,
                      onScreen: true
                    }
                });
            }
        })
        .catch(err=>{
            console.log(err)
            if(err.response && err.response.data){
                Store.addNotification({
                    title: "Error!",
                    message:  err.response.data.message,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                      duration: 5000,
                      onScreen: true
                    }
                });
            }
        });
        setAddLoader(false)
        setCountrySelected(false);
        // setCustomizationDict({});
    }
    var title = <div style={{display:'flex',alignItems:'center'}}>
                    {!isKpiCheckPage ? 'Kpi Check' : 'Debug Script'}
                    {getDataLoader && <CircularProgress sx={{color:'orange',marginLeft:'12px'}} size={'20px'}/>}
                </div>
    
    useEffect(() => {
        document.addEventListener('keydown',e=>{
            if(e.key==='Escape'){
                setShowAddTab(false);
                setAddTabData({...addTabData,file:null});
            }
        })
        
        return () => {
            document.removeEventListener('keydown',e=>{
                if(e.key==='Escape'){
                    setShowAddTab(false);
                    setAddTabData({...addTabData,file:null});
                }
            })
        }
    }, [addTabData])
    
    const enterKeyPressed = (e) => {
        if(e.key==='Enter'){
            if(addTabData.file && addTabData.noOfDays && addTabData.noOfUser){
                addDebug()
            }
        }
    }
    var i1CountOptions = () => {
        if(addTabData.file && 
            (   
                !isKpiCheckPage
                &&
                (
                    (addTabData.file.name && (addTabData.file.name.includes('modd') || addTabData.file.name.includes('auto'))) 
                    || 
                    (!isKpiCheckPage && (addTabData.file.includes('modd') || addTabData.file.includes('auto')))
                )
            )
        ){
            var moddFile = true
        }
        if(moddFile){
            return [10,50,100,200,250,500,1000]
        }
        else{
            if(getLocalStorageValue('adminPanelLoginData').allowedPages.includes('debug_perform_above_250')){
                return [10,50,100,200,250,500,1000]
            }
            return [10,50,100,200,250]

        }
    } 

    var noOfDaysFunct = () => {
        if(getLocalStorageValue('adminPanelLoginData').allowedPages.includes('debug_perform_above_15_days')){
            return [1,3,5,7,10,15,21,28,35]
        }
        return [1,3,5,7,10,15]
    } 
    
    
    const changeCustomUrlData = (e,index,key) => {
        var tempCustomUrlData = customUrlList;
        tempCustomUrlData[index][key] = key==='key' ? 'data->' + e.target.value : e.target.value;
        setCustomUrlList(tempCustomUrlData);
    }
    const [shareWithName, setShareWithName] = useState(false)
    const shareUserData = async() => {
        await axios({
            method: 'POST',
            url: "".concat(Constants.BACKENDHOST,"tools/share_flow_report"),
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token,
            },
            params:{
                serial:shareFromHistoryComponent ? shareFromHistoryComponent : openTabsList[activeTab].serial,
                share_with:shareWithName
            }
        })
        .then(resp => {
            console.log(resp)
            Store.addNotification({
                title: "Success!",
                message: resp.data.message,
                type: "success",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                  duration: 5000,
                  onScreen: true
                }
            });
            setShareReportPopup(false);
            setShareWithName(false);
            setShareFromHistoryComponent(false);
        })
        .catch(err => {
            console.error(err);
            if(err.response && err.response.data){
                Store.addNotification({
                    title: "Error!",
                    message:  err.response.data.message,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                      duration: 5000,
                      onScreen: true
                    }
                });
            }
        })
    };
    var sharePopupData = () => {
        return <div style={{height:'200px',display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column'}}>
            <div style={{transform:'scale(0.8)',width:'100%'}}>
                <ReactSelect
                    placeholder={'Share Report With...'}
                    value={shareWithName ? {label:capitalize(shareWithName),value:shareWithName} : false}
                    options={userData.map((item,index)=>{return {label:capitalize(item),value:item}})}
                    onChange={(e)=>{setShareWithName(e.value);}}
                    menuPortalTarget={document.body} 
                    styles={{...Constants.ReactSelectStyles}}
                />
            </div>
            <Button disabled={!shareWithName} onClick={shareUserData} sx={{...Constants.CONTAINEDBUTTON,textTransform:'capitalize',marginTop:'20px',}}>
                Share
            </Button>
        </div>
    }
    return (
        <div>
            <TopbarV2 title={title}/>
            <div style={{display:'flex'}}>
                <HistoryComponent
                    historyWindowWidth={historyWindowWidth}
                    setHistoryWindowWidth={setHistoryWindowWidth}
                    openTabs={getDebugData}
                    historyResponse={historyResponse}
                    setHistoryResponse={setHistoryResponse}
                    historyResponseLoader={historyResponseLoader}
                    setHistoryResponseLoader={setHistoryResponseLoader}
                    getDebugHistory={getDebugHistory}
                    historyPage={historyPage}
                    setHistoryPage={setHistoryPage}
                    historyPages={historyPages}
                    historyFor={'Report'}
                    query={query}
                    setQuery={setQuery}
                    rightClicked={[{label:'Share Item',onClick:(e,item)=>{
                        setShareReportPopup(true);
                        setShareFromHistoryComponent(item.serial);
                    }}]}
                />
                <div style={{position:'relative',width:'calc(100% - '+historyWindowWidth+'px - 5px)'}}>
                    <div className='debugHistoryHead' >
                        <div style={{position:'absolute',width:'100%',display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                            <div style={{width:'90%',display:'flex',alignItems:'center',overflowX:'auto'}}>
                                {openTabsList.map((item,index)=>{
                                    return <div className='openTabContainer' style={{height:(index===activeTab ? 'calc(40px - 5px)' : '40px'),color:(index===activeTab ? 'black' : 'rgb(100,100,100 )'),borderBottom:(index===activeTab ? '5px solid orange' : '1px solid rgb(200,200,200)')}} key={index} onClick={()=>{setShowInDifferentTables(false);setActiveTab(index);}}>
                                            <div style={{width:'90%',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',fontSize:'12px',fontWeight:'500'}}>
                                                {item.filename}
                                            </div>
                                            <div style={{cursor:'pointer'}} onClick={()=>{closeTab(item)}}>
                                                X
                                            </div>
                                        </div>
                                })}
                            </div>
                            <div className='addBoxContainer' style={{cursor:'pointer'}} onClick={()=>{
                                if(historyResponse.length > 0 && historyResponse[0].reportstatus==='Pending'){
                                    Store.addNotification({
                                        title: "Warning!",
                                        message: 'Please wait for the last file to complete processing, or refresh the page and try again.',
                                        type: "warning",
                                        insert: "top",
                                        container: "top-right",
                                        animationIn: ["animate__animated", "animate__fadeIn"],
                                        animationOut: ["animate__animated", "animate__fadeOut"],
                                        dismiss: {
                                          duration: 6000,
                                          onScreen: true
                                        }
                                    });
                                }
                                else{
                                    setShowAddTab(true);
                                }
                            }}>
                                Add
                            </div>
                        </div>
                    </div>
                    
                    {
                        (activeTab || activeTab===0) 
                            && 
                        <DebugFileData 
                            performAnotherDebug={setShowAddTab}
                            performAnotherDebugReportNo={setOpenedDebugReport}
                            fileData={openTabsList[activeTab]} 
                            loader={getDataLoader} 
                            showInDifferentTables={showInDifferentTables} 
                            setShowInDifferentTables={setShowInDifferentTables} 
                            shareReportPopup={shareReportPopup} 
                            setShareReportPopup={setShareReportPopup}
                        />
                    }
                    {
                        shareReportPopup
                        &&
                        <Popup head={'Share Report'} data={sharePopupData()} handleClose={(e)=>{if(shareReportPopup){if(document.getElementById('threeDotMenuDebug')){document.getElementById('threeDotMenuDebug').style.animation = 'threeDotClosed 0.5s forwards'};setShareReportPopup(false);}}}/>
                    }
                    {
                        showAddTab &&
                        <div className='addTabContainer'>
                            <div className='addTabWrapper'>
                                <div style={{display:'flex',justifyContent:'space-between',alignItems:'center',cursor:'default'}}>
                                    <div>Add File for Debug</div>
                                    <div style={{cursor:'pointer'}} onClick={()=>{setShowAddTab(false);setAddTabData({...addTabData,file:null});setCustomUrlList([])}}>X</div>
                                </div>
                                
                              
                                {isKpiCheckPage &&
                                <>
                                    <input type="file" name="file" accept='.py' id='scriptSelect' style={{height:'100%',width:'100%'}} onChange={changeHandler}  hidden/>
                                    <label className='addFileWrapper' htmlFor='scriptSelect' >
                                        {addTabData.file ? addTabData.file.name : '+ Click to Add File'}
                                    </label>
                                </>
                                }
                                {!isKpiCheckPage &&
                                    <div style={{marginTop:'40px',paddingBottom:'10px',transform:'scale(0.9)'}}>
                                        <Constants.CssTextField size='small' fullWidth value={addTabData.file ? addTabData.file : ''} label='Filename' onKeyDown={enterKeyPressed} onChange={(e)=>{setAddTabData({...addTabData,file:e.target.value})}}/>
                                        
                                        {/* <CreatableSelect                                                
                                            isClearable
                                            placeholder={'Filename'}
                                            value={addTabData.file ? addTabData.file : false}
                                            onChange={(e)=>{setAddTabData({...addTabData,file:e})}}
                                            menuPortalTarget={document.body} 
                                            styles={{...Constants.ReactSelectStyles}}
                                        /> */}
                                    </div>
                                }
                               
                                <div className='addFileParams'>
                                    <div style={{width:'25%'}}>
                                        <div style={{fontSize:'12px'}}>Retention Days</div>
                                        <ReactSelect                                                
                                            placeholder={'Retention Days'}
                                            options={noOfDaysFunct().map((item,index)=>{return {label:item,value:item}})}
                                            value={addTabData.noOfDays ? {label:addTabData.noOfDays,value:addTabData.noOfDays} : false}
                                            onChange={(e)=>{setAddTabData({...addTabData,noOfDays:e.value})}}
                                            menuPortalTarget={document.body} 
                                            styles={{...Constants.ReactSelectStyles}}
                                        />
                                    </div>
                                    <div style={{width:'25%'}}>
                                        <div style={{fontSize:'12px'}}>I1 Count</div>
                                        <ReactSelect                                                
                                            placeholder={'I1 Count'}
                                            options={i1CountOptions().map((item,index)=>{return {label:item,value:item}})}
                                            value={addTabData.noOfDays ? {label:addTabData.noOfUser,value:addTabData.noOfUser} : false}
                                            onChange={(e)=>{setAddTabData({...addTabData,noOfUser:e.value})}}
                                            menuPortalTarget={document.body} 
                                            styles={{...Constants.ReactSelectStyles}}
                                        />
                                    </div>
                                    <div style={{width:'30%',display:'flex',justifyContent:'center',flexDirection:'column'}}>
                                            
                                        {isKpiCheckPage
                                        &&
                                        <div style={{width:'100%',display:'flex',alignItems:'center'}}>
                                            <Tooltip title={"Will show you app_data values when a particular key's value is None in script."}>
                                                <div style={{display:'flex',alignItems:'center'}}>
                                                    <input id='app_data_values' checked={getAppData} type='checkbox' onChange={e=>{setGetAppData(e.target.checked);}}/>
                                                    <label htmlFor='app_data_values'>Get App Data</label>
                                                </div>
                                            </Tooltip>
                                        </div>
                                        }

                                        <div style={{width:'100%',display:'flex',alignItems:'center',marginTop:'10px'}}>
                                            <Tooltip title={"Will show you app_data values when a particular key's value is None in script."}>
                                                <div style={{display:'flex',alignItems:'center'}}>
                                                    <input id='event_data_values' checked={getEventValues} type='checkbox' onChange={e=>{setGetEventValues(e.target.checked);}}/>
                                                    <label htmlFor='event_data_values'>Get Event Values</label>
                                                </div>
                                            </Tooltip>
                                        </div>
                                        <div style={{width:'100%',display:'flex',alignItems:'center',marginTop:'10px'}}>
                                            <Tooltip title={"Will show you ranges of percentage of user (lower and upper limit)."}>
                                                <div style={{display:'flex',alignItems:'center'}}>
                                                    <input id='get_ranges_values' checked={getRanges} type='checkbox' onChange={e=>{setGetRanges(e.target.checked);}}/>
                                                    <label htmlFor='get_ranges_values'>Get Ranges</label>
                                                </div>
                                            </Tooltip>
                                        </div>
                                    </div>
                                        
                                    {/* <Constants.CssTextField size='small' value={addTabData.noOfDays} label='Retention Days' onKeyDown={enterKeyPressed} onChange={(e)=>{setAddTabData({...addTabData,noOfDays:e.target.value})}}/>
                                    <Constants.CssTextField size='small' value={addTabData.noOfUser} label='I1 Count' onKeyDown={enterKeyPressed} onChange={(e)=>{setAddTabData({...addTabData,noOfUser:e.target.value})}}/> */}
                                </div>
                                
                                
                                <div style={{transform:'scale(0.75)',marginTop:'10px',marginBottom:'-5px'}}>
                                    <ReactSelect                                                
                                            placeholder={'Country'}
                                            options={Constants.COUNTRIES_LIST.map((item,index)=>{return {label:item,value:item}})}
                                            value={countrySelected ? {label:countrySelected,value:countrySelected} : false}
                                            onChange={(e)=>{setCountrySelected(e.value)}}
                                            menuPortalTarget={document.body} 
                                            styles={{...Constants.ReactSelectStyles}}
                                        />
                                </div>
                                <div style={{fontSize:'12px',color:getLocalStorageValue('ambientColor'),cursor:'pointer',marginTop:'5px'}} onClick={()=>{setAddCustomizationButton(!addCustomizationButton)}}>Add Customization</div>
                                
                                <div style={{transition:'all 0.4s linear',transform:(addCustomizationButton ? 'scaleY(1)' : 'scaleY(0)'),marginTop:(addCustomizationButton ? '5px' : '-20px'),opacity:(addCustomizationButton ? 1 : 1),display:'flex',alignItems:'center',marginBottom:(addCustomizationButton ? '-5px' : '-20px')}}>
                                    <Constants.CssTextField size='small' sx={{transform:'scale(0.75)'}} value={customizationDict.channel} label='Channel Name' onKeyDown={enterKeyPressed} onChange={(e)=>{setCustomizationDict({...customizationDict,channel:e.target.value})}}/>
                                    <Constants.CssTextField size='small' sx={{transform:'scale(0.75)'}} value={customizationDict.network} label='Network Name' onKeyDown={enterKeyPressed} onChange={(e)=>{setCustomizationDict({...customizationDict,network:e.target.value})}}/>
                                    <Constants.CssTextField size='small' sx={{transform:'scale(0.75)'}} value={customizationDict.offerid} label='Offer ID' onKeyDown={enterKeyPressed} onChange={(e)=>{setCustomizationDict({...customizationDict,offerid:e.target.value})}}/>
                                </div>
                                {!isKpiCheckPage &&
                                <div style={{padding:'10px',paddingTop:'30px',fontSize:'14px'}}>
                                    <div>Add Custom URL Host Events:</div>
                                    <div style={{maxHeight:'200px',overflow:'auto',paddingTop:'10px',paddingBottom:'10px'}}>
                                        {customUrlList.map((item,index)=>{
                                            return <div style={{position:'relative',display:'flex',flexWrap:'wrap',alignItems:'center'}}>
                                                    <Constants.CssTextField size='small' onChange={(e)=>{changeCustomUrlData(e,index,'url')}} placeholder='Host URL' key='Host URL' sx={{width:'70%',transform:'scale(0.8)',transformOrigin:"left"}}/>
                                                    <Constants.CssTextField size='small' onChange={(e)=>{changeCustomUrlData(e,index,'key')}} placeholder='Event Key Name' key='Event Key Name' sx={{width:'30%',transform:'scale(0.8) translateX(-20%)',transformOrigin:"left"}}/>
                                                    <Tooltip title='Remove URL.'>
                                                        <IconButton sx={{position:'absolute',right:0}} onClick={()=>{
                                                                        var tempList = customUrlList;
                                                                        tempList.splice(index,1);
                                                                        setCustomUrlList(tempList);
                                                                        refreshComponent(12);
                                                                    }}>
                                                            <CloseOutlined sx={{fontSize:'15px'}}/>
                                                        </IconButton>
                                                    </Tooltip>  
                                                    
                                                </div>
                                        })}
                                    </div>
                                    <div>
                                        <Tooltip title='Add more custom urls.'>
                                            <IconButton onClick={()=>{setCustomUrlList([...customUrlList,{host:'',key:''}])}}>
                                                <AddCircleOutline  sx={{fontSize:'15px',color:'orange'}}/>
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                </div>
                                }
                                <div style={{display:'flex',justifyContent:'center'}}>
                                    <Button disabled={!addTabData.file || !addTabData.noOfDays || !addTabData.noOfUser} sx={{...Constants.CONTAINEDBUTTON,textTransform:'none',fontWeight:'400',marginTop:'20px'}} onClick={()=>{addDebug()}}> 
                                        {addLoader && <CircularProgress sx={{color:'white'}} size={'18px'}/>}
                                        {!addLoader && "Submit"}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default DebugPage;