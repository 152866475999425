import { FilterAlt } from '@mui/icons-material'
import { Button, IconButton, Tooltip } from '@mui/material'
import React, { useEffect, useState } from 'react'
import './filterComponent.css';
import DateRangePickerComponent from '../../components/dateRangePicker/dateRangePicker';
import SelectComponent from '../../components/selectComponent/selectComponent';

const FilterComponent = (props) => {
    const [filterData,setFilterData] = [props.filterData,props.setFilterData]
    const [showFilterMenu, setShowFilterMenu] = useState(false)
    const [activeSelect, setActiveSelect] = useState(false)
    useEffect(() => {
        document.addEventListener('click',e=>{
            setShowFilterMenu(false);
        })
        
        return () => {
            document.removeEventListener('click',e=>{
                setShowFilterMenu(false);
            })
        }
    }, [])

    var filterList = props.filterList
    return (
        <div className='filterComponentContainer'>
            <Tooltip title={'Add Filters'}>
                <IconButton onClick={(e)=>{e.stopPropagation();setShowFilterMenu(true);}}>
                    <FilterAlt sx={{fontSize:'18px'}}/>
                </IconButton>
            </Tooltip>
            {showFilterMenu
            &&
            <div className='stintFilterContainer' onClick={(e)=>{e.stopPropagation();}}>
                {
                    filterList.map((item,index)=>{
                        return <div className='stintFilterItem' key={index}>
                                {
                                    item.icon
                                    &&
                                    <div style={{position:'absolute',top:'30px',left:'13px'}}>
                                        {<item.icon sx={{color:'rgb(80,80,80)',fontSize:'20px'}}/>}
                                    </div>
                                }
                                {
                                    <div className='stintFilterLabel'>
                                        {item.label}
                                    </div>
                                }
                                {item.type==='daterange'
                                &&
                                <DateRangePickerComponent label={item.label} value={item.value} propsParent={props} customDateRangeFunction={props.customDateRangeFunction}  filterData={filterData} setFilterData={setFilterData}/>
                                }
                                {item.type==='select'
                                &&
                                <div style={{height:'40px',width:'100%',cursor:'pointer'}}>
                                    <SelectComponent activeSelect={activeSelect} setActiveSelect={setActiveSelect} propsParent={props} filterItem={item} filterData={filterData} setFilterData={setFilterData}/>
                                </div>
                                }
                            </div>
                    })
                }
                <div style={{position:'absolute',height:'50px',width:'calc(100% - 40px)',cursor:'pointer',bottom:'0px',display:'flex',justifyContent:'flex-end',alignItems:'center',marginRight:'20px',marginLeft:'20px'}}>
                    <div style={{marginRight:'10px'}} onClick={()=>{props.resetRequest();}}>Reset</div>
                    <div onClick={()=>{props.submitRequest();setShowFilterMenu(false);}}>Submit</div>
                </div>
            </div>
            }
        </div>
    )
}

export default FilterComponent