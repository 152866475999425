import axios from 'axios'
import React, { useState } from 'react'
import { getLocalStorageValue, useForceUpdate } from '../../helper/util'
import * as Constants from '../../data/constants';
import { Store } from 'react-notifications-component';
import TopbarV2 from '../../components/topbarv2/topbarv2';
import { ArrowDownward, ArrowUpward, Close, Search } from '@mui/icons-material';
import { Button, CircularProgress, IconButton, Input } from '@mui/material';
import '../../css/pages/v1/campaignInfo.css';
import Popup from '../../components/popup/popup';
import { orange } from '@mui/material/colors';
import { downloadFile } from './downloadCampaigns/downloadCampaigns';
import {parseDiff, Diff, Hunk} from 'react-diff-view';
import {diffLines, formatLines} from 'unidiff';



const CampaignInfoPage = () => {
    const refreshComponent = useForceUpdate();
    const [response, setResponse] = useState(false)
    const [loader, setShowLoader] = useState(false)
    const [saveButtonLoader, setShowSaveButtonLoader] = useState(false)
    const [filename, setFilename] = useState(false)
    const [enhancedLastChanges, setEnhancedLastChanges] = useState(true)
    const [newCustomization, setNewCustomization] = useState({upper_limit:70,lower_limit:5})
    const [showAddCustomizationPopup, setShowAddCustomizationPopup] = useState(false)
    const [customizationFound, setCustomizationFound] = useState([])
    const [lastChangeScriptIndex, setlastChangeScriptIndex] = useState([])

    var addCustomizationApi = async() => {
        setShowSaveButtonLoader(true)
        if(showAddCustomizationPopup==='ID Matching (Appsflyer)'){
            var data = {
                filename,
                gp_referrer:newCustomization.gp_referrer,
                id_matching:newCustomization.id_matching,
                probabilistic:newCustomization.probabilistic,
                customization_type:showAddCustomizationPopup,
                is_forced:newCustomization.forced,
            }
        }
        else{
            data = {
                filename,
                network_name:newCustomization.network_name,
                channel:newCustomization.channel,
                offer_id:newCustomization.offer_id,
                customization:newCustomization.customization,
                customization_type:showAddCustomizationPopup,
                is_forced:newCustomization.forced,
            }
        }
        if(showAddCustomizationPopup === 'Retention Customization'){
            data['customization'] = (newCustomization.upper_limit ? newCustomization.upper_limit : '70') + ',' +(newCustomization.lower_limit || newCustomization.lower_limit==='0' ? newCustomization.lower_limit : '5')
        }

        if(showAddCustomizationPopup === 'Delay Event Customization'){
            data['customization_type'] = 'DELAY_EVENT_CUSTOMIZATION'
            data['customization'] = {
                'event_name':newCustomization.event_name,
                'event_stop_date':newCustomization.event_stop_date
            }
        }
        await axios({
            method: "POST",
            url: Constants.BACKENDHOST+"campaigns/campaign-add-customization",
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token,
                'Content-type':'application/json'
            },
            data
        })
        .then(resp => {
            if(resp.data.response_code===201){
                setNewCustomization({...newCustomization,resp_forced:true})
                Store.addNotification({
                    title: "Warning!",
                    message: resp.data.data.status,
                    type: "warning",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 10000,
                        onScreen: true
                    }
                });
            }
            else{
                Store.addNotification({
                    title: "Success!",
                    message: resp.data.data.status,
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });
                setNewCustomization({})
                setShowAddCustomizationPopup(false)
                getCampaignInfo()
            }
            
        })
        .catch(err=>{
            if(err.response && err.response.data){
                Store.addNotification({
                    title: "Error!",
                    message: err.response.data.message,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });
            }
        })
        setShowSaveButtonLoader(false)
    }
    var getPopup = (showAddCustomizationPopup) => {
        if(showAddCustomizationPopup === 'GEO-Customizations' || showAddCustomizationPopup === 'Retention Customization' || showAddCustomizationPopup === 'Delay Event Customization' ){
            var data = <div style={{display:'flex',flexDirection:'column',transform:'scale(0.8)'}}>
                <Constants.CssTextField size='small' sx={{marginTop:'5px'}} onChange={(e)=>{setNewCustomization({...newCustomization,channel:e.target.value})}} label='Channel'/>
                <Constants.CssTextField size='small' sx={{marginTop:'20px'}} onChange={(e)=>{setNewCustomization({...newCustomization,network_name:e.target.value})}} label='Network'/>
                <Constants.CssTextField size='small' sx={{marginTop:'20px'}} onChange={(e)=>{setNewCustomization({...newCustomization,offer_id:e.target.value})}} label='Offer ID'/>
                { showAddCustomizationPopup === 'Retention Customization'
                &&
                <div style={{display:'flex',justifyContent:'space-around'}}>
                    <Constants.CssTextField size='small' sx={{marginTop:'20px'}} defaultValue={70} onChange={(e)=>{setNewCustomization({...newCustomization,upper_limit:e.target.value})}} label='Upper Limit'/>
                    <Constants.CssTextField size='small' sx={{marginTop:'20px'}} defaultValue={5} onChange={(e)=>{setNewCustomization({...newCustomization,lower_limit:e.target.value})}} label='Lower Limit'/>
                </div>
                }
                { showAddCustomizationPopup === 'Delay Event Customization'
                &&
                <div style={{display:'flex',justifyContent:'space-around'}}>
                    <Constants.CssTextField size='small' sx={{marginTop:'20px'}} onChange={(e)=>{setNewCustomization({...newCustomization,event_stop_date:e.target.value})}} label='Date (YYYY-MM-DD)'/>
                    <Constants.CssTextField size='small' sx={{marginTop:'20px'}} onChange={(e)=>{setNewCustomization({...newCustomization,event_name:e.target.value})}} label='Event Name'/>
                </div>
                }
                { showAddCustomizationPopup === 'GEO-Customizations'
                &&
                <Constants.CssTextField size='small' sx={{marginTop:'20px'}} onChange={(e)=>{setNewCustomization({...newCustomization,customization:e.target.value})}} label='Customization'/>
                }
                {newCustomization.resp_forced
                &&
                <div style={{marginTop:'10px'}}>
                    <input id='forcedPush' type='checkbox' onChange={(e)=>{setNewCustomization({...newCustomization,forced:e.target.checked})}}/>
                    <label htmlFor='forcedPush'>Yes, I want to update.</label>
                </div>
                }
                <Button disabled={saveButtonLoader ||(showAddCustomizationPopup === 'Retention Customization' && (!newCustomization.lower_limit || !newCustomization.upper_limit)) || (showAddCustomizationPopup === 'GEO-Customizations' && !newCustomization.customization)} sx={{...Constants.CONTAINEDBUTTON,marginTop:'20px'}} onClick={addCustomizationApi}>
                        {saveButtonLoader && <CircularProgress size={'20px'} sx={{color:'white'}}/>}
                        {!saveButtonLoader && 'Save'}
                </Button>
            </div>
        }
        if(showAddCustomizationPopup === 'ID Matching (Appsflyer)'){
            var data = <div style={{display:'flex',flexDirection:'column',transform:'scale(0.8)'}}>
                <Constants.CssTextField size='small' value={newCustomization.gp_referrer || newCustomization.gp_referrer===0 ? newCustomization.gp_referrer : ''} sx={{marginTop:'5px'}} onChange={(e)=>{if(e.target.value===''){e.target.value=0}if(parseInt(e.target.value)<100){setNewCustomization({...newCustomization,gp_referrer:parseInt(e.target.value),id_matching:(100 - parseInt(e.target.value)),probabilistic:0});refreshComponent(12);}}} label='GP Referrer'/>
                <Constants.CssTextField size='small' value={newCustomization.id_matching || newCustomization.id_matching===0 ? newCustomization.id_matching : ''} sx={{marginTop:'20px'}} onChange={(e)=>{if(parseInt(e.target.value)<100){setNewCustomization({...newCustomization,id_matching:parseInt(e.target.value),probabilistic:0});refreshComponent(12);}}} label='ID Matching'/>
                <Constants.CssTextField size='small' value={newCustomization.probabilistic || newCustomization.probabilistic===0 ? newCustomization.probabilistic : ''} sx={{marginTop:'20px'}} onChange={(e)=>{if(parseInt(e.target.value)<100){setNewCustomization({...newCustomization,probabilistic:parseInt(e.target.value) });refreshComponent(12);}}} label='Probabilistic'/>
                {newCustomization.resp_forced
                &&
                <div style={{marginTop:'10px'}}>
                    <input id='forcedPush' type='checkbox' onChange={(e)=>{setNewCustomization({...newCustomization,forced:e.target.checked})}}/>
                    <label htmlFor='forcedPush'>Yes, I want to update.</label>
                </div>
                }
                <Button disabled={saveButtonLoader || (!newCustomization.gp_referrer && newCustomization.gp_referrer!==0) || (!newCustomization.id_matching && newCustomization.id_matching!==0) || (!newCustomization.probabilistic  && newCustomization.probabilistic!==0)} sx={{...Constants.CONTAINEDBUTTON,marginTop:'20px'}} onClick={addCustomizationApi}>
                        {saveButtonLoader && <CircularProgress size={'20px'} sx={{color:'white'}}/>}
                        {!saveButtonLoader && 'Save'}
                </Button>
            </div>
        }
        return <Popup head={(newCustomization.isEdit ? 'Edit ' : 'Add New ' )+showAddCustomizationPopup} data={data} handleClose={()=>{setShowAddCustomizationPopup(false)}}/>
    }
    
    var getCampaignInfo = async() => {
        setShowLoader(true)
        setResponse(false)
        setlastChangeScriptIndex([])
        await axios({
            method: "GET",
            url: Constants.BACKENDHOST+"campaigns/campaign-related-data/"+filename,
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token,
            },
        })
        .then(resp => {
            var customizationList = ['GEO-Customizations','ID Matching (Appsflyer)','Retention Customization','Delay Event Customization']
            var tempcustomizationFound = []
            for(var customizationIndex in customizationList){
                var foundFlag = false
                var customization = customizationList[customizationIndex]
                for(var index in resp.data.data){
                    if(resp.data.data[index].label===customization){
                        foundFlag = true
                    }
                }
                if(!foundFlag){
                    tempcustomizationFound.push(customization)
                }
            }
            setCustomizationFound(tempcustomizationFound)
            setResponse(resp.data.data)
        })
        .catch(err=>{
            if(err.response && err.response.data){
                Store.addNotification({
                    title: "Error!",
                    message: err.response.data.message,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });
            }
        })
        setShowLoader(false)
    }

    var downloadOldFile=async(rowItem, getData=false)=>{
        // /autolabs/campaigns/download-script-from-commit/<filename>/<commit_hash></commit_hash>
        var commitHash = rowItem.hash
        
        var responseData = await axios({
            method: "GET",
            url: Constants.BACKENDHOST+"campaigns/download-script-from-commit/"+filename+"/"+commitHash,
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token,
                'Content-type':'application/json',
            },
        })
        .then(resp => {
            var file = filename + "_" + rowItem['Date'] + ".py"
            if(getData){
                return resp.data
            }
            else{
                downloadFile(resp.data,file)
            }
        })
        .catch(err=>{
            console.log(err)
            if(err.response && err.response.data){
                Store.addNotification({
                    title: "Error!",
                    message: err.response.data.message,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });
            }
        })
        return responseData
    }
    
    
    var checkBoxLogic=(rowItem)=>{
        var tempLastChangeScriptIndex = lastChangeScriptIndex
        if(tempLastChangeScriptIndex.length==2){
            tempLastChangeScriptIndex.splice(1,1)
        }
        if(tempLastChangeScriptIndex.includes(rowItem.hash)){
            tempLastChangeScriptIndex = tempLastChangeScriptIndex.filter(e => e!=rowItem.hash)
        }
        else{
            tempLastChangeScriptIndex.push(rowItem.hash)
        }
        setlastChangeScriptIndex(tempLastChangeScriptIndex)
        refreshComponent(1234);
        
    }

    const [code, setCode] = useState(false)
    const [diff, setDiff] = useState('')
    const [showComparePopup, setShowComparePopup] = useState(false)
    const [compareLoader, setCompareLoader] = useState(false)

    var compareTwoScriptLogic=async()=>{
        
        // 0 is new and 1 is old
        setCompareLoader(true)
        var oldHash = lastChangeScriptIndex[1]
        var newHash = lastChangeScriptIndex[0]
        var oldData = await downloadOldFile({hash:oldHash}, true)
        var newData = await downloadOldFile({hash:newHash}, true)
        setCode({oldCode:oldData,newCode:newData})
        setShowComparePopup(true)
        var diffText = await formatLines(diffLines(oldData,newData), {context: 3})
        const [diff] = await parseDiff(diffText, {nearbySequences: 'zip'});
        setDiff(diff)
        setCompareLoader(false)
    }

    var lastChangesHeaders = ['Date','Message','Additional Comment','Pushed By']
    if(getLocalStorageValue('adminPanelLoginData').allowedPages.includes('can_compare_old_scripts')){
        lastChangesHeaders = ['', 'Date','Message','Additional Comment','Pushed By', 'Download']
    }

    return (
        <div>
            <TopbarV2 title={'Campaign Info'}/>
            <div style={{display:'flex',alignItems:'center',flexDirection:'column',marginTop:'30px',height:'calc(100vh - 100px)',overflow:'auto'}}>
                <div style={{display:'flex',justifyContent:'center',alignItems:'center',backgroundColor:'white',width:'100%',paddingBottom:'10px',position:'sticky',top:0}}>
                    <Constants.CssTextField placeholder='Campaign Name' sx={{minWidth:'300px'}} size='small' onChange={e=>{setFilename(e.target.value)}} onKeyDown={e => {if(e.key==='Enter'){getCampaignInfo()}}}/>
                    <IconButton onClick={()=>{getCampaignInfo()}}>
                        {loader && <CircularProgress size={'18px'} sx={{color:localStorage.getItem('ambientColor')}}/>}
                        {!loader && <Search sx={{color:'rgb(100,100,100)'}}/>}
                    </IconButton>
                </div>

                {
                    response
                    &&
                    <div style={{marginTop:'20px',width:'80%',padding:'50px',border:'2px solid '+(response.error ? 'red' : 'orange'),borderRadius:'14px',background:(response.error ? 'rgb(255, 233, 233)' : 'rgb(255, 252, 247)')}}>
                        {!response.error && response.map((item,index)=>{
                            if(item.type==='str'){
                                return <div style={{display:'flex',marginBottom:'10px'}} key={index}>
                                            <div style={{minWidth:'200px',fontWeight:'500',textTransform:'none'}}>{item.label}</div>
                                            <div style={{width:'calc(100% - 200px)',overflow:'auto',paddingBottom:'20px'}}>
                                                {item.value}
                                        </div>
                                    </div>
                            }
                            if(item.label==='Retention'){
                                return  <div style={{display:'flex',marginBottom:'10px'}} key={index}>
                                            <div style={{minWidth:'200px',fontWeight:'500',textTransform:'none'}}>{item.label}</div>
                                            <div style={{width:'calc(100% - 200px)',overflow:'auto',paddingBottom:'20px',textTransform:'none'}}>
                                                <table style={{borderSpacing:'0px'}}>
                                                    <thead>
                                                        <tr>
                                                            {Object.keys(item.value).map((retentionItem,retentionIndex)=>{
                                                                return <td style={{padding:'5px',border:'1px solid rgb(200,200,200)'}} key={retentionIndex}>
                                                                    {retentionItem}
                                                                </td>
                                                            })}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            {Object.keys(item.value).map((retentionItem,retentionIndex)=>{
                                                                return <td style={{padding:'5px',border:'1px solid rgb(200,200,200)'}}  key={retentionIndex}>
                                                                    {item.value[retentionItem]}
                                                                </td>
                                                            })}
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                            }
                            if(item.type==='list'){
                                if(item.label==='Last changes' && !enhancedLastChanges){
                                    var value = item.value.slice(0,3) 
                                }
                                else{
                                    value = item.value
                                }

                                return  <div style={{display:'flex',marginBottom:'10px'}} key={index}>
                                            <div style={{minWidth:'200px',fontWeight:'500',textTransform:'none', display:"flex", flexDirection:"column", alignItems:"flex-start"}}>
                                                {item.label}
                                                {
                                                    lastChangeScriptIndex.length===2
                                                    &&
                                                    <div style={{transform:"scale(0.7)", transformOrigin:"left"}}>
                                                        <Button type='submit' color='warning' variant='contained' onClick={()=>{compareTwoScriptLogic()}}>
                                                            {compareLoader
                                                            && 
                                                            <CircularProgress sx={{color:"white"}} size={"20px"}/>}
                                                            {
                                                                !compareLoader 
                                                                &&
                                                                "Compare"
                                                            }
                                                        </Button>
                                                    </div>
                                                }
                                            </div>
                                            <div style={{width:'calc(100% - 200px)',overflow:'auto',paddingBottom:'20px',textTransform:'none'}}>
                                                <table style={{borderSpacing:'0px'}}>
                                                    <thead>
                                                        <tr>
                                                            {item.label==='Last changes'
                                                                && value && value.length>0 && lastChangesHeaders.map((retentionItem,retentionIndex)=>{
                                                                
                                                                return <td style={{padding:'5px',fontWeight:'500',border:'1px solid rgb(200,200,200)'}} key={retentionIndex}>
                                                                    {retentionItem} 
                                                                </td>
                                                            })}
                                                            {item.label!=='Last changes'
                                                                && value && value.length>0 && Object.keys(value[0]).map((retentionItem,retentionIndex)=>{
                                                                return <td style={{padding:'5px',fontWeight:'500',border:'1px solid rgb(200,200,200)'}} key={retentionIndex}>
                                                                    {retentionItem} 
                                                                </td>
                                                            })}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                            {value && value.length>0 && value.map((rowItem,rowIndex)=>{
                                                                return <tr key={rowIndex}>
                                                                            {
                                                                                item.label==='Last changes'
                                                                                && 
                                                                                lastChangesHeaders.map((retentionItem,retentionIndex)=>{
                                                                                    if (retentionItem === 'Download' && getLocalStorageValue('adminPanelLoginData').allowedPages.includes('can_compare_old_scripts')){
                                                                                        return <td style={{padding:'5px',fontSize:'13px',border:'1px solid rgb(200,200,200)', color:'orange', cursor:'pointer'}}  key={retentionIndex} onClick={()=>{downloadOldFile(rowItem)}}> 
                                                                                            Download
                                                                                        </td>
                                                                                    }
                                                                                    if (retentionItem === '' && getLocalStorageValue('adminPanelLoginData').allowedPages.includes('can_compare_old_scripts')){
                                                                                        return <td style={{padding:'5px',fontSize:'13px',border:'1px solid rgb(200,200,200)'}}  key={retentionIndex}> 
                                                                                            <input type='checkbox' value={lastChangeScriptIndex.includes(rowItem.hash)}  checked={lastChangeScriptIndex.includes(rowItem.hash)} onChange={()=>{checkBoxLogic(rowItem)}} />
                                                                                            {lastChangeScriptIndex.includes(rowItem.hash) 
                                                                                                && 
                                                                                            lastChangeScriptIndex.indexOf(rowItem.hash) === 1
                                                                                                &&
                                                                                                'Old'
                                                                                            }
                                                                                            {lastChangeScriptIndex.includes(rowItem.hash) 
                                                                                                && 
                                                                                            lastChangeScriptIndex.indexOf(rowItem.hash) === 0
                                                                                                &&
                                                                                                'New'
                                                                                            }
                                                                                        </td>
                                                                                    }
                                                                                    

                                                                                    return <td style={{padding:'5px',fontSize:'13px',border:'1px solid rgb(200,200,200)'}}  key={retentionIndex}>
                                                                                        <div style={{whiteSpace:'pre-line'}}>
                                                                                            {typeof rowItem[retentionItem]==='string' && rowItem[retentionItem]}
                                                                                            {typeof rowItem[retentionItem]==='object' && rowItem[retentionItem].map((jono,jonoIndex)=>{return jono+ (jonoIndex===rowItem[retentionItem].length -1 ? " " : ", ")})}
                                                                                        </div>
                                                                                    </td>
                                                                                    
                                                                                })
                                                                            }
                                                                            {
                                                                                item.label!=='Last changes'
                                                                                && 
                                                                                Object.keys(rowItem).map((retentionItem,retentionIndex)=>{
                                                                                return <td style={{padding:'5px',fontSize:'13px',border:'1px solid rgb(200,200,200)'}}  key={retentionIndex}>
                                                                                    <div style={{whiteSpace:'pre-line'}}>
                                                                                        {typeof rowItem[retentionItem]==='string' && rowItem[retentionItem]}
                                                                                        {typeof rowItem[retentionItem]==='object' && rowItem[retentionItem].map((jono,jonoIndex)=>{return jono+ (jonoIndex===rowItem[retentionItem].length -1 ? " " : ", ")})}
                                                                                    </div>
                                                                                </td>
                                                                            })}
                                                                        </tr>
                                                            })}
                                                    </tbody>
                                                </table>
                                                {item.label==='Last changes' && item.value && item.value.length>3 && <div style={{display:'flex',alignItems:'center',fontSize:"13px",color:'orange',paddingTop:'10px'}} onClick={()=>{setEnhancedLastChanges(!enhancedLastChanges);refreshComponent(123);}}>{enhancedLastChanges ? 'Hide' : 'Show All'}  Data {enhancedLastChanges ? <ArrowUpward sx={{fontSize:'14px',marginLeft:'5px'}}/> : <ArrowDownward sx={{fontSize:'14px',marginLeft:'5px'}}/>}</div>}
                                            </div>
                                        </div>
                            }
                            if(item.type==='dict'){
                                return <div key={index} style={{display:'flex',marginBottom:"20px"}}>
                                    <div style={{width:'200px',fontWeight:'500',textTransform:'none'}}>{item.label}</div>
                                    {
                                        item.label==='ID Matching (Appsflyer)'
                                        &&
                                        <table style={{tableLayout:'fixed',width:'calc(100% - 200px)',borderSpacing:'0px'}}>
                                            <thead>
                                                <tr>
                                                    <td style={{width:'30%',fontWeight:'500',fontSize:'14px',padding:'5px',border:'1px solid rgb(200,200,200)'}}>GP_REFERER</td>
                                                    <td style={{width:'30%',fontWeight:'500',fontSize:'14px',padding:'5px',border:'1px solid rgb(200,200,200)'}}>ID_MATCHING</td>
                                                    <td style={{width:'30%',fontWeight:'500',fontSize:'14px',padding:'5px',border:'1px solid rgb(200,200,200)'}}>PROBABILISTIC</td>
                                                    <td width='100px' style={{padding:'5px',fontSize:'13px',textTransform:'none',border:'1px solid rgb(200,200,200)'}}></td>
                                                </tr>
                                            </thead>
            
                                            <tbody>
                                                <tr>
                                                    <td style={{padding:'5px',fontSize:'13px',textTransform:'none',border:'1px solid rgb(200,200,200)'}}>
                                                        {item.value.GP_REFERER}
                                                    </td>
                                                    <td style={{padding:'5px',fontSize:'13px',textTransform:'none',border:'1px solid rgb(200,200,200)'}}>
                                                        {item.value.ID_MATCHING}
                                                    </td>
                                                    <td style={{padding:'5px',fontSize:'13px',textTransform:'none',border:'1px solid rgb(200,200,200)'}}>
                                                        {item.value.PROBABILISTIC}
                                                    </td>
                                                    <td width='100px' style={{padding:'5px',fontSize:'13px',textTransform:'none',border:'1px solid rgb(200,200,200)',color:'orange',cursor:'pointer',textAlign:'center'}} onClick={(e)=>{e.stopPropagation();setShowAddCustomizationPopup(item.label);setNewCustomization({gp_referrer:item.value.GP_REFERER,id_matching:item.value.ID_MATCHING,probabilistic:item.value.PROBABILISTIC,resp_forced:true,isEdit:true})}}> 
                                                        Edit 
                                                    </td>

                                                </tr>
                                                
                                            </tbody>
                                        </table>
                                    }
                                    {item.label!=='ID Matching (Appsflyer)'
                                        &&
                                    <table style={{tableLayout:'fixed',width:'calc(100% - 200px)',borderSpacing:'0px'}}>
                                        <thead>
                                            <tr>
                                                <td style={{width:'15%',fontWeight:'500',fontSize:'14px',padding:'5px',border:'1px solid rgb(200,200,200)'}}>Channel</td>
                                                <td style={{width:'15%',fontWeight:'500',fontSize:'14px',padding:'5px',border:'1px solid rgb(200,200,200)'}}>Network</td>
                                                <td style={{width:'15%',fontWeight:'500',fontSize:'14px',padding:'5px',border:'1px solid rgb(200,200,200)'}}>Offer ID</td>
                                                <td style={{padding:'5px',fontWeight:'500',fontSize:'14px',border:'1px solid rgb(200,200,200)'}}>Value</td>
                                            </tr>
                                        </thead>
        
                                        <tbody>
                                            {Object.keys(item.value).map((proxyItem,proxyIndex)=>{
                                                return<tr key={proxyIndex}>
                                                    <td style={{padding:'5px',fontSize:'13px',textTransform:'none',border:'1px solid rgb(200,200,200)'}}>
                                                        {proxyItem.split('::')[0] === '*' ? 'All' : proxyItem.split('::')[0]}
                                                    </td>
                                                    <td style={{padding:'5px',fontSize:'13px',textTransform:'none',border:'1px solid rgb(200,200,200)'}}>
                                                        {proxyItem.split('::')[1] === '*' ? 'All' : proxyItem.split('::')[1]}
                                                    </td>
                                                    <td style={{padding:'5px',fontSize:'13px',textTransform:'none',border:'1px solid rgb(200,200,200)'}}>
                                                        {proxyItem.split('::')[2] === '*' ? 'All' : proxyItem.split('::')[2]}
                                                    </td>
                                                    <td style={{padding:'5px',fontSize:'13px',textTransform:'none',border:'1px solid rgb(200,200,200)',width:'60%'}}>
                                                        {(item.label==='RETENTION_CUSTOMIZATIONS' || item.label==='RR')
                                                        &&
                                                        <div style={{display:'flex',flexWrap:'wrap',flexDirection:'column',height:'100px',overflow:'auto'}}>
                                                            {Object.keys(item.value[proxyItem]).map((valueItem,valueIndex)=>{
                                                                return <div key={valueIndex} style={{paddingLeft:'10px',paddingBottom:'5px',paddingRight:'10px',textTransform:'none',borderRight:'1px solid rgb(200,200,200)'}}>
                                                                    <div style={{display:'flex',alignItems:'center'}}>
                                                                        <div style={{width:'25px'}}>
                                                                            {valueItem}
                                                                        </div>
                                                                        <div style={{display:'flex',alignItems:'center'}}>
                                                                            : &nbsp;{item.value[proxyItem][valueItem]}
                                                                        </div>
                                                                    </div>
                                                                    
                                                                    </div>
                                                            })}
                                                        </div>
                                                        }
                                                        {(item.label==='TZ-Customizations')
                                                        &&
                                                        <div style={{display:'flex',flexWrap:'wrap',flexDirection:'column',height:'100px',overflow:'auto'}}>
                                                            {Object.keys(item.value[proxyItem]).map((valueItem,valueIndex)=>{
                                                                return <div key={valueIndex} style={{paddingLeft:'10px',paddingBottom:'5px',paddingRight:'10px',textTransform:'none',borderRight:'1px solid rgb(200,200,200)'}}>
                                                                    <div style={{display:'flex',alignItems:'center'}}>
                                                                        <div style={{width:'25px'}}>
                                                                            {valueItem}
                                                                        </div>
                                                                        {/* <div style={{display:'flex',alignItems:'center'}}>
                                                                            : &nbsp;{item.value[proxyItem][valueItem]}
                                                                        </div> */}
                                                                    </div>
                                                                    
                                                                    </div>
                                                            })}
                                                        </div>
                                                        }
                                                        
                                                        
                                                        {item.label!=='RETENTION_CUSTOMIZATIONS'
                                                        &&
                                                        item.label!=='RR'
                                                        &&
                                                        item.label!=='TZ-Customizations'
                                                        &&
                                                        <div style={{display:'flex',flexWrap:'wrap'}}>
                                                            {item.value[proxyItem] && item.value[proxyItem].map((valueItem,valueIndex)=>{
                                                                return <div key={valueIndex} style={{paddingLeft:'2px',textTransform:'none'}}>
                                                                    {valueItem}{valueIndex === item.value[proxyItem].length - 1 ? '' : ', '}
                                                                    </div>
                                                            })}
                                                        </div>
                                                        }                                                      
                                                    </td>
                                                </tr>
                                            })}
                                            {getLocalStorageValue('adminPanelLoginData').allowedPages.includes('can_edit_customizations')
                                                &&
                                            item.label === 'GEO-Customizations'
                                            &&
                                            <tr>
                                                <td className='addNewCustomization' onClick={(e)=>{e.stopPropagation();setShowAddCustomizationPopup(item.label)}} colSpan={4}>+ Add New {item.label}</td>
                                            </tr>
                                            }
                                            {getLocalStorageValue('adminPanelLoginData').allowedPages.includes('can_edit_customizations')
                                                &&
                                            item.label === 'Retention Customization'
                                            &&
                                            <tr>
                                                <td className='addNewCustomization' onClick={(e)=>{e.stopPropagation();setShowAddCustomizationPopup(item.label)}} colSpan={4}>+ Add New {item.label}</td>
                                            </tr>
                                            }

                                            {getLocalStorageValue('adminPanelLoginData').allowedPages.includes('can_edit_customizations')
                                                &&
                                            item.label === 'Delay Event Customization'
                                            &&
                                            <tr>
                                                <td className='addNewCustomization' onClick={(e)=>{e.stopPropagation();setShowAddCustomizationPopup(item.label)}} colSpan={4}>+ Add New {item.label}</td>
                                            </tr>
                                            }
                                        </tbody>
                                    </table>
                                        }
                                      
                                    </div>     
                            }
                            if(item.type==='dictofdict'){
                                return <div key={index} style={{display:'flex',marginBottom:"20px"}}>
                                    <div style={{width:'200px',fontWeight:'500',textTransform:'none'}}>{item.label}</div>
                                        {item.label!=='TZ-Customization'
                                            &&
                                        <table style={{tableLayout:'fixed',width:'calc(100% - 200px)',borderSpacing:'0px'}}>
                                            <thead>
                                                <tr>
                                                    {item.label!=='Delay Event Customization'
                                                    &&
                                                    <td style={{width:'15%',fontWeight:'500',fontSize:'14px',padding:'5px',border:'1px solid rgb(200,200,200)'}}>Country</td>
                                                    }
                                                    {item.label==='Delay Event Customization' &&
                                                    <td style={{width:'15%',fontWeight:'500',fontSize:'14px',padding:'5px',border:'1px solid rgb(200,200,200)'}}>Event Name</td>
                                                    }
                                                    <td style={{width:'15%',fontWeight:'500',fontSize:'14px',padding:'5px',border:'1px solid rgb(200,200,200)'}}>Channel</td>
                                                    <td style={{width:'15%',fontWeight:'500',fontSize:'14px',padding:'5px',border:'1px solid rgb(200,200,200)'}}>Network</td>
                                                    <td style={{width:'15%',fontWeight:'500',fontSize:'14px',padding:'5px',border:'1px solid rgb(200,200,200)'}}>Offer ID</td>
                                                    {item.label!=='Delay Event Customization'
                                                        &&
                                                    <td style={{padding:'5px',fontWeight:'500',fontSize:'14px',border:'1px solid rgb(200,200,200)'}}>From</td>
                                                    }
                                                    {item.label!=='Delay Event Customization'
                                                        &&
                                                    <td style={{padding:'5px',fontWeight:'500',fontSize:'14px',border:'1px solid rgb(200,200,200)'}}>To</td>
                                                    }
                                                    {item.label==='Delay Event Customization'
                                                    &&
                                                    <td style={{padding:'5px',fontWeight:'500',fontSize:'14px',border:'1px solid rgb(200,200,200)'}}>Date</td>
                                                    }

                                                </tr>
                                            </thead>
            
                                            <tbody>
                                                {Object.keys(item.value).map((proxyItem,proxyIndex)=>{
                                                    return Object.keys(item.value[proxyItem]).map((proxyItemChild,proxyIndexChild)=>{
                                                        return<tr key={proxyIndex}>
                                                            <td style={{padding:'5px',fontSize:'13px',textTransform:'none',border:'1px solid rgb(200,200,200)'}}>
                                                                {proxyItem}
                                                            </td>
                                                            <td style={{padding:'5px',fontSize:'13px',textTransform:'none',border:'1px solid rgb(200,200,200)'}}>
                                                                {proxyItemChild.split('::')[0] === '*' ? 'All' : proxyItemChild.split('::')[0]}
                                                            </td>
                                                            <td style={{padding:'5px',fontSize:'13px',textTransform:'none',border:'1px solid rgb(200,200,200)'}}>
                                                                {proxyItemChild.split('::')[1] === '*' ? 'All' : proxyItemChild.split('::')[1]}
                                                            </td>
                                                            <td style={{padding:'5px',fontSize:'13px',textTransform:'none',border:'1px solid rgb(200,200,200)'}}>
                                                                {proxyItemChild.split('::')[2] === '*' ? 'All' : proxyItemChild.split('::')[2]}
                                                            </td>
                                                            {item.label!=='Delay Event Customization'
                                                                && 
                                                                <td style={{padding:'5px',fontSize:'13px',textTransform:'none',border:'1px solid rgb(200,200,200)'}}>
                                                                    {item.value[proxyItem][proxyItemChild].from}
                                                                </td>
                                                            }
                                                            {item.label!=='Delay Event Customization'
                                                                &&
                                                                <td style={{padding:'5px',fontSize:'13px',textTransform:'none',border:'1px solid rgb(200,200,200)'}}>
                                                                    {item.value[proxyItem][proxyItemChild].to}
                                                                </td>
                                                            }
                                                            {item.label==='Delay Event Customization'
                                                            &&
                                                            <td style={{padding:'5px',fontSize:'13px',textTransform:'none',border:'1px solid rgb(200,200,200)'}}>
                                                                {item.value[proxyItem][proxyItemChild]}
                                                            </td>
                                                            }
                                                        </tr>
                                                    })
                                                })}
                                                {getLocalStorageValue('adminPanelLoginData').allowedPages.includes('can_edit_customizations')
                                                    &&
                                                item.label === 'GEO-Customizations'
                                                &&
                                                <tr>
                                                    <td className='addNewCustomization' onClick={(e)=>{e.stopPropagation();setShowAddCustomizationPopup(item.label)}} colSpan={4}>+ Add New {item.label}</td>
                                                </tr>
                                                }
                                                {getLocalStorageValue('adminPanelLoginData').allowedPages.includes('can_edit_customizations')
                                                    &&
                                                item.label === 'Retention Customization'
                                                &&
                                                <tr>
                                                    <td className='addNewCustomization' onClick={(e)=>{e.stopPropagation();setShowAddCustomizationPopup(item.label)}} colSpan={4}>+ Add New {item.label}</td>
                                                </tr>
                                                }

                                                {getLocalStorageValue('adminPanelLoginData').allowedPages.includes('can_edit_customizations')
                                                    &&
                                                item.label === 'Delay Event Customization'
                                                &&
                                                <tr>
                                                    <td className='addNewCustomization' onClick={(e)=>{e.stopPropagation();setShowAddCustomizationPopup(item.label)}} colSpan={4}>+ Add New {item.label}</td>
                                                </tr>
                                                }
                                            </tbody>
                                        </table>
                                        }
                                      
                                    </div>     
                            }
                            return null;
                        })}
                        
                        {getLocalStorageValue('adminPanelLoginData').allowedPages.includes('can_edit_customizations')
                            &&
                            !response.error
                            &&
                            customizationFound.length>0
                            &&
                            customizationFound.map((item,index)=>{
                                return <div className='addNewCustomization' key={index} onClick={(e)=>{e.stopPropagation();setShowAddCustomizationPopup(item)}}>
                                        + Add New {item}
                                        </div>
                            })  
                        }
                        {response.error && response.error}
                    </div>
                }
                {showAddCustomizationPopup
                &&
                getPopup(showAddCustomizationPopup)
                }
            </div>

            {
                    showComparePopup
                    &&
                    code
                    &&
                    (code.oldCode || code.oldCode==='')
                    &&
                    (code.newCode || code.newCode==='')
                    &&
                    <div style={{position:'fixed',top:0,left:100,height:'100vh',width:'calc(100vw - 100px)',zIndex:'1000',display:'flex',alignItems:'center',justifyContent:'center',background:'rgba(100,100,100,0.3)'}} onClick={()=>{setShowComparePopup(false)}}>
                        <div style={{height:'90vh',width:'80vw',background:"white",border:'1px solid rgb(200,200,200)',position:'relative',boxShadow:'0 0 10px 5px rgb(200,200,200)'}} onClick={(e)=>{e.stopPropagation();}}>
                            <div style={{position:'sticky',top:0,fontWeight:'500',background:'white',display:'flex',justifyContent:'space-between',height:'50px',alignItems:'center',paddingLeft:'10px',paddingRight:'10px',borderBottom:'1px solid rgb(200,200,200)'}}>
                                <div>File Comparison &nbsp;{filename+'(old)'+" <-> " +filename+"(new)"}</div>
                                <div onClick={()=>{setShowComparePopup(false);}}><Close sx={{color:'rgb(100,100,100)'}}/></div>
                            </div>
                            <div style={{fontSize:'13px',maxHeight:'calc(90vh - 100px)',overflow:'auto'}}>
                                <Diff viewType="split" diffType='' hunks={diff.hunks}>
                                    {hunks =>
                                        hunks.map(hunk => (
                                            <Hunk key={hunk.content} hunk={hunk} />
                                        ))
                                    }
                                </Diff>
                            </div>
                        </div>
                    </div>
                }
        </div>
    )
}

export default CampaignInfoPage