import { Button, CircularProgress, IconButton, Tooltip } from '@mui/material';
import React, { useState } from 'react';
import TopbarV2 from '../../../components/topbarv2/topbarv2';
import './receiptUpdater.css';
import * as Constants from '../../../data/constants';
import FiddlerPng from '../../../img/fiddler.png';
import 'react-circular-progressbar/dist/styles.css';
import axios from 'axios';
import { getLocalStorageValue } from '../../../helper/util';

const ReceiptUpdater = () => {
    const [selectedLogsFile, setselectedLogsFile] = useState([])
    const [uploadingActive, setUploadingActive] = useState({})
    const [uploadLoader, setUploadLoader] = useState(false)
    const [uploadStatus, setUploadStatus] = useState({})
    const handleChooseLogFiles = (event) => {
        setselectedLogsFile(event.target.files)
    }
    const updateUploadingActiveProgress = (tempUploadingActive,name,progress) => {
        tempUploadingActive = {...tempUploadingActive,[name]:progress}
        setUploadingActive(tempUploadingActive)
        return tempUploadingActive
    }

    const uploadLogs = async() => {
        setUploadLoader(true)
        var tempUploadingActive = uploadingActive
        var tempUploadStatus = uploadStatus

        for(var index in selectedLogsFile){
            if(selectedLogsFile[index].name && selectedLogsFile[index].name.includes('.saz')){
                var filename = selectedLogsFile[index].name
                tempUploadStatus = {...tempUploadStatus,[filename]:{successCount:0}}
                tempUploadingActive = updateUploadingActiveProgress(tempUploadingActive,filename,10)
                const formData = new FormData();
                formData.append("logs", selectedLogsFile[index]);
                await axios({
                    method: "POST",
                    url: Constants.BACKENDHOST+"campaigns/updatereceipt",
                    headers:{
                        Authorization:getLocalStorageValue('adminPanelLoginData').token,
                        "Content-Type": "multipart/form-data",
                    },
                    data:formData,
                })
                .then(resp => {
                    if(resp.data.data[filename]){
                        // resp.data.data[filename] = {"status": {"crossroadsautoios.py": "Receipt Updated", "crossroadsios.py": "Receipt Updated"}}
                        if(typeof resp.data.data[filename].status === 'string'){
                            tempUploadStatus[filename] = resp.data.data[filename].status
                        }
                        else{
                            for(var scriptname in resp.data.data[filename].status){
                                if(resp.data.data[filename].status[scriptname]==='Receipt Updated'){
                                    tempUploadStatus[filename].successCount += 1
                                    tempUploadStatus[filename][scriptname] = resp.data.data[filename].status[scriptname]
                                }
                                else{
                                    tempUploadingActive = updateUploadingActiveProgress(tempUploadingActive,filename,'error')
                                    tempUploadStatus[filename][scriptname] = resp.data.data[filename].status[scriptname]
                                }
                            }
                        }
                        tempUploadingActive = updateUploadingActiveProgress(tempUploadingActive,filename,100)
                    }
                })
                .catch(err=>{
                    tempUploadingActive = updateUploadingActiveProgress(tempUploadingActive,filename,'error')
                    tempUploadStatus[filename] = 'Server Error'
                })
                setUploadStatus(tempUploadStatus)
            }
        }
        setUploadLoader(false)
    }   

    const getScriptsName = (scriptName) => {
        var returnString = ''
        for(var index in scriptName){
            if(index!=='successCount'){
                returnString += index + " : "
                returnString += scriptName[index].toString()
                returnString += '\n'
            }
        }
        return returnString
    }
    return (
        <div>
            <TopbarV2 title='IOS Receipt Updater'/>
            <div className='logFilesSelectorContainer'>
                <div style={{paddingLeft:'30px'}}>
                    <div>Add Logs Files</div>
                    <div style={{color:'rgb(100,100,100)',fontSize:'12px'}}>You can also select multiple log files.</div>
                </div>
                <div style={{paddingRight:'30px'}}>
                    <Button sx={{...Constants.CONTAINEDBUTTON,fontSize:'10px'}} onClick={()=>{document.getElementById('uploadLogFileInput').click();}}>
                        <input multiple accept='.saz' id='uploadLogFileInput' type="file" name="file" onChange={handleChooseLogFiles} hidden />
                        Add Logs
                    </Button>
                </div>
            </div>
            {selectedLogsFile
            &&
            Object.keys(selectedLogsFile).length > 0
            &&
            <div style={{marginLeft:'50px',marginRight:'50px',marginBottom:'20px',display:'flex',justifyContent:'space-between',alignItems:'center'}}>
                <Button sx={{...Constants.CONTAINEDBUTTON,fontSize:'10px',minHeight:'30px',minWidth:'100px'}} onClick={()=>{uploadLogs()}}>
                    {uploadLoader && <CircularProgress size={'14px'} sx={{color:'white'}}/>}
                    {!uploadLoader && 'Update Receipts'}
                </Button>
            </div>
            }
            <div className='selectedLogsFileContainer'>
                {Object.keys(selectedLogsFile).map((key,index)=>{
                    var item = selectedLogsFile[key]
                    return <div key={index} className='logsFileWrapper'>
                                <div style={{display:"flex",alignItems:'center'}}>
                                    <img style={{paddingRight:'10px'}} src={FiddlerPng} alt='' height={'30px'} width={'30px'}/>
                                    <div>
                                        <div>{item.name}</div>
                                        <div style={{fontSize:'11px',color:'rgb(100,100,100)'}}>{item.lastModifiedDate.toLocaleString('en-GB', { year:'numeric',month:'short',day:'numeric',hour:'numeric',minute:'numeric',second:'numeric'})}</div>
                                    </div>
                                </div>
                                <div style={{ marginTop:'-10px'}}>
                                    {

                                       (uploadingActive[item.name] === 100
                                        ||
                                        uploadingActive[item.name] === 'error')
                                        &&
                                        <div>
                                            {typeof uploadStatus[item.name] === 'string' && 
                                            <Tooltip title={uploadStatus[item.name]}>
                                                <IconButton size='small'>
                                                    <div>0/0</div>
                                                </IconButton>
                                            </Tooltip>
                                            
                                            }
                                            {typeof uploadStatus[item.name] === 'object' && 
                                                <Tooltip title={<div style={{whiteSpace:'pre-line'}}>{getScriptsName(uploadStatus[item.name])}</div>}>
                                                    <IconButton size='small'>
                                                        <div>{uploadStatus[item.name].successCount}/{Object.keys(uploadStatus[item.name]).length-1}</div>
                                                    </IconButton>
                                                </Tooltip>
                                            }
                                        </div>
                                    }
                                    {   
                                        (uploadLoader
                                        &&
                                        (!uploadingActive[item.name] 
                                            ||
                                        (uploadingActive[item.name] 
                                            && 
                                        uploadingActive[item.name] !== 100
                                            &&
                                        uploadingActive[item.name] !== 'error'
                                        ))
                                        )
                                        &&
                                        <div style={{paddingRight:'10px'}}>
                                            <CircularProgress style={{color:'orange',marginTop:'5px'}} size='20px'/>
                                        </div>
                                    }
                                </div>
                            </div>
                })}
            </div>
        </div>
    )
}

export default ReceiptUpdater