import React,{useState} from 'react';
import { SidebarData } from '../../data/sidebarTabs';
import './sidebarv2.css';
import {NavLink} from 'react-router-dom';
import SettingsIcon from '@mui/icons-material/Settings';
import appAnalyticsLogo from '../../img/AppanalyticsLogo.png';
import { getLocalStorageValue } from '../../helper/util';
const SidebarV2 = (props) => {
    var ambientColor = localStorage.getItem('ambientColor')
    var ambientColor2 = localStorage.getItem('ambientColor2')
    const [hoveredItem, setHoveredItem] = useState(false)
    const [manualUseState, setManualUseState] = useState(false)
    const [sidebartabs, setSidebartabs] = useState([])
    var allowedPages = getLocalStorageValue('adminPanelLoginData').allowedPages
    // allowedPages = ['/home','/campaigns/kpicheck','/campaigns/campaignInfo','/accounts/permissions']
    const checkAllowedPages = () => {
        var temptabs = []
        for(var index in SidebarData){
            var item = SidebarData[index]
            if(item.link && (allowedPages.includes(item.link) || item.permissionNotRequired)){
                temptabs.push(item)
            }
            var tempSubnav = []
            for(var subnavIndex in item.subNav){
                var subnavItem = item.subNav[subnavIndex]
                if(allowedPages.includes(subnavItem.link) || subnavItem.permissionNotRequired){
                    tempSubnav.push(subnavItem)
                }
            }
            if(tempSubnav.length>0){
                temptabs.push({...item,subNav:tempSubnav})
            }
        }
        setSidebartabs(temptabs)
    }
    if(!manualUseState){
        setManualUseState(true)
        checkAllowedPages()
    }
    return (
        <div className='sidebarV2Container' style={{width:(props.sidebarClose ? '100px' : '200px')}}>
            <div className='sidebarV2TopRow' onClick={()=>{props.setSidebarClose()}}>
                <img style={{transition:'all 0.5s linear'}} src={appAnalyticsLogo} alt='' width={props.sidebarClose ? '80px' : '120px'}/> 
            </div>
            <div className='sidebarV2TabsContainer' style={{width:(props.sidebarClose ? '80%' : '100%')}}>
                <svg viewBox='1 5 3 10'  height='100%' width={props.sidebarClose ? '80px' : '70px'}>
                    <defs>
                        <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
                            <stop offset="0%"   stopColor={ambientColor}/>
                            <stop offset="100%" stopColor={ambientColor2}/>
                        </linearGradient>
                    </defs>
                    <g>
                        <path id='sidebarPath' strokeWidth="10" d="M 1 1 L 1 19 C 2 17 4 18 4 15 C 4 12 4 8 4 5 C 4 2 2 3 1 1" />
                    </g>
                </svg>
                <div className='sidebarV2TabsWrapper'>
                    {sidebartabs.map((item,index)=>{
                        return <div key={index} className='sidebarV2Tabs'>
                                    <NavLink to={item.subNav ? item.subNav[0].link : item.link} className='sidebarV2Icon' onMouseEnter={()=>{setHoveredItem(item.title)}} onMouseLeave={()=>{setHoveredItem(false)}}>
                                        {item.icon}
                                    </NavLink>
                                    <NavLink to={item.subNav ? item.subNav[0].link : item.link} style={{textDecoration:'none'}}>
                                        <div className={props.sidebarClose ? 'sidebarV2TabNameHide sidebarV2TabName' : 'sidebarV2TabName'} onMouseEnter={()=>{setHoveredItem(item.title)}} onMouseLeave={()=>{setHoveredItem(false)}}>
                                            {item.title}
                                        </div>
                                    </NavLink>
                                    {hoveredItem === item.title 
                                        &&
                                        props.sidebarClose
                                        && 
                                        <div className='sidebarV2TabHoverContainer' onMouseEnter={()=>{setHoveredItem(item.title)}} onMouseLeave={()=>{setHoveredItem(false)}}>
                                            <div className='sidebarV2TabHoverWrapper'>
                                                {!item.subNav 
                                                    &&
                                                <NavLink className='sidebarV2TabHoverLink' to={item.subNav ? item.subNav[0].link : item.link}>{item.title}</NavLink>
                                                }
                                                {item.subNav 
                                                    &&
                                                    <div>
                                                        <div className='sidebarV2TabHoverTitle'>
                                                            {item.title}
                                                        </div>
                                                        <div style={{display:'flex',flexDirection:'column',marginTop:'10px'}}>
                                                            {item.subNav.map((childItem,childIndex)=>{
                                                                if(allowedPages.includes(childItem.link)){
                                                                    return <NavLink key={childIndex} className='sidebarV2TabHoverLink' to={childItem.link}>{childItem.title}</NavLink>
                                                                }
                                                                return null
                                                            })}
                                                        </div>  

                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    }
                                    {hoveredItem === item.title 
                                        &&
                                        !props.sidebarClose
                                        && 
                                        item.subNav 
                                        &&
                                        <div className='sidebarV2TabHoverContainer' style={{paddingLeft:'70%'}} onMouseEnter={()=>{setHoveredItem(item.title)}} onMouseLeave={()=>{setHoveredItem(false)}}>
                                            <div className='sidebarV2TabHoverWrapper' >
                                                <div>
                                                    <div className='sidebarV2TabHoverTitle'>
                                                        {item.title}
                                                    </div>
                                                    <div style={{display:'flex',flexDirection:'column',marginTop:'10px'}}>
                                                        {item.subNav.map((childItem,childIndex)=>{
                                                            return <NavLink key={childIndex} className='sidebarV2TabHoverLink' to={childItem.link}>{childItem.title}</NavLink>
                                                        })}
                                                    </div>  
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                    })}
                </div>
            </div>
            <div className='sidebarV2BottomRow'>
                <NavLink 
                    style={{textDecoration:"none",color:"black"}}
                    to={{
                        pathname:"/account/profile",
                        }}>
                    <SettingsIcon className='settingsButton' sx={{fontSize:'30px'}}/>
                </NavLink>
            </div>
        </div>
    )
}

export default SidebarV2