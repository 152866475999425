import React from 'react';
import './error.css';
import ErrorImage from './error.png'
const ErrorComponent = (props) => {
    return (
        <div className='errorContainer'>
            <img src={ErrorImage} alt='' width={'30%'}/>
            <div style={{fontWeight:500,fontSize:'26px',fontFamily:'monospace'}}>Something is wrong!</div>
            <div style={{width:"80%",display:'flex',alignItems:'center',justifyContent:'center'}}>
                <pre style={{color:'red',whiteSpace:'pre-wrap'}}>
                    {props.data}
                </pre>
            </div>
        </div>
    )
}

export default ErrorComponent